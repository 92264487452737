const DICTIONARY_PATH: string = 'builder.daysCopy';

export const builderDaysCopyComponentConfig = {
  dictionary: {
    uSelectS: 'uSelectS',
    message: `${DICTIONARY_PATH}.message`,
    apply: `${DICTIONARY_PATH}.apply`
  },
  checkboxes:  [
    { check: true, value: 'duplicate', name: `${DICTIONARY_PATH}.duplicate` },
    { check: false, value: 'rebuildNewDays', name: `${DICTIONARY_PATH}.rebuildNewDays` }
  ]
};
