<div class="builder-days-week__wrapper">
  <!--
  <div class="builder-days-week__close" (click)="close()">
    <div class="u-icon u-icon_pointer u-icon_app-builder-close"></div>
  </div>
  -->
  <div class="builder-days-week__main">
    <div class="builder-days-week__message">
      {{config().dictionary.message | translate}}
    </div>
    <div class="builder-days-week__list">
      @for (item of checkboxes; track item) {
        <div class="builder-days-week__item">
          <div class="builder-days-week__item-content">
            <div class="builder-days-week__item-checkbox">
              <u-checkbox class="u-checkbox_square" [ngModel]="item.check" (ngModelChange)="checkItem(item)"></u-checkbox>
            </div>
            <div class="builder-days-week__item-name">{{item.name | translate}}</div>
          </div>
        </div>
      }
    </div>
    <div class="builder-days-week__actions">
      <button class="builder-days-week__actions-btn" (click)="apply()">{{config().dictionary.apply | translate}}</button>
    </div> 
  </div>
</div>
