import { Component, HostBinding, Output, EventEmitter, signal, inject } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { cloneDeep } from 'lodash';

import { LocalizationService } from '@app/shared/services';
import { AppLanguage } from '@app/shared/models';
import { builderDaysDuplicateComponentConfig } from './builder-days-duplicate.component.config';

@Component({
  selector: 'app-builder-days-duplicate',
  templateUrl: './builder-days-duplicate.component.html',
  styleUrls: [ './builder-days-duplicate.component.scss', './builder-days-duplicate.component.rtl.scss' ]
})
export class BuilderDaysDuplicateComponent {
  @Output() action = new EventEmitter();

  @HostBinding('class') hostClasses: string = 'builder-days-duplicate';

  private readonly bsModalRef = inject(BsModalRef);
  private readonly localizationService = inject(LocalizationService);

  readonly #config = signal(cloneDeep(builderDaysDuplicateComponentConfig));

  readonly config = this.#config.asReadonly();

  days: number[] = [];
  isRtl = this.localizationService.isRtl();
  lang: AppLanguage = this.localizationService.getLanguage();

  close() {
    this.bsModalRef.hide();

    this.action.emit({ type: 'apply', content: { days: this.days } });
  }

  apply() {
    this.bsModalRef.hide();

    this.action.emit({ type: 'apply', content: { days: this.days } });
  }
}
