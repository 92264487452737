<div
  class="floating-area__container"
  [ngClass]="{
    'floating-area__container_move': isMoving()
  }"
  [ngStyle]="{
    'top': isFullScreenMode() ? '0' : position().y + 'px',
    'left': isRtl() && isFullScreenMode() ? 'auto' : (isFullScreenMode() ? '0' : position().x + 'px'),
    'right': isRtl() && isFullScreenMode() ? '0' : 'auto'
  }"
>
  <div
    class="floating-area__content"
    [ngClass]="{
      'floating-area__content_full-screen': isFullScreenMode(),
      'floating-area__content_move': isMoving()
    }"
    [style.width]="isFullScreenMode() ? '100vw' : size().w + 'px'"
    [style.height]="isFullScreenMode() ? '100vh' : size().h + 'px'"
  >
    <div
      class="floating-area__top-bar"
      draggable="true"
      (dragstart)="startDrag($event)"
    >
      <ng-container *ngTemplateOutlet="topBarTemplate()" />
    </div>

    <div class="floating-area__body">
      <ng-content />
    </div>
  </div>

  <div
    class="floating-area__resizers"
    [style.width]="isFullScreenMode() ? '0' : size().w + 'px'"
    [style.height]="isFullScreenMode() ? '0' : size().h + 'px'"
  >
    @if (!isFullScreenMode()) {
      <div
        (mousedown)="startResize($event, ['right'], 'x')"
        class="floating-area__resizer floating-area__resizer_right"
      ></div>
      <div
        (mousedown)="startResize($event, ['left'], 'x')"
        class="floating-area__resizer floating-area__resizer_left"
      ></div>
      <div
        (mousedown)="startResize($event, ['bottom'],'y')"
        class="floating-area__resizer floating-area__resizer_bottom"
      ></div>
      <div
        (mousedown)="startResize($event, ['top'], 'y')"
        class="floating-area__resizer floating-area__resizer_top"
      ></div>
      <div
        (mousedown)="startResize($event, ['top', 'right'], 'xy')"
        class="floating-area__resizer floating-area__resizer_top-right"
      ></div>
      <div
        (mousedown)="startResize($event, ['top', 'left'], 'xy')"
        class="floating-area__resizer floating-area__resizer_top-left"
      ></div>
      <div
        (mousedown)="startResize($event, ['bottom', 'left'], 'xy')"
        class="floating-area__resizer floating-area__resizer_bottom-left"
      ></div>
      <div
        #resizeCorner
        (mousedown)="startResize($event, ['bottom', 'right'], 'xy')"
        class="floating-area__resizer floating-area__resizer_bottom-right"
      ></div>
    }
  </div>
</div>
