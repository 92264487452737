<div class="table-page__container">
  <div class="table-page__table-container" [ngClass]="{ 'table-page__table-container_edit': tablePageService.isAddEditMode }">
    <app-grid-header
      *ngIf="!config.hideMetadata"
      [template]="config.gridHeaderTemplate || gridHeaderTemplate.Default"
      [filteredColumns]="tableConfig.showClearFilters !== false && columnsFilteredStore"
      [showButtons]="tableConfig.showButtons ?? (tablePageService.rows && tableConfig.showCounters && !tablePageService.isAddEditMode)"
      [showCounters]="tablePageService.rows && tableConfig.showCounters && !tablePageService.isAddEditMode"
      [selected]="tablePageService.selectedRows.length"
      [showSelected]="tableConfig.showSelected"
      [total]="columnsFilteredStore ? tablePageService.filteredRows().length : (tablePageService.totalCount || tablePageService.filteredRows().length)"
      [attentionMessage]="config.attentionMessage"
      [dictionary]="config.gridHeaderDictionary"
      (buttonClick)="onGridHeaderButtonClick($event)"
    ></app-grid-header>
    <div class="table-page-grid__container">
      <u-grid
        class="u-grid_app-table-page"
        *ngIf="uGridService.getUserIdFromLocalStorage()"
        [attr.id]="config.id"
        [rows]="tablePageService.rows || []"
        [columns]="tableConfig.columns"
        [resetColumnsFilter]="tablePageService.resetColumnsFilter()"
        [columnMode]="'force'"
        [headerHeight]="45"
        [headerOffset]="10"
        [emptyBodyTemplate]="tableConfig.emptyBodyTemplate"
        [stickyRow]="tablePageService.stickyRow"
        [stickyRowHeight]="tableConfig.stickyRowHeight || (tablePageService.stickyRowHeight$ | async) || 65"
        [footerHeight]="0"
        [sorts]="tableConfig.sorts"
        [rowHeight]="tableConfig.rowHeight || 55"
        [reorderable]="true"
        [scrollbarV]="tableConfig.rowHeight !== 'auto'"
        [scrollbarH]="true"
        [isRtl]="isRtl"
        [lang]="lang"
        [editMode]="'cell'"
        [selectionType]="'checkbox'"
        [saveTableProps]="tableConfig.saveTableProps ?? true"
        [showColumnsFilter]="!tableConfig.hideColumnsFilters"
        [saveColumnsFilters]="!tableConfig.hideColumnsFilters"
        [messages]="tablePageConfig.dictionary.uGrid | translate"
        [messagesTable]="(tableConfig.messagesTable || tablePageConfig.dictionary.uGridMessagesTable) | translate"
        [selected]="tablePageService.selectedRows"
        [rowIdentity]="rowIdentity"
        [tableName]="config.id"
        [scrollToRowIndex]="tablePageService.scrollToRowIndex"
        [rowClassObjects]="tablePageService.specificRowClassObjects()"
        [userId]="uGridService.getUserIdFromLocalStorage()"
        [disabledRowProp]="tableConfig.disabledRowProp"
        (selectRows)="setSelectedRows($event)"
        (closeIconClicked)="closeIconClicked.emit($event)"
        (columnsFilteredChange)="columnsFilteredChange($event)"
        (resetColumnsFilterChange)="resetColumnsFilterChange($event)"
        (editValuesClose)="editRowValue.emit($event)"
        (visibleColumns)="onVisibleColumns($event)"
        (sort)="onSortTable($event)"
        (scrollTable)="onScrollTable($event)"
        (filterRows)="onFilterRows($event)"
      ></u-grid>
    </div>
  </div>

  <div class="table-page__edit-panel" [ngClass]="{ 'table-page__edit-panel_hidden': !tablePageService.isAddEditMode }">
    <ng-container #editRowContainer></ng-container>
  </div>
</div>

<!-- ****** Cell Templates ****** -->
<ng-template #normalCell ngx-datatable-cell-template let-cell="value">
  <span class="cell-value" [title]="cell">{{cell}}</span>
</ng-template>

<ng-template #translateCell ngx-datatable-cell-template let-cell="value">
  <span class="cell-value" [title]="cell | translate">{{cell | translate}}</span>
</ng-template>

<ng-template #booleanCell ngx-datatable-cell-template let-cell="value">
  <span class="cell-value" [title]="(cell ? tablePageConfig.dictionary.yes : tablePageConfig.dictionary.no) | translate">{{(cell ? tablePageConfig.dictionary.yes : tablePageConfig.dictionary.no) | translate}}</span>
</ng-template>

<ng-template #numberCell ngx-datatable-cell-template let-value="value">
  {{value | number: appConstants.NUMBER_FORMAT}}
</ng-template>

<ng-template #editRowCell ngx-datatable-cell-template let-row="row" let-cell="value">
  <div class="table-page__edit-wrapper">
    <span class="table-page__edit-name cell-value" [title]="cell">{{cell}}</span>
    <button
      *ngIf="tableConfig.disabledRowProp ? !row[tableConfig.disabledRowProp] : true"
      uButton
      type="button"
      class="u-grid-row-visible"
      [disabled]="tableConfig.disableEditing"
      (click)="$event.stopPropagation(); rowEditClick(row)"
    >
      {{tablePageConfig.dictionary.edit | translate}}
    </button>

    @if (row.threeDotsMenuItems?.length) {
      <ng-container *ngTemplateOutlet="threeDotsMenu; context: { $implicit: row }" />
    }
  </div>
</ng-template>

<ng-template #editViewRowCell ngx-datatable-cell-template let-row="row" let-cell="value">
  <div class="table-page__edit-wrapper">
    <span class="table-page__edit-name cell-value" [title]="cell">{{cell}}</span>
    <button
      *ngIf="tableConfig.disabledRowProp ? !row[tableConfig.disabledRowProp] : true"
      uButton
      type="button"
      class="u-grid-row-visible"
      [disabled]="tableConfig.disableEditing"
      (click)="$event.stopPropagation(); rowEditClick(row)"
    >
      {{(row.isEditable ? tablePageConfig.dictionary.edit : tablePageConfig.dictionary.view) | translate}}
    </button>

    @if (row.threeDotsMenuItems?.length) {
      <ng-container *ngTemplateOutlet="threeDotsMenu; context: { $implicit: row }" />
    }
  </div>
</ng-template>

<div
  class="modal fade u-modal u-modal_content u-modal_app-add-edit-modal"
  bsModal
  #staticModal="bs-modal"
  [config]="{
    backdrop: true,
    animated: true,
    keyboard: false,
    ignoreBackdropClick: true
  }"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <ng-container #modalContainer></ng-container>
    </div>
  </div>
</div>

<ng-template #threeDotsMenu let-row>
  <u-three-dots-popover
    [class]="'table-page__three-dots u-grid-row-block'"
    [items]="row.threeDotsMenuItems"
    [placement]="isRtl ? [ 'bottom-right', 'bottom-left', 'top-right', 'top-left' ] : [ 'bottom-left', 'bottom-right', 'top-left', 'top-right' ]"
    [container]="'body'"
    [visibleClass]="true"
    [autoClose]="'outside'"
    (selectDotsItemAction)="selectThreeDotsItemAction($event, row)"
  />
</ng-template>
