import {
  Component,
  OnInit,
  HostBinding,
  Input,
  OnChanges,
  Output,
  EventEmitter,
  SimpleChanges,
  computed
} from '@angular/core';
import { UntypedFormArray } from '@angular/forms';
import { cloneDeep } from 'lodash';
import { UPopupService } from '@shift/ulib';

import { EventData, EventDataType } from '@app/shared/models';
import { InputShiftsService } from '@app/shared/services';
import { AuthDataSnapshotService } from '@app/auth/services';
import { AuthCustomerType, AuthModuleBranchesFeatureType } from '@app/auth/models';
import { inputShiftsComponentConfig } from './input-shifts.component.config';

@Component({
  selector: 'app-input-shifts',
  templateUrl: './input-shifts.component.html',
  styleUrls: [ './input-shifts.component.scss', './input-shifts.component.rtl.scss' ],
  providers: [ InputShiftsService, AuthDataSnapshotService ]
})
export class InputShiftsComponent implements OnInit, OnChanges {
  @Input() form: UntypedFormArray;
  @Input() config: object;

  @Output() eventData: EventEmitter<EventData> = new EventEmitter();

  @HostBinding('class') hostClasses: string = 'input-shifts';

  readonly branchesFeatureType = computed(() => this.authDataSnapshotService.modules()?.branches?.type);

  authModuleBranchesFeatureType = AuthModuleBranchesFeatureType;
  eventDataType = EventDataType;
  tracking = cloneDeep(inputShiftsComponentConfig.tracking.default);
  dictionary = cloneDeep(inputShiftsComponentConfig.dictionary.default);

  constructor(
    private authDataSnapshotService: AuthDataSnapshotService,
    private uPopupService: UPopupService,
    public inputShiftsService: InputShiftsService
  ) {}

  ngOnInit() {
    this.setCustomerAndDictionary(true);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.config && this.config) {
      this.setCustomerAndDictionary();
    }
  }

  setCustomerAndDictionary(initForm?: boolean) {
    const customer = this.authDataSnapshotService.customer();

    this.setCustomerCategoryTypeDictionary(customer.type);

    if (initForm) {
      this.inputShiftsService.initForm(this.form, customer.type);
    }
  }

  setCustomerCategoryTypeDictionary(customerType: AuthCustomerType) {
    this.tracking = cloneDeep(inputShiftsComponentConfig.tracking[customerType] || inputShiftsComponentConfig.tracking.default);
    this.dictionary = cloneDeep(inputShiftsComponentConfig.dictionary[customerType] || inputShiftsComponentConfig.dictionary.default);

    this.setDictionary();
  }

  setDictionary() {
    if (this.config && this.config['dictionary']) {
      Object.assign(this.dictionary, this.config['dictionary']);
    }
  }

  removeShift(index: number) {
    const optionsPopup = {
      message: this.dictionary.removeMessage,
      yes: this.dictionary.yes,
      no: this.dictionary.no
    };

    this.uPopupService.showMessage(
      optionsPopup,
      () => {
        this.inputShiftsService.removeShift({
          index
        });
      }
    );
  }

  sendEventData(type: EventDataType, name: string) {
    this.eventData.emit({
      type,
      name
    });
  }
}
