import { ContactType } from '@app/shared/models';

const DICTIONARY_PATH: string = 'accompanies.addEditInfo';

const generalYesNo = {
  yes: 'general.yes',
  no: 'general.no'
};

export const accompaniesAddEditInfoComponentConfig = {
  contacts: [
    { value: ContactType.Phone, name: 'general.contacts.phone' },
    { value: ContactType.Mobile, name: 'general.contacts.mobile' },
    { value: ContactType.Fax, name: 'general.contacts.fax' },
    { value: ContactType.Email, name: 'general.contacts.email' }
  ],
  dictionary: {
    generalDetails: `${DICTIONARY_PATH}.generalDetails`,
    contactDetails: `${DICTIONARY_PATH}.contactDetails`,
    first: `${DICTIONARY_PATH}.first`,
    last: `${DICTIONARY_PATH}.last`,
    id: `${DICTIONARY_PATH}.id`,
    address: `${DICTIONARY_PATH}.address`,
    birthday: `${DICTIONARY_PATH}.birthday`,
    percentageOfJob: `${DICTIONARY_PATH}.percentageOfJob`,
    status: `${DICTIONARY_PATH}.status`,
    comment: `${DICTIONARY_PATH}.comment`,
    contacts: {
      contactInformation: `${DICTIONARY_PATH}.contacts.item.contactInformation`
    },
    required: 'general.required',
    select: 'general.select',
    newContact: 'shared.contacts.newContact',
    uInputDate: 'uInputDate',
    uSelectS: 'uSelectS',
    confirmationPopups: {
      existAccompany: {
        message: `${DICTIONARY_PATH}.existAccompany`,
        ...generalYesNo
      },
      existPerson: {
        message: `${DICTIONARY_PATH}.existPerson`,
        ...generalYesNo
      },
      assignAccompany: {
        message: `${DICTIONARY_PATH}.assignAccompany`,
        ...generalYesNo
      },
      deleteContactConfirm: {
        message: `${DICTIONARY_PATH}.deleteContactConfirm`,
        ...generalYesNo
      }
    }
  }
};
