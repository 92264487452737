import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter, map, take, takeUntil } from 'rxjs/operators';

import { AuthDataService } from '@app/auth/services';
import { AuthModuleBranchesFeatureType } from '@app/auth/models';
import { ApiService } from '@app/shared/services';
import { Area, Areas, CityCombinations, CityCombinationsRule } from '@app/shared/models';
import { Branch, BranchesAddEdit, BranchDetails, BranchTabData, BranchesAddEditCopyAreasBody, BranchesAddEditCopyCityCombinationsBody } from '@app/branches/models';

@Injectable({
  providedIn: 'root'
})
export class BranchesService implements OnDestroy {
  private unsubscribe: Subject<void> = new Subject();
  private BASE_PATH: string = 'Branches';
  private BASE_PATH_TYPE: string = '';

  constructor(
    private apiService: ApiService,
    private authDataService: AuthDataService
  ) {
    this.initBasePathType();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private initBasePathType() {
    const basePathTypedFeatureTypes: AuthModuleBranchesFeatureType[] = [ AuthModuleBranchesFeatureType.Iec ];

    this.authDataService.modules$
      .pipe(
        map(modules => modules?.branches?.type),
        filter((data: AuthModuleBranchesFeatureType) => !!data && basePathTypedFeatureTypes.includes(data)),
        take(1),
        takeUntil(this.unsubscribe)
      )
      .subscribe(type => this.BASE_PATH_TYPE = `/${type}`);
  }

  getBranch(branchId: number): Observable<Branch> {
    return this.apiService.get(`${this.BASE_PATH}${this.BASE_PATH_TYPE}/${branchId}/Details`);
  }

  getBranchTab(branchId: number, apiPath: string): Observable<BranchTabData> {
    return this.apiService.get(`${this.BASE_PATH}${this.BASE_PATH_TYPE}/${branchId}/${apiPath}`);
  }

  getBranches(params?: object): Observable<Branch[]> {
    return this.apiService.get(`${this.BASE_PATH}${this.BASE_PATH_TYPE}/Table`, params);
  }

  deleteBranches(branchIds: number[]): Observable<void> {
    return this.apiService.delete(`${this.BASE_PATH}${this.BASE_PATH_TYPE}`, {}, { branchIds });
  }

  saveBranch(branch: BranchesAddEdit): Observable<number> {
    return this.apiService.post(`${this.BASE_PATH}${this.BASE_PATH_TYPE}`, branch);
  }

  updateBranch(branch: BranchDetails): Observable<number> {
    return this.apiService.put(`${this.BASE_PATH}${this.BASE_PATH_TYPE}`, branch);
  }

  isAssignedToRoutes(branchId: number): Observable<boolean> {
    return this.apiService.get(`${this.BASE_PATH}${this.BASE_PATH_TYPE}/${branchId}/IsAssignedToFutureRoutes`);
  }

  getBranchesForCopyAreas(branchId: number): Observable<{ id: number; name: string; }[]> {
    return this.apiService.get(`${this.BASE_PATH}${this.BASE_PATH_TYPE}/${branchId}/BranchesForCopyAreas`);
  }

  getBranchesForCopyCityCombinations(branchId: number): Observable<{ id: number; name: string; }[]> {
    return this.apiService.get(`${this.BASE_PATH}${this.BASE_PATH_TYPE}/${branchId}/BranchesForCopyCityCombinations`);
  }

  getBranchAreas(branchId: number): Observable<Areas> {
    return this.apiService.get(`${this.BASE_PATH}${this.BASE_PATH_TYPE}/${branchId}/Areas`);
  }

  getBranchCityCombinations(params: { branchId: number; customerId?: number; }): Observable<CityCombinations> {
    return this.apiService.get(`${this.BASE_PATH}${this.BASE_PATH_TYPE}/CityCombinations`, params);
  }

  copyCityCombinations(body: BranchesAddEditCopyCityCombinationsBody): Observable<{ rules: CityCombinationsRule[]; }> {
    return this.apiService.post(`${this.BASE_PATH}${this.BASE_PATH_TYPE}/CopyCityCombinations`, body);
  }

  copyAreas(body: BranchesAddEditCopyAreasBody): Observable<{ areas: Area[]; }> {
    return this.apiService.post(`${this.BASE_PATH}${this.BASE_PATH_TYPE}/CopyAreas`, body);
  }
}
