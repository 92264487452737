import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { SignalRService } from '@app/shared/services';
import { RoutesDailyMovePassengersMoveFinished } from '@app/routes/models';

@Injectable()
export class RoutesMovePassengersHubService extends SignalRService {
  BASE_PATH = 'routesMovePassengersHub';

  onPassengersMoveFinished(): Observable<RoutesDailyMovePassengersMoveFinished> {
    return this.onChannel('PassengersMoveFinished');
  }
}
