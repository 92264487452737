export enum FilesUploadError {
  Unsupported = 1,
  MaxFileSize = 2,
  MaxStorageCapacity = 3,
  ExistingFile = 4
}

export enum FilesEntityType {
  Passenger = 1,
  Accompany = 2,
  School = 3,
  Supplier = 4,
  TransportCompany = 5,
  ShuttleCompany = 6
}

export enum FilesContainerType {
  Customer = 1,
  ShuttleCompany = 2,
  Driver = 3,
  Car = 4
}

export interface FilesBaseParams {
  containerType: FilesContainerType;
  containerId?: number;
  entityType: FilesEntityType;
  entityId: string;
}

export interface FilesDownloadParams extends FilesBaseParams {
  fileType?: number;
  fileName: string;
}

export interface FilesDeleteParams extends FilesBaseParams {
  fileType?: number;
  fileName: string;
}

export interface FilesGetFromFolderItem {
  name: string;
  folderName: string;
}

export interface FilesUpload {
  errors: FilesUploadError[];
  fileName: string;
}
