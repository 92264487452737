const DICTIONARY_PATH: string = 'builder.mapFilter';

export const builderMapFilterComponentConfig = {
  dictionary: {
    showOnMap: `${DICTIONARY_PATH}.showOnMap`,
    passengers: `${DICTIONARY_PATH}.passengers`,
    stations: `${DICTIONARY_PATH}.stations`,
    cancel: `${DICTIONARY_PATH}.cancel`,
    apply: `${DICTIONARY_PATH}.apply`
  }
};
