import { Component, EventEmitter, inject, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { UPopupService } from '@shift/ulib';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { filter, finalize, take, takeUntil, tap } from 'rxjs/operators';
import { cloneDeep } from 'lodash';

import { AuthModulePassengersFeatureType } from '@app/auth/models';
import { ValidationService } from '@app/shared/services/validation.service';
import { TrackingService } from '@app/shared/services/tracking.service';
import { LocalizationService } from '@app/shared/services/localization.service';
import { LocalizedToastrService } from '@app/shared/services/localized-toast.service';
import { PassengersService } from '@app/passengers/services/passengers.service';
import { ModalActions } from '@app/shared/models';
import { AuthDataSnapshotService } from '@app/auth/services';
import { PassengerPickUpType, PassengersAddEditMode, PassengersTabName } from '@app/passengers/models';
import { PassengersAddEditDataService } from '@app/passengers/services/passengers-add-edit-data.service';
import { passengersConfig } from '@app/passengers/configs';

@Component({
  selector: 'app-passengers-army-add-edit',
  templateUrl: './passengers-army-add-edit.component.html',
  styleUrls: [ './passengers-army-add-edit.component.scss', './passengers-army-add-edit.component.rtl.scss' ],
  providers: [ AuthDataSnapshotService ]
})
export class PassengersArmyAddEditComponent implements OnInit, OnDestroy {
  @Output() action = new EventEmitter();

  private unsubscribe: Subject<void> = new Subject();

  addEditForm: UntypedFormGroup;
  editPassenger: any;
  buttons = cloneDeep(passengersConfig.addEditButtons).filter(button =>
    !button.permission || this.authDataSnapshotService.checkPermission(button.permission)
  );
  title: string;
  isRtl = this.localizationService.isRtl();
  isPassengerSavingActive: boolean;
  mode: PassengersAddEditMode = PassengersAddEditMode.Default;
  excludedRouteId: number;
  passengersTabName = PassengersTabName;

  constructor(
    private fb: UntypedFormBuilder,
    private toastr: LocalizedToastrService,
    private translate: TranslateService,
    private modalRef: BsModalRef,
    private passengersService: PassengersService,
    private validationService: ValidationService,
    private uPopupService: UPopupService,
    private trackingService: TrackingService,
    private localizationService: LocalizationService,
    private modalRemoveRef: BsModalRef,
    public passengersAddEditDataService: PassengersAddEditDataService,
    public authDataSnapshotService: AuthDataSnapshotService
  ) {}

  ngOnInit() {
    this.translate.get('passengers.addEdit.passengerTitle')
      .pipe(take(1))
      .subscribe((title: string) => {
        this.title = title;
      });

    this.initAddEditForm();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();

    this.passengersAddEditDataService.resetData();
  }

  private initAddEditForm() {
    this.addEditForm = this.fb.group({
      details: this.fb.group({
        id: [ 0 ],
        passengerId: [ 0 ],
        firstName: [ '', Validators.required ],
        lastName: [ '', Validators.required ],
        gender: [ 0 ],
        address: [ '' ],
        latitude: [ 0 ],
        longitude: [ 0 ],
        placeId: [ null ],
        pickUpType: [ PassengerPickUpType.FromStation, Validators.required ],
        birthDate: [ '' ],
        status: [ '', Validators.required ],
        comment: [ '' ],
        stationId: [ null ],
        stationAddress: this.fb.group({
          fullAddress: [],
          latitude: [],
          longitude: [],
          placeId: []
        }),
        departmentId: [ null ],
        branchId: [ 0 ],
        contacts: this.fb.array([]),
        eligibleForShuttle: [ true ],
        tagsList: [ [] ],
        refundEligibility: [ null ]
      })
    });

    const details = this.addEditForm.get('details');

    details.get('birthDate').setValidators([
      this.validationService.checkBirthday.bind(this)
    ]);

    if (this.editPassenger.editData) {
      this.updatePassenger(this.editPassenger.editData);
    }

    if (!this.authDataSnapshotService.managePassengersPermission()) {
      this.addEditForm.disable();
    }

    details.get('departmentId').valueChanges
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => this.track('Assing Command'));

    details.get('branchId').valueChanges
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => this.track('Assing Base'));
  }

  checkActions(action: ModalActions) {
    switch (action) {
      case ModalActions.Submit: {
        this.submit();

        break;
      }

      case ModalActions.Shutdown: {
        this.closeModal();

        break;
      }

      case ModalActions.Delete: {
        this.deletePassenger();

        break;
      }
    }
  }

  private track(name: string) {
    this.trackingService.track(`[${passengersConfig.trackingId}] - ${name}`);
  }

  updatePassenger(data: any) {
    this.addEditForm.get('details').patchValue({
      id: data.id,
      passengerId: data.passengerId,
      firstName: data.firstName,
      lastName: data.lastName,
      identity: data.identity,
      gender: data.gender,
      address: data.address,
      latitude: data.latitude,
      longitude: data.longitude,
      placeId: data.placeId,
      pickUpType: data.pickUpType,
      birthDate: data.birthDate,
      status: data.status,
      comment: data.comment,
      stationId: data.stationId,
      stationAddress: {
        latitude: data.stationAddress && data.stationAddress.latitude,
        longitude: data.stationAddress && data.stationAddress.longitude,
        placeId: data.stationAddress && data.stationAddress.placeId,
        fullAddress: data.stationAddress && data.stationAddress.fullAddress
      },
      departmentId: data.departmentId,
      branchId: data.branchId,
      eligibleForShuttle: data.eligibleForShuttle,
      tagsList: data.tagsList,
      refundEligibility: data.refundEligibility
    });

    if (data.contacts.length) {
      this.updatePassengerContacts(data.contacts);
    }
  }

  updatePassengerContacts(passengerContacts: any) {
    const passengerContactsForm = this.addEditForm.get('details.contacts') as UntypedFormArray;

    passengerContactsForm.controls.forEach((ob: any, i: number) => passengerContactsForm.removeAt(i));
    passengerContactsForm.controls = [];

    passengerContacts.forEach((passengerContact: any) => {
      const personContactForm = this.fb.group({
        type: [ passengerContact.type, [ Validators.required ] ],
        id: [ passengerContact.id ],
        value: [ passengerContact.value ],
        isConfirmed: [ passengerContact.isConfirmed ],
        oldValue: [ passengerContact.oldValue ]
      });

      const type = personContactForm.get('type');
      const data = personContactForm.get('value');

      this.validationService.updateValidationContactData(data, type.value);

      type.valueChanges
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((value: any) => {
          if (!value) { return; }

          this.validationService.updateValidationContactData(data, value);
        });

      passengerContactsForm.push(personContactForm);
    });
  }

  submit() {
    if (this.isPassengerSavingActive) {
      return;
    }

    this.track(this.editPassenger.editData ? 'Edited passenger saved' : 'Saved new');

    if (this.addEditForm.valid && !this.addEditForm.dirty) {
      this.modalRef.hide();

      return;
    }

    this.savePassenger();
  }

  private savePassenger() {
    if (!this.addEditForm.valid) {
      this.uPopupService.showErrorMessage({ message: passengersConfig.dictionary.error.passengerNotValid });

      return;
    }

    this.isPassengerSavingActive = true;

    const details = this.addEditForm.get('details').getRawValue();

    this.passengersAddEditDataService.savePassengerAndCheckRoutes(
      {
        ...this.addEditForm.getRawValue(),
        details: {
          ...details,
          stationAddress: details.stationAddress.fullAddress ? details.stationAddress : null
        }
      },
      AuthModulePassengersFeatureType.Soldier
    )
      .pipe(
        tap(data => {
          if (!data) {
            this.isPassengerSavingActive = false;
          }
        }),
        filter(data => !!data),
        finalize(() => this.isPassengerSavingActive = false),
        take(1),
        takeUntil(this.unsubscribe)
      )
      .subscribe(([ passenger, wasEditedByRoutesPopup ]) => {
        this.modalRemoveRef.hide();
        this.toastr.success(passengersConfig.dictionary.successful);

        this.track(this.addEditForm.get('details').value.id > 0 ? 'edit' : 'add');

        if (wasEditedByRoutesPopup && this.mode === PassengersAddEditMode.AssignToRoute) {
          const passengerRoutes = passenger.passengerRoutes;
          const removeFromCurrentRoute = this.excludedRouteId && passengerRoutes && passengerRoutes.length &&
            passengerRoutes.some(route => !route.keep && route.routeId === this.excludedRouteId);

          this.action.emit({
            type: removeFromCurrentRoute ? ModalActions.Delete : ModalActions.Submit,
            ...(removeFromCurrentRoute ? {} : { value: passenger })
          });

          return;
        }

        this.action.emit({ type: ModalActions.Submit, value: passenger });
      });
  }

  deletePassenger() {
    this.track(ModalActions.Delete);

    const passengerId = this.addEditForm.get('details.passengerId').value;

    if (passengerId <= 0) {
      this.modalRef.hide();
      return;
    }

    this.uPopupService.showMessage({
      message: passengersConfig.dictionary.addEdit.deleteConfirm,
      yes: passengersConfig.dictionary.yes,
      no: passengersConfig.dictionary.no
    }, () => {
      if (passengerId > 0) {
        this.passengersService.deletePassengers([ passengerId ])
          .pipe(take(1))
          .subscribe(() => {
            this.track(ModalActions.Delete);
            this.modalRef.hide();
            this.action.emit({ type: ModalActions.Delete });
          });
      }
    });
  }

  closeModal() {
    if (!this.addEditForm.dirty) {
      this.action.emit({ type: ModalActions.Close });

      return;
    }

    const popupOptions = {
      showXIcon: true,
      message: passengersConfig.dictionary.closeConfirm,
      yes: passengersConfig.dictionary.yes,
      no: passengersConfig.dictionary.no
    };

    if (this.addEditForm.touched) {
      if (this.uPopupService.popupRef) { return; }

      this.uPopupService.showMessage(popupOptions,
        () => {
          if (this.addEditForm.valid) {
            this.submit();
          } else {
            this.uPopupService.showErrorMessage({ message: passengersConfig.dictionary.error.passengerNotValid });
          }
        },
        () => {
          if (!this.editPassenger.editData) {
            this.modalRef.hide();
            this.action.emit({ type: ModalActions.Close });
          } else {
            this.modalRef.hide();
            this.action.emit({ type: ModalActions.Close, value: this.editPassenger && this.editPassenger.editData && this.editPassenger.editData.id });
          }
        }
      );
    } else {
      this.modalRef.hide();
      this.action.emit({ type: ModalActions.Close });
    }
  }

  updatePersonExist(person: any) {
    this.addEditForm.get('details').patchValue({
      passengerId: 0,
      id: person.id,
      firstName: person.firstName,
      lastName: person.lastName,
      identity: person.identity,
      address: person.address,
      birthDate: person.birthDate,
      comment: person.comment,
      latitude: person.latitude,
      longitude: person.longitude
    });

    if (person.contacts) {
      this.updatePassengerContacts(person.contacts);
    }
  }

  updatePassengerExist(id: any) {
    this.passengersService.getPassengerDetails(id)
      .pipe(take(1))
      .subscribe(passenger => {
        this.updatePassenger(passenger);
      });
  }

  onTabSelect(tabName: PassengersTabName, addEditForm: UntypedFormGroup) {
    this.passengersAddEditDataService.onTabSelect(tabName, addEditForm)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe();
  }
}
