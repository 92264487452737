<div
  class="builder-filter__close"
  (click)="closeAction.emit()"
>
  <div class="u-icon u-icon_pointer u-icon_app-builder-close"></div>
</div>
<div
  *ngIf="filterForm.get('dataType').value === builderFilterDataType.Passengers"
  class="builder-filter__name"
>
  {{config.dictionary.passengers | translate}}
</div>
<div
  *ngIf="filterForm.get('dataType').value === builderFilterDataType.Stations"
  class="builder-filter__name"
>
  {{config.dictionary.stations | translate}}
</div>
<div class="builder-filter__wrapper">
  <div class="builder-filter__item">
    <div class="builder-filter__group">
      <div class="builder-filter__group-name">{{config.dictionary.type | translate}}</div>
      <div class="builder-filter__group-field">
        <u-select-s
          [container]="container"
          [placement]="placement"
          [messages]="config.dictionary.uSelectS | translate"
          [items]="config.dataTypes"
          [formControl]="filterForm.get('dataType')"
          [emptyName]="config.dictionary.select | translate"
          [emptyRemove]="config.dictionary.removeSelect | translate"
          [empty]="false"
        ></u-select-s>
      </div>
    </div>
  </div>
  <div
    *ngIf="showSchools"
    class="builder-filter__item"
  >
    <div class="builder-filter__group">
      <div class="builder-filter__group-name">{{config.dictionary.schools | translate}}</div>
      <div class="builder-filter__group-field">
        <u-multiselect
          [disableTooltip]="false"
          [container]="container"
          [placement]="placement"
          [placeholder]="config.dictionary.select | translate"
          [messages]="config.dictionary.uMultiselect | translate"
          [items]="options.schools"
          [formControl]="filterForm.get('schoolIds')"
        ></u-multiselect>
      </div>
    </div>
  </div>
  <div
    *ngIf="showSchools"
    class="builder-filter__item"
  >
    <div class="builder-filter__group">
      <div class="builder-filter__group-name">{{config.dictionary.classes | translate}}</div>
      <div class="builder-filter__group-field">
        <u-multiselect
          [disableTooltip]="false"
          [container]="container"
          [placement]="placement"
          [placeholder]="config.dictionary.select | translate"
          [messages]="config.dictionary.uMultiselect | translate"
          [items]="options.classes"
          [formControl]="filterForm.get('classTypeIds')"
        ></u-multiselect>
      </div>
    </div>
  </div>
  <div
    *ngIf="showShifts && filterForm.get('dataType').value === builderFilterDataType.Passengers"
    class="builder-filter__item"
  >
    <div class="builder-filter__group">
      <div class="builder-filter__group-name">{{config.dictionary.shifts | translate}}</div>
      <div class="builder-filter__group-field">
        <u-multiselect
          [disableTooltip]="false"
          [container]="container"
          [placement]="placement"
          [placeholder]="config.dictionary.select | translate"
          [messages]="config.dictionary.uMultiselect | translate"
          [items]="options.shifts"
          [formControl]="filterForm.get('shiftIds')"
        ></u-multiselect>
      </div>
    </div>
  </div>
  <div
    *ngIf="filterForm.get('dataType').value === builderFilterDataType.Passengers && !!showBranches"
    class="builder-filter__item"
  >
    <div class="builder-filter__group">
      <div class="builder-filter__group-name">
        {{(branchesType === branchesTypes.Base ? config.dictionary.bases : config.dictionary.branches) | translate}}
      </div>
      <div class="builder-filter__group-field">
        <u-multiselect
          [disableTooltip]="false"
          [container]="container"
          [placement]="isRtl ? [ 'top-right' ] : [ 'top-left' ]"
          [messages]="config.dictionary.uMultiselect | translate"
          [placeholder]="config.dictionary.select | translate"
          [lazyLoadItems]="hasMasterCustomerFeature ? lazyLoadItems && lazyLoadItems[masterCustomerDataType.Branches] : null"
          [items]="hasMasterCustomerFeature ? null : options.branches"
          [formControl]="filterForm.get('branchIds')"
        ></u-multiselect>
      </div>
    </div>
  </div>
  <div class="builder-filter__item">
    <div class="builder-filter__group">
      <div class="builder-filter__group-name">{{config.dictionary.cities | translate}}</div>
      <div class="builder-filter__group-field">
        <u-input-cities
          [items]="cities()"
          [formControl]="filterForm.get('cities')"
          [updateOn]="filterForm.get('cities').updateOn"
          [placement]="placement"
          [container]="container"
          [multiselect]="true"
        ></u-input-cities>
      </div>
    </div>
  </div>
  <div
    *ngIf="filterForm.get('dataType').value === builderFilterDataType.Stations"
    class="builder-filter__item"
  >
    <div class="builder-filter__group">
      <div class="builder-filter__group-name">{{config.dictionary.assignedPassengers | translate}}</div>
      <div class="builder-filter__group-field">
        <u-select-s
          [container]="container"
          [placement]="placement"
          [messages]="config.dictionary.uSelectS | translate"
          [items]="options.assignedPassengers"
          [formControl]="filterForm.get('stationsFilterType')"
          [emptyName]="config.dictionary.select | translate"
          [empty]="false"
        ></u-select-s>
      </div>
    </div>
  </div>
  <div
    *ngIf="filterForm.get('dataType').value === builderFilterDataType.Passengers"
    class="builder-filter__item"
  >
    <div class="builder-filter__group">
      <div class="builder-filter__group-name">{{config.dictionary.assign | translate}}</div>
      <div class="builder-filter__group-field">
        <u-select-s
          [container]="container"
          [placement]="placement"
          [messages]="config.dictionary.uSelectS | translate"
          [items]="options.assigns"
          [formControl]="filterForm.get('assignmentType')"
          [emptyName]="config.dictionary.select | translate"
          [empty]="false"
        ></u-select-s>
      </div>
    </div>
  </div>
  <div
    *ngIf="showEligibleShuttle"
    class="builder-filter__item"
  >
    <div class="builder-filter__group">
      <div class="builder-filter__group-name">{{config.dictionary.eligibleShuttle | translate}}</div>
      <div class="builder-filter__group-field">
        <u-select-s
          [container]="container"
          [placement]="placement"
          [messages]="config.dictionary.uSelectS | translate"
          [items]="options.eligibleShuttles"
          [formControl]="filterForm.get('eligibleToShuttle')"
          [emptyName]="config.dictionary.select | translate"
          [emptyRemove]="config.dictionary.removeSelect | translate"
          [emptyValue]="null"
        ></u-select-s>
      </div>
    </div>
  </div>
  <div
    *ngIf="showAccompany"
    class="builder-filter__item"
  >
    <div class="builder-filter__group">
      <div class="builder-filter__group-name">{{config.dictionary.eligibleAcc | translate}}</div>
      <div class="builder-filter__group-field">
        <u-select-s
          [container]="container"
          [placement]="placement"
          [messages]="config.dictionary.uSelectS | translate"
          [items]="options.eligibleAccs"
          [formControl]="filterForm.get('needAccompany')"
          [emptyName]="config.dictionary.select | translate"
          [emptyRemove]="config.dictionary.removeSelect | translate"
          [emptyValue]="null"
        ></u-select-s>
      </div>
    </div>
  </div>
  <div
    *ngIf="showGender"
    class="builder-filter__item"
  >
    <div class="builder-filter__group">
      <div class="builder-filter__group-name">{{config.dictionary.gender | translate}}</div>
      <div class="builder-filter__group-field">
        <u-select-s
          [container]="container"
          [placement]="placement"
          [messages]="config.dictionary.uSelectS | translate"
          [items]="options.genders"
          [formControl]="filterForm.get('gender')"
          [emptyName]="config.dictionary.select | translate"
          [empty]="false"
        ></u-select-s>
      </div>
    </div>
  </div>
  <div
    *ngIf="filterForm.get('dataType').value === builderFilterDataType.Passengers"
    class="builder-filter__item"
  >
    <div class="builder-filter__group">
      <div class="builder-filter__group-name">
        {{(departmentsType === departmentsTypes.Section ? config.dictionary.commands : config.dictionary.departments) | translate}}
      </div>
      <div class="builder-filter__group-field">
        <u-multiselect
          [disableTooltip]="false"
          [container]="container"
          [placement]="isRtl ? [ 'top-right' ] : [ 'top-left' ]"
          [messages]="config.dictionary.uMultiselect | translate"
          [placeholder]="config.dictionary.select | translate"
          [lazyLoadItems]="hasMasterCustomerFeature ? lazyLoadItems && lazyLoadItems[masterCustomerDataType.Departments] : null"
          [items]="
            hasMasterCustomerFeature ? 
            null : 
            (
              builderDataService.buildModeRideOrders() ?
               (
                (builderDataService.modeAdd() || builderDataService.rideOrder.hasRequestingDeparment) ? 
                (builderDataService.addEditForm.get('details.regionCode').value ? builderDataStoreService.departmentsByMemberRegionCodesOptions[builderDataService.addEditForm.get('details.regionCode').value] : options.departments) : 
                builderDataStoreService.departmentsByRegionCodesOptions?.[builderDataService.addEditForm.get('details.regionCode').value]
               )
               :
               builderDataService.addEditForm.get('details.regionCode').value ? builderDataStoreService.departmentsByRegionCodesOptions[builderDataService.addEditForm.get('details.regionCode').value] : options.departments
            )
          "
          [formControl]="filterForm.get('departmentIds')"
        ></u-multiselect>
      </div>
    </div>
  </div>
  <div
    *ngIf="showTags && filterForm.get('dataType').value === builderFilterDataType.Passengers"
    class="builder-filter__item"
  >
    <div class="builder-filter__group">
      <div class="builder-filter__group-name">{{config.dictionary.tags | translate}}</div>
      <div class="builder-filter__group-field">
        <u-multiselect
          [disableTooltip]="false"
          [container]="container"
          [placement]="isRtl ? [ 'top-right' ] : [ 'top-left' ]"
          [messages]="config.dictionary.uMultiselect | translate"
          [placeholder]="config.dictionary.select | translate"
          [lazyLoadItems]="hasMasterCustomerFeature ? lazyLoadItems && lazyLoadItems[masterCustomerDataType.Tags] : null"
          [items]="hasMasterCustomerFeature ? null : options.tags"
          [formControl]="filterForm.get('tagIds')"
        ></u-multiselect>
      </div>
    </div>
  </div>
</div>
<div class="builder-filter__action">
  <button
    uButton
    *ngIf="filterForm.dirty"
    class="u-font-weight-bold"
    (click)="apply()"
  >
    {{config.dictionary.apply | translate}}
  </button>
</div>
