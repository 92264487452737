const DICTIONARY_PATH: string = 'builder.fullSettings';

export const builderFullSettingsComponentConfig = {
  dictionary: {
    yes: 'general.yes',
    no: 'general.no',
    calculateRoutes: `${DICTIONARY_PATH}.calculateRoutes`,
    apply: `${DICTIONARY_PATH}.apply`,
    close: {
      confirm: `${DICTIONARY_PATH}.close.confirm`
    }
  }
};
