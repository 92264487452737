import { Pipe, PipeTransform } from '@angular/core';

import { Activity, ActivityPassengerChange, ActivityPassengerChangesAffectRoutesType } from '@app/activities/models';

@Pipe({
  name: 'activitiesPassengerChangeDash',
  standalone: true
})
export class ActivitiesPassengerChangeDashPipe implements PipeTransform {
  transform(activity: Activity, passengerChange: ActivityPassengerChange): boolean {
    return (
      passengerChange.type === ActivityPassengerChangesAffectRoutesType.SchoolChanged &&
      !activity.additionalData.isRouteUpdateFailed &&
      passengerChange.doesAffectRoutes
    ) ||
    !passengerChange.doesAffectRoutes;
  }
}
