import { inject, Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { AuthService } from '@app/auth/services';

@Injectable()
export class AuthRefreshAccessTokenInterceptor implements HttpInterceptor {
  private readonly authService = inject(AuthService);

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const apiUrl = environment.config.serverUrlBase === '/' ? `${location.origin}/api` : `${environment.config.serverUrlBase}api`;

    return req.url && req.url.includes(apiUrl) ? of(this.authService.isExpiredAccessToken())
      .pipe(
        switchMap(expired => expired ? this.refreshAccessToken(req, next) : next.handle(req))
      ) : next.handle(req);
  }

  private refreshAccessToken(request: HttpRequest<any>, next: HttpHandler) {
    return this.authService.refreshAccessToken().pipe(switchMap(token => next.handle(this.addTokenHeader(request, token))));
  }

  private addTokenHeader(request: HttpRequest<any>, token: string) {
    return request.clone({ headers: request.headers.set('Authorization', `Bearer ${token}`) });
  }
}
