import { AuthModulePassengersFeatureType } from '@app/auth/models';
import { CustomerDataType } from '@app/shared/models';
import { RoutesMovePassengersFilterType } from '@app/routes/models';

const DICTIONARY_PATH: string = 'routes.movePassengers';

export const routesMovePassengersComponentConfig = {
  dictionary: {
    yes: 'general.yes',
    no: 'general.no',
    closeConfirm: 'general.closeConfirm',
    passengers: `${DICTIONARY_PATH}.passengers`,
    searchPassengers: `${DICTIONARY_PATH}.searchPassengers`,
    station: `${DICTIONARY_PATH}.station`,
    anonymousPassengers: `${DICTIONARY_PATH}.anonymousPassengers`,
    allDone: `${DICTIONARY_PATH}.allDone`,
    noResults: `${DICTIONARY_PATH}.noResults`,
    routes: `${DICTIONARY_PATH}.routes`,
    routesAmount: `${DICTIONARY_PATH}.routesAmount`,
    searchRoutes: `${DICTIONARY_PATH}.searchRoutes`,
    noCar: `${DICTIONARY_PATH}.noCar`,
    anonymousPassenger: `${DICTIONARY_PATH}.anonymousPassenger`,
    noAvailableRoutes: `${DICTIONARY_PATH}.noAvailableRoutes`,
    moveAmountPassengers: `${DICTIONARY_PATH}.moveAmountPassengers`,
    moveAmountStations: `${DICTIONARY_PATH}.moveAmountStations`,
    saveAndSendSC: `${DICTIONARY_PATH}.saveAndSendSC`,
    saveAndClose: `${DICTIONARY_PATH}.saveAndClose`,
    dateIsNotSelected: `${DICTIONARY_PATH}.dateIsNotSelected`,
    shortDays: `${DICTIONARY_PATH}.shortDays`,
    changeType: `${DICTIONARY_PATH}.changeType`,
    period: `${DICTIONARY_PATH}.period`,
    resetChanges: `${DICTIONARY_PATH}.resetChanges`,
    resetChangesPopup: {
      title: `${DICTIONARY_PATH}.resetChangesPopup.title`,
      reset: `${DICTIONARY_PATH}.resetChangesPopup.reset`,
      no: `${DICTIONARY_PATH}.resetChangesPopup.no`
    },
    filters: {
      placeholders: {
        branches: `${DICTIONARY_PATH}.filters.placeholders.branches`,
        institution: `${DICTIONARY_PATH}.filters.placeholders.institution`,
        activityCenters: `${DICTIONARY_PATH}.filters.placeholders.activityCenters`
      }
    },
    messages: {
      select: 'uSelectS',
      multiselect: 'uMultiselect'
    }
  },
  customerDataTypesByFeatureType: {
    [AuthModulePassengersFeatureType.Student]: [ CustomerDataType.Schools, CustomerDataType.Branches ]
  },
  filterTypes: [
    { value: RoutesMovePassengersFilterType.EducationalInstitution, name: `${DICTIONARY_PATH}.filters.types.educationalInst` },
    { value: RoutesMovePassengersFilterType.ActivityCenters, name: `${DICTIONARY_PATH}.filters.types.activityCenters` }
  ],
  defaultFilterTypesByFeatureType: {
    [AuthModulePassengersFeatureType.Student]: RoutesMovePassengersFilterType.EducationalInstitution
  }
};
