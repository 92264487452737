<div #routeTemplatesTable class="route-templates-table">
  <div
    *ngIf="mode === routeTemplatesMode.Default && showCreateNewRoutes && authDataSnapshotService.createRoutesPermission()"
    class="route-templates-table__new-routes"
    [ngClass]="{'route-templates-table__new-routes_disabled': disableCreateNewRoutes}"
    [uTooltip]="disableCreateNewRoutes ? (config.dictionary.newRoutesDisabled | translate) : ''"
    (click)="createNewRoutes()"
  >
    <span class="route-templates-table__new-routes-icon"></span>
    <span class="route-templates-table__new-routes-text">{{config.dictionary.newRoutes | translate}}</span>
  </div>

  <app-table-page
    class="table-page_route-templates"
    [ngClass]="{
      'table-page_white': mode === routeTemplatesMode.Readonly,
      'table-page_route-templates-filters': mode === routeTemplatesMode.Default && showCreateNewRoutes && authDataSnapshotService.createRoutesPermission(),
      'table-page_route-templates-modal': mode === routeTemplatesMode.Readonly
    }"
    [config]="config.tablePageConfig"
    (delete)="deleteRouteTemplates()"
    (refresh)="loadingDataService.updateRefresh()"
    (selectRows)="onSelectRows($event)"
  ></app-table-page>
</div>

<ng-template #emptyBodyTemplate>
  <div class="route-templates-table-empty-body">
    <div class="route-templates-table-empty-body__section">
      <span class="route-templates-table-empty-body__section-title">{{config.dictionary.emptyBody.firstSection.title | translate}}</span>

      <span class="route-templates-table-empty-body__section-row">
        <span class="route-templates-table-empty-body__section-row-text">{{config.dictionary.emptyBody.firstSection.body['1'] | translate}}</span>
      </span>
    </div>
    <div class="route-templates-table-empty-body__section">
      <span class="route-templates-table-empty-body__section-title">{{config.dictionary.emptyBody.secondSection.title | translate}}</span>

      <div class="route-templates-table-empty-body__section-row">
        <span class="route-templates-table-empty-body__section-row-arrow"></span>
        <span class="route-templates-table-empty-body__section-row-text">{{config.dictionary.emptyBody.secondSection.body['1']  | translate}}</span>
      </div>

      <div class="route-templates-table-empty-body__section-row">
        <span class="route-templates-table-empty-body__section-row-arrow"></span>
        <span class="route-templates-table-empty-body__section-row-text">{{config.dictionary.emptyBody.secondSection.body['2'] | translate}}</span>
      </div>

      <div class="route-templates-table-empty-body__section-row">
        <span class="route-templates-table-empty-body__section-row-arrow"></span>
        <span class="route-templates-table-empty-body__section-row-text">{{config.dictionary.emptyBody.secondSection.body['3'] | translate}}</span>
      </div>
    </div>
    <button
      uButton
      class="route-templates-table-empty-body__create"
      [size]="uButtonSize.Large"
      [view]="uButtonView.Primary"
      (click)="openBuilderRouteTemplates()"
    >
      {{config.dictionary.emptyBody.createTemplate | translate}}
    </button>
  </div>
  <span class="route-templates-table-empty-body-image"></span>
</ng-template>

<ng-template #routeTemplatesActivitiesCell ngx-datatable-cell-template let-row="row" let-value="value">
  <div
    (click)="openTemplateActivities(row.id)"
    class="route-templates-table-activities"
    [ngClass]="{ 'route-templates-table-activities_disabled': !value.length }"
  >
    <span class="route-templates-table-activities__icon"></span>
    <span class="route-templates-table-activities__counter" *ngIf="value | activitiesCounter">{{value | activitiesCounter}}</span>
  </div>
</ng-template>

<ng-template
  ngx-datatable-cell-template
  #routeTemplatesCodeCell
  let-row="row"
  let-value="value"
>
  <div class="route-templates-table-name-cell">
    <div class="route-templates-table-name-cell__text">{{value}}</div>
    <div
      *ngIf="mode === routeTemplatesMode.Default && !row.locked && !row.saveStatus"
      class="route-templates-table-name-cell__actions"
    >
      <button
        uButton
        type="button"
        class="u-grid-row-block"
        (click)="$event.stopPropagation(); rowEditClick(row); trackEvent('click on Edit')"
      >
        {{config.dictionary.edit | translate}}
      </button>
      <u-three-dots-popover
        [class]="'u-grid-row-block'"
        [items]="config.threeDotsItems"
        [placement]="isRtl ? [ 'bottom-right', 'bottom-left', 'top-right', 'top-left' ] : [ 'bottom-left', 'bottom-right', 'top-left', 'top-right' ]"
        [container]="'body'"
        [visibleClass]="true"
        [autoClose]="'outside'"
        (click)="trackEvent('click on 3 dots menu')"
        (selectDotsItemAction)="selectDotsItemAction($event, row)"
      ></u-three-dots-popover>
    </div>
    <div
      *ngIf="row.locked || row.saveStatus"
      class="route-templates-table-name-cell__progress"
    >
      <div 
        class="route-templates-table-name-cell__loader"
        *ngIf="row.locked && !row.saveStatus"
        #lockedByTooltip="uTooltip"
        [uTooltip]="lockedBy"
        [viewportElement]="routeTemplatesTable.querySelector('datatable-body')"
        [tooltipClass]="'u-tooltip_general'"
        [placement]="isRtl ? [ 'bottom-right', 'top-right' ] : [ 'bottom-left', 'top-left' ]"
        [container]="'body'"
        [triggers]="'manual'"
        (mouseenter)="openLockedByTooltip(row, lockedByTooltip)"
        (mouseleave)="closeLockedByTooltip(lockedByTooltip)"
      >
        <div class="loader loader_circle loader_all-events"></div>
        <ng-template #lockedBy>
          <div class="route-lock-state-clock">
            <div class="route-lock-state-clock__text">{{'general.doneBy' | translate}} {{routeLockState.lockedByUser}}</div>
            <div class="route-lock-state-clock__text">
              {{routeLockState.lockDateTime | momentConvert : appConstants.DATE_FORMAT_ISO : appConstants.DATE_FORMAT_BASE_SLASH_COMMA_TIME}}
            </div>
          </div>
        </ng-template>
      </div>
      <div
        class="route-templates-table-name-cell__save-status"
        *ngIf="row.saveStatus"
        [ngClass]="{
          'route-templates-table-name-cell__save-status_successful': row.saveStatus === routeSaveStatus.Successful,
          'route-templates-table-name-cell__save-status_failed': row.saveStatus === routeSaveStatus.Failed
        }"
      ></div>
    </div>
  </div>
</ng-template>

<ng-template
  ngx-datatable-cell-template
  #routeTemplatesTimeCell
  let-row="row"
  let-value="value"
>
  <div class="route-templates-table-time-cell" *ngIf="value.startTime || value.endTime">
    <span class="route-templates-table-time-cell__start" [ngClass]="{'route-templates-table-time-cell__start_highlighted': row.direction === direction.Backward}">
      {{value.startTime ? (value.startTime | momentConvert : appConstants.TIME_FORMAT_FULL : appConstants.TIME_FORMAT) : null}}
    </span>
    -
    <span class="route-templates-table-time-cell__end" [ngClass]="{'route-templates-table-time-cell__end_highlighted': row.direction === direction.Forward}">
      {{value.endTime ? (value.endTime | momentConvert : appConstants.TIME_FORMAT_FULL : appConstants.TIME_FORMAT) : null}}
    </span>
  </div>

  <span class="route-templates-table-time-cell__inactive" *ngIf="!value.startTime && !value.endTime">{{config.dictionary.inactive | translate}}</span>
</ng-template>

<ng-template #routeTemplatesTimeCellFilter ngx-datatable-header-filter-template let-item="item">
  <div class="route-templates-table-time-cell-filter" *ngIf="item">
    <span class="route-templates-table-time-cell-filter__start">
      {{item.startTime ? (item.startTime | momentConvert : appConstants.TIME_FORMAT_FULL : appConstants.TIME_FORMAT) : null}}
    </span>
    -
    <span class="route-templates-table-time-cell-filter__end">
      {{item.endTime ? (item.endTime | momentConvert : appConstants.TIME_FORMAT_FULL : appConstants.TIME_FORMAT) : null}}
    </span>
  </div>
  <div class="route-templates-table-time-cell-filter__inactive" *ngIf="!item">
    {{config.dictionary.inactive | translate}}
  </div>
</ng-template>

<ng-template
  ngx-datatable-cell-template
  #routeTemplatesDirectionCell
  let-value="value"
>
  {{config.dictionary.directions[value] | translate}}
</ng-template>

<ng-template #routeTemplatesDirectionCellFilter ngx-datatable-header-filter-template let-item="item">
  {{config.dictionary.directions[item] | translate}}
</ng-template>

<ng-template
  ngx-datatable-cell-template
  #routeTemplatesShuttleCompanyNameCell
  let-value="value"
>
  {{value || (config.dictionary.required | translate)}}
</ng-template>

<ng-template ngx-datatable-header-filter-template let-item="item" #routeTemplatesShuttleCompanyNameCellFilter>
  {{item || (config.dictionary.required | translate)}}
</ng-template>
