@for (period of formStore.controls; track period; let i = $index; let last = $last) {
  <div class="routes-periods__item">
    <app-routes-period
      [dayIndicatorConfig]="dayIndicatorConfig"
      [changes]="period.value"
      [showTypesChange]="false"
      [showInputType]="false"
      [showDatesForCustomPreset]="true"
      [authCustomer]="authCustomer"
      [activeDays]="activeDays"
      [isOwnedBySc]="isOwnedBySc"
      [inputValidURange]="period.valid"
      (updatePeriod)="updatePeriod($event, period)"
      (updateDates)="updateDates($event, period)"
    ></app-routes-period>
    <div class="routes-periods__item-buttons">
      @if (!last || i > 0) {
        <div class="u-icon u-icon_pointer u-icon_app-trash-with-hover routes-periods__item-button routes-periods__item-button_trash" (click)="removePeriod(i)"></div>
      }

      @if (last && !hidePlusIcon) {
        <div class="u-icon u-icon_pointer u-icon_app-add routes-periods__item-button" (click)="addPeriod(); clickOnPlusIcon.emit()"></div>
      }
    </div>

    <div class="routes-periods__item-comment">
      <input
        uInput
        type="text"
        [placeholder]="config.dictionary.comment | translate"
        [formControl]="period.get('comment')"
      >
    </div>
  </div>
}
