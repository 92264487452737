import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { filter, map, pairwise, startWith, take, takeUntil, tap } from 'rxjs/operators';
import { cloneDeep, isEqual } from 'lodash';
import { UMultiselectItem, UMultiselectItemChild, UMultiselectModeType } from '@shift/ulib';

import { InputBranch, InputBranchItem } from '@app/shared/models';
import { AuthDataSnapshotService } from '@app/auth/services';
import { inputBranchesComponentConfig } from './input-branches.component.config';

@Component({
  selector: 'app-input-branches',
  templateUrl: './input-branches.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ AuthDataSnapshotService ]
})
export class InputBranchesComponent implements OnChanges, OnInit, OnDestroy {
  @Input() infoTooltipClass: string = 'u-tooltip_app-input-branches';
  @Input() items: InputBranchItem[];
  @Input() lazyLoadItems: Observable<InputBranchItem[]>;
  @Input() valid: boolean = true;
  @Input() classUInput: string;
  @Input() classUDropdownContent: string;
  @Input() placement: string[] = [ 'bottom-left', 'bottom-right' ];
  @Input() container: string;
  @Input() control: UntypedFormControl;
  @Input() showSelectAll: boolean = true;
  @Input() placeholder: string = 'general.select';
  @Input() readonly: boolean;
  @Input() set disabled(value: boolean) {
    this._disabled = value;

    this.updateDisabledState();
  }

  @ViewChild('tooltip', { static: false }) tooltip: TemplateRef<any>;

  @HostBinding('class') hostClasses: string = 'input-branches';

  private invalidBranchValues: string[] = [];
  private unsubscribe: Subject<void> = new Subject();
  private _disabled: boolean;

  uMultiselectModeType = UMultiselectModeType;
  branchesControl: UntypedFormControl;
  branchesItems: UMultiselectItem[];
  branchesLazyLoadItems: Observable<UMultiselectItem[]>;
  config = cloneDeep(inputBranchesComponentConfig);

  get disabled(): boolean { return this._disabled; }

  constructor(private authDataSnapshotService: AuthDataSnapshotService) {}

  ngOnInit() {
    this.init();
    this.initBranchesControl();
    this.onControlValueChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.items) {
      this.clearInvalidBranchValues();

      this.branchesItems = this.items && this.getBranchesItems(this.items);
    }

    if (changes.lazyLoadItems) {
      this.branchesLazyLoadItems = this.lazyLoadItems && this.lazyLoadItems
        .pipe(
          map(data => {
            this.clearInvalidBranchValues();

            this.branchesItems = this.getBranchesItems(data);

            return this.branchesItems;
          })
        );
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private init() {
    const branchesFeatureType = this.authDataSnapshotService.modules()?.branches?.type;

    this.config.dictionary.assignAutomatically.info.title = this.config.dictionary.assignAutomatically.info.title[branchesFeatureType];
  }

  private clearInvalidBranchValues() {
    this.invalidBranchValues = [];
  }

  private updateInvalidBranchValues(value: string) {
    this.invalidBranchValues = [ ...this.invalidBranchValues, value ];
  }

  private getMultiselectItemValue(id: number, isChild?: boolean): string {
    return isChild ? `branch-${id}-auto` : `branch-${id}`;
  }

  private updateDisabledState() {
    if (this.branchesControl) {
      this.branchesControl[this.disabled ? 'disable' : 'enable']({ emitEvent: false });
    }
  }

  private initBranchesControl() {
    this.branchesControl = new UntypedFormControl({
      value: this.getMappedInputBranchValues(this.control.value),
      disabled: this.disabled
    });

    this.onBranchesControlValueChanges();
  }

  private onBranchesControlValueChanges() {
    this.branchesControl.valueChanges
      .pipe(
        filter(data => !!data),
        tap(data => {
          this.control.markAsDirty();
          this.control.patchValue(this.getInputBranches(data));
        }),
        startWith<null, null>(null),
        pairwise(),
        filter(([ previous, current ]) => {
          if (!previous || !(<[]>current).length) { return true; }

          const previousInvalidItems = (<[]>previous).filter(item => this.invalidBranchValues.includes(item));
          const currentInvalidItems = (<[]>current).filter(item => this.invalidBranchValues.includes(item));

          return previousInvalidItems.length !== currentInvalidItems.length;
        }),
        takeUntil(this.unsubscribe)
      )
      .subscribe(() => this.refreshBranchesItems());
  }

  private onControlValueChanges() {
    this.control.valueChanges
      .pipe(
        startWith<null, null>(null),
        pairwise(),
        filter(([ previous, current ]) => !isEqual(previous, current)),
        map(data => Array.isArray(data) && data[data.length - 1]),
        filter(data => !!data),
        takeUntil(this.unsubscribe)
      )
      .subscribe((branches: InputBranch[]) => this.updateBranchesControlValue(branches));
  }

  private updateBranchesControlValue(branches: InputBranch[]) {
    const value = this.getMappedInputBranchValues(branches);

    if (!isEqual(this.branchesControl.value, value)) {
      this.branchesControl.patchValue(value);
    }
  }

  private getMappedInputBranchValues(branches: InputBranch[]): string[] {
    return branches.reduce((acc, branch) => (
      [
        ...acc,
        this.getMultiselectItemValue(branch.branchId),
        ...(branch.assignAutomatically ? [ this.getMultiselectItemValue(branch.branchId, true) ] : [])
      ]
    ), []);
  }

  private getBranchesItemChild(branch: InputBranchItem): UMultiselectItemChild {
    const isSelected = this.control.value.some(item => item.branchId === branch.branchId && item.assignAutomatically);
    const value = this.getMultiselectItemValue(branch.branchId, true);
    const notAvailable = !branch.isAssignAutomaticallyAllowed && !isSelected;

    if (!branch.isAssignAutomaticallyAllowed) { this.updateInvalidBranchValues(value); }

    return {
      value,
      notAvailable,
      invalid: !branch.isAssignAutomaticallyAllowed && isSelected,
      name: this.config.dictionary.assignAutomatically.text,
      infoTooltipTemplateName: notAvailable ? null : 'tooltip'
    };
  }

  private getBranchesItems(branches: InputBranchItem[]): UMultiselectItem[] {
    return branches.map(branch => {
      const value = this.getMultiselectItemValue(branch.branchId);

      return {
        value,
        name: branch.name,
        subtitle: branch.customerName,
        notAvailable: false,
        invalid: false,
        children: [ this.getBranchesItemChild(branch) ]
      };
    });
  }

  private getRefreshedBranchesItemChildren(children: UMultiselectItemChild[], isParentNotAvailable?: boolean): UMultiselectItemChild[] {
    return children.map(child => {
      if (child.invalid || isParentNotAvailable) {
        const isChildSelected = this.branchesControl.value.includes(child.value);

        return {
          ...child,
          invalid: !isParentNotAvailable && isChildSelected,
          notAvailable: isParentNotAvailable || !isChildSelected,
          infoTooltipTemplateName: isParentNotAvailable || !isChildSelected ? null : child.infoTooltipTemplateName
        };
      }

      return child;
    });
  }

  private refreshBranchesItems() {
    this.branchesItems = this.branchesItems && this.branchesItems.map(branch => {
      if (!branch.invalid && !branch.children) { return branch; }

      const isSelected = this.branchesControl.value.includes(branch.value);
      const notAvailable = (branch.invalid && !isSelected) || branch.notAvailable;
      const childrenUnavailable = branch.children.every(child => child.invalid && !this.branchesControl.value.includes(child.value));

      return {
        ...branch,
        notAvailable: branch.invalid ? childrenUnavailable : notAvailable,
        invalid: branch.invalid && isSelected && !childrenUnavailable,
        ...(branch.children ? { children: this.getRefreshedBranchesItemChildren(branch.children, notAvailable) } : {})
      };
    });
  }

  private getInputBranches(values: string[]): InputBranch[] {
    const filteredValues = values.filter(value => /^branch-\d+$/.test(value));

    return filteredValues.reduce((acc, filteredValue) => {
      const branchId = Number(filteredValue.match(/\d+/)[0]);

      return [
        ...acc,
        {
          branchId,
          assignAutomatically: values.includes(this.getMultiselectItemValue(branchId, true))
        }
      ];
    }, []);
  }
}
