import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

import { Activity } from '@app/activities/models';
import { AppConstants } from '@app/shared/constants';

@Pipe({
  name: 'activitiesSearch',
  standalone: true
})
export class ActivitiesSearchPipe implements PipeTransform {
  transform(activities: Activity[], value: string, types: { [key: string]: string; }): Activity[] {
    if (value) {
      const searchValue = value.toLowerCase();

      return activities.filter(activity =>
        (types[activity.type] && types[activity.type].toLowerCase().includes(searchValue)) ||
        (activity.routeName && activity.routeName.toLowerCase().includes(searchValue)) ||
        (activity.routeCode && activity.routeCode.toLowerCase().includes(searchValue)) ||
        (activity.additionalData && activity.additionalData.comment && activity.additionalData.comment.toLowerCase().includes(searchValue)) ||
        moment(activity.startDateTime).format(AppConstants.DATE_FORMAT_BASE_SLASH_SIMPLE).toLowerCase().includes(searchValue) ||
        moment(activity.endDateTime).format(AppConstants.DATE_FORMAT_BASE_SLASH_SIMPLE).toLowerCase().includes(searchValue)
      );
    }

    return activities;
  }
}
