<div class="activities__header">
  <div class="activities__header-title">
    <div class="activities__header-title-content">
      <div class="activities__header-title-content-text">{{config.dictionary.title | translate}}</div>
      <div class="activities__header-title-content-line" *ngIf="activitiesDataService.showDate$ | async"></div>
      <div class="activities__header-title-content-date" *ngIf="activitiesDataService.showDate$ | async">{{activeDate$ | async}}</div>
    </div>
  </div>
  <div class="activities__header-icons">
    <div (click)="refresh()" class="activities__header-refresh"></div>
    <div (click)="closeActivities.emit()" class="activities__header-close"></div>
  </div>
</div>
<div class="activities__body" *ngIf="(activitiesWithFilters$ | async) as activitiesWithFilters">
  <div class="activities__search">
    <input
      type="text"
      uInput
      class="activities__search-field"
      [placeholder]="config.dictionary.search | translate"
      [formControl]="search"
    >
    <div
      class="activities__filter-icon activities__filter-icon_active"
      [uPopover]="popoverActivitiesFilters"
      [placement]="isRtl ? [ 'left-top' ] : [ 'right-top' ]"
      [autoClose]="false"
      [popoverClass]="'u-popover_app-activities-filter'"
      #popover="uPopover"
      (click)="initFiltersPopover(popover)"
    ></div>
    <ng-template #popoverActivitiesFilters>
      <app-activities-filters
        [activitiesFilters]="activitiesFilters$ | async"
        (applyFilters)="applyActivitiesFilters($event)"
        (clearFilters)="clearActivitiesFilters()"
        (closeFilters)="closeFiltersPopover()"
      ></app-activities-filters>
    </ng-template>
  </div>
  <div *ngIf="!activitiesWithFilters.length" class="activities__body-no-content">
    {{config.dictionary.noNotifications | translate}}
  </div>
  <div class="activities__items" *ngIf="activitiesWithFilters.length">
    <app-activities-item
      *ngFor="let activity of activitiesWithFilters | activitiesSearch: search.value : (config.dictionary.types | translate)"
      class="activities__item"
      [activity]="activity"
      [authUserInfo]="authDataSnapshotService.userInfo()"
      (toggle)="toggleActivity($event)"
      (delete)="deleteActivity($event)"
      (archive)="archiveActivity($event)"
      (sendEmails)="sendActivityEmails($event)"
      (downloadExcel)="downloadExcel($event)"
      (downloadFile)="downloadFile($event)"
    ></app-activities-item>
  </div>
  <div class="activities__actions">
    <button
      uButton
      class="u-font-weight-bold"
      [disabled]="(activeRouteId$ | async) === null"
      (click)="resetFilters()"
    >
      {{config.dictionary.resetFilters | translate}}
    </button>
  </div>
</div>
