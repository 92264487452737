import { Injectable } from '@angular/core';

import { aiChatConfig } from '@app/ai-chat/configs';
import { AiChatState } from '@app/ai-chat/models';

@Injectable()
export class AiChatStorageService {
  get(): AiChatState {
    return JSON.parse(localStorage.getItem(aiChatConfig.storageKey));
  }

  update(value: Partial<AiChatState>) {
    localStorage.setItem(aiChatConfig.storageKey, JSON.stringify({
      ...this.get(),
      ...value
    }));
  }
}
