import { HeaderMenuIconsTemplate } from '@app/shared/models';
import { BuilderFullStep } from '@app/builder/models';

const DICTIONARY_PATH: string = 'builder.full';

export const builderFullComponentConfig = {
  headerMenuIconsTemplate: {
    [BuilderFullStep.SelectPassengers]: HeaderMenuIconsTemplate.BuilderFull,
    [BuilderFullStep.RoutesResults]: HeaderMenuIconsTemplate.BuilderFullResults
  },
  dictionary: {
    resetFiltersBtn: 'general.resetFiltersBtn',
    map: {
      noSelectedPassengers: `${DICTIONARY_PATH}.map.noSelectedPassengers`
    },
    routes: {
      counter: `${DICTIONARY_PATH}.routes.counter`,
      createRoutes: `${DICTIONARY_PATH}.routes.createRoutes`
    },
    filterTitle: `${DICTIONARY_PATH}.filterTitle`,
    skippedPassengersInfo: {
      amount: `${DICTIONARY_PATH}.skippedPassengersInfo.amount`,
      getList: `${DICTIONARY_PATH}.skippedPassengersInfo.getList`
    },
    aiVsAlgoSummary: `${DICTIONARY_PATH}.aiVsAlgoSummary`
  }
};
