import { RoutePlannerCompareProp } from '@app/route-planner/models';

const DICTIONARY_PATH: string = 'builder.fullSummary';

const dictionary = {
  aiVsAlgoSummary: `${DICTIONARY_PATH}.aiVsAlgoSummary`,
  aiFoundSuggestions: `${DICTIONARY_PATH}.aiFoundSuggestions`,
  howDoesItWork: `${DICTIONARY_PATH}.howDoesItWork`,
  howDoesItWorkDescription: `${DICTIONARY_PATH}.howDoesItWorkDescription`,
  originalPlaning: `${DICTIONARY_PATH}.originalPlaning`,
  ai: `${DICTIONARY_PATH}.ai`,
  gap: `${DICTIONARY_PATH}.gap`,
  cost: `${DICTIONARY_PATH}.cost`,
  nis: `${DICTIONARY_PATH}.nis`,
  km: `${DICTIONARY_PATH}.km`,
  hrs: `${DICTIONARY_PATH}.hrs`,
  averageCostPerSeat: `${DICTIONARY_PATH}.averageCostPerSeat`,
  routes: `${DICTIONARY_PATH}.routes`,
  occupancy: `${DICTIONARY_PATH}.occupancy`,
  distance: `${DICTIONARY_PATH}.distance`,
  rideTime: `${DICTIONARY_PATH}.rideTime`,
  averageRideTimePerPassenger: `${DICTIONARY_PATH}.averageRideTimePerPassenger`,
  passengersAboveAverageTime: `${DICTIONARY_PATH}.passengersAboveAverageTime`
};

const rows = [
  {
    prop: RoutePlannerCompareProp.TotalCost,
    name: dictionary.cost,
    symbol: dictionary.nis
  },
  {
    prop: RoutePlannerCompareProp.CostPerSeat,
    name: dictionary.averageCostPerSeat,
    symbol: dictionary.nis
  },
  {
    prop: RoutePlannerCompareProp.RidesCount,
    name: dictionary.routes,
    symbol: ''
  },
  {
    prop: RoutePlannerCompareProp.OccupancyPercentage,
    name: dictionary.occupancy,
    symbol: '%'
  },
  {
    prop: RoutePlannerCompareProp.TotalDistanceKm,
    name: dictionary.distance,
    symbol: dictionary.km
  },
  {
    prop: RoutePlannerCompareProp.TotalTime,
    name: dictionary.rideTime,
    symbol: dictionary.hrs
  },
  {
    prop: RoutePlannerCompareProp.PassengerAverageTime,
    name: dictionary.averageRideTimePerPassenger,
    symbol: dictionary.hrs
  },
  {
    prop: RoutePlannerCompareProp.PassengersAboveAverageCount,
    name: dictionary.passengersAboveAverageTime,
    symbol: ''
  }
];

export const builderFullSummaryComponentConfig = {
  dictionary,
  rows
};
