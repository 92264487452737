import {
  Component,
  HostBinding,
  ViewChild,
  inject,
  signal
} from '@angular/core';
import { cloneDeep } from 'lodash';
import { UDropdownDirective } from '@shift/ulib';

import { LocalizationService, TrackingService } from '@app/shared/services';
import { DaysOfWeek } from '@app/shared/models';
import { BuilderDataService } from '@app/builder/services';
import { builderActionsComponentConfig } from './builder-actions.component.config';

@Component({
  selector: 'app-builder-actions',
  templateUrl: './builder-actions.component.html',
  styleUrls: [ './builder-actions.component.scss', './builder-actions.component.rtl.scss' ]
})
export class BuilderActionsComponent {
  @HostBinding('class')
  get hostClasses() {
    return {
      'builder-actions': true,
      'builder-actions_disabled': !this.builderDataService.isDetailsCustomerIdValid || !this.builderDataService.createOrEditRoutesAllowed()
    };
  }

  @ViewChild('dayActive') public dayActive: UDropdownDirective;

  private readonly trackingService = inject(TrackingService);
  private readonly localizationService = inject(LocalizationService);
  public readonly builderDataService = inject(BuilderDataService);

  readonly #config = signal(cloneDeep(builderActionsComponentConfig));

  readonly config = this.#config.asReadonly();

  readonly isRtl = this.localizationService.isRtl();

  prev() {
    if (this.builderDataService.modeEdit()) {
      this.trackingService.track('[Route Edit, Map tab] - click on previous day');
    }

    this.builderDataService.activeRidePrev();
  }

  next() {
    if (this.builderDataService.modeEdit()) {
      this.trackingService.track('[Route Edit, Map tab] - click on next day');
    }

    this.builderDataService.activeRideNext();
  }

  selectDate() {
    if (this.builderDataService.modeEdit()) {
      this.trackingService.track('[Route Edit, Map tab] - select ride date');
    }
  }

  setActiveDay(obj: { value: DaysOfWeek; disabled: boolean; }) {
    if (obj.disabled) {
      return;
    }

    this.dayActive.close();

    this.builderDataService.setActiveDay(obj.value);
  }
}
