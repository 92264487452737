import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, inject, input, model, OnInit, signal, TemplateRef, ViewChild } from '@angular/core';
import { DOCUMENT, NgClass, NgStyle, NgTemplateOutlet } from '@angular/common';

import { FloatingAreaPosition, FloatingAreaAnchorType, FloatingAreaDirectionType, FloatingAreaSize } from '@app/shared/models';

@Component({
  selector: 'app-floating-area',
  standalone: true,
  imports: [
    NgTemplateOutlet,
    NgClass,
    NgStyle
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './floating-area.component.html',
  styleUrl: './floating-area.component.scss'
})
export class FloatingAreaComponent implements OnInit {
  readonly minSize = input.required<FloatingAreaSize>();
  readonly isFullScreenMode = input.required<boolean>();
  readonly isRtl = input<boolean>(false);
  readonly topBarTemplate = input<TemplateRef<any>>();
  readonly position = model.required<FloatingAreaPosition>();
  readonly size = model.required<FloatingAreaSize>();

  @HostBinding('class') hostClasses: string = 'floating-area';

  @ViewChild('resizeCorner') resizeCornerRef: ElementRef;

  private readonly document = inject(DOCUMENT);

  readonly #isMoving = signal(false);

  readonly isMoving = this.#isMoving.asReadonly();

  ngOnInit() {
    this.checkPosition();
  }

  private checkPosition() {
    const position = { ...this.position() };
    const size = this.size();

    if (position.x + size.w > window.innerWidth) {
      position.x = window.innerWidth - size.w - 10;
    }

    if (position.x < 0) {
      position.x = 10;
    }

    if (position.y + size.h > window.innerHeight) {
      position.y = window.innerHeight - size.h - 10;
    }

    if (position.y < 0) {
      position.y = 10;
    }

    this.position.set(position);
  }

  startDrag(event: MouseEvent) {
    event.preventDefault();

    if (this.isFullScreenMode()) { return; }

    this.#isMoving.set(true);

    const mouseX = event.clientX;
    const mouseY = event.clientY;
    const lastPosition = this.position();

    const duringDrag = (e: MouseEvent) => {
      const dx = e.clientX - mouseX;
      const dy = e.clientY - mouseY;
      const position = { x: lastPosition.x + dx, y: lastPosition.y + dy };

      this.position.set(position);
    };

    const finishDrag = () => {
      this.checkPosition();

      this.document.removeEventListener('mousemove', duringDrag);
      this.document.removeEventListener('mouseup', finishDrag);

      this.#isMoving.set(false);
    };

    this.document.addEventListener('mousemove', duringDrag);
    this.document.addEventListener('mouseup', finishDrag);
  }

  startResize(event: MouseEvent, anchors: FloatingAreaAnchorType[], direction: FloatingAreaDirectionType) {
    event.preventDefault();

    const mouseX = event.clientX;
    const mouseY = event.clientY;
    const lastPosition = this.position();
    const dimensionWidth: number = this.resizeCornerRef.nativeElement.parentNode.offsetWidth;
    const dimensionHeight: number = this.resizeCornerRef.nativeElement.parentNode.offsetHeight;

    const duringResize = (e: MouseEvent) => {
      if (e.clientX < 0 || e.clientY < 0 || e.clientX > window.innerWidth || e.clientY > window.innerHeight) {
        return;
      }

      const position = { ...lastPosition };
      const size = { ...this.size() };
      let dw = dimensionWidth;
      let dh = dimensionHeight;

      if (direction === 'x' || direction === 'xy') {
        if (anchors.includes('left')) {
          dw += mouseX - e.clientX;
        } else if (anchors.includes('right')) {
          dw -= mouseX - e.clientX;
        }
      }

      if (direction === 'y' || direction === 'xy') {
        if (anchors.includes('top')) {
          dh += mouseY - e.clientY;
        } else if (anchors.includes('bottom')) {
          dh -= mouseY - e.clientY;
        }
      }

      if (anchors.includes('left')) {
        if (dw >= this.minSize().w) {
          position.x = lastPosition.x + e.clientX - mouseX;
          size.w = dw;
        } else {
          position.x = lastPosition.x + dimensionWidth - this.minSize().w;
          size.w = this.minSize().w;
        }
      }

      if (anchors.includes('top')) {
        if (dh >= this.minSize().h) {
          position.y = lastPosition.y + e.clientY - mouseY;
          size.h = dh;
        } else {
          position.y = lastPosition.y + dimensionHeight - this.minSize().h;
          size.h = this.minSize().h;
        }
      }

      if (anchors.includes('bottom') || anchors.includes('right')) {
        size.w = Math.max(dw, this.minSize().w);
        size.h = Math.max(dh, this.minSize().h);
      }

      this.position.set(position);
      this.size.set(size);
    };

    const finishResize = () => {
      this.document.removeEventListener('mousemove', duringResize);
      this.document.removeEventListener('mouseup', finishResize);
    };

    this.document.addEventListener('mousemove', duringResize);
    this.document.addEventListener('mouseup', finishResize);
  }
}
