import { Injectable } from '@angular/core';
import { UntypedFormControl, AbstractControl, Validators, ValidationErrors, ValidatorFn } from '@angular/forms';
import * as moment from 'moment';

import { isValidEmail } from '@app/shared/utils';
import { AppConstants } from '../constants';
import { ContactType } from '../models';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {
  static number(control: UntypedFormControl) {
    if (!control.value) {
      return null;
    }

    const regexp = /^[0-9]*$/;

    return regexp.test(control.value) ? null : {
      number: true
    };
  }

  static numberOrNull(control: UntypedFormControl) {
    if (control.value === null || !control.value) {
      return null;
    }

    const regexp = /^[0-9]*$/;

    return regexp.test(control.value) ? null : {
      number: true
    };
  }

  static equalsTo(value: number | string): ValidatorFn {
    return (control: UntypedFormControl): ValidationErrors | null => {
      if (!value || control.value === value) {
        return null;
      }

      return { notEqual: true };
    };
  }

  static email(control: UntypedFormControl) {
    if (!control.value) {
      return null;
    }

    return isValidEmail(control.value) ? null : {
      email: true
    };
  }

  static procedureNumber(control: UntypedFormControl) {
    if (!control.value) {
      return null;
    }

    const regexp = /^\d\d-(\d{4,})+$/;

    return regexp.test(control.value) ? null : {
      procedureNumber: true
    };
  }

  static minMobile(control: UntypedFormControl) {
    if (!control.value) {
      return null;
    }

    const regexp = /^\d\d{8,13}$/;

    return regexp.test(control.value) ? null : {
      minMobile: true
    };
  }

  static minPhone(control: UntypedFormControl) {
    if (!control.value) {
      return null;
    }

    const regexp = /^\d\d{4,14}$/;

    return regexp.test(control.value) ? null : {
      minPhone: true
    };
  }

  static floatNumber(control: UntypedFormControl) {
    if (!control.value) {
      return null;
    }

    const regexp = /^\d+(\.\d{1,})?$/;

    return regexp.test(control.value) ? null : {
      floatNumber: true
    };
  }

  static identifierCustomer(control: UntypedFormControl) {
    if (!control.value) {
      return null;
    }

    const regexp = /^\d{6}$/;

    return regexp.test(control.value) ? null : {
      invalidIdentifier: true
    };
  }

  static identity(control: UntypedFormControl): { invalidIdentifier: boolean } | null {
    if (!control.value) {
      return null;
    }

    if (control.value.toString().length > 9) {
      return { invalidIdentifier: true };
    }

    let sum = 0;
    let intID = parseInt(control.value, 10);

    if (!intID) {
      return { invalidIdentifier: true };
    }

    for (let i = 0; intID > 0; i++) {
      const value = (intID % 10) * (i % 2 + 1);

      sum += (value % 10) + Math.floor(value / 10);
      intID = Math.floor(intID / 10);
    }

    if (sum % 10 !== 0) {
      return { invalidIdentifier: true };
    }

    return null;
  }

  static licenseNumber(control: UntypedFormControl): { invalidLicenseNumber: boolean } | null {
    if (!control.value) {
      return null;
    }

    const regexp = /^[a-zA-Z0-9]{1,9}$/;

    return regexp.test(control.value) ? null : {
      invalidLicenseNumber: true
    };
  }

  static driversCode(control: UntypedFormControl): { invalidDriversCode: boolean } | null {
    if (!control.value) {
      return null;
    }

    const regexp = /^\d{0,10}$/;

    return regexp.test(control.value) ? null : {
      invalidDriversCode: true
    };
  }

  static scheduleTime(control: UntypedFormControl) {
    if (!control.value) {
      return null;
    }

    const invalid = control.value
      .some((ob) => ob.startTime && ob.endTime && moment(ob.startTime, AppConstants.TIME_FORMAT).isSameOrAfter(moment(ob.endTime, AppConstants.TIME_FORMAT)));

    return invalid ? { invalidScheduleTime: true } : null;
  }

  static timeIsNotZero(control: UntypedFormControl) {
    if (!control.value) {
      return null;
    }

    return control.value.split(':').map(time => Number(time)).some(time => time > 0) ? null : {
      timeZero: true
    };
  }

  static arrayIsNotEmpty(control: UntypedFormControl) {
    if (!control.value) {
      return null;
    }

    return control.value.length ? null : {
      emptyArray: true
    };
  }

  static areas(control: UntypedFormControl) {
    if (!control.value) {
      return null;
    }

    return control.value.some((area, areaIndex) =>
      !area.cityId ||
      !area.cityArea.name ||
      !area.cityArea.polygon.length ||
      control.value.some((obj, index) => obj.cityId === area.cityId && area.cityArea.name === obj.cityArea.name && areaIndex !== index)
    ) ? { required: true } : null;
  }

  static checkDateOfBirth(control: UntypedFormControl) {
    if (!control.value) {
      return { wrongDate: true };
    }

    const date = moment(control.value, AppConstants.DATE_FORMAT_BASE_DOT);

    return (AppConstants.BIRTH_DATE_REG_EXP.test(control.value) && date.isValid() && date.isSameOrBefore(moment(), 'day')) ?
      null : { wrongDate: true };
  }

  static greaterThanZero(control: UntypedFormControl) {
    if (!control.value) {
      return null;
    }

    return control.value > 0 ? null : { greaterThanZero: true };
  }

  checkStartDate(endDateControl: UntypedFormControl, startDateControl: UntypedFormControl)  {
    if (!endDateControl.value) { return null; }

    const startDate = moment(startDateControl.value).format(AppConstants.DATE_FORMAT_BASE_LINE);
    const endDate = moment(endDateControl.value).format(AppConstants.DATE_FORMAT_BASE_LINE);
    const isAfter = moment(endDate).isAfter(startDate);

    return isAfter ? null : { isAfter: true };
  }

  checkEndDate(startDateControl: UntypedFormControl, endDateControl: UntypedFormControl) {
    if (!startDateControl.value) { return null; }

    const startDate = moment(startDateControl.value).format(AppConstants.DATE_FORMAT_BASE_LINE);
    const endDate = moment(endDateControl.value).format(AppConstants.DATE_FORMAT_BASE_LINE);
    const isBefore = moment(startDate).isBefore(endDate);

    return isBefore ? null : { isBefore: true };
  }

  checkProcedureNumberTable = value => {
    const regexp = /^\d\d-(\d{4,})+$/;

    return regexp.test(value) && value !== '' ? null : {
      prop: 'procedureNumber',
      invalid: true
    };
  };

  checkStartDateTable(startDate, endDate) {
    if (!endDate) { return ; }

    const startDateFormat = moment(startDate).format(AppConstants.DATE_FORMAT_BASE_LINE);
    const endDateFormat = moment(endDate).format(AppConstants.DATE_FORMAT_BASE_LINE);
    const isAfter = moment(startDateFormat).isAfter(endDateFormat);

    return !isAfter ? null : {
      prop: 'startDate',
      invalid: true
    };
  }

  checkEndDateTable(startDate, endDate) {
    if (!endDate) { return ; }

    const startDateFormat = moment(startDate).format(AppConstants.DATE_FORMAT_BASE_LINE);
    const endDateFormat = moment(endDate).format(AppConstants.DATE_FORMAT_BASE_LINE);
    const isBefore = moment(endDateFormat).isAfter(startDateFormat);

    return isBefore ? null : {
      prop: 'endDate',
      invalid: true
    };
  }

  checkContractScope(value, prop) {
    return !value ? { prop: prop, invalid: true } : null;
  }

  updateValidationContactData(control: AbstractControl, value: any) {
    const isEmail: boolean = ContactType.Email === value;

    if (!isEmail) {
      this.setValidators([ ValidationService.number, ValidationService.minMobile ], control);
    } else {
      this.setValidators(ValidationService.email, control);
    }
  }

  removeAllValidators = (control: AbstractControl) => {
    control.clearValidators();
    control.updateValueAndValidity();
  };

  setValidators = (validators: any, control: AbstractControl) => {
    this.removeAllValidators(control);

    control.setValidators([ Validators.required ].concat(validators));

    control.updateValueAndValidity();
  };

  checkIdentifierPersonContactsAccompany = (personContacts: UntypedFormControl, control: UntypedFormControl) => {
    const allRemove = personContacts.value.length <= 0;

    return personContacts.value.length > 0 && !allRemove || !!control.value ? null : { required: true };
  };

  startTimeBeforeEndTime(startTime: UntypedFormControl, endTime: UntypedFormControl) {
    if (!endTime.value) {
      return null;
    }

    return startTime.value && endTime.value && moment(startTime.value, AppConstants.TIME_FORMAT).isSameOrAfter(moment(endTime.value, AppConstants.TIME_FORMAT)) ? { invalidTime: true } : null;
  }

  checkBirthday(control: UntypedFormControl) {
    const  { value } = control;

    return value === false ? { wrongDate: true } : false;
  }

  conditionalValidator(predicate: Function, validator: Function) {
    return (formControl => {
      if (!formControl.parent) {
        return null;
      }

      if (predicate()) {
        return validator(formControl);
      }

      return null;
    });
  }
}
