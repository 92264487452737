import { Component, OnInit, HostBinding, OnDestroy, Output, EventEmitter, inject, DestroyRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CdkDragDrop, CdkDragStart } from '@angular/cdk/drag-drop';
import { from, Observable, throwError } from 'rxjs';
import { catchError, finalize, map, switchMap, tap } from 'rxjs/operators';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as moment from 'moment';
import { cloneDeep } from 'lodash';
import { UMultiselectItem, UPopupService, USelectSItem } from '@shift/ulib';

import { CustomerDataService, LocalizationService, HeaderDataService } from '@app/shared/services';
import { MovePassengersRouteParticipantType, CustomerData, CustomerDataType } from '@app/shared/models';
import { AppConstants } from '@app/shared/constants';
import { AuthDataSnapshotService } from '@app/auth/services';
import {
  AuthCustomer,
  AuthModulePassengersFeatureType,
  AuthModuleRoutesTableFeatureMovePassengersDefaultOption,
  AuthModuleRoutesTableFeatureType
} from '@app/auth/models';
import { RouteStationsMoveService, RoutesMovePassengersDataService } from '@app/routes/services';
import {
  RoutesChangeType,
  RoutesMovePassengersActionParamsStations,
  RoutesMovePassengersFilterType,
  RoutesMovePassengersPassenger,
  RoutesMovePassengersRoute,
  RoutesMovePassengersRouteInitData,
  RoutesMovePassengersSaveActionType,
  RoutesMovePassengersStation,
  RouteDirection
} from '@app/routes/models';
import { routesMovePassengersConfig } from '@app/routes/configs';
import { routesMovePassengersComponentConfig } from './routes-move-passengers.component.config';

@Component({
  selector: 'app-routes-move-passengers',
  templateUrl: './routes-move-passengers.component.html',
  styleUrls: [ './routes-move-passengers.component.scss', './routes-move-passengers.component.rtl.scss' ],
  providers: [ RoutesMovePassengersDataService, AuthDataSnapshotService ]
})
export class RoutesMovePassengersComponent implements OnInit, OnDestroy {
  @Output() saved: EventEmitter<void> = new EventEmitter();

  @HostBinding('class') hostClasses: string = 'routes-move-passengers';

  private readonly destroyRef = inject(DestroyRef);
  private readonly bsModalRef = inject(BsModalRef);
  private readonly uPopupService = inject(UPopupService);
  private readonly localizationService = inject(LocalizationService);
  private readonly authDataSnapshotService = inject(AuthDataSnapshotService);
  private readonly customerDataService = inject(CustomerDataService);
  private readonly headerDataService = inject(HeaderDataService);
  private readonly routeStationsMoveService = inject(RouteStationsMoveService);
  public readonly routesMovePassengersDataService = inject(RoutesMovePassengersDataService);

  sourceRoute: RoutesMovePassengersRouteInitData;
  authCustomer: AuthCustomer;
  direction = RouteDirection;
  movePassengersRouteParticipantType = MovePassengersRouteParticipantType;
  appConstants: AppConstants = AppConstants;
  searchRoutes: string = '';
  searchPassengers: string = '';
  dragContent: string;
  isRtl: boolean = this.localizationService.isRtl();
  isPassengerDragging: boolean;
  featureType: AuthModulePassengersFeatureType;
  movePassengersDefaultOptions: AuthModuleRoutesTableFeatureMovePassengersDefaultOption[];
  authModulePassengersFeatureType = AuthModulePassengersFeatureType;
  routesMovePassengersFilterType = RoutesMovePassengersFilterType;
  customerDataType = CustomerDataType;
  customerData: { [key in keyof CustomerDataType]: (USelectSItem | UMultiselectItem)[]; };
  config = cloneDeep(routesMovePassengersComponentConfig);

  ngOnInit() {
    this.init();
  }

  ngOnDestroy() {
    this.resetRouteEditSession();
  }

  private resetRouteEditSession() {
    this.routesMovePassengersDataService.stopRouteEditSession();
    this.routesMovePassengersDataService.removeSession();
  }

  private getCustomerData(featureType: AuthModulePassengersFeatureType, options: AuthModuleRoutesTableFeatureMovePassengersDefaultOption[]) {
    return (
      this.config.customerDataTypesByFeatureType[featureType] ?
        this.customerDataService.getCustomerData({ types: this.config.customerDataTypesByFeatureType[featureType] })
        :
        this.routeStationsMoveService.getBranches().pipe(map(branches => ({ branches }))) as Observable<CustomerData>
    )
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        tap(data => {
          this.initCustomerData(data);
          this.initForm(options);
        })
      );
  }

  private initCustomerData(data: CustomerData) {
    this.customerData = Object.entries(data).reduce((acc, [ key, value ]) => ({
      ...acc,
      [key]: value.map(({ id, name }) => ({ value: id, name }))
    }), {} as { [key in keyof CustomerDataType]: (USelectSItem | UMultiselectItem)[]; });
  }

  private initForm(options: AuthModuleRoutesTableFeatureMovePassengersDefaultOption[]) {
    const activeDate = this.headerDataService.getActiveDate();
    const isOwnedBySc = this.routesMovePassengersDataService.isOwnedBySc();

    this.routesMovePassengersDataService.initForm(
      {
        typeChange: !isOwnedBySc ?
          RoutesChangeType.Unplanned : options?.includes(AuthModuleRoutesTableFeatureMovePassengersDefaultOption.ChangeTypePermanent) ? RoutesChangeType.Planned : null,
        dates: !isOwnedBySc || options?.includes(AuthModuleRoutesTableFeatureMovePassengersDefaultOption.PeriodToday) ? [ activeDate ] : [],
        dateFrom: this.sourceRoute.routeStartDate,
        dateTo: this.sourceRoute.routeEndDate,
        type: '',
        availablePresets: routesMovePassengersConfig.defaultForm.availablePresets,
        checkDaysActive: [ moment(activeDate).get('day') ],
        checkDaysAvailable: this.sourceRoute.days,
        typeFilter: this.config.defaultFilterTypesByFeatureType[this.featureType] || null,
        branchIds: this.getFormDefaultBranchIds()
      }
    );
  }

  private getFormDefaultBranchIds(): number[] {
    if (routesMovePassengersConfig.mandatoryFilterFeatureTypes.includes(this.featureType)) {
      return [];
    }

    return this.customerData?.[CustomerDataType.Branches].map(({ value }) => value);
  }

  init() {
    const modules = this.authDataSnapshotService.modules();
    const routesTableFeatureTypeShuttleCompany = modules?.routesTable?.type === AuthModuleRoutesTableFeatureType.ShuttleCompany;

    this.featureType = modules?.passengers?.type;
    this.movePassengersDefaultOptions = modules?.routesTable?.movePassengersDefaultOptions;

    this.routesMovePassengersDataService.updateIsOwnedBySc(!routesTableFeatureTypeShuttleCompany || this.sourceRoute.isOwnedBySc);
    this.routesMovePassengersDataService.updatePassengerFeatureType(this.featureType);
    this.routesMovePassengersDataService.trackAction('click on move passenger');
    this.routesMovePassengersDataService.initSaveButton(this.authCustomer.type);

    this.initRoute(this.sourceRoute);
  }

  private initRoute(route: RoutesMovePassengersRouteInitData) {
    this.routesMovePassengersDataService.sourceRoute = route;

    this.routeStationsMoveService
      .initialize()
      .pipe(
        switchMap(guid =>
          from(this.routesMovePassengersDataService.initSession(guid))
            .pipe(
              catchError(err => {
                this.uPopupService.showErrorMessage({ message: 'general.error' });

                return throwError(err);
              })
            )
        ),
        switchMap(() => this.routeStationsMoveService.start(route.routeId)),
        switchMap(() => this.getCustomerData(this.featureType, this.movePassengersDefaultOptions))
      )
      .subscribe(() => {
        if (this.routesMovePassengersDataService.checkUpdateRoutesAndStationsAvailability()) {
          this.routesMovePassengersDataService.updateRoutesAndStations();
        }
      });
  }

  closeModal(): void {
    this.routesMovePassengersDataService.trackAction('click on close');

    if (this.routesMovePassengersDataService.hasChanges) {
      this.uPopupService.showMessage({
        showXIcon: true,
        message: this.config.dictionary.closeConfirm,
        yes: this.config.dictionary.yes,
        no: this.config.dictionary.no
      },
      () => this.save(),
      () => this.hideModal());
    } else {
      this.hideModal();
    }
  }

  hideModal(): void {
    this.bsModalRef.hide();
  }

  private updateItemsDraggingState(value: boolean) {
    this.isPassengerDragging = value;

    this.routesMovePassengersDataService.updateStationsAndPassengersDraggingState(value);
  }

  private updateDraggingData(event: CdkDragStart, stations: RoutesMovePassengersStation[]) {
    event.source.data = { stations };
  }

  multiplePassengersDragStarted(
    event: CdkDragStart,
    passenger: RoutesMovePassengersPassenger,
    station: RoutesMovePassengersStation
  ) {
    this.routesMovePassengersDataService.trackAction('drag passenger');

    if (!passenger.checked) {
      passenger.checked = true;

      this.routesMovePassengersDataService.togglePassengerCheckbox(station);
    }

    this.updateItemsDraggingState(true);

    this.updateDraggingData(
      event,
      this.routesMovePassengersDataService.stations.map(item => ({ ...item }))
    );
  }

  multipleStationsDragStarted(event: CdkDragStart, station: RoutesMovePassengersStation) {
    this.routesMovePassengersDataService.trackAction('drag station');

    this.dragContent = station.name;

    if (station && !station.checked) {
      station.checked = true;

      this.routesMovePassengersDataService.toggleStationCheckbox(station);
    }

    this.updateItemsDraggingState(true);

    this.updateDraggingData(
      event,
      this.routesMovePassengersDataService.stations.map(currentStation => ({ ...currentStation }))
    );
  }

  multipleEntitiesDropped(event: CdkDragDrop<any>) {
    if (event.previousContainer !== event.container) {
      this.routesMovePassengersDataService.removeStationsAndPassengersByIds(event.item.data.stations);
    }

    this.updateItemsDraggingState(false);
  }

  dragStarted(itemName, type: 'station' | 'passenger') {
    this.dragContent = itemName;

    this.routesMovePassengersDataService.trackAction(`drag ${type}`);
  }

  dragEnded(singleDaySelected: boolean = false) {
    this.dragContent = null;

    if (singleDaySelected) {
      this.updateItemsDraggingState(false);
    }
  }

  droppedStation(event: CdkDragDrop<any>) {
    if (event.container.id.startsWith('route') && event.container !== event.previousContainer) {
      this.routesMovePassengersDataService.removeStationById(event.item.data.station.rideStationId);
    }
  }

  droppedPassenger(event: CdkDragDrop<any>) {
    if (event.container.id.startsWith('route') && event.container !== event.previousContainer) {
      const data = event.item.data;

      this.routesMovePassengersDataService.removePassengerById(data.passenger.passengerId, data.station.rideStationId);
    }
  }

  private getMoveToRouteSingleDayGetParams(stations: RoutesMovePassengersStation[]): RoutesMovePassengersActionParamsStations[] {
    if (!stations || !stations.length) { return []; }

    return stations
      .filter(currentStation => currentStation.checked || currentStation.passengers.some(passenger => passenger.checked))
      .map(currentStation => ({
        rideStationId: currentStation.rideStationId,
        passengerIds: currentStation.checked ? null :
          currentStation.passengers
            .filter(passenger => passenger.checked)
            .map(passenger => passenger.passengerId)
      }));
  }

  private getMoveToRouteGetParams(
    data: { station: RoutesMovePassengersStation; passenger?: RoutesMovePassengersPassenger; }
  ): RoutesMovePassengersActionParamsStations[] {
    if (!data.station) { return []; }

    return [
      {
        rideStationId: data.station.rideStationId,
        passengerIds: data.passenger ? [ data.passenger.passengerId ] : null
      }
    ];
  }

  droppedIntoRoute(event: CdkDragDrop<any>, route: RoutesMovePassengersRoute, singleDaySelected: boolean = false) {
    if (event.container === event.previousContainer) { return; }

    this.routesMovePassengersDataService.moveToRoute(
      singleDaySelected ? this.getMoveToRouteSingleDayGetParams(event.item.data.stations) : this.getMoveToRouteGetParams(event.item.data),
      this.sourceRoute.routeId,
      route
    );
  }

  save(sendBackgroundEmail?: boolean) {
    if (!sendBackgroundEmail) {
      this.routesMovePassengersDataService.trackAction('click on save');
    }

    this.routeStationsMoveService
      .finish(sendBackgroundEmail)
      .pipe(
        finalize(() => this.routesMovePassengersDataService.updateIsLoading(false))
      )
      .subscribe(() => {
        if (sendBackgroundEmail) {
          this.routesMovePassengersDataService.trackAction('click on save & send');
        }

        this.saved.emit();
        this.hideModal();
      });
  }

  private resetSession() {
    this.routeStationsMoveService.resetSession()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.searchPassengers = '';
        this.searchRoutes = '';

        this.routesMovePassengersDataService.resetSingleDaySelected();
        this.routesMovePassengersDataService.resetMapData();

        if (this.routesMovePassengersDataService.checkUpdateRoutesAndStationsAvailability()) {
          this.routesMovePassengersDataService.updateRoutesAndStations();
        }
      });
  }

  private saveSession() {
    this.routeStationsMoveService.saveSession()
      .pipe(
        finalize(() => this.routesMovePassengersDataService.updateIsLoading(false)),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => {
        this.routesMovePassengersDataService.resetForm({
          dateFrom: this.sourceRoute.routeStartDate,
          dateTo: this.sourceRoute.routeEndDate,
          checkDaysActive: [ moment(this.headerDataService.getActiveDate()).get('day') ],
          checkDaysAvailable: this.sourceRoute.days
        });

        this.resetSession();
      });
  }

  showResetChangesConfirmationPopup() {
    this.routesMovePassengersDataService.trackAction('click on reset changes button');

    this.routesMovePassengersDataService.showResetChangesConfirmationPopup(
      {
        message: this.config.dictionary.resetChangesPopup.title,
        showXIcon: true,
        yes: this.config.dictionary.resetChangesPopup.reset,
        no: this.config.dictionary.resetChangesPopup.no
      },
      {
        dateFrom: this.sourceRoute.routeStartDate,
        dateTo: this.sourceRoute.routeEndDate,
        checkDaysActive: [ moment(this.headerDataService.getActiveDate()).get('day') ],
        checkDaysAvailable: this.sourceRoute.days
      },
      () => this.resetSession()
    );
  }

  onSaveActionChange(data) {
    if (this.routesMovePassengersDataService.isLoading() || this.routesMovePassengersDataService.form.invalid) { return; }

    this.routesMovePassengersDataService.updateIsLoading(true);

    switch (data.selected.value) {
      case RoutesMovePassengersSaveActionType.SaveAndClose: {
        this.save();

        break;
      }

      case RoutesMovePassengersSaveActionType.SaveAndSendToSC: {
        this.save(true);

        break;
      }

      case RoutesMovePassengersSaveActionType.Save: {
        this.saveSession();

        break;
      }
    }
  }

  updateRouteColor(routeId: number) {
    this.routesMovePassengersDataService.trackAction('click on station map icon');
    this.routesMovePassengersDataService.updateRouteColor(routeId);
  }
}
