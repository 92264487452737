import {
  GridColumn,
  CellTemplates,
  HeaderMenuIconsTemplate,
  TablePageConfig,
  GridHeaderTemplate
} from '@app/shared/models';

const DICTIONARY_PATH: string = 'accompanies';

const columns: GridColumn[] = [
  {
    prop: 'check',
    name: '',
    hideable: false,
    width: 43,
    sortable: false,
    canAutoResize: false,
    draggable: false,
    resizeable: false,
    headerCheckboxable: true,
    checkboxable: true,
    custom: true,
    hideDivider: true
  },
  {
    prop: 'firstName',
    name: `${DICTIONARY_PATH}.table.columns.firstName`,
    minWidth: 170,
    hideColumn: false,
    filterType: 'text',
    cellTemplateName: CellTemplates.EditRow
  },
  {
    prop: 'lastName',
    name: `${DICTIONARY_PATH}.table.columns.lastName`,
    minWidth: 170,
    hideColumn: false,
    filterType: 'text'
  },
  {
    prop: 'address',
    name: `${DICTIONARY_PATH}.table.columns.address`,
    minWidth: 170,
    hideColumn: false,
    custom: false,
    filterType: 'text'
  },
  { prop: 'mobile',
    name: `${DICTIONARY_PATH}.table.columns.mobile`,
    minWidth: 170,
    hideColumn: false,
    custom: false,
    filterType: 'text'
  },
  {
    prop: 'comments',
    name: `${DICTIONARY_PATH}.table.columns.comments`,
    minWidth: 170,
    hideColumn: false,
    custom: false,
    cellTemplateName: 'commentCell'
  },
  {
    prop: 'identity',
    name: `${DICTIONARY_PATH}.table.columns.identity`,
    minWidth: 170,
    hideColumn: true,
    custom: false,
    filterType: 'text'
  },
  {
    prop: 'birthDate',
    name: `${DICTIONARY_PATH}.table.columns.birthDate`,
    minWidth: 170,
    hideColumn: true,
    custom: false,
    filterType: 'select'
  },
  {
    prop: 'status',
    name: `${DICTIONARY_PATH}.table.columns.status`,
    minWidth: 170,
    hideColumn: true,
    custom: false,
    filterType: 'select',
    cellTemplateName: 'statusCell'
  },
  {
    prop: 'jobPercentage',
    name: `${DICTIONARY_PATH}.table.columns.jobPercentage`,
    minWidth: 170,
    hideColumn: true,
    custom: false,
    filterType: 'select'
  }
];

const tablePageConfig: TablePageConfig = {
  id: 'accompanies',
  trackingId: 'Accompanies',
  headerMenuIconsTemplate: HeaderMenuIconsTemplate.Accompanies,
  gridHeaderTemplate: GridHeaderTemplate.Accompanies,
  dictionary: {
    exportToExcel: {
      columns: `${DICTIONARY_PATH}.table.columns`,
      excelFileName: `${DICTIONARY_PATH}.table.excelFileName`
    }
  },
  tableConfig: {
    columns,
    resetColumnsFiltersStorage: true,
    showCounters: true
  }
};

export const accompaniesComponentConfig = {
  tablePageConfig,
  dictionary: {
    deleteConfirmType: `${DICTIONARY_PATH}.deleteConfirmType`,
    successful: 'general.successful',
    yes: 'general.yes',
    no: 'general.no'
  }
};
