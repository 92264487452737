const DICTIONARY_PATH: string = 'builder.convertTo';

export const builderConvertToComponentConfig = {
  dictionary: {
    uMultiselect: 'uMultiselect',
    message: `${DICTIONARY_PATH}.message`,
    select: `${DICTIONARY_PATH}.select`,
    apply: `${DICTIONARY_PATH}.apply`
  },
  checkboxes: [
    { check: true, value: 'anonymous', name: `${DICTIONARY_PATH}.anonymous` },
    { check: false, value: 'specific', name: `${DICTIONARY_PATH}.specific` }
  ]
};
