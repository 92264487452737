import {
  InputShift,
  InputContact,
  AddEditModalConfig,
  CitiesPolicyCity,
  CityCombinationsButton,
  CityCombinationsRule,
  CityCombinations,
  AreasButton,
  Areas,
  Area,
  FormTypeGroup
} from '@app/shared/models';

export type BranchTabData = BranchDetails | InputShift[] | BranchesAddEditShuttlePolicy | CityCombinations | Areas | BranchesAddEditIntegrations;

export enum BranchesOption {
  Without
}

export enum BranchesAddEditModalTabDataName {
  Details = 'details',
  Shifts = 'shifts',
  CitiesPolicy = 'shuttlePolicy',
  CitiesCombinations = 'citiesCombinations',
  BranchAreas = 'branchAreas',
  Integrations = 'integrations'
}

export enum BranchesAddEditGeneralButtonId {
  CopyFromBranch = 'copyFromBranch'
}

export enum BranchesAddEditCopyFromBranchMode {
  CitiesPolicy = 'citiesPolicy',
  CitiesCombinations = 'citiesCombinations',
  BranchAreas = 'branchAreas'
}

export enum BranchesAddEditEventDataName {
  CopyFromBranchCitiesPolicy = 'copyFromBranchCitiesPolicy',
  CopyFromBranchCitiesCombinations = 'copyFromBranchCitiesCombinations',
  CopyFromBranchAreas = 'copyFromBranchAreas'
}

export class BranchBase {
  branchId: number;
  name: string;
  address: {
    fullAddress: string;
    latitude: number;
    longitude: number;
  };
  comments: string;
  isMain: boolean;

  constructor(values: object) {
    Object.assign(this, values);
  }
}

export class Branch extends BranchBase {
  phone: string;
}

export class BranchDetails extends BranchBase {
  reservationEnabled: boolean;
  isTimeTableActive: boolean;
  contacts: InputContact[];
}

export class BranchesAddEditShuttlePolicy {
  cities: CitiesPolicyCity[];
}

export interface BranchesAddEdit {
  details: BranchDetails;
  shifts: InputShift[];
  shuttlePolicy: BranchesAddEditShuttlePolicy;
  cityCombinationRules: CityCombinationsRule[];
  integrations?: BranchesAddEditIntegrations;
}

export interface BranchesAddEditComponentConfig {
  unavailableMainBranchTabs: BranchesAddEditModalTabDataName[];
  trackingTabDataNames: { [key: string]: string; };
  dictionary: { [key: string]: string; };
  addEditModalConfig: AddEditModalConfig;
}

export interface BranchesAddEditCityCombinationsButton extends Omit<CityCombinationsButton, 'id'> {
  id: BranchesAddEditGeneralButtonId;
}

export interface BranchesAddEditAreasButton extends Omit<AreasButton, 'id'> {
  id: BranchesAddEditGeneralButtonId;
}

export interface BranchAddEditCopyFromBranchData {
  branch: number;
  field: (string | number)[];
}

export interface BranchesAddEditCopyBody {
  branchId: number;
  copyBranchId: number;
}

export interface BranchesAddEditCopyCityCombinationsBody extends BranchesAddEditCopyBody {
  cityCombinationRules: CityCombinationsRule[];
  copyCityCombinationRuleIds: number[];
}

export interface BranchesAddEditCopyAreasBody extends BranchesAddEditCopyBody {
  areas: Area[];
  copyAreaIds: number[];
}

export interface BranchesAddEditIntegrationsTimeTableData {
  placeId: string;
}

export interface BranchesAddEditIntegrations {
  timeTableData: BranchesAddEditIntegrationsTimeTableData;
}

export type BranchesAddEditIntegrationsForm = FormTypeGroup<BranchesAddEditIntegrations>;
