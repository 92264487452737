<div class="files__header">
  <div class="u-text-line u-text-line_app-title files__header-title">{{(filesComponentConfig.dictionary.title) | translate}}</div>
    <div
      *ngIf="!disabled"
      class="files__header-upload"
    >
      <u-input-file
        [allowedExtensions]="[ 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'pdf', 'jpeg', 'jpg', 'png' ]"
        [formControl]="form.get('file')"
        [showSelectedFileName]="false"
        [placeholder]="filesComponentConfig.dictionary.attachFile | translate"
        (change)="uploadFile()"
        (click)="clickOnAttachFile.emit()"
      ></u-input-file>
      <div *ngIf="uploadFileErrors && uploadFileErrors.length" class="files__errors">
        <div *ngFor="let error of uploadFileErrors" class="files__errors-item">
          {{filesComponentConfig.dictionary.errors[error] && filesComponentConfig.dictionary.errors[error] | translate}}
        </div>
      </div>
    </div>
</div>
<div *ngFor="let file of files" class="files__file">
  <div class="files__file-title">{{filesComponentConfig.dictionary.file | translate}}</div>
  <div
    class="files__file-name"
    title="{{file.name}}"
    (click)="downloadFile(file)"
  >
    {{file.name}}
  </div>
  <div
    *ngIf="!disabled"
    class="files__file-delete"
    (click)="deleteFile(file)"
  ></div>
</div>
