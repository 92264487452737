export enum VisibleComponent {
  Feed = 'feed',
  Notes = 'notes',
  Activities = 'activities',
  RouteNotes = 'route-notes',
  MonitoringDashboard = 'monitoring-dashboard',
  ImportContract = 'import-contract',
  PassengersImport = 'passengers-import',
  MovePassengers = 'move-passengers',
  ReOptimization = 're-optimization',
  AiAutoAssignment = 'ai-auto-assignment',
  AiChat = 'ai-chat'
}

export interface VisibleComponents {
  [VisibleComponent.Feed]: boolean;
  [VisibleComponent.Notes]: boolean;
  [VisibleComponent.Activities]: boolean;
  [VisibleComponent.RouteNotes]: boolean;
  [VisibleComponent.MonitoringDashboard]: boolean;
  [VisibleComponent.ImportContract]: boolean;
  [VisibleComponent.PassengersImport]: boolean;
  [VisibleComponent.MovePassengers]: boolean;
  [VisibleComponent.ReOptimization]: boolean;
  [VisibleComponent.AiAutoAssignment]: boolean;
  [VisibleComponent.AiChat]: boolean;
}
