@for (conversation of conversations(); track $index) {
  @if (conversation.messages[conversation.messages.length - 1]; as lastMessage) {
    <div class="ai-chat-conversations__item" (click)="selectConversation.emit(conversation.id)">
      <div class="ai-chat-conversations__icon"></div>

      <div class="ai-chat-conversations__content">
        <div class="ai-chat-conversations__content-text" [title]="lastMessage.text">
          {{lastMessage.text}}
        </div>

        <div class="ai-chat-conversations__content-info">
          {{lastMessage.sendDate | momentConvert : appConstants.DATE_FORMAT_ISO : appConstants.DATE_FORMAT_BASE_SLASH_COMMA_TIME}}
          
          @if (lastMessage.user) { - {{lastMessage.user}} }
        </div>
      </div>

      <div class="ai-chat-conversations__arrow"></div>
    </div>
  }
}
