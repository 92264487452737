import {
  Component,
  HostBinding,
  OnInit,
  OnChanges,
  ElementRef,
  input,
  output,
  inject
} from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { NgClass } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';
import { UClickOutsideDirective, UTooltipModule } from '@shift/ulib';

import { NotesListItem } from '@app/notes/models';
import { AutofocusDirective } from '@app/shared/directives';
import { noteComponentConfig } from './note.component.config';

@Component({
  selector: 'app-note',
  templateUrl: './note.component.html',
  styleUrls: [ './note.component.scss', './note.component.rtl.scss' ],
  standalone: true,
  imports: [
    NgClass,
    UClickOutsideDirective,
    AutofocusDirective,
    ReactiveFormsModule,
    UTooltipModule,
    TranslateModule
  ]
})
export class NoteComponent implements OnInit, OnChanges {
  readonly note = input<NotesListItem>();

  readonly remove = output<number>();
  readonly noteRead = output<number>();
  readonly clickInsideNote = output<number>();
  readonly clickOutsideNote = output<number>();
  readonly noteSave = output<NotesListItem>();
  readonly noteUpdate = output<NotesListItem>();

  @HostBinding('class') hostClasses: string = 'note';

  private readonly elementRef = inject(ElementRef);

  text: UntypedFormControl;
  isFocused: boolean;
  config = cloneDeep(noteComponentConfig);

  ngOnInit() {
    this.text = new UntypedFormControl(this.note().text);
  }

  ngOnChanges() {
    if (this.note().id && !this.note().isRead) {
      this.checkReadNotes();
    }
  }

  private checkReadNotes() {
    const observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting === true) {
        this.noteRead.emit(this.note().id);
        observer.disconnect();
      }
    }, { threshold: [ 0 ] });

    if (this.elementRef.nativeElement) {
      observer.observe(this.elementRef.nativeElement);
    }
  }

  updateFocused(isFocused: boolean) {
    this.isFocused = isFocused;
  }

  removeNote(event: MouseEvent) {
    event.stopPropagation();

    this.remove.emit(this.note().id);
  }

  onClickInsideNote(noteTextarea: HTMLElement) {
    noteTextarea.style.height = `${noteTextarea.scrollHeight}px`;

    this.clickInsideNote.emit(this.note().id);
  }

  onClickOutsideNote(noteTextarea: HTMLElement) {
    noteTextarea.style.height = '64px';

    if (this.note().allowEdit && this.text.dirty) {
      if (this.note().id >= 0) {
        this.noteUpdate.emit({ ...this.note(), text: this.text.value });
      } else {
        this.noteSave.emit({ ...this.note(), text: this.text.value });
      }
    }

    this.clickOutsideNote.emit(this.note().id);

    this.updateFocused(false);
  }
}
