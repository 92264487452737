import { AuthModuleName } from '@app/auth/models';
import { BuilderFilterDataType } from '@app/builder/models';

const DICTIONARY_PATH: string = 'builder.filter';

export const builderFilterComponentConfig = {
  dictionary: {
    uSelectS: 'uSelectS',
    uMultiselect: 'uMultiselect',
    removeSelect: 'general.removeSelect',
    passengers: `${DICTIONARY_PATH}.passengers`,
    stations: `${DICTIONARY_PATH}.stations`,
    type: `${DICTIONARY_PATH}.type`,
    select: `${DICTIONARY_PATH}.select`,
    schools: `${DICTIONARY_PATH}.schools`,
    classes: `${DICTIONARY_PATH}.classes`,
    shifts: `${DICTIONARY_PATH}.shifts`,
    cities: `${DICTIONARY_PATH}.cities`,
    assignedPassengers: `${DICTIONARY_PATH}.assignedPassengers`,
    assign: `${DICTIONARY_PATH}.assign`,
    eligibleShuttle: `${DICTIONARY_PATH}.eligibleShuttle`,
    eligibleAcc: `${DICTIONARY_PATH}.eligibleAcc`,
    gender: `${DICTIONARY_PATH}.gender`,
    commands: `${DICTIONARY_PATH}.commands`,
    departments: `${DICTIONARY_PATH}.departments`,
    tags: `${DICTIONARY_PATH}.tags`,
    bases: `${DICTIONARY_PATH}.bases`,
    branches: `${DICTIONARY_PATH}.branches`,
    apply: `${DICTIONARY_PATH}.apply`
  },
  dataTypes: [
    { value: BuilderFilterDataType.Passengers, name: `${DICTIONARY_PATH}.dataTypes.passengers`, feature: { module: AuthModuleName.Passengers } },
    { value: BuilderFilterDataType.Stations, name: `${DICTIONARY_PATH}.dataTypes.stations` }
  ]
};
