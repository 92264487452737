const DICTIONARY_PATH: string = 'builder.info';

export const builderInfoComponentConfig = {
  dictionary: {
    time: `${DICTIONARY_PATH}.time`,
    traffic: `${DICTIONARY_PATH}.traffic`,
    route: `${DICTIONARY_PATH}.route`,
    accompany: `${DICTIONARY_PATH}.accompany`
  }
};
