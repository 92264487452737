const DICTIONARY_PATH: string = 'shared.cobrowseIo';

export const cobrowseIoConfig = {
  dictionary: {
    yes: 'general.yes',
    no: 'general.no',
    confirm: 'general.confirm',
    screenShare: `${DICTIONARY_PATH}.screenShare`,
    shareCode: `${DICTIONARY_PATH}.shareCode`
  }
};
