import { ChangeDetectionStrategy, Component, HostBinding, computed, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { NavigationCancel, NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { BsModalService } from 'ngx-bootstrap/modal';
import { cloneDeep } from 'lodash';
import { USidebarMenuHeaderItem, USidebarMenuHeaderLanguage, USidebarMenuItem, USidebarMenuModule, USidebarMenuService } from '@shift/ulib';

import { environment } from '@environments/environment';
import { CobrowseIoService, LocalizationService, TrackingService, AccessibilityAffidavitModalService } from '@app/shared/services';
import { AppLanguage, SidebarMenuHeaderItemValue } from '@app/shared/models';
import { AuthDataService } from '@app/auth/services';
import { AuthUserInfo } from '@app/auth/models';
import { AuthFacade } from '@app/auth/state/facades';
import { AuthCustomerSwitchRoleComponent } from '@app/auth/components';
import { sidebarMenuComponentConfig } from './sidebar-menu.component.config';

@Component({
  selector: 'app-sidebar-menu',
  templateUrl: './sidebar-menu.component.html',
  styleUrls: [ './sidebar-menu.component.scss', './sidebar-menu.component.rtl.scss' ],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ USidebarMenuModule ],
  providers: [ Location, { provide: LocationStrategy, useClass: PathLocationStrategy }, AccessibilityAffidavitModalService ]
})
export class SidebarMenuComponent {
  private readonly router = inject(Router);
  private readonly location = inject(Location);
  private readonly modalService = inject(BsModalService);
  private readonly localizationService = inject(LocalizationService);
  private readonly trackingService = inject(TrackingService);
  private readonly cobrowseIoService = inject(CobrowseIoService);
  private readonly authFacade = inject(AuthFacade);
  private readonly authDataService = inject(AuthDataService);
  private readonly accessibilityAffidavitModalService = inject(AccessibilityAffidavitModalService);
  public readonly uSidebarMenuService = inject(USidebarMenuService);

  config = cloneDeep(sidebarMenuComponentConfig);
  isRtl: boolean = this.localizationService.isRtl();
  routeActivePaths = toSignal(
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel),
        map(event => {
          if (event instanceof NavigationEnd) {
            return {
              [this.getPathFromUrl(event.url)]: true
            };
          }

          if (event instanceof NavigationCancel) {
            return {
              [this.getPathFromUrl(event.url)]: false,
              [this.getPathFromUrl(this.router.url)]: true
            };
          }
        })
      ),
    {
      initialValue: {
        [this.getPathFromUrl(this.location.path())]: true
      }
    }
  );
  sidebarMenuConfigByCustomerType = computed(() => {
    const userInfo = this.authDataService.userInfo();
    const sidebarMenuConfig = cloneDeep(sidebarMenuComponentConfig.menu[userInfo.customer.type] || sidebarMenuComponentConfig.menu.default);
    const menuHeaderLogoByCustomerType = sidebarMenuComponentConfig.menuHeaderLogos?.[userInfo.customer.type] || sidebarMenuComponentConfig.menuHeaderLogos.default;

    return {
      ...sidebarMenuConfig,
      bottomItems: sidebarMenuConfig.bottomItems.map(bottomItem => bottomItem.id === 'help-link' ? {
        ...bottomItem,
        link: environment.config.helpLink
      } : bottomItem),
      header: {
        ...sidebarMenuConfig.header,
        userName: userInfo.person.firstName,
        customerName: userInfo.customer.name,
        logoPath: menuHeaderLogoByCustomerType?.[environment.config.environmentType] || menuHeaderLogoByCustomerType.default,
        languages: sidebarMenuConfig.header.languages.map(language => ({ ...language, isActive: language.code === this.localizationService.getLanguage() }))
      },
      items: sidebarMenuConfig.items
        .reduce((acc, item) => ([
          ...acc,
          ...(this.checkMenuItem(item, userInfo) ? [
            {
              ...item,
              ...(item.children ? {
                children: item.children.filter(child => this.checkMenuItem(child, userInfo))
              } : {})
            }
          ] : [])
        ]), [])
    };
  });
  sidebarMenuConfig = computed(() => {
    const routeActivePaths = this.routeActivePaths();
    const sidebarMenuConfigByCustomerType = this.sidebarMenuConfigByCustomerType();

    return {
      ...sidebarMenuConfigByCustomerType,
      items: sidebarMenuConfigByCustomerType.items
        .map(item => {
          let routePathActive = routeActivePaths[item.link];
          const children = item.children?.map(child => {
            if (routeActivePaths[child.link]) {
              routePathActive = routeActivePaths[child.link];
            }

            return {
              ...child,
              active: routeActivePaths[child.link]
            };
          });

          return {
            ...item,
            children,
            active: routePathActive
          };
        })
    };
  });

  @HostBinding('class')
  get hostClasses() {
    return {
      'sidebar-menu': true,
      'sidebar-menu_collapsed': this.uSidebarMenuService.collapsed(),
      'sidebar-menu_opened': this.uSidebarMenuService.opened()
    };
  }

  private getPathFromUrl(url: string): string {
    return url.replace('/', '').split('?')[0];
  }

  private logout() {
    this.track('Click on logout');

    this.authFacade.logout();
  }

  private showCustomerRoleSwitch() {
    this.modalService.show(
      AuthCustomerSwitchRoleComponent,
      {
        class: 'u-modal u-modal_app-auth-customer-switch-role',
        animated: true,
        ignoreBackdropClick: true,
        keyboard: false,
        initialState: {
          userInfo: this.authDataService.userInfo()
        }
      }
    );
  }

  private track(message: string) {
    this.trackingService.track(`[${this.config.trackingId}] - ${message}`);
  }

  private trackItemClick(item: USidebarMenuItem) {
    if (item.link) {
      this.track(`Navigate to ${item.link}`);

      return;
    }

    if (this.config.trackingMessagesByMenuItemId[item.id]) {
      this.track(this.config.trackingMessagesByMenuItemId[item.id]);
    }
  }

  private checkMenuItem(item, userInfo: AuthUserInfo): boolean {
    return (!item.optionalModule || userInfo.optionalModules.includes(item.optionalModule)) &&
      (!item.feature || this.authDataService.checkFeature(item.feature)) &&
      (!item.features || this.authDataService.checkFeatures(item.features)) &&
      (!item.featureGroup || this.authDataService.checkFeatureGroup(item.featureGroup)) &&
      (!item.checkBySelfSCId || !!userInfo.customer.selfShuttleCompanyId);
  }

  setLanguage(language: USidebarMenuHeaderLanguage) {
    this.localizationService.saveLanguage(<AppLanguage>language.code);

    location.reload();
  }

  headerItemSelect(item: USidebarMenuHeaderItem) {
    switch (item.value) {
      case SidebarMenuHeaderItemValue.SwitchCustomer: {
        this.showCustomerRoleSwitch();

        break;
      }

      case SidebarMenuHeaderItemValue.AccessibilityAffidavit: {
        this.accessibilityAffidavitModalService.openModal();

        break;
      }

      case SidebarMenuHeaderItemValue.Logout: {
        this.logout();

        break;
      }
    }
  }

  onNavigate(item: USidebarMenuItem) {
    this.trackItemClick(item);

    if (item.id === 'screen-share') {
      this.cobrowseIoService.createCode();
    }

    if (!item.link) {
      return;
    }

    if (!item.externalLink) {
      this.router.navigateByUrl(item.link);
    } else {
      window.open(item.link, '_blank');
    }
  }
}
