import { Component, HostBinding, Output, EventEmitter, signal, inject } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { cloneDeep } from 'lodash';

import { builderDaysWeekComponentConfig } from './builder-days-week.component.config';

@Component({
  selector: 'app-builder-days-week',
  templateUrl: './builder-days-week.component.html',
  styleUrls: [ './builder-days-week.component.scss', './builder-days-week.component.rtl.scss' ]
})
export class BuilderDaysWeekComponent {
  @Output() action = new EventEmitter();

  @HostBinding('class') hostClasses: string = 'builder-days-week';

  private readonly bsModalRef = inject(BsModalRef);

  readonly #config = signal(cloneDeep(builderDaysWeekComponentConfig));

  readonly config = this.#config.asReadonly();
  readonly checkboxes = cloneDeep(this.config().checkboxes);

  checkItem(item) {
    item.check = !item.check;

    const checkboxes = this.checkboxes.filter(checkbox => checkbox.value !== item.value);

    checkboxes.forEach(checkbox => checkbox.check = !item.check);
  }

  close() {
    this.bsModalRef.hide();
  }

  apply() {
    this.bsModalRef.hide();

    this.action.emit({ type: 'apply', content: { checkboxes: this.checkboxes } });
  }
}
