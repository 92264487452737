import { inject, Injectable } from '@angular/core';
import { UPopupService } from '@shift/ulib';

import { AuthUserInfo } from '@app/auth/models';
import { environment } from '@environments/environment';
import { cobrowseIoConfig } from '@app/shared/configs';

// eslint-disable-next-line @typescript-eslint/naming-convention
declare const CobrowseIO: any;

@Injectable({
  providedIn: 'root'
})
export class CobrowseIoService {
  private readonly uPopupService = inject(UPopupService);

  initializeService = async (data: AuthUserInfo): Promise<void> => {
    CobrowseIO.customData = {
      user_id: data.customer.name,
      user_name: `${data.person.firstName} ${data.person.lastName}`,
      user_email: data.person.email,
      device_id: data.person.roleName,
      device_name: null
    };

    CobrowseIO.license = environment.config.cobrowseLicense;

    await CobrowseIO.client();
    await CobrowseIO.start();

    CobrowseIO.confirmSession = () => new Promise<void>((resolve, reject) => {
      this.uPopupService.showMessage({
        message: cobrowseIoConfig.dictionary.screenShare,
        yes: cobrowseIoConfig.dictionary.yes,
        no: cobrowseIoConfig.dictionary.no
      }, () => {
        resolve();
      }, () => {
        reject();
      }, () => {
        reject();
      });
    });
  };

  async createCode(): Promise<void> {
    const currentSession = CobrowseIO.currentSession;

    if (!currentSession || (currentSession && currentSession._resource.state !== 'active')) {
      await CobrowseIO.client();
      const createSessionCode = await CobrowseIO.createSessionCode();

      this.uPopupService.showMessage({
        message: cobrowseIoConfig.dictionary.shareCode,
        yes: cobrowseIoConfig.dictionary.confirm,
        copyInput: {
          value: createSessionCode
        }
      }, null);
    }
  }
}
