import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Meta } from '@angular/platform-browser';
import { ResolveEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

import { responsiveConfig } from '@app/shared/configs';

@Injectable({
  providedIn: 'root'
})
export class ResponsiveService {
  private readonly destroyRef = inject(DestroyRef);
  private readonly router = inject(Router);
  private readonly meta = inject(Meta);

  private updateViewport(path: string) {
    let content = 'width=1280';

    if (responsiveConfig.urls.map(url => `/${url}`).includes(path)) {
      content = 'width=device-width, initial-scale=1, interactive-widget=resizes-content';
    }

    const viewport = this.meta.getTag('name=viewport');

    if (viewport.content !== content) {
      this.meta.updateTag({ name: 'viewport', content });
    }
  }

  init() {
    this.updateViewport(this.router.url);

    this.router.events
      .pipe(
        filter(event => event instanceof ResolveEnd),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((event: ResolveEnd) => this.updateViewport(event.url));
  }
}
