import { Injectable, inject, computed } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthFacade } from '@app/auth/state/facades';
import {
  AuthCustomer,
  AuthModuleFeature,
  AuthModuleFeatureGroup,
  AuthModuleFeatureGroupCondition,
  AuthModuleItemFeatureAndPermission,
  AuthModules,
  AuthPermission,
  AuthPermissionGroup,
  AuthPermissionGroupCondition,
  AuthPolicies
} from '@app/auth/models';

@Injectable({
  providedIn: 'root'
})
export class AuthDataService {
  public readonly authFacade = inject(AuthFacade);

  readonly userInfo$ = this.authFacade.userInfo$;
  readonly customer$: Observable<AuthCustomer> = this.userInfo$.pipe(map(data => data.customer));
  readonly permissions$: Observable<AuthPermission[]> = this.userInfo$.pipe(map(data => data.permissions));
  readonly policies$: Observable<AuthPolicies> = this.userInfo$.pipe(map(data => data.policies));
  readonly modules$: Observable<AuthModules> = this.userInfo$.pipe(map(data => data.modules));

  readonly userInfo = toSignal(this.userInfo$);
  readonly customer = computed(() => this.userInfo()?.customer);
  readonly modules = computed(() => this.userInfo()?.modules);
  readonly permissions = computed(() => this.userInfo()?.permissions);
  readonly policies = computed(() => this.userInfo()?.policies);
  readonly createRoutesPermission = computed(() => this.checkPermission(AuthPermission.CreateRoutes));
  readonly editRoutesPermission = computed(() => this.checkPermission(AuthPermission.EditRoutes));
  readonly deleteRoutesPermission = computed(() => this.checkPermission(AuthPermission.DeleteRoutes));
  readonly assignAccompanyToRidesPermission = computed(() => this.checkPermission(AuthPermission.AssignAccompanyToRides));
  readonly approveRideOrdersPermission = computed(() => this.checkPermission(AuthPermission.ApproveRideOrders));
  readonly policiesAndSettingsManagementPermission = computed(() => this.checkPermission(AuthPermission.PoliciesAndSettingsManagement));
  readonly managePassengersPermission = computed(() => this.checkPermission(AuthPermission.ManagePassengers));
  readonly manageAccompaniesPermission = computed(() => this.checkPermission(AuthPermission.ManageAccompanies));
  readonly manageShuttleCompaniesPermission = computed(() => this.checkPermission(AuthPermission.ManageShuttleCompanies));

  checkPermission(permission: AuthPermission) {
    return this.permissions().includes(permission);
  }

  checkPermissions(permissions: AuthPermission[]) {
    return permissions.every(permission => this.checkPermission(permission));
  }

  checkPermissionGroup(group: AuthPermissionGroup) {
    return group.condition === AuthPermissionGroupCondition.Or ?
      group.values.some(value => this.checkPermissions(value)) :
      group.values.every(value => this.checkPermissions(value));
  }

  checkFeature(feature: AuthModuleFeature): boolean {
    if (feature) {
      const featureModule = this.modules()[feature.module];
      let check = !!featureModule;

      if (!check) { return false; }

      if (feature.name) {
        check = !!featureModule[feature.name];
      }

      if (feature.name && feature.values) {
        check = !!(featureModule[feature.name] && (
          Array.isArray(featureModule[feature.name]) ?
            feature.values.every(value => featureModule[feature.name].includes(value)) :
            feature.values.includes(featureModule[feature.name]))
        );
      }

      return check;
    }

    return false;
  }

  checkFeatures(features: AuthModuleFeature[]): boolean {
    return features.every(feature => this.checkFeature(feature));
  }

  checkFeatureGroup(feature: AuthModuleFeatureGroup): boolean {
    return feature.condition === AuthModuleFeatureGroupCondition.Or ?
      feature.values.some(value => this.checkFeatures(value)) :
      feature.values.every(value => this.checkFeatures(value));
  }

  checkFeatureAndPermission(item: AuthModuleItemFeatureAndPermission): boolean {
    return (
      item.feature ? this.checkFeature(item.feature) :
        item.features ? this.checkFeatures(item.features) :
          !item.featureGroup || this.checkFeatureGroup(item.featureGroup)
    ) &&
    (
      item.permission ? this.checkPermission(item.permission) :
        !item.permissionGroup || this.checkPermissionGroup(item.permissionGroup)
    );
  }
}
