import {
  Component,
  HostBinding,
  ChangeDetectionStrategy,
  input,
  computed,
  output,
  signal
} from '@angular/core';
import { cloneDeep } from 'lodash';

import { AppConstants } from '@app/shared/constants';
import { AuthModuleRouteBuilderFeatureType, AuthUserInfo } from '@app/auth/models';
import { Activity, ActivityType, ActivityAdditionalStatus } from '@app/activities/models';
import { activitiesItemComponentConfig } from './activities-item.component.config';

@Component({
  selector: 'app-activities-item',
  templateUrl: './activities-item.component.html',
  styleUrls: [ './activities-item.component.scss', './activities-item.component.rtl.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActivitiesItemComponent {
  readonly authUserInfo = input<AuthUserInfo>();
  readonly activity = input<Activity>();

  readonly toggle = output<number>();
  readonly delete = output<number>();
  readonly archive = output<number>();
  readonly sendEmails = output<Activity>();
  readonly downloadExcel = output<Activity>();
  readonly downloadFile = output<Activity>();

  @HostBinding('class') hostClasses: string = 'activities-item';

  readonly #displayRemoveIcon = signal(false);

  readonly displayRemoveIcon = this.#displayRemoveIcon.asReadonly();
  readonly config = computed(() => cloneDeep(activitiesItemComponentConfig[this.authUserInfo().customer.type] || activitiesItemComponentConfig.default));
  readonly activitiesFeatureTempComments = computed(() => this.authUserInfo().modules?.activities?.tempComments);
  readonly routeBuilderFeatureTypeShuttleCompany = computed(() => this.authUserInfo().modules?.routeBuilder?.type === AuthModuleRouteBuilderFeatureType.ShuttleCompany);
  readonly showDelete = computed(() =>
    this.displayRemoveIcon() &&
    this.activity().type === ActivityType.TempComment &&
    this.activitiesFeatureTempComments() &&
    (!this.routeBuilderFeatureTypeShuttleCompany() || this.activity().isOwnedBySc)
  );
  readonly showExcel = computed(() =>
    [ ActivityType.RouteBulkChange, ActivityType.AiTransportationSuggestions ].includes(this.activity().type) &&
    this.activity().additionalData &&
    this.activity().additionalData.changeStatus &&
    this.activity().additionalData.changeStatus !== ActivityAdditionalStatus.Success
  );
  readonly showDownloadFile = computed(() => this.activityTypeXReport() && this.activity().additionalData);
  readonly activityTypeXReport = computed(() => this.activity().type === ActivityType.XReport);

  readonly appConstants = AppConstants;
  readonly activityType = ActivityType;
  readonly activityAdditionalStatus = ActivityAdditionalStatus;

  toggleRemoveIcon(visible: boolean) {
    this.#displayRemoveIcon.set(visible);
  }
}
