import { DestroyRef, Injectable } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { map, take } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import * as moment from 'moment';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UStep } from '@shift/ulib';

import { AppConstants } from '@app/shared/constants';
import { AuthModuleRoutePlannerFeatureSkippedPassengersExport } from '@app/auth/models';
import { AuthDataService } from '@app/auth/services';
import { RoutePlannerService } from '@app/route-planner/services';
import { RoutePlannerSkippedPassenger } from '@app/route-planner/models';
import {
  BuilderFullVisibleComponents,
  BuilderFullSkippedAction,
  BuilderFullStep,
  BuilderFullPassengers,
  BuilderFullMessage
} from '@app/builder/models';
import { BuilderFullSkippedPassengersComponent } from '@app/builder/components/builder-full/builder-full-skipped-passengers/builder-full-skipped-passengers.component';
import { builderFullConfig } from '@app/builder/configs';

@Injectable()
export class BuilderFullCommonService {
  private skippedPassengersExport: AuthModuleRoutePlannerFeatureSkippedPassengersExport;

  visibleComponents: BuilderFullVisibleComponents = {
    main: false,
    routes: false,
    passengers: true,
    filter: true,
    settings: false,
    map: false
  };

  passengers: BuilderFullPassengers = {
    all: 0,
    selected: 0,
    selectedItems: [],
    columnsFiltered: false,
    resetColumnsFilter: false
  };

  skippedPassengers: RoutePlannerSkippedPassenger[] = [];
  showSkippedPassengersPopup: boolean;
  fromDate: string;
  toDate: string;

  message: BuilderFullMessage = null;
  steps: UStep[] = cloneDeep(builderFullConfig.steps);

  constructor(
    private destroyRef: DestroyRef,
    private modalRef: BsModalRef,
    private modalService: BsModalService,
    private routePlannerService: RoutePlannerService,
    private authDataService: AuthDataService
  ) {
    this.getRoutePlannerFeature();
  }

  private getRoutePlannerFeature() {
    this.authDataService.modules$
      .pipe(
        map(modules => modules?.routePlanner?.skippedPassengersExport),
        take(1)
      )
      .subscribe(skippedPassengersExport => this.skippedPassengersExport = skippedPassengersExport);
  }

  private downloadSkippedPassengers() {
    this.routePlannerService.downloadSkippedPassengersExcel(`Unassigned passengers ${this.fromDate}-${this.toDate}.xlsx`)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe();
  }

  updateVisible(params: any): void {
    Object.assign(this.visibleComponents, params);
  }

  getSkippedPassengers(params: RoutePlannerSkippedPassenger[], startDate: string, endDate: string) {
    this.skippedPassengers = params;
    this.showSkippedPassengersPopup = false;

    this.fromDate = moment(startDate).format(AppConstants.DATE_FORMAT_BASE_SLASH);
    this.toDate = moment(endDate).format(AppConstants.DATE_FORMAT_BASE_SLASH);

    if (this.skippedPassengersExport && params.length) {
      this.showSkippedPassengerPopup(params);
    }
  }

  updatePassengers(params: any): void {
    Object.assign(this.passengers, params);
  }

  showSkippedPassengerPopup(skippedPassengers: RoutePlannerSkippedPassenger[]) {
    this.modalRef = this.modalService.show(
      BuilderFullSkippedPassengersComponent,
      {
        class: 'u-modal u-modal_app-builder-full-skipped-passengers',
        animated: true,
        ignoreBackdropClick: true,
        backdrop: false,
        keyboard: false,
        initialState: {
          skippedPassengers
        }
      }
    );

    this.modalRef.content.action.subscribe(type => {
      this.showSkippedPassengersPopup = true;

      switch (type) {
        case BuilderFullSkippedAction.Download: {
          this.downloadSkippedPassengers();
          this.modalRef.hide();
          break;
        }

        case BuilderFullSkippedAction.Close: {
          this.modalRef.hide();
          break;
        }
      }
    });
  }

  showMessage(type: 'success' | 'error', message: string): void {
    this.message = {
      type,
      content: message
    };
  }

  removeMessage(): void {
    this.message = null;
  }

  updateSteps(stepsUpdate: { value: BuilderFullStep; active?: boolean; clickable?: boolean; disabled?: boolean; }[]) {
    this.steps = this.steps.map(step => {
      const stepUpdate = stepsUpdate.find(obj => obj.value === step.value);

      if (stepUpdate) {
        return {
          ...step,
          ...stepUpdate
        };
      }

      return step;
    });
  }
}
