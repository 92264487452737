<div class="builder-days-copy__wrapper">
  <!--
  <div class="builder-days-copy__close" (click)="close()">
    <div class="u-icon u-icon_pointer u-icon_app-builder-close"></div>
  </div>
  -->
  <div class="builder-days-copy__main">
    <div class="builder-days-copy__message">
      {{config().dictionary.message | translate}}
    </div>
    <div class="builder-days-copy__list" (click)="$event.stopPropagation()">
      @for (item of checkboxes; track item) {
        <div class="builder-days-copy__item">
          <div class="builder-days-copy__item-content">
            <div class="builder-days-copy__item-checkbox">
              <u-checkbox class="u-checkbox_square" [ngModel]="item.check" (ngModelChange)="checkItem(item)"></u-checkbox>
            </div>
            <div class="builder-days-copy__item-name">{{item.name | translate}}</div>
          </div>

          @if (item.value === 'duplicate') {
            <div class="builder-days-copy__item-select">
              <u-select-s
                [items]="days"
                [(ngModel)]="dayActive"
                [empty]="false"
                [placeholder]="config().dictionary.uSelectS | translate"
              ></u-select-s>
            </div>
          }
        </div>
      }
    </div>
    <div class="builder-days-copy__actions">
      <button class="builder-days-copy__actions-btn" (click)="apply()">{{config().dictionary.apply | translate}}</button>
    </div> 
  </div>
</div>
