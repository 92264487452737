import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ApiService } from '@app/shared/services';
import { FileSaverService } from '@app/files/services';
import {
  Contract,
  ContractAddEdit,
  ContractAddEditResponse,
  ContractFile,
  ContractFine,
  ContractsIsContractExistsParams
} from '@app/contracts/models';

@Injectable({
  providedIn: 'root'
})
export class ContractsService {
  private BASE_PATH: string = 'Contracts';

  constructor(
    private apiService: ApiService,
    private fileSaverService: FileSaverService
  ) {}

  getList(): Observable<Contract[]> {
    return this.apiService.get(`${this.BASE_PATH}/List`);
  }

  getContract(id: number): Observable<ContractAddEdit> {
    return this.apiService.get(`${this.BASE_PATH}/${id}`);
  }

  saveContract(params: ContractAddEdit): Observable<ContractAddEditResponse> {
    return this.apiService.post(`${this.BASE_PATH}/Save`, params);
  }

  deleteContract(id: number): Observable<void> {
    return this.apiService.delete(`${this.BASE_PATH}/${id}`);
  }

  getContractFines(procedureNumber: string): Observable<ContractFine[]> {
    return this.apiService.get(`${this.BASE_PATH}/Fines`, { procedureNumber });
  }

  isContractExist(params: ContractsIsContractExistsParams): Observable<boolean> {
    return this.apiService.get(`${this.BASE_PATH}/IsContractExists`, params);
  }

  getContractFile(contractId: number): Observable<ContractFile> {
    return this.apiService.get(`${this.BASE_PATH}/File`, { contractId })
      .pipe(tap(res => res && this.fileSaverService.downloadBlobFile(this.fileSaverService.base64ToBlob(res.content), res.name)));
  }
}
