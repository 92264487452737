<div class="builder-map-filter__wrapper" [delayClickOutsideInit]="true" (uClickOutside)="close()">
  <div class="builder-map-filter__close" (click)="close()">
    <div class="u-icon u-icon_pointer u-icon_app-builder-close"></div>
  </div>
  <div class="builder-map-filter__main">
    <div class="builder-map-filter__message">
      {{config().dictionary.showOnMap | translate}}
    </div>
    <div class="builder-map-filter__list">
      <div class="builder-map-filter__item">
        <div class="builder-map-filter__item-field">
          <u-checkbox class="u-checkbox u-checkbox_square-medium" [(ngModel)]="filter.passengers"></u-checkbox>
        </div>
        <div class="builder-map-filter__item-name">{{config().dictionary.passengers | translate}}</div>
      </div>
      <div class="builder-map-filter__item">
        <div class="builder-map-filter__item-field">
          <u-checkbox class="u-checkbox u-checkbox_square-medium" [(ngModel)]="filter.stations"></u-checkbox>
        </div>
        <div class="builder-map-filter__item-name">{{config().dictionary.stations | translate}}</div>
      </div>
    </div>
    <div class="builder-map-filter__actions">
      <button class="builder-map-filter__actions-btn builder-map-filter__actions-btn_clear" (click)="close()">{{config().dictionary.cancel | translate}}</button>
      <button class="builder-map-filter__actions-btn" (click)="apply()">{{config().dictionary.apply | translate}}</button>
    </div> 
  </div>
</div>