import { OnInit } from '@angular/core';
import { ChangeDetectorRef, DestroyRef, Directive, inject, input } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { tap } from 'rxjs/operators';

@Directive({
  selector: '[appFormExtractChanges]',
  standalone: true
})
export class FormExtractChangesDirective implements OnInit {
  private readonly changeDetectorRef = inject(ChangeDetectorRef);
  private readonly destroyRef = inject(DestroyRef);

  readonly appFormExtractChanges = input.required<UntypedFormControl | UntypedFormGroup>();

  ngOnInit() {
    this.appFormExtractChanges().valueChanges
      .pipe(
        tap(() => this.changeDetectorRef.markForCheck()),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }
}
