const DICTIONARY_PATH: string = 'builder.fullMovePassengers';

export const builderFullMovePassengersConfig = {
  dictionary: {
    yes: 'general.yes',
    no: 'general.no',
    confirm: 'general.confirm',
    confirmCarChange: `${DICTIONARY_PATH}.confirmCarChange`,
    movePassengersError: `${DICTIONARY_PATH}.movePassengersError`
  }
};
