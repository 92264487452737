import { URangePreset, USelectSItem } from '@shift/ulib';

import {
  GeneralCardButton,
  GeneralCardButtonValue
} from '@app/shared/models';
import { routesConfig } from '@app/routes/configs';
import {
  RoutesCancellationChargeType,
  RoutesCancellationBillingType,
  RoutesCancelRideClauseType
} from '@app/routes/models';

const DICTIONARY_PATH: string = 'routes.cancelRide';

const dictionary = {
  title: `${DICTIONARY_PATH}.title`,
  multipleContractsApply: `${DICTIONARY_PATH}.multipleContractsApply`,
  fields: {
    type: `${DICTIONARY_PATH}.fields.type`,
    period: `${DICTIONARY_PATH}.fields.period`,
    cancelClause: `${DICTIONARY_PATH}.fields.cancelClause`,
    charge: `${DICTIONARY_PATH}.fields.charge`,
    comment: `${DICTIONARY_PATH}.fields.comment`
  },
  actions: {
    saveAndClose: 'general.saveAndClose',
    saveAndSendSC: 'general.saveAndSendSC',
    saveAndEditEmail: 'general.saveAndEditEmail'
  },
  select: {
    messages: 'uSelectS',
    placeholder: 'general.select'
  },
  unplanned: 'general.unplanned'
};

const cancelClauseAccordingToContract: USelectSItem = {
  name: `${DICTIONARY_PATH}.cancelClause.accordingToContract`,
  value: RoutesCancelRideClauseType.AccordingToContract
};

const cancelClauseList: USelectSItem[] = [
  { name: `${DICTIONARY_PATH}.cancelClause.noCharge`, value: RoutesCancelRideClauseType.NoCharge },
  { name: `${DICTIONARY_PATH}.cancelClause.manual`, value: RoutesCancelRideClauseType.Manual }
];

const chargeTypeList: USelectSItem[] = [
  { name: `${DICTIONARY_PATH}.chargeType.nis`, value: RoutesCancellationChargeType.Fixed },
  { name: `${DICTIONARY_PATH}.chargeType.percent`, value: RoutesCancellationChargeType.Percentage }
];

const availablePresets: URangePreset[] = [
  URangePreset.DisplayedDay,
  URangePreset.Today,
  URangePreset.Tomorrow,
  URangePreset.ActiveWeekDay,
  URangePreset.FromTodayOn,
  URangePreset.FromCustomDayOn,
  URangePreset.All,
  URangePreset.Custom,
  URangePreset.UpcomingWeek
];

const buttons: GeneralCardButton[] = [
  {
    value: GeneralCardButtonValue.SaveAndClose,
    name: 'general.saveAndClose'
  },
  {
    value: GeneralCardButtonValue.SaveAndSendSC,
    name: 'general.saveAndSendSC'
  },
  {
    value: GeneralCardButtonValue.SaveAndEditEmail,
    name: 'general.saveAndEditEmail'
  }
];

export const routesCancelRideComponentConfig = {
  trackingId: `${routesConfig.trackingId} - Cancel ride`,
  cancelClauseAccordingToContract,
  cancelClauseList,
  chargeTypeList,
  dictionary,
  availablePresets,
  buttons,
  billingTypeByCancelClause: {
    [RoutesCancelRideClauseType.NoCharge]: RoutesCancellationBillingType.NoCharge,
    [RoutesCancelRideClauseType.Manual]: RoutesCancellationBillingType.Manual,
    [RoutesCancelRideClauseType.AccordingToContract]: RoutesCancellationBillingType.AccordingToContract
  }
};
