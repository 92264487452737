import { Component, HostBinding, Output, EventEmitter, signal, inject } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { cloneDeep } from 'lodash';

import { ModalActions } from '@app/shared/models';
import { builderMapFilterComponentConfig } from './builder-map-filter.component.config';

@Component({
  selector: 'app-builder-map-filter',
  templateUrl: './builder-map-filter.component.html',
  styleUrls: [ './builder-map-filter.component.scss', './builder-map-filter.component.rtl.scss' ]
})
export class BuilderMapFilterComponent {
  @Output() action = new EventEmitter();

  @HostBinding('class') hostClasses: string = 'builder-map-filter';

  private readonly bsModalRef = inject(BsModalRef);

  readonly #config = signal(cloneDeep(builderMapFilterComponentConfig));

  readonly config = this.#config.asReadonly();

  filter = {
    passengers: false,
    stations: false
  };

  close() {
    this.bsModalRef.hide();

    this.action.emit({ type: ModalActions.Close });
  }

  apply() {
    if (this.filter.passengers || this.filter.stations) {
      this.bsModalRef.hide();

      this.action.emit({ type: 'apply', content: { filter: this.filter } });
    }
  }
}
