import { Injectable, Signal, computed, inject, signal } from '@angular/core';
import { concatMap, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { Observable, pipe } from 'rxjs';
import { toObservable } from '@angular/core/rxjs-interop';
import { UInputCitiesCity, UMultiselectItem, USelectSItem } from '@shift/ulib';

import { patchSignal, rxMethod, tapResponse, transformToInputCities } from '@app/shared/utils';
import { AuthDataService } from '@app/auth/services';
import { PassengersInitialDataState, PassengersInitialDataType } from '@app/passengers/models';
import { PassengersService } from '@app/passengers/services';
import { passengersConfig, passengersInitialDataConfig } from '@app/passengers/configs';

@Injectable()
export class PassengersInitialDataStoreService {
  private readonly authDataService = inject(AuthDataService);
  private readonly passengersService = inject(PassengersService);

  readonly #state = signal<PassengersInitialDataState>(passengersInitialDataConfig.initialState);

  readonly state = this.#state.asReadonly();
  readonly data = computed(() => this.state().data);
  readonly initialBaseDataTypesLoaded = computed(() => this.state().initialBaseDataTypesLoaded);
  readonly customerId = computed(() => this.state().customerId);
  readonly items: Signal<{
    [key: string]: (UInputCitiesCity | UMultiselectItem | USelectSItem)[];
  }> = computed(() =>
      this.data() &&
    Object.keys(this.data()).reduce((acc, item) => ({
      ...acc,
      [item]: item === PassengersInitialDataType.PassengerCities && this.data()[item] ?
        transformToInputCities(this.data()[item]) : this.data()[item]?.map(obj => ({ name: obj.name, value: obj.id }))
    }), {})
    );

  readonly data$ = toObservable(this.data);
  readonly initialBaseDataTypesLoaded$ = toObservable(this.initialBaseDataTypesLoaded);
  readonly items$ = toObservable(this.items);
  readonly customerId$ = toObservable(this.customerId);
  readonly getInitialBaseDataTypesLoaded$ = this.initialBaseDataTypesLoaded$
    .pipe(
      tap(initialBaseDataTypesLoaded => !initialBaseDataTypesLoaded && this.loadInitialBaseDataTypes()),
      filter(initialBaseDataTypesLoaded => !!initialBaseDataTypesLoaded)
    );
  readonly getItemsByInitialDataType$ = (dataType: PassengersInitialDataType) =>
    this.items$
      .pipe(
        map(state => state?.[dataType]),
        tap(data => !data && this.getInitialData({ types: [ dataType ] })),
        filter(data => !!data)
      );
  readonly getBranchesItems$ = this.getItemsByInitialDataType$(PassengersInitialDataType.Branches) as Observable<(UMultiselectItem | USelectSItem)[]>;
  readonly getDepartmentsItems$ = this.getItemsByInitialDataType$(PassengersInitialDataType.Departments) as Observable<(UMultiselectItem | USelectSItem)[]>;
  readonly getClassTypesItems$ = this.getItemsByInitialDataType$(PassengersInitialDataType.ClassTypes) as Observable<(UMultiselectItem | USelectSItem)[]>;
  readonly getDisabilityCodesItems$ = this.getItemsByInitialDataType$(PassengersInitialDataType.DisabilityCodes) as Observable<(UMultiselectItem | USelectSItem)[]>;
  readonly getTagsItems$ = this.getItemsByInitialDataType$(PassengersInitialDataType.Tags) as Observable<(UMultiselectItem | USelectSItem)[]>;
  readonly getPassengerCitiesItems$ = this.getItemsByInitialDataType$(PassengersInitialDataType.PassengerCities) as Observable<UInputCitiesCity[]>;
  readonly getPassengerCitiesItemsBySelectedBranchIds$ = (branchIds: number[]) => this.getPassengerCitiesItems$
    .pipe(
      map(cities =>
        cities.reduce((acc, city) => {
          const branches = branchIds.length ? city.branches.filter(branch => branchIds.includes(branch.branchId)) : city.branches;

          if (branches?.length) {
            return [ ...acc, { ...city, branches } ];
          }

          return acc;
        }, [])
      )
    );

  readonly getInitialData = rxMethod<{ types: PassengersInitialDataType[]; initialBaseDataTypesLoaded?: boolean; }>(
    pipe(
      concatMap(({ types, initialBaseDataTypesLoaded }) =>
        this.passengersService.getInitialData({ types, ...(this.customerId() ? { customerId: this.customerId() } : {}) })
          .pipe(
            tapResponse(
              data => patchSignal(this.#state, state => ({
                initialBaseDataTypesLoaded: initialBaseDataTypesLoaded || state.initialBaseDataTypesLoaded,
                data: {
                  ...state.data,
                  ...types.reduce((acc, item) => ({ ...acc, [item]: [] }), {}),
                  ...data
                }
              })),
              () => null
            )
          )
      )
    )
  );

  readonly loadInitialBaseDataTypes = rxMethod<void>(
    pipe(
      filter(() => !this.initialBaseDataTypesLoaded()),
      switchMap(() => this.authDataService.modules$.pipe(map(modules => modules?.passengers?.type), take(1))),
      map(type => passengersConfig.initialBaseDataTypes[type] || passengersConfig.initialBaseDataTypes.default),
      tap(types => this.getInitialData({ types, initialBaseDataTypesLoaded: true }))
    )
  );

  updateCustomerId(customerId: number) {
    patchSignal(this.#state, { customerId });
  }

  reset() {
    this.#state.set(passengersInitialDataConfig.initialState);
  }
}
