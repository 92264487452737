import { AfterViewInit, ChangeDetectionStrategy, Component, effect, ElementRef, HostBinding, inject, signal, ViewChild } from '@angular/core';
import { NgClass } from '@angular/common';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { UButtonModule, UButtonSize, UCommonModule } from '@shift/ulib';
import { cloneDeep } from 'lodash';

import { FormExtractChangesDirective } from '@app/shared/directives';
import { AiChatDataService } from '@app/ai-chat/services';
import { AiChatMode, AiChatState } from '@app/ai-chat/models';
import { AiChatSelectedConversationViewTableComponent } from './ai-chat-selected-conversation-view-table/ai-chat-selected-conversation-view-table.component';
import { aiChatSelectedConversationComponentConfig } from './ai-chat-selected-conversation.component.config';

@Component({
  selector: 'app-ai-chat-selected-conversation',
  standalone: true,
  imports: [
    NgClass,
    FormsModule,
    TranslateModule,
    ReactiveFormsModule,
    UCommonModule,
    UButtonModule,
    FormExtractChangesDirective,
    AiChatSelectedConversationViewTableComponent
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './ai-chat-selected-conversation.component.html',
  styleUrls: [ './ai-chat-selected-conversation.component.scss', './ai-chat-selected-conversation.component.rtl.scss' ]
})
export class AiChatSelectedConversationComponent implements AfterViewInit {
  @HostBinding('class') hostClasses: string = 'ai-chat-selected-conversation';

  @ViewChild('body') private body: ElementRef;

  public readonly aiChatDataService = inject(AiChatDataService);

  readonly config = signal(cloneDeep(aiChatSelectedConversationComponentConfig)).asReadonly();

  readonly messageText = new FormControl<string>('');
  readonly uButtonSize = UButtonSize;

  constructor() {
    effect(() => {
      const selectedConversationMessages = this.aiChatDataService.selectedConversation()?.messages;

      if (selectedConversationMessages) {
        const lastMessage = selectedConversationMessages[selectedConversationMessages.length - 1];

        if (lastMessage?.user) {
          this.messageText.disable();
        } else {
          this.messageText.enable();
        }

        this.scrollToBottom();
      }
    });

    effect(() => {
      this.aiChatDataService.mode();

      this.scrollToBottom();
    });
  }

  ngAfterViewInit() {
    this.scrollToBottom();
  }

  private scrollToBottom() {
    setTimeout(() => {
      if (this.body) {
        this.body.nativeElement.scrollTo({
          top: this.body.nativeElement.scrollHeight
        });
      }
    }, 0);
  }

  sendMessageText() {
    if (this.messageText.value) {
      this.aiChatDataService.sendMessage(this.messageText.value);

      this.messageText.reset('');
    }
  }

  viewTheTable(table: number) {
    if (table) {
      const selectedConversationMessageTableId = this.aiChatDataService.selectedConversationMessageTableId();

      const state: Partial<AiChatState> = {
        selectedConversationMessageTableId: selectedConversationMessageTableId === table ? null : table
      };

      if (selectedConversationMessageTableId !== table) {
        state.mode = AiChatMode.FullScreen;
      }

      this.aiChatDataService.updateState(state);
    }
  }
}
