import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';

export type FormTypeBase<T> = {
  [K in keyof T]: T[K] extends object
    ? T[K] extends Date
      ? FormControl<T[K] | null>
      : T[K] extends unknown[]
        ? FormControl<T[K] | null>
        : FormTypeBaseGroup<T[K]>
    : FormControl<T[K] | null>;
}

export type FormTypeBaseGroup<T> = FormGroup<FormTypeBase<T>>;

export type FormType<T> = {
  [K in keyof T]: T[K] extends AbstractControl
    ? T[K]
    : T[K] extends object
      ? T[K] extends Date
        ? FormControl<T[K] | null>
        : T[K] extends unknown[]
          ? FormArray<FormTypeGroup<T[K] extends (infer V)[] ? V : T[K]>>
          : FormTypeGroup<T[K]>
      : FormControl<T[K] | null>;
}

export type FormTypeGroup<T> = FormGroup<FormType<T>>;
