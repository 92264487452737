import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  HostBinding,
  inject,
  signal,
  DestroyRef,
  computed
} from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  Validators,
  ReactiveFormsModule
} from '@angular/forms';
import { NgClass } from '@angular/common';
import { filter } from 'rxjs/operators';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { TranslateModule } from '@ngx-translate/core';
import * as moment from 'moment';
import { cloneDeep } from 'lodash';
import { UAddress, UButtonModule, UCommonModule, UIdModule, UPopupService, USelectSItem } from '@shift/ulib';

import { AppConstants } from '@app/shared/constants';
import {
  ValidationService,
  LocalizationService,
  PersonsService,
  ConstantsService,
  TrackingService
} from '@app/shared/services';
import { appDictionaryConfig } from '@app/shared/configs';
import { PersonRole } from '@app/shared/models';
import { FilesComponent } from '@app/files/components';
import { AuthDataSnapshotService } from '@app/auth/services';
import { AccompaniesAddEditMode, AccompanyAddEdit, AccompanyAddEditForm } from '@app/accompanies/models';
import { accompaniesAddEditInfoComponentConfig } from './accompanies-add-edit-info.component.config';

@Component({
  selector: 'app-accompanies-add-edit-info',
  templateUrl: './accompanies-add-edit-info.component.html',
  styleUrls: [ './accompanies-add-edit-info.component.scss', './accompanies-add-edit-info.component.rtl.scss' ],
  standalone: true,
  imports: [
    UCommonModule,
    TranslateModule,
    ReactiveFormsModule,
    UIdModule,
    NgClass,
    FilesComponent,
    UButtonModule
  ]
})
export class AccompaniesAddEditInfoComponent implements OnInit {
  @Input() addEditForm: AccompanyAddEditForm;
  @Input() mode: AccompaniesAddEditMode;

  @Output() loadAccompany =  new EventEmitter<number>();
  @Output() closeModal: EventEmitter<boolean> = new EventEmitter();
  @Output() updateForm = new EventEmitter<AccompanyAddEdit>();

  @HostBinding('class') hostClasses: string = 'accompanies-add-edit-info';

  private readonly destroyRef = inject(DestroyRef);
  private readonly fb = inject(UntypedFormBuilder);
  private readonly uPopupService = inject(UPopupService);
  private readonly validationService = inject(ValidationService);
  private readonly personsService = inject(PersonsService);
  private readonly constantsService = inject(ConstantsService);
  public readonly authDataSnapshotService = inject(AuthDataSnapshotService);
  public readonly localizationService = inject(LocalizationService);
  public readonly trackingService = inject(TrackingService);

  readonly #config = signal(cloneDeep(accompaniesAddEditInfoComponentConfig));
  readonly #statuses = toSignal(this.constantsService.getConstants('accompanyStatusType'));

  readonly config = this.#config.asReadonly();
  readonly statusesItems = computed((): USelectSItem[] => this.#statuses()?.accompanyStatusType?.map(status => ({
    value: status.id,
    name: status.name
  })));

  appDictionaryConfig = appDictionaryConfig;
  isRtl = this.localizationService.isRtl();
  idPlus = 0;
  accompanyId: number;
  disableBirthdayAfterToday: string = moment().startOf('day').format(AppConstants.DATE_FORMAT_ISO);

  ngOnInit() {
    this.accompanyId = this.addEditForm.get('accompanyId').value;

    if (this.accompanyId <= 0) {
      this.addContact();
    }

    this.onContactsChange();
  }

  get personContactsForm(): UntypedFormArray {
    return this.addEditForm.get('contacts') as UntypedFormArray;
  }

  private onContactsChange() {
    this.personContactsForm.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.addEditForm.get('identity').updateValueAndValidity());
  }

  addContact() {
    this.idPlus++;

    const personContactForm = this.fb.group({
      id: [ -this.idPlus ],
      type: [ '', [ Validators.required ] ],
      value: [ '' ],
      useForUpdates: [ false ]
    });

    const { type, value } = personContactForm.controls;

    this.validationService.updateValidationContactData(value, type.value);

    type.valueChanges
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter(contactType => !!contactType)
      )
      .subscribe(contactType => this.validationService.updateValidationContactData(value, contactType));

    this.personContactsForm.insert(0, personContactForm);
  }

  removeContact(index: number) {
    this.uPopupService.showMessage(
      this.config().dictionary.confirmationPopups.deleteContactConfirm,
      () => this.personContactsForm.removeAt(index)
    );

    this.personContactsForm.markAsDirty();
  }

  updateAddress(data: UAddress) {
    this.addEditForm.patchValue({
      address: data.address,
      latitude: data.lat,
      longitude: data.lng,
      placeId: +data.placeId
    });

    this.addEditForm.markAsDirty();
  }

  switchToAccompany(id: number) {
    this.loadAccompany.emit(id);
  }

  setPersonDetails(person) {
    const accompany: AccompanyAddEdit = { ...person };

    accompany.accompanyId = 0;
    accompany.jobPercentage = this.addEditForm.get('jobPercentage').value;

    this.updateForm.emit(accompany);
  }

  checkPersonExist(contact, identity) {
    if (contact || identity) {
      this.personsService.findMember(contact.value, identity, PersonRole.Accompany)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(res => {
          if (res.value) {
            if (res.value !== this.addEditForm.controls['accompanyId'].value) {
              this.uPopupService.showMessage(
                this.mode === AccompaniesAddEditMode.AssignToRoute ? this.config().dictionary.confirmationPopups.assignAccompany : this.config().dictionary.confirmationPopups.existAccompany,
                () => this.switchToAccompany(res.value),
                () => {
                  const accompanyContactsForm = this.addEditForm.get('contacts') as UntypedFormArray;
                  const control = accompanyContactsForm.controls.find(c => c.value.value === contact);

                  if (control) {
                    control.patchValue({ value: '' });
                  }

                  if (this.mode === AccompaniesAddEditMode.AssignToRoute) {
                    this.closeModal.emit();
                  }
                }
              );
            }
          } else {
            this.personsService.findPerson(contact.value, identity)
              .pipe(takeUntilDestroyed(this.destroyRef))
              .subscribe(data => {
                if (data) {
                  this.uPopupService.showMessage(
                    this.config().dictionary.confirmationPopups.existPerson,
                    () => this.setPersonDetails(data),
                    () => {
                      const accompanyContactsForm = this.addEditForm.get('contacts') as UntypedFormArray;
                      const control = accompanyContactsForm.controls.find(c => c.value.value === contact);

                      if (control) {
                        control.patchValue({ value: '' });
                      }
                    }
                  );
                }
              });
          }
        });
    }
  }
}
