<div class="builder-routes__grid-wrapper" #gridWrapper>
  <div
    class="builder-routes__grid-main"
    [ngClass]="{ 'builder-routes__grid-main_disabled-events': builderRoutesStoreService.showBackdrop() }"
  >
    <u-grid
      [class]="'u-grid_app-builder-routes'"
      [tableName]="builderRoutesConfig.tableName"
      [saveTableProps]="true"
      [saveColumnsFilters]="true"
      [resetColumnsFilter]="builderRoutesStoreService.gridColumns().resetFilter"
      [columns]="columns"
      [rows]="builderRoutesStoreService.searchedRoutes()"
      [rowClassObjects]="builderRoutesStoreService.gridRowClassObjects()"
      [headerHeight]="45"
      [headerOffset]="10"
      [footerHeight]="0"
      [rowHeight]="55"
      [reorderable]="true"
      [scrollbarV]="true"
      [scrollbarH]="false"
      [isRtl]="isRtl"
      [lang]="lang"
      [sorts]="tableSorts"
      [scrollToOffsetY]="scrollToOffsetY"
      [selectionType]="'checkbox'"
      [messages]="config().dictionary.uGrid | translate"
      [messagesTable]="builderRoutesStoreService.gridMessages()"
      [showColumnsFilter]="true"
      [selected]="selectedRows"
      [disabledRowProp]="'locked'"
      (selectRows)="selectedRowsChange($event)"
      (activate)="activateRow($event)"
      (resetColumnsFilterChange)="builderRoutesStoreService.resetColumnsFilterChange($event)"
      (columnsFilteredChange)="builderRoutesStoreService.columnsFilteredChange($event)"
    ></u-grid>
  </div>
</div>

<ng-template #branchesCell ngx-datatable-cell-template let-value="value">
  <span class="builder-routes-cell" [title]="value?.length ? (value | join) : none">{{value?.length ? (value | join) : none}}</span>
</ng-template>

<ng-template #directionCell ngx-datatable-cell-template let-row="row">
  {{builderConfig.dictionary.directions[row.direction] | translate}}
</ng-template>

<ng-template #statusColumn ngx-datatable-cell-template let-row="row">
  <div class="builder-routes__grid-status-wrapper">
    <div
      class="builder-routes__grid-status"
      [ngClass]="{
        'builder-routes__grid-status_saved': row.status === builderRouteStatus.Saved,
        'builder-routes__grid-status_failed': row.status === builderRouteStatus.Failed
      }"
      *ngIf="builderDataService.config().mode === builderMode.Add && (row.status === null || row.status >= 0)"
    >
      <span 
        *ngIf="builderFullRoutesGenerationService.modeAi() && row.hasAiSuggestions"
        class="u-icon u-icon_app-ai-mode-active"
      ></span>
      {{routeStatus[row.status] | translate}}
    </div>
    <button
      *ngIf="!row.locked && editRouteId !== row.routeId && (
        builderDataService.config().mode !== builderMode.Add || (builderDataService.config().viewMode === builderViewMode.Full && (row.status === null || row.status === builderRouteStatus.Failed))
      )"
      uButton
      type="button"
      class="builder-routes__grid-edit-button u-grid-row-block"
      [ngClass]="{'builder-routes__grid-edit-button_without-margin': builderDataService.config().mode === builderMode.Add && (row.status === null || row.status >= 0)}"
      (click)="$event.stopPropagation(); editRoute(row)"
    >
      {{(authDataSnapshotService.editRoutesPermission() ? config().dictionary.edit : config().dictionary.view) | translate}}
    </button>
    <div
      *ngIf="builderDataService.config().viewMode === builderViewMode.Full && (row.status === null || row.status === builderRouteStatus.Failed)"
      #threeDotsPopover="uPopover"
      class="u-icon u-icon_pointer u-icon_app-builder-dots-more-big u-grid-row-visible"
      [class.builder-routes__grid-three-dots_visible]="threeDotsPopover.isOpen()"
      [uPopover]="threeDots"
      [container]="'body'"
      [placement]="isRtl ? [ 'bottom-right', 'top-right' ] : [ 'bottom-left', 'top-left' ]"
      [autoClose]="'outside'"
      [popoverClass]="'u-popover_app-builder-routes-three-dots'"
      [viewportElement]="gridWrapper"
      (click)="$event.stopPropagation(); selectThreeDotsRow(row, threeDotsPopover)"
    ></div>
  </div>
</ng-template>

<ng-template #codeColumn ngx-datatable-cell-template let-row="row">
  <div class="builder-routes__grid-code">{{row.code}}</div>
</ng-template>

<ng-template #periodColumn ngx-datatable-cell-template let-row="row">
  <div class="buider-routes__period">
    <div class="buider-routes__period-dates">{{row | builderRoutesPeriod}}</div>
    <div class="builder-routes__period-list">{{row.days | obData: (config().dictionary.shortDays | translate)}}</div>
  </div>
</ng-template>

<ng-template #periodFilter ngx-datatable-cell-template let-item="item">
  <div
    class="builder-routes__period-days"
    title="{{item | obData: (config().dictionary.shortDays | translate)}}"
  >
    {{item | obData: (config().dictionary.shortDays | translate)}}
  </div>
</ng-template>

<ng-template #carTypeCell ngx-datatable-cell-template let-row="row" let-value="value">
  <span
    class="builder-routes__car-type"
    [uTooltip]="row.carTypeCapacity ? (config().dictionary.capacity | translate) + ': ' + row.carTypeCapacity : value"
    [placement]="[ 'bottom' ]"
    [container]="'body'"
  >
    {{value}}
  </span>
</ng-template>

<ng-template #threeDots>
  <div class="u-list u-list_app-builder-routes-three-dots">
    <div
      *ngIf="authDataSnapshotService.editRoutesPermission()"
      class="u-list__item"
      (click)="threeDotsRowPopover.close(); movePassengers()"
    >
      {{config().dictionary.threeDots.movePassengers | translate}}
    </div>
    <div
      *ngIf="threeDotsRow.startDate === threeDotsRow.endDate && authDataSnapshotService.createRoutesPermission()"
      class="u-list__item"
      (click)="threeDotsRowPopover.close(); splitRoute()"
    >
      {{config().dictionary.threeDots.splitRoute | translate}}
    </div>
    <div
      *ngIf="authDataSnapshotService.createRoutesPermission()"
      class="u-list__item"
      (click)="threeDotsRowPopover.close(); duplicateRoute()"
    >
      {{config().dictionary.threeDots.duplicateRoute | translate}}
    </div>
    <div
      *ngIf="authDataSnapshotService.deleteRoutesPermission()"
      class="u-list__item"
      (click)="threeDotsRowPopover.close(); removeRoute()"
    >
      {{config().dictionary.threeDots.removeRoute | translate}}
    </div>
  </div>
</ng-template>

<ng-template #passengersCell ngx-datatable-cell-template let-value="value" let-row="row">
  <div class="builder-routes-passengers">
    <span class="builder-routes-passengers__value" [title]="value">{{value}}</span>
    <span 
      *ngIf="builderDataService.config().viewMode === builderViewMode.Full"
      class="builder-routes-passengers__info u-grid-row-visible"
      (click)="$event.stopPropagation()"
    >
      <app-builder-routes-passengers-info
        [routeId]="row.routeId"
        [viewportElement]="gridWrapper.querySelector('datatable-body')"
      ></app-builder-routes-passengers-info>
    </span>
  </div>
</ng-template>
