import { AuthModuleName, AuthModuleRoutePlannerAiFeature } from '@app/auth/models';
import { RoutePlannerRoutesGeneration } from '@app/route-planner/models';
import { MenuIcon } from '@app/shared/models';

const DICTIONARY_PATH: string = 'builder.fullRoutesGeneration';

const dictionary = {
  mode: {
    algo: `${DICTIONARY_PATH}.mode.algo`,
    ai: `${DICTIONARY_PATH}.mode.ai`
  },
  suggestionsReady: `${DICTIONARY_PATH}.suggestionsReady`
};

const algoModeMenuIcon: MenuIcon = {
  value: RoutePlannerRoutesGeneration.Algo,
  name: dictionary.mode.algo,
  id: 'builder-full-routes-generation-mode-algo',
  urls: {
    default: '/assets/images/builder/full/algo-mode.svg',
    hover: '/assets/images/builder/full/algo-mode-hover.svg'
  },
  config: {
    style: {
      width: 17,
      height: 17
    }
  }
};

const aiModeMenuIcon: MenuIcon = {
  value: RoutePlannerRoutesGeneration.Ai,
  name: dictionary.mode.ai,
  id: 'builder-full-routes-generation-mode-ai',
  urls: {
    default: '/assets/images/builder/full/ai-mode.svg',
    hover: '/assets/images/builder/full/ai-mode-hover.svg'
  },
  config: {
    style: {
      width: 17,
      height: 17
    }
  },
  feature: {
    module: AuthModuleName.RoutePlannerAi,
    name: AuthModuleRoutePlannerAiFeature.Type
  }
};

export const builderFullRoutesGenerationComponentConfig = {
  dictionary,
  modeMenuIcons: {
    ltr: [ algoModeMenuIcon, aiModeMenuIcon ],
    rtl: [
      algoModeMenuIcon,
      {
        ...aiModeMenuIcon,
        urls: {
          default: '/assets/images/builder/full/ai-mode-rtl.svg',
          hover: '/assets/images/builder/full/ai-mode-hover-rtl.svg'
        }
      }
    ]
  }
};
