import { Component, OnInit, HostBinding, Output, EventEmitter, OnDestroy, signal } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { uniq } from 'lodash';
import * as moment from 'moment';
import { cloneDeep } from 'lodash';
import { URangePreset, USelectSItem } from '@shift/ulib';

import { AppConstants } from '@app/shared/constants';
import { AppLanguage, ModalActions } from '@app/shared/models';
import { appConfig } from '@app/shared/configs';
import { LocalizationService, TrackingService } from '@app/shared/services';
import { RoutesChangeType } from '@app/routes/models';
import {
  BuilderDuplicateChangesCalendarSave,
  BuilderDuplicateChangesAction
} from '@app/builder/models';
import { builderDuplicateChangesComponentConfig } from './builder-duplicate-changes.component.config';

@Component({
  selector: 'app-builder-duplicate-changes',
  templateUrl: './builder-duplicate-changes.component.html',
  styleUrls: [ './builder-duplicate-changes.component.scss', './builder-duplicate-changes.component.rtl.scss' ]
})
export class BuilderDuplicateChangesComponent implements OnInit, OnDestroy {
  @Output() action: EventEmitter<BuilderDuplicateChangesAction> = new EventEmitter();

  @HostBinding('class') hostClasses: string = 'builder-duplicate-changes';

  private unsubscribe: Subject<void> = new Subject();

  readonly #config = signal(cloneDeep(builderDuplicateChangesComponentConfig));

  readonly config = this.#config.asReadonly();

  datesChanges: BuilderDuplicateChangesCalendarSave = {
    dates: [],
    dateFrom: null,
    dateTo: null,
    type: null,
    checkDaysActive: [],
    checkDaysAvailable: []
  };
  duplicateDay: string;
  plannedType: number;
  isRtl = true;
  lang: AppLanguage = appConfig.defaultLanguage;
  form: UntypedFormGroup;
  changeTypes: USelectSItem[] = [];
  viewportElement: HTMLElement;
  routeChangesType = RoutesChangeType;
  availablePresetsPlanned: URangePreset[] = [
    URangePreset.FromCustomDayOn,
    URangePreset.FromTomorrowOn,
    URangePreset.All,
    URangePreset.Custom,
    URangePreset.UpcomingWeek
  ];
  availablePresetsNotPlanned: URangePreset[] = [
    URangePreset.ActiveWeekDay,
    URangePreset.Custom,
    URangePreset.UpcomingWeek
  ];

  constructor(
    private bsModalRef: BsModalRef,
    private localizationService: LocalizationService,
    private fb: UntypedFormBuilder,
    private trackingService: TrackingService
  ) {}

  ngOnInit() {
    this.isRtl = this.localizationService.isRtl();
    this.lang = this.localizationService.getLanguage();

    this.createForm();
    this.formSubscribe();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  formSubscribe() {
    this.form.get('plannedType').valueChanges
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(value => {
        if (value === RoutesChangeType.Planned) {
          const updateChangesDates = this.getDates(URangePreset.FromTomorrowOn,  this.datesChanges.dateTo);

          this.datesChanges.type = URangePreset.FromTomorrowOn;
          this.datesChanges.dates = updateChangesDates.dates;
          this.datesChanges.checkDaysActive = updateChangesDates.checkDaysActive;
        }

        if (value === RoutesChangeType.Unplanned) {
          const updateChangesDates = this.getDates(URangePreset.ActiveWeekDay,  this.duplicateDay);

          this.datesChanges.type = URangePreset.ActiveWeekDay;
          this.datesChanges.dates = updateChangesDates.dates;
          this.datesChanges.checkDaysActive = updateChangesDates.checkDaysActive;
        }
      });
  }

  createForm() {
    this.form = this.fb.group({
      plannedType: [ this.plannedType ]
    });
  }

  close() {
    this.bsModalRef.hide();
    this.action.emit({ type: ModalActions.Close, content: { datesChanges: this.datesChanges } });
  }

  apply() {
    const plannedType = this.form.get('plannedType').value;

    this.trackingService.track(`[Route Edit, Agenda tab] - duplicate${plannedType === RoutesChangeType.Planned ? ', select fixed' : ' temp'}`);
    this.bsModalRef.hide();
    this.action.emit({ type: 'apply', content: { datesChanges: this.datesChanges, plannedType } });
  }

  save(data: BuilderDuplicateChangesCalendarSave) {
    this.datesChanges = data;
  }

  getDates(preset: string, date: string): { dates: string[]; checkDaysActive: number[]; } {
    const tomorrow = moment().add(1, 'days').startOf('day').format(AppConstants.DATE_FORMAT_ISO);

    if (preset === URangePreset.FromTomorrowOn) {
      const presetDates = [ tomorrow, date ];
      const activeDays = this.getActiveDays(presetDates);

      return {
        dates: presetDates,
        checkDaysActive: activeDays
      };
    }

    if (preset === URangePreset.ActiveWeekDay) {
      const startWeek = moment(date).startOf('week').format(AppConstants.DATE_FORMAT_ISO);
      const endWeek = moment(date).endOf('week').format(AppConstants.DATE_FORMAT_ISO);
      const presetDates = [ startWeek, endWeek ];
      const activeDays = this.getActiveDays(presetDates);

      return {
        dates: presetDates,
        checkDaysActive: activeDays
      };
    }
  }

  getActiveDays(dates: string[]): number[] {
    const days = this.generateDates(dates[0], dates[dates.length - 1])
      .map((date: Date) => moment(date).day());

    return uniq(days);
  }

  generateDates(dateFrom: string, dateTo: string): Date[] {
    const datesRange = [];
    let startDate = new Date(moment(dateFrom).startOf('day').toDate());
    const endDate = new Date(moment(dateTo).startOf('day').toDate());

    while (startDate <= endDate) {
      datesRange.push(startDate);
      startDate = moment(startDate).add(1, 'day').toDate();
    }

    return datesRange;
  }
}
