const DICTIONARY_PATH: string = 'builder.routes';

export const builderRoutesComponentConfig = {
  dictionary: {
    uGrid: 'uGrid',
    none: 'general.none',
    edit: 'general.edit',
    view: 'general.view',
    capacity: 'general.capacity',
    shortDays: 'general.shortDays',
    yes: 'general.yes',
    no: 'general.no',
    confirmations: {
      removeRoute: `${DICTIONARY_PATH}.confirmations.removeRoute`
    },
    threeDots: {
      movePassengers: `${DICTIONARY_PATH}.threeDots.movePassengers`,
      splitRoute: `${DICTIONARY_PATH}.threeDots.splitRoute`,
      duplicateRoute: `${DICTIONARY_PATH}.threeDots.duplicateRoute`,
      removeRoute: `${DICTIONARY_PATH}.threeDots.removeRoute`
    }
  }
};
