import { GeneralCardButton, GeneralCardButtonValue } from '@app/shared/models';
import { AuthCustomerType } from '@app/auth/models';
import { RoutesTransportationAiSuggestionsType } from '@app/routes/models';

const DICTIONARY_PATH: string = 'routes.aiSuggestionsConfirm';

const continueButton: GeneralCardButton = { name: 'general.continue', value: GeneralCardButtonValue.Continue };

const aiAssignmentOptions = [
  { value: RoutesTransportationAiSuggestionsType.Driver, name: `${DICTIONARY_PATH}.driver`, checked: false },
  { value: RoutesTransportationAiSuggestionsType.Vehicle, name: `${DICTIONARY_PATH}.car`, checked: false }
];

export const routesAiSuggestionsConfirmComponentConfig = {
  continueButton,
  dictionary: {
    title: `${DICTIONARY_PATH}.whatWouldYouLikeToAssign`,
    howDoesItWork: `${DICTIONARY_PATH}.howDoesItWork`,
    explanation: `${DICTIONARY_PATH}.explanation`
  },
  aiAssignmentOptions: {
    default: [
      { value: RoutesTransportationAiSuggestionsType.ShuttleCompany, name: `${DICTIONARY_PATH}.shuttleCompany`, checked: false }
    ],
    selfShuttleCompany: [
      ...aiAssignmentOptions,
      { value: RoutesTransportationAiSuggestionsType.ShuttleCompany, name: `${DICTIONARY_PATH}.shuttleCompany`, checked: false }
    ],
    [AuthCustomerType.ShuttleCompany]: [
      ...aiAssignmentOptions,
      { value: RoutesTransportationAiSuggestionsType.ShuttleCompany, name: `${DICTIONARY_PATH}.subContractor`, checked: false }
    ]
  }
};
