import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  DestroyRef,
  EventEmitter,
  HostBinding,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { UntypedFormControl } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { of } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { UPopoverDirective } from '@shift/ulib';

import { TrackingService } from '@app/shared/services';
import { AuthDataService } from '@app/auth/services';
import {
  BuilderSearch,
  BuilderSearchItem,
  BuilderSearchItemType
} from '@app/builder/models';
import { BuilderDataService, BuilderService } from '@app/builder/services';
import { builderConfig } from '@app/builder/configs';
import { builderSearchComponentConfig } from './builder-search.component.config';

@Component({
  selector: 'app-builder-search',
  templateUrl: './builder-search.component.html',
  styleUrls: [ './builder-search.component.scss', './builder-search.component.rtl.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BuilderSearchComponent implements OnInit, OnChanges {
  @Input() disabled: boolean;
  @Input() point: any;
  @Input() masterSubCustomerIds: number[] = [];

  @Output() filterAction: EventEmitter<any> = new EventEmitter();
  @Output() searchAction: EventEmitter<any> = new EventEmitter();
  @Output() selectAction: EventEmitter<BuilderSearchItem> = new EventEmitter();
  @Output() formControlClick: EventEmitter<void> = new EventEmitter();

  @ViewChild('searchPopover', { static: false }) searchPopover: UPopoverDirective;

  @HostBinding('class')
  get hostClasses() {
    return {
      'builder-search': true,
      'builder-search_disabled': this.disabled,
      'builder-search_ride-order': this.builderDataService.buildModeRideOrders()
    };
  }

  private readonly destroyRef = inject(DestroyRef);
  private readonly cd = inject(ChangeDetectorRef);
  private readonly builderService = inject(BuilderService);
  private readonly trackingService = inject(TrackingService);
  private readonly authDataService = inject(AuthDataService);
  public readonly document = inject(DOCUMENT);
  public readonly builderDataService = inject(BuilderDataService);

  readonly authModules = this.builderDataService.authModules;
  readonly customerIsOwnedByScAndSupervisorModuleStatusActive = this.builderDataService.customerIsOwnedByScAndSupervisorModuleStatusActive;
  readonly customerSupervisorModuleStatusFeatureTypeGeneric = this.builderDataService.customerSupervisorModuleStatusFeatureTypeGeneric;
  readonly customerSupervisorModuleStatusFeatureTypeCommander = this.builderDataService.customerSupervisorModuleStatusFeatureTypeCommander;
  readonly accompanyFeatureType = computed(() => this.authModules()?.accompany?.type);
  readonly educationInstitutionsFeatureType = computed(() => this.authModules()?.educationInstitutions?.type);

  search: UntypedFormControl = new UntypedFormControl('');
  items: any = [];
  visibleItemType: BuilderSearchItemType = BuilderSearchItemType.All;
  loadedList: boolean;
  builderSearchItemType = BuilderSearchItemType;
  config = cloneDeep({
    ...builderSearchComponentConfig,
    items: Object.entries(builderSearchComponentConfig.items).reduce((acc, [ key, value ]) => ({
      ...acc,
      [key]: value.filter(item =>
        (!item.feature || this.authDataService.checkFeature(item.feature)) &&
        (!item.permission || this.authDataService.checkPermission(item.permission))
      )
    }), {})
  });

  ngOnInit() {
    this.updateConfigDictionary();

    this.search
      .valueChanges
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        debounceTime(200),
        distinctUntilChanged(),
        switchMap((value: string) => {
          this.loadedList = false;

          this.toggleSearchPopover(false);

          this.searchAction.emit(value);

          if (value) {
            return this.builderService.search({
              searchText: value,
              ...(this.masterSubCustomerIds ? { masterSubCustomerIds: this.masterSubCustomerIds } : {})
            });
          } else {
            this.items = cloneDeep(this.config.items);

            return of();
          }
        })
      )
      .subscribe((data: BuilderSearch) => this.parseItems(data));
  }

  ngOnChanges(changes: any) {
    if (changes.point && this.point && !this.disabled) {
      this.search.patchValue(this.point.address);
    }

    if (changes.masterSubCustomerIds && this.search.value) {
      this.search.patchValue('');
    }

    if (changes.disabled && typeof this.disabled === 'boolean') {
      if (this.disabled) {
        this.search.disable({ emitEvent: false });
      } else {
        this.search.enable({ emitEvent: false });
      }
    }
  }

  private updateConfigDictionary() {
    this.config.dictionary = Object.entries(this.config.dictionary).reduce((acc, [ key, value ]) => {
      const item = this.config.dictionaryByFeature[value] && this.config.dictionaryByFeature[value].find(obj => this.authDataService.checkFeature(obj.feature));

      return {
        ...acc,
        [key]: value,
        ...(item && item.name ? { [key]: item.name } : {})
      };
    }, {});
  }

  private toggleSearchPopover(value: boolean) {
    if (this.searchPopover) {
      this.searchPopover[value ? 'open' : 'close']();
    }
  }

  parseItems(data: BuilderSearch) {
    const items = cloneDeep(this.config.items);

    if (data.addresses) {
      items.addresses = data.addresses.map(address => new BuilderSearchItem(BuilderSearchItemType.Address, null, address.name, address.fullAddress, address.latitude, address.longitude, address.placeId));
    }

    if (data.passengers) {
      items.passengers = [
        ...items.passengers,
        ...data.passengers.map(passenger =>
          new BuilderSearchItem(
            BuilderSearchItemType.Passenger,
            passenger.id,
            passenger.name,
            null,
            null,
            null,
            null,
            passenger.isSupervisor,
            null,
            passenger.customerName
          )
        )
      ];
    }

    if (data.stations) {
      items.stations = [
        ...items.stations,
        ...data.stations.map(station => new BuilderSearchItem(BuilderSearchItemType.Station, station.id, station.name))
      ];
    }

    if (data.branches) {
      items.branches = data.branches.map(branch => new BuilderSearchItem(BuilderSearchItemType.Branch, branch.id, branch.name));
    }

    if (data.schools) {
      items.schools = data.schools.map(school => new BuilderSearchItem(BuilderSearchItemType.School, school.id, school.name));
    }

    if (data.accompanies) {
      items.accompanies = [ ...items.accompanies, ...data.accompanies.map(accompany => new BuilderSearchItem(BuilderSearchItemType.Accompany, accompany.id, accompany.name)) ];
    }

    this.items = items;
    this.loadedList = true;

    this.toggleSearchPopover(true);

    this.cd.detectChanges();
  }

  updateVisibleItemType(value: BuilderSearchItemType) {
    this.visibleItemType = value === this.visibleItemType ? BuilderSearchItemType.All : value;
  }

  showListChange(event: MouseEvent, type: boolean) {
    event.stopPropagation();

    if (type) {
      if (this.builderDataService.modeAdd()) {
        this.trackingService.track('[Route Add, Map tab] - click on search');
      } else {
        this.trackingService.track('[Route Edit, Map tab] - click on search');
      }
    }

    if (this.loadedList) {
      this.toggleSearchPopover(type);
    }
  }

  limitTo(count: number) {
    if (this.visibleItemType !== BuilderSearchItemType.All) {
      return count;
    }

    return 3;
  }

  itemSelect(event: MouseEvent, item: BuilderSearchItem, addToStation: boolean = false) {
    if (addToStation) {
      this.trackingService.track(`[${builderConfig.routeAddEditTrackingId}] - click on Assign to station icon`);
    }

    event.preventDefault();

    this.selectAction.emit({ ...item, addToStation });

    this.toggleSearchPopover(false);

    this.search.patchValue('');
  }

  outsideClick() {
    this.toggleSearchPopover(false);
    this.updateVisibleItemType(BuilderSearchItemType.All);
  }

  filter() {
    if (this.builderDataService.modeAdd()) {
      this.trackingService.track('[Route Add, Map tab] - click on filter');
    } else {
      this.trackingService.track('[Route Edit, Map tab] - click on filter');
    }

    this.filterAction.emit();
  }
}
