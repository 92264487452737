<app-table-page
  [config]="config().tablePageConfig"
  (excelExport)="exportToExcel()"
  (addRow)="addRow()"
  (editRow)="editRow($event)"
  (refresh)="getAccompanies()"
  (delete)="deleteConfirmAccompanies()"
></app-table-page>

<ng-template #commentCell ngx-datatable-cell-template let-value="value">
  <div
    [uTooltip]="value"
    [placement]="['bottom', 'top']"
    [container]="'body'"
    [tooltipClass]="'u-tooltip_app-wrap-long-word'"
  >
    {{value}}
  </div>
</ng-template>

<ng-template #statusCell ngx-datatable-cell-template let-row="row">
  <div class="accompanies__status">
    {{row.status | translate}}
  </div>
</ng-template>
