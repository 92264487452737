import { TemplateRef } from '@angular/core';
import { UGridSort } from '@shift/ulib';

import { HeaderMenuIconsTemplate, GridHeaderTemplate } from '@app/shared/models';

export class TablePageConfig {
  id: string;
  trackingId?: string;
  modalComponent?: any;
  hideMetadata?: boolean;
  tableConfig: TableConfig;
  ignoreAppHeader?: boolean;
  headerMenuIconsTemplate?: HeaderMenuIconsTemplate;
  gridHeaderTemplate?: GridHeaderTemplate;
  gridHeaderDictionary?: { selected?: string; all?: string; };
  dictionary?: TableDictionary;
  attentionMessage?: string;
  showGlobalSearch?: boolean;
}

export class TableConfig {
  rows?: any[];
  columns: any[];
  sorts?: UGridSort[];
  rowHeight?: number | 'auto';
  hideColumnsFilters?: boolean;
  emptyBodyTemplate?: TemplateRef<any>;
  stickyRowHeight?: number;
  cellTemplates?: { [key: string]: TemplateRef<any>; };
  headerCellTemplates?: { [key: string]: TemplateRef<any>; };
  filterTemplates?: { [key: string]: TemplateRef<any>; };
  showCounters: boolean;
  showButtons?: boolean;
  showSelected?: boolean;
  counterLabel?: string;
  messagesTable?: string;
  disableEditing?: boolean;
  editRowMode?: TablePageRowEditMode;
  showClearFilters?: boolean = true;
  disabledRowProp?: string;
  filterTypeItemsFns?: { [key: string]: Function; };
  filterTypeItemsSearchFns?: { [key: string]: Function; };
  editTypeDropdown?: { list: [], prop: string | number; };
  cellClassFns?: { [key: string]: Function; };
  filterSortingFns?: { [key: string]: Function; };
  skipExportToExcelColumns?: string[];
  resetColumnsFiltersStorage?: boolean;
  comparatorFns?: { [key: string]: Function; };
  saveTableProps?: boolean;
}

export class TableEditRowValue {
  newValue: string | number | boolean;
  oldValue: string | number | boolean;
  prop: string;
  row: any;
}

class TableDictionary {
  exportToExcel?: {
    columns: string;
    excelFileName: string;
  };
}

export enum CellTemplates {
  Normal = 'normalCell',
  Boolean = 'booleanCell',
  EditRow = 'editRowCell',
  EditViewRow = 'editViewRowCell'
}

export enum TablePageRowEditMode {
  Modal = 'modal',
  SplitLayout = 'split-layout'
}

export interface ExportToExcelObject {
  data: {
    ids: number[];
    columns: { field: string, alias: string; }[];
  };
  fileName: string;
}
