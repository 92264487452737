import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
  OnDestroy,
  ElementRef,
  ViewChildren,
  QueryList,
  ChangeDetectorRef,
  HostBinding,
  inject,
  signal,
  DestroyRef,
  input,
  output,
  computed,
  effect,
  untracked
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { forkJoin, merge, Observable, of, Subject } from 'rxjs';
import * as moment from 'moment';
import {
  first,
  filter,
  debounceTime,
  switchMap,
  map,
  tap,
  delay,
  concatMap,
  bufferToggle,
  windowToggle,
  mergeAll,
  take
} from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import {
  UGridSort,
  UGridScroll,
  UThreeDotsPopoverItem,
  UInputCitiesCityLocation,
  UPopoverDirective,
  UTooltipDirective,
  UDaysOrderService,
  UThreeDotsPopupAction
} from '@shift/ulib';

import {
  LocalizationService,
  CommonService,
  TrackingService,
  HeaderSearchFiltersService,
  HeaderDataService
} from '@app/shared/services';
import {
  AppLanguage,
  WindowResize,
  ThreeDotsPopoverItem,
  DaysOfWeek,
  NavigationPaths,
  VisibleComponent
} from '@app/shared/models';
import { appConfig, gridConfig } from '@app/shared/configs';
import { generateWeekDates } from '@app/shared/utils';
import { AppConstants } from '@app/shared/constants';
import { RouteLockState } from '@app/route-lock-state/models';
import { RouteLockStateService } from '@app/route-lock-state/services';
import { ActivityType } from '@app/activities/models';
import { ActivitiesDataService } from '@app/activities/services';
import { AuthCustomer, AuthModuleRouteBuilderFeatureType } from '@app/auth/models';
import { AuthDataService, AuthDataSnapshotService } from '@app/auth/services';
import { BuilderCommonService, BuilderRoutesStoreService } from '@app/builder/services';
import { BuilderRoute, BuilderRouteStatus } from '@app/builder/models';
import { FeedFilterService } from '@app/feed/services';
import { FeedFilter } from '@app/feed/models';
import {
  RoutesCancelRideModalService,
  RoutesCommonService,
  RoutesRestoreRideModalService,
  RoutesTempCommentModalService,
  RoutesTableService,
  RoutesDataService
} from '@app/routes/services';
import {
  RoutesWeeklyParams,
  RouteWeekly,
  RouteWeeklyRow,
  RoutesThreeDotsDeleteAction,
  RoutesWeeklyTableMode,
  RoutesViewTypeMode,
  RouteSaveStatus,
  RouteDirection,
  RoutesThreeDotsPopoverItemAction,
  RoutesWeeklyColumn,
  RouteWeeklyRide,
  RoutesWeeklyChangesAction,
  RoutesWeeklyChangesData
} from '@app/routes/models';
import { routesConfig } from '@app/routes/configs';
import { routesWeeklyComponentConfig } from './routes-weekly.component.config';

@Component({
  selector: 'app-routes-weekly',
  templateUrl: './routes-weekly.component.html',
  styleUrls: [ './routes-weekly.component.scss', './routes-weekly.component.rtl.scss' ],
  providers: [ RoutesRestoreRideModalService, RoutesCancelRideModalService, RoutesTempCommentModalService, AuthDataSnapshotService, RoutesDataService ]
})
export class RoutesWeeklyComponent implements OnInit, OnDestroy {
  readonly tableAssignmentType = input('accompanyName');
  readonly refreshTableData = input<boolean>();
  readonly clearWeeklyCheckedItems = input<boolean>(false);
  readonly feedFilter = input<FeedFilter>();
  readonly resetColumnsFilter = input<boolean>();
  readonly closeAllPopovers = input<boolean>();
  readonly highlightRouteIds = input<number[]>([]);

  readonly refresh = output<void>();
  readonly checkedRoutes = output<{ routes: RouteWeeklyRow[]; }>();
  readonly visibleRoutesAmountChanged = output<number>();
  readonly resetColumnsFilterAction = output<boolean>();
  readonly columnsFilteredAction = output<boolean>();
  readonly deleteRouteAction = output<RoutesThreeDotsDeleteAction>();

  @ViewChild('weeklyViewTable', { static: true }) public weeklyViewTable: ElementRef<any>;
  @ViewChild('codeRoute', { static: true }) public codeRoute: TemplateRef<any>;
  @ViewChild('directionColumn', { static: true }) public directionColumn: TemplateRef<any>;
  @ViewChild('directionFilter', { static: true }) public directionFilter: TemplateRef<any>;
  @ViewChild('startTime', { static: true }) public startTime: TemplateRef<any>;
  @ViewChild('endTime', { static: true }) public endTime: TemplateRef<any>;
  @ViewChild('hourColumnFilter', { static: true }) public hourColumnFilter: TemplateRef<any>;
  @ViewChild('dayColumn', { static: true }) public dayColumn: TemplateRef<any>;
  @ViewChild('dayColumnHeader', { static: true }) public dayColumnHeader: TemplateRef<any>;
  @ViewChild('activitiesColumn', { static: true }) public activitiesColumn: TemplateRef<any>;
  @ViewChildren('popoverCanceled') public popoversCanceled: QueryList<UPopoverDirective>;

  @HostBinding('class') hostClasses: string = 'routes-weekly';

  private readonly destroyRef = inject(DestroyRef);
  private readonly router = inject(Router);
  private readonly cdRef = inject(ChangeDetectorRef);
  private readonly translateService = inject(TranslateService);
  private readonly uDaysOrderService = inject(UDaysOrderService);
  private readonly headerSearchFiltersService = inject(HeaderSearchFiltersService);
  private readonly localizationService = inject(LocalizationService);
  private readonly authDataService = inject(AuthDataService);
  private readonly commonService = inject(CommonService);
  private readonly trackingService = inject(TrackingService);
  private readonly builderRoutesStoreService = inject(BuilderRoutesStoreService);
  private readonly builderCommonService = inject(BuilderCommonService);
  private readonly feedFilterService = inject(FeedFilterService);
  private readonly headerDataService = inject(HeaderDataService);
  private readonly activitiesDataService = inject(ActivitiesDataService);
  private readonly routesTableService = inject(RoutesTableService);
  private readonly routesCommonService = inject(RoutesCommonService);
  private readonly routeLockStateService = inject(RouteLockStateService);
  private readonly routesRestoreRideModalService = inject(RoutesRestoreRideModalService);
  private readonly routesCancelRideModalService = inject(RoutesCancelRideModalService);
  private readonly routesTempCommentModalService = inject(RoutesTempCommentModalService);
  private readonly routesDataService = inject(RoutesDataService);
  public readonly authDataSnapshotService = inject(AuthDataSnapshotService);

  private userId: number;
  private tableOffsetY: number;
  private showAccompany: boolean;
  private routeBuilderFeatureType: AuthModuleRouteBuilderFeatureType;
  private routeBuilderFeatureTypeShuttleCompany: boolean;
  private getRoutesAction: Subject<RoutesWeeklyParams> = new Subject();
  private activitiesUpdateOn: Subject<void> = new Subject();
  private activitiesUpdateOff: Subject<void> = new Subject();

  readonly #config = signal(cloneDeep(routesWeeklyComponentConfig));
  readonly #rowClassObjects = signal([]);
  readonly #drivers = signal<{ value: number; name: string; }[]>([]);
  readonly #accompanies = signal<{ value: number; name: string; }[]>([]);
  readonly #tableSorts = signal<UGridSort[]>([]);

  readonly config = this.#config.asReadonly();
  readonly rowClassObjects = this.#rowClassObjects.asReadonly();
  readonly tableSorts = this.#tableSorts.asReadonly();
  readonly viewTypeMode = computed(() => this.#config().viewTypeMode);
  readonly activeChangeItems = computed(() => {
    if (this.tableAssignmentType() === 'driver') {
      return this.#drivers();
    }

    if (this.tableAssignmentType() === 'accompanyName') {
      return this.#accompanies();
    }

    return [];
  });
  readonly showEmail = computed(() => this.tableAssignmentType() === 'accompanyName');

  readonly activitiesUpdateOn$: Observable<void> = this.activitiesUpdateOn.asObservable();
  readonly activitiesUpdateOff$: Observable<void> = this.activitiesUpdateOff.asObservable();

  readonly routeDirection = RouteDirection;
  readonly appConstants = AppConstants;
  readonly routeSaveStatus = RouteSaveStatus;
  readonly lang: AppLanguage = this.localizationService.getLanguage();
  readonly isRtl: boolean = this.localizationService.isRtl();
  readonly weekDaysTranslation: { [key: string]: string; } = this.translateService.instant(this.config().dictionary.tableWeekDays);
  readonly anonymousAccompany: { [key: string]: string | null; } = { value: null, name: this.translateService.instant(this.config().dictionary.anonymousAccompany) };
  readonly withoutAccompany: { [key: string]: string | null; } = { value: null, name: this.translateService.instant(this.config().dictionary.none) };

  routes: RouteWeeklyRow[] = [];
  columns: RoutesWeeklyColumn[] = [];
  selectedRows: RouteWeeklyRow[] = [];
  activeWeekDays: number[] = [];
  routesStore: RouteWeeklyRow[] = [];
  changePopover: UPopoverDirective;
  activeRoute: RouteWeeklyRow;
  activeRide: RouteWeeklyRide;
  selfShuttleCompany: { value: number; name: string; } = {
    name: '',
    value: null
  };
  activeStartDate = this.formatDate(new Date(moment().startOf('week').toDate()).toISOString());
  activeEndDate = this.formatDate(new Date(moment().endOf('week').toDate()).toISOString());
  activeDate: string;

  headerDateInWeek: boolean;
  requiredRecalculationRides: boolean;
  authCustomer: AuthCustomer;

  tableName = 'routes-weekly';
  filteredRows = [];

  threeDotsMenu: UThreeDotsPopoverItem[];
  threeDotsMenuActive: UThreeDotsPopoverItem[];
  threeDotsMenuCanceled: UThreeDotsPopoverItem[];

  viewportElement: HTMLElement;
  routeLockState: RouteLockState;
  lockedByTooltipIdsClosed: string[] = [];
  rowIdentity: Function = row => row.id;

  ngOnInit() {
    this.headerDataService.updateShowWeekSwitch(true);
    this.initConfig();
    this.attachTemplatesToColumns();

    this.activeStartDate = this.formatDate(new Date(moment(this.headerDataService.getDate(), AppConstants.DATE_FORMAT_ISO).startOf('week').toDate()).toISOString());
    this.activeEndDate = this.formatDate(new Date(moment(this.headerDataService.getDate(), AppConstants.DATE_FORMAT_ISO).endOf('week').toDate()).toISOString());

    this.authDataService.userInfo$
      .pipe(
        take(1),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(userData => {
        this.userId = userData.person.memberId;
        this.authCustomer = userData.customer;
        this.showAccompany = !!userData.modules?.accompany;
        this.routeBuilderFeatureType = userData.modules?.routeBuilder?.type;
        this.routeBuilderFeatureTypeShuttleCompany = this.routeBuilderFeatureType === AuthModuleRouteBuilderFeatureType.ShuttleCompany;

        const threeDotsMenu = routesConfig.threeDotsMenu[userData.modules?.routesTable?.type] || routesConfig.threeDotsMenu.default;

        this.threeDotsMenu = this.getFilteredThreeDotsMenu(threeDotsMenu.threeDotsMenu);
        this.threeDotsMenuActive = this.getFilteredThreeDotsMenu(threeDotsMenu.threeDotsMenuActive);
        this.threeDotsMenuCanceled = this.getFilteredThreeDotsMenu(threeDotsMenu.threeDotsMenuCanceled);

        this.generateColumns();
        this.onGetRoutesAction();
      });

    const windowSize: WindowResize = {
      width: window.innerWidth,
      height: window.innerHeight
    };

    this.setViewportElement(windowSize);

    this.headerSearchFiltersService.appliedSearch$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.trackingService.track('[Routes] - search');
        this.getRoutes();
      });

    this.headerSearchFiltersService.appliedFilters$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.trackingService.track('[Routes] - filter weekly Routes');
        this.getRoutes();
      });

    this.unSelectedRoutes();
    this.getRoutes();

    this.headerDataService.weekSwitchChange$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(dates => {
        const { startDate, endDate } = dates;

        if (!startDate && !endDate) { return; }

        this.activitiesDataService.loadActivitiesLive(this.headerDataService.getWeekDatesRange());

        this.updateActiveData(startDate, endDate);
      });

    this.onActivitiesUpdate();
  }

  constructor() {
    effect(() => {
      if (this.refreshTableData()) {
        this.getRoutes();

        this.refresh.emit();
      }
    });

    effect(() => {
      if (this.clearWeeklyCheckedItems()) {
        this.unSelectedRoutes();
      }
    });

    effect(() => {
      if (this.feedFilter()) {
        this.filterRoutesByFeedFilter();
      }
    });

    effect(() => {
      if (this.closeAllPopovers()) {
        if (this.changePopover && this.changePopover.isOpen()) {
          this.changePopover.close();
        }
      }
    });

    effect(() => {
      if (this.highlightRouteIds().length) {
        this.selectedRows = [];

        this.checkedRoutes.emit({ routes: [] });

        untracked(() => this.highlightRoutes(this.highlightRouteIds()));
      }
    });
  }

  ngOnDestroy() {
    this.headerDataService.updateShowWeekSwitch(false);
  }

  private initConfig() {
    this.#config.set({
      ...this.config(),
      dictionary: {
        ...this.config().dictionary,
        messagesTable: {
          editBtnMessage: this.authDataSnapshotService.editRoutesPermission() ? this.config().dictionary.edit : this.config().dictionary.view
        }
      }
    });
  }

  private attachTemplatesToColumns() {
    this.#config.update(config => ({
      ...config,
      columns: config.columns.map(column => column.cellTemplateName ? { ...column, cellTemplate: this[column.cellTemplateName] } : column)
    }));
  }

  private getFilteredThreeDotsMenu(menu: ThreeDotsPopoverItem[]): ThreeDotsPopoverItem[] {
    return menu.filter(item =>
      this.config().hiddenThreeDotsPopoverItemActions.includes(item.action as RoutesThreeDotsPopoverItemAction) ? false :
        (item.feature ? this.authDataSnapshotService.checkFeature(item.feature) : true) &&
        (item.permission ? this.authDataSnapshotService.checkPermission(item.permission) : true)
    );
  }

  private checkForRequiredRecalculationRides(routes: RouteWeeklyRow[]) {
    this.requiredRecalculationRides = !!routes && routes.some(route => route.rides && route.rides.some(ride => ride.requiredRecalculation));
  }

  onGetRoutesAction() {
    this.getRoutesAction
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        debounceTime(100),
        tap(() => this.activitiesUpdateOff.next()),
        switchMap(params => this.routesTableService.getWeekly(params))
      )
      .subscribe(res => {
        if (res.success) {
          this.routes = this.updateRoutes(res.value);
          this.routesStore = this.routes;
          this.getListData();

          if (!this.headerDateInWeek) {
            this.routes = this.updateDayData(this.activeStartDate);
            this.routesStore = this.routes;
          }

          this.visibleRoutesAmountChanged.emit(this.routes.length);

          this.checkForRequiredRecalculationRides(this.routes);

          if (this.feedFilterService.filteringEnabled) {
            this.filterRoutesByFeedFilter();
          } else {
            const feedFilterSub = this.feedFilterService.filteringEnabledEvent.subscribe(() => {
              this.filterRoutesByFeedFilter();
              feedFilterSub.unsubscribe();
            });
          }

          if (this.builderRoutesStoreService.state().grid.props.sorts) {
            this.#tableSorts.set(this.builderRoutesStoreService.state().grid.props.sorts);
          }

          this.activitiesUpdateOn.next();
        }
      });
  }

  updateActiveData(dateStart: string, dateEnd: string) {
    this.activeStartDate = this.formatDate(new Date(moment(dateStart).startOf('week').toDate()).toISOString());
    this.activeEndDate = this.formatDate(new Date(moment(dateEnd).endOf('week').toDate()).toISOString());
    this.headerDateInWeek = this.checkDateInWeek(moment().startOf('day').toISOString(), this.activeStartDate, this.activeEndDate);

    this.generateColumns();

    this.headerDataService.dateSet(this.activeDate);

    this.getRoutes();
    this.unSelectedRoutes();
  }

  generateColumns() {
    this.columns = [
      ...this.#config().columns
        .filter(column => this.authDataService.checkFeatureAndPermission(column))
        .map(column => column.prop === 'code' ? {
          ...column,
          showEditBtn: !!this.routeBuilderFeatureType,
          showThreeDots: this.authDataSnapshotService.editRoutesPermission()
        } : column),
      ...this.initWeekColumns()
    ];
  }

  initWeekColumns() {
    const columns: RoutesWeeklyColumn[] = [];
    const weekDates = generateWeekDates(this.activeStartDate, this.activeEndDate);

    for (let i = 0; i <= 6; i++) {
      columns.push({
        prop: `ride-${i}`,
        name: this.weekDaysTranslation[i],
        minWidth: 111,
        maxWidth: 111,
        resizeable: false,
        cellTemplate: this.dayColumn,
        index: i,
        date: moment(weekDates[i]).startOf('day').format(AppConstants.DATE_FORMAT_ISO),
        headerTemplate: this.dayColumnHeader,
        custom: true,
        clickble: true,
        highlight: this.checkIsActiveDate(weekDates[i]) && this.headerDateInWeek || !this.headerDateInWeek && i === 0
      });
    }

    if (!this.uDaysOrderService.sundayFirstDay()) {
      columns.splice(
        columns.findIndex(column => column.prop === `ride-${DaysOfWeek.Saturday}`),
        0,
        cloneDeep(columns.splice(columns.findIndex(column => column.prop === `ride-${DaysOfWeek.Sunday}`), 1))[0]
      );
    }

    this.activeDate = columns.find(column => column.highlight).date;

    return columns;
  }

  checkIsActiveDate(date: Date) {
    const activeDate = new Date(moment().startOf('day').toISOString());
    const isoDate = new Date(moment(date).startOf('day').toISOString());

    return moment(activeDate).isSame(isoDate);
  }

  formatDate(date: string) {
    return new Date(moment(date).format(AppConstants.DATE_FORMAT_BASE_LINE)).toISOString();
  }

  getRoutesParams(): RoutesWeeklyParams {
    const filters = this.headerSearchFiltersService.getAppliedFiltersValue();

    return {
      periodEnd: this.activeEndDate,
      periodStart: this.activeStartDate,
      weeklyTableMode: this.tableAssignmentType() === 'accompanyName' ? RoutesWeeklyTableMode.Accompany : RoutesWeeklyTableMode.Driver,
      searchText: this.headerSearchFiltersService.getSearchValue(),
      schoolIds: <number[]>filters.schools || [],
      classTypeIds: <number[]>filters.classes || [],
      passengerIds: <number[]>filters.passengers || [],
      departmentIds: <number[]>filters.departments || [],
      branchIds: <number[]>filters.branches || [],
      direction: RouteDirection.BackwardAndForward,
      shiftIds: <number[]>filters.shifts || [],
      tagIds: <number[]>filters.tags || [],
      cities: <UInputCitiesCityLocation[]>filters.citiesAreas || [],
      masterSubCustomerIds: <number[]>filters.subCustomers || []
    };
  }

  getRoutes() {
    this.getRoutesAction.next(this.getRoutesParams());
  }

  selectRoutes(data: { selected: RouteWeeklyRow[]; }) {
    this.selectedRows = data.selected;

    this.checkedRoutes.emit({ routes: data.selected });
  }

  unSelectedRoutes() {
    this.selectedRows = [];
    this.checkedRoutes.emit({ routes: [] });
  }

  updateRoutes(routes: RouteWeekly[]): RouteWeeklyRow[] {
    this.activeWeekDays = this.getDays(generateWeekDates(this.activeStartDate, this.activeEndDate));

    return routes.map(route => this.parseRoute(route));
  }

  parseRoute(route: RouteWeekly): RouteWeeklyRow {
    const weekDates = generateWeekDates(this.activeStartDate, this.activeEndDate);
    const rideDays = {};
    const routeDates = this.generateDates(route.startDate, route.endDate);
    const days = routeDates
      .map((date) => moment(date).day())
      .filter((value: number, dateIndex, arr) => arr.indexOf(value) === dateIndex);

    weekDates.forEach(dayOfWeek => {
      const day = moment(dayOfWeek).startOf('day').day();

      rideDays[`ride-${day}`] = route.rides.find(obj => moment(obj.date).startOf('day').day() === day) || null;

      if (rideDays[`ride-${day}`]) {
        const rideAccompany = rideDays[`ride-${day}`].accompany;
        const rideShuttleCompany = rideDays[`ride-${day}`].shuttleCompany;
        const rideSelfShuttleCompany =  rideDays[`ride-${day}`].isSelfShuttleCompany;
        const rideNeedAccompany = rideDays[`ride-${day}`].needAccompany;

        if (!rideAccompany && !rideNeedAccompany) {
          rideDays[`ride-${day}`].accompany = this.withoutAccompany;
        } else if (this.isAnonymousAccompany(rideAccompany)) {
          rideDays[`ride-${day}`].accompany.name = this.anonymousAccompany.name;
        }

        if (rideShuttleCompany) {
          if (!rideSelfShuttleCompany) {
            if (rideShuttleCompany.value === this.authCustomer.customerId) {
              rideDays[`ride-${day}`].isSelfShuttleCompany = true;
            }
          }
        }
      }
    });

    const totalPassengers = route.rides.reduce((acc: number, ride) => acc + ride.totalPassengers, 0);
    const totalPassengerCustomers = route.rides.reduce((acc: number, ride) => acc + ride.totalPassengerCustomers, 0);
    const selfShuttleCompany = route.rides.find(obj => obj.isSelfShuttleCompany);

    if (selfShuttleCompany) {
      this.selfShuttleCompany = selfShuttleCompany.shuttleCompany;
    }

    let activeDateData = {};
    let activeDateRide = null;

    if (this.headerDateInWeek) {
      const activeDate = new Date(moment(this.headerDataService.getDate(), AppConstants.DATE_FORMAT_ISO).startOf('day').toISOString());

      activeDateRide = route.rides.find(ride => {
        const rideDate = moment(ride.date).startOf('day').toISOString();
        const isActiveDateRide = moment(rideDate).isSame(activeDate);

        if (isActiveDateRide) { return ride; }
      });

      activeDateData = {
        startDateTime: activeDateRide && activeDateRide.startDateTime ? activeDateRide.startDateTime : null,
        endDateTime: activeDateRide && activeDateRide.endDateTime ? activeDateRide.endDateTime : null,
        shuttleCompany: activeDateRide && activeDateRide.shuttleCompany ? (activeDateRide.shuttleCompany ? activeDateRide.shuttleCompany.name : null) : null
      };
    }

    const isOwnedBySc = !this.routeBuilderFeatureTypeShuttleCompany || route.isOwnedBySc;

    let threeDotsItems = this.threeDotsMenuActive;

    if (activeDateRide && activeDateRide.isCancelled) {
      threeDotsItems = totalPassengers !== 0 ? this.threeDotsMenuCanceled : this.threeDotsMenu;
    }

    if (!isOwnedBySc) {
      threeDotsItems = threeDotsItems.filter(item => !routesConfig.threeDotsMenuOwnedByScActions.includes(<RoutesThreeDotsPopoverItemAction>item.action));
    }

    return {
      ...route,
      ...rideDays,
      ...activeDateData,
      threeDotsItems,
      hideActions: !activeDateRide,
      days,
      totalPassengers,
      totalPassengerCustomers,
      saveStatus: null,
      lockState: null
    };
  }

  getDays(weekDates: Date[]) {
    return weekDates.map((date: Date) => moment(date).day());
  }

  onFilterRows(rows: any) {
    this.filteredRows = rows;
    this.visibleRoutesAmountChanged.emit(rows.length);
  }

  getListData() {
    const supplierId = this.selfShuttleCompany.value !== null ? this.selfShuttleCompany.value : this.authCustomer.selfShuttleCompanyId;

    forkJoin([
      this.showAccompany ? this.routesTableService.getAccompaniesList() : of(null),
      supplierId ? this.routesTableService.getDriversList() : of(null)
    ])
      .pipe(first())
      .subscribe(([ accompanies, drivers ]) => {
        if (accompanies && accompanies.success) {
          this.#accompanies.set([ this.anonymousAccompany, ...accompanies.value ]);
        }

        if (drivers && drivers.success) {
          this.#drivers.set(drivers.value);
        }
      });
  }

  generateDates(startDate: string, endDate: string): Date[] {
    const datesRange = [];
    let start = new Date(moment(startDate).startOf('day').toDate());
    const end = new Date(moment(endDate).startOf('day').toDate());

    while (start <= end) {
      datesRange.push(start);
      start = moment(start).add(1, 'day').toDate();
    }

    return datesRange;
  }

  openWeeklyPopover(popover: UPopoverDirective, ride: RouteWeeklyRide, row: RouteWeeklyRow) {
    this.changePopover = popover;
    this.activeRoute = row;
    this.activeRide = ride;
  }

  weeklyChangesActions(data: RoutesWeeklyChangesAction) {
    const { item, duplicate, sendEmail } = data;
    const dataSave: RoutesWeeklyChangesData = {
      duplicate,
      sendEmail,
      ...this.activeRide,
      routeId: this.activeRoute.id
    };

    if (this.tableAssignmentType() === 'accompanyName') {
      const accompany = {
        accompanyId: item.value
      };

      if (accompany.accompanyId !== -1) {
        this.saveChange(dataSave, accompany, 'changeAccompany');
      } else {
        this.saveChange(dataSave, {}, 'changeResetAccompany');
      }

      this.trackingService.track('[Track weekly view change] - temp change - accompany changed');
    } else if (this.tableAssignmentType() === 'driver') {
      const driver = {
        shuttleCompanyId: this.selfShuttleCompany.value,
        driverId: item.value === -1 ? null : item.value,
        updateDriver: true
      };

      this.saveChange(dataSave, driver, 'changeShuttleSettings');
      this.trackingService.track('[Track weekly view change] - temp change - driver changed');
    }
  }

  saveChange(data: RoutesWeeklyChangesData, obj: object, serviceName: string) {
    const { duplicate, sendEmail, routeId, date } = data;

    let params = {
      routeId,
      sendBackgroundEmail: false,
      generateEditableEmail: sendEmail,
      value: {
        ...obj,
        comment: '',
        type: 1,
        dateFrom: this.activeStartDate,
        dateTo: this.activeEndDate,
        days: this.activeWeekDays
      }
    };

    if (!duplicate) {
      const changeDate = {
        dateFrom: date,
        dateTo: date,
        days: [
          moment(date).day()
        ]
      };

      params = {
        ...params,
        value: {
          ...params.value,
          ...changeDate
        }
      };
    }

    this.routesTableService[serviceName](params)
      .pipe(first())
      .subscribe(() => {
        this.changePopover.close();
        this.selectRoutes({ selected: [] });
      });
  }

  updateDayData(date: string) {
    const day = moment(date).day();

    return this.routes.map((route) => {
      const activeRide = route[`ride-${day}`];
      const startDateTime = activeRide && activeRide.startDateTime ? activeRide.startDateTime : null;
      const endDateTime = activeRide && activeRide.endDateTime ? activeRide.endDateTime : null;
      const shuttleCompany = activeRide && activeRide.shuttleCompany ? (activeRide.shuttleCompany ? activeRide.shuttleCompany.name : null) : null;
      const isOwnedBySc = !this.routeBuilderFeatureTypeShuttleCompany || route.isOwnedBySc;
      let threeDotsItems = this.threeDotsMenuActive;

      if (activeRide && activeRide.isCancelled) {
        threeDotsItems = activeRide.totalPassengers !== 0 ? this.threeDotsMenuCanceled : this.threeDotsMenu;
      }

      if (!isOwnedBySc) {
        threeDotsItems = threeDotsItems.filter(item => !routesConfig.threeDotsMenuOwnedByScActions.includes(<RoutesThreeDotsPopoverItemAction>item.action));
      }

      return {
        ...route,
        startDateTime,
        endDateTime,
        shuttleCompany,
        threeDotsItems,
        hideActions: !activeRide
      };
    });
  }

  checkDateInWeek(date: string, startDate: string, endDate: string) {
    const today = new Date(moment(date, AppConstants.DATE_FORMAT_ISO).startOf('day').toISOString());
    const weekDates = generateWeekDates(startDate, endDate);

    return weekDates.some(day => moment(day).isSame(today));
  }

  dayHeaderClick(currentColumn: RoutesWeeklyColumn) {
    const { date } = currentColumn;

    this.activeDate = date;
    const formatDate = moment(date).startOf('day').format(AppConstants.DATE_FORMAT_ISO);
    this.headerDataService.dateSet(formatDate);

    this.columns = this.columns.map(column => ({ ...column, highlight: column.prop === currentColumn.prop }));
    this.routes = this.updateDayData(date);
  }

  editRoute({ row }: { row: RouteWeeklyRow; }) {
    const routes: BuilderRoute[] = this.filteredRows.map(obj => ({
      status: BuilderRouteStatus.None,
      routeId: obj.id,
      code: obj.code,
      name: obj.name,
      direction: obj.direction,
      days: obj.days,
      startDate: obj.startDate,
      endDate: obj.endDate,
      rideStartDateTime: moment(obj.startDateTime).format(AppConstants.TIME_FORMAT),
      rideEndDateTime: moment(obj.endDateTime).format(AppConstants.TIME_FORMAT),
      totalPassengers: obj.totalPassengers,
      carTypeName: obj.carType || null,
      carTypeCapacity: obj.carType ? obj.carType.value : null,
      shuttleCompany: obj.shuttleCompany || null,
      locked: obj.locked
    }));

    let localStorageTableProps = JSON.parse(localStorage.getItem(appConfig.storageKeys.usersTablesProps)) || {};

    if (Object.keys(localStorageTableProps).length > 0) {
      localStorageTableProps = localStorageTableProps[this.userId] && localStorageTableProps[this.userId][this.tableName];
    }

    this.builderRoutesStoreService.updateGridProps({
      columnsOrder: localStorageTableProps?.columnsOrder,
      columnsStoreVisible: localStorageTableProps?.columnsStoreVisible,
      sorts: this.tableSorts(),
      offsetY: this.tableOffsetY,
      hiddenColumns: [ 'days' ]
    });

    this.builderCommonService.editRouteIdSet(row.id);
    this.builderRoutesStoreService.routesSet(routes);

    this.router.navigateByUrl(NavigationPaths.Builder);
  }

  isAnonymousAccompany(accompany: { value: number; name: string; }) {
    if (!accompany) { return; }

    const { value, name } = accompany;

    return !value && !name;
  }

  filterRoutesByFeedFilter() {
    if (this.feedFilter() && !this.feedFilter().value && !this.feedFilter().items) {
      this.routes = this.routesStore;
    } else if (this.feedFilter() && this.feedFilter().value) {
      this.routes = this.routesStore.filter(route => this.feedFilter().items.some(id => id === route.id));

      this.visibleRoutesAmountChanged.emit(this.routes.length);
    }
  }

  columnsFilteredChange(data: boolean) {
    this.columnsFilteredAction.emit(data);
  }

  resetColumnsFilterChange(resetColumnsFilter: boolean) {
    this.resetColumnsFilterAction.emit(resetColumnsFilter);
  }

  private highlightRoutes(routeIds: number[]) {
    this.#rowClassObjects.set(routeIds.map(routeId => ({
      className: gridConfig.rowHighlightClasses.default,
      rowPropertyName: 'id',
      value: routeId
    })));

    setTimeout(() => {
      this.#rowClassObjects.update(state => state.filter(obj => !(
        obj.className === gridConfig.rowHighlightClasses.default &&
        obj.rowPropertyName === 'id' &&
        routeIds.includes(obj.value)
      )));
    }, 3000);
  }

  setViewportElement(sizes: WindowResize): void {
    const { width, height } = sizes;

    this.viewportElement = width >= 1600 && height >= 800 ? this.weeklyViewTable.nativeElement.querySelector('datatable-body') : null;
  }

  windowResize(sizes: WindowResize): void {
    this.setViewportElement(sizes);
  }

  selectDotsItemAction({ action, data }: UThreeDotsPopupAction): void {
    const { id, startDate, endDate, days, code, name, isOwnedBySc, rideStartDateTime, totalPassengers, active } = data;

    switch (action) {
      case RoutesThreeDotsPopoverItemAction.DeleteRoute: {
        this.deleteRouteAction.emit({ routeId: id, activeRoute: data, mode: RoutesViewTypeMode.WeeklyView });

        break;
      }

      case RoutesThreeDotsPopoverItemAction.CancelRide: {
        this.routesCancelRideModalService.openModal(
          {
            routeId: id,
            routeStartDate: startDate,
            routeEndDate: endDate,
            days,
            code,
            name,
            isOwnedBySc,
            rideStartDateTime
          },
          this.viewTypeMode()
        );

        break;
      }

      case RoutesThreeDotsPopoverItemAction.RestoreRide: {
        this.routesRestoreRideModalService.openModal(
          {
            routeId: id,
            routeStartDate: startDate,
            routeEndDate: endDate,
            days,
            code,
            name,
            isOwnedBySc,
            totalPassengers
          },
          this.viewTypeMode()
        );

        break;
      }

      case RoutesThreeDotsPopoverItemAction.TempComment: {
        this.routesTempCommentModalService.openModal(
          {
            routeId: id,
            code,
            name,
            active
          },
          this.viewportElement
        );

        break;
      }

      case RoutesThreeDotsPopoverItemAction.CreateRouteTemplate: {
        this.trackingService.track(`[${routesConfig.trackingId}} > 3 dot menu] - click on create template from route`);
        this.routesCommonService.createRouteTemplate(id, this.headerDataService.getDate());

        break;
      }
    }
  }

  onScrollTable(scrollPosition: UGridScroll) {
    this.tableOffsetY = scrollPosition.offsetY;
  }

  onSortTable(sorts: UGridSort[]) {
    this.#tableSorts.set(sorts);
  }

  openRouteActivities(routeId: number) {
    this.commonService.updateVisibleComponent(VisibleComponent.Activities, true);

    this.activitiesDataService.loadActivities({
      ...this.headerDataService.getDateRange(),
      routeId
    });

    this.trackingService.track('[Route table, Weekly] - Click on activity icon');
  }

  onActivitiesUpdate() {
    merge(
      this.activitiesDataService.activitiesUpdate$.pipe(bufferToggle(this.activitiesUpdateOff$, () => this.activitiesUpdateOn$)),
      this.activitiesDataService.activitiesUpdate$.pipe(windowToggle(this.activitiesUpdateOn$, () => this.activitiesUpdateOff$))
    )
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        mergeAll(),
        map(activities => {
          const routeIds = this.routes.map(row => row.id);

          return activities.filter(activity => routeIds.includes(activity.routeId));
        }),
        filter(activities => !!activities.length),
        map(activitiesUpdate => {
          const savedRouteIds: number[] = activitiesUpdate.filter(activity => activity.type === ActivityType.RouteSaveSuccess).map(activity => activity.routeId);

          const { rows, saveStatusRouteIds } = this.routesDataService.getRouteRowsWithActivitiesUpdate(this.routes, activitiesUpdate);
          this.routes = rows;

          this.cdRef.markForCheck();

          return { saveStatusRouteIds, savedRouteIds };
        }),
        filter(data => !!(data.saveStatusRouteIds.length || data.savedRouteIds.length)),
        delay(2000),
        tap(({ saveStatusRouteIds }) => {
          if (saveStatusRouteIds.length) {
            this.routes = this.routes.map(route => saveStatusRouteIds.includes(route.id) ? {
              ...route,
              saveStatus: null
            } : route);
          }

          this.cdRef.markForCheck();
        }),
        filter(({ savedRouteIds }) => !!savedRouteIds.length),
        concatMap(({ savedRouteIds }) => this.updateRoutesByIds(savedRouteIds))
      )
      .subscribe();

    this.activitiesUpdateOn.next();
  }

  updateRoutesByIds(routeIds: number[]) {
    return this.routesTableService.getWeekly(
      {
        ...this.getRoutesParams(),
        routeIds
      },
      true
    )
      .pipe(
        filter(data => !!(data.success && data.value)),
        tap(data => {
          if (data.value.length) {
            this.routes = this.routes.map(route => {
              const routeUpdate = data.value.find(item => item.id === route.id);

              if (routeUpdate) {
                return {
                  ...route,
                  ...this.parseRoute(routeUpdate)
                };
              }

              return route;
            });
          } else {
            this.routes = this.routes.filter(route => !routeIds.includes(route.id));
            this.visibleRoutesAmountChanged.emit(this.routes.length);
          }

          this.cdRef.markForCheck();
        })
      );
  }

  openLockedByTooltip(route: RouteWeeklyRow, tooltip: UTooltipDirective) {
    if (tooltip.isOpen()) {
      return;
    }

    this.lockedByTooltipIdsClosed = this.lockedByTooltipIdsClosed.filter(tooltipId => tooltipId !== tooltip.uTooltipWindowId);

    if (route.lockState) {
      this.routeLockState = route.lockState;

      tooltip.open();

      return;
    }

    this.routeLockStateService.getRouteLockState(route.id)
      .pipe(first())
      .subscribe(
        routeLockState => {
          if (this.lockedByTooltipIdsClosed.some(tooltipId => tooltipId === tooltip.uTooltipWindowId)) {
            this.lockedByTooltipIdsClosed = this.lockedByTooltipIdsClosed.filter(tooltipId => tooltipId !== tooltip.uTooltipWindowId);

            return;
          }

          this.routes = this.routes.map(obj => obj.id === route.id ? { ...obj, lockState: routeLockState } : obj);
          this.routeLockState = routeLockState;

          tooltip.open();
        }
      );
  }

  closeLockedByTooltip(tooltip: UTooltipDirective) {
    tooltip.close();

    this.lockedByTooltipIdsClosed.push(tooltip.uTooltipWindowId);
  }
}
