<app-floating-area
  [position]="aiChatDataService.position()"
  [size]="aiChatDataService.size()"
  [minSize]="aiChatConfig.minSize"
  [isFullScreenMode]="aiChatDataService.isFullScreenMode()"
  [isRtl]="isRtl"
  [topBarTemplate]="topBarTemplate"
  (positionChange)="aiChatDataService.updateState({ position: $event })"
  (sizeChange)="aiChatDataService.updateState({ size: $event })"
>
  @if (aiChatDataService.selectedConversation()) {
    <app-ai-chat-selected-conversation />
  } @else {
    <app-ai-chat-conversations
      [conversations]="aiChatDataService.conversationsSortedByDate()"
      (selectConversation)="aiChatDataService.selectConversation($event)"
    />
  }
</app-floating-area>

<ng-template #topBarTemplate>
  <div class="ai-chat-header">
    @if (!aiChatDataService.selectedConversation()) {
      <div class="ai-chat-header__title">
        {{config().dictionary.title | translate}}
      </div>
    }

    <div class="ai-chat-header__actions">
      @if (aiChatDataService.selectedConversation()) {
        <div class="ai-chat-header__actions-item ai-chat-header__actions-item_back" (click)="onAiChatAction(aiChatAction.Back)"></div>
      }
  
      <div class="ai-chat-header__actions-item ai-chat-header__actions-item_new-chat" (click)="onAiChatAction(aiChatAction.NewChat)"></div>
      <div
        class="ai-chat-header__actions-item"
        [ngClass]="{
          'ai-chat-header__actions-item_full-screen': aiChatDataService.isFullScreenMode(),
          'ai-chat-header__actions-item_default': !aiChatDataService.isFullScreenMode()
        }"
        (click)="onAiChatAction(aiChatAction.SwitchMode)"
      ></div>
      <div class="ai-chat-header__actions-item ai-chat-header__actions-item_close" (click)="onAiChatAction(aiChatAction.Close)"></div>
    </div>
  </div>
</ng-template>
