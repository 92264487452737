import { inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { ApiService } from '@app/shared/services';
import { AiChatConversation } from '@app/ai-chat/models';

@Injectable()
export class AiChatService {
  private apiService = inject(ApiService);

  private BASE_PATH: string = 'AiChat';

  getConversations(): Observable<Record<number, AiChatConversation>> {
    return of([ 1, 2, 3 ].reduce((acc: Record<number, AiChatConversation>, id) => ({
      ...acc,
      [id + 1]: {
        id: id + 1,
        messages: [
          {
            id: 3 * id + 2,
            text: 'What is the average occupancy rate in vehicles by vehicle type?',
            user: 'Israel Israeli',
            sendDate: `2025-01-01T12:${10 * id + 12}:11`,
            tableId: null
          },
          {
            id: 3 * id + 3,
            text: 'A table is attached with the requested information:',
            user: null,
            sendDate: `2025-01-01T12:${10 * id + 13}:11`,
            tableId: 1
          }
        ]
      }
    }), {}));
  }

  createConversation(tempId: number, messageText: string, messageUser: string): Observable<AiChatConversation> {
    return of({
      id: tempId + 1,
      messages: [
        {
          id: tempId * 3 + 1,
          text: messageText,
          user: messageUser,
          sendDate: new Date().toISOString(),
          tableId: null
        }
      ]
    });
  }

  getTable(id: number): Observable<{ columns: { prop: string; name: string; }[]; rows: any[]; }> {
    return of({
      columns: [
        { prop: 'routeName', name: 'Route name' },
        { prop: 'code', name: 'Code' },
        { prop: 'direction', name: 'Direction' },
        { prop: 'startTime', name: 'Start time' },
        { prop: 'endTime', name: 'End time' },
        { prop: 'stations', name: 'Stations' },
        { prop: 'passengers', name: 'Passengers' }
      ],
      rows: [
        {
          routeName: 'Route name 1',
          code: '1',
          direction: 'Pickup',
          startTime: '06:00',
          endTime: '08:00',
          stations: 1,
          passengers: 1
        },
        {
          routeName: 'Route name 2',
          code: '2',
          direction: 'Pickup',
          startTime: '08:00',
          endTime: '10:00',
          stations: 2,
          passengers: 2
        },
        {
          routeName: 'Route name 3',
          code: '3',
          direction: 'Pickup',
          startTime: '06:00',
          endTime: '08:00',
          stations: 1,
          passengers: 1
        },
        {
          routeName: 'Route name 4',
          code: '4',
          direction: 'Pickup',
          startTime: '08:00',
          endTime: '10:00',
          stations: 2,
          passengers: 2
        }
      ]
    });
  }
}
