import { Component, HostBinding, Input, OnDestroy, OnInit, signal } from '@angular/core';
import { Subject } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { cloneDeep } from 'lodash';

import { LocalizationService, TrackingService } from '@app/shared/services';
import { AuthModuleAccompanyFeatureType } from '@app/auth/models';
import { AuthDataService } from '@app/auth/services';
import { BuilderActiveRide } from '@app/builder/models';
import { BuilderModalService, BuilderDataService } from '@app/builder/services';
import { BuilderMapFilterComponent } from '../builder-map-filter/builder-map-filter.component';
import { builderInfoComponentConfig } from './builder-info.component.config';

@Component({
  selector: 'app-builder-info',
  templateUrl: './builder-info.component.html',
  styleUrls: [ './builder-info.component.scss', './builder-info.component.rtl.scss' ]
})
export class BuilderInfoComponent implements OnInit, OnDestroy {
  @Input() mapRide: any;
  @Input() activeRide: BuilderActiveRide;
  @Input() showEyeFilters: boolean = true;

  @HostBinding('class') hostClasses: string = 'builder-info';

  private unsubscribe: Subject<void> = new Subject();

  readonly #config = signal(cloneDeep(builderInfoComponentConfig));

  readonly config = this.#config.asReadonly();

  mapFilterRef: BsModalRef;
  distanceDictionary = this.localizationService.getDistanceDictionary();

  displayAccompany: boolean;

  constructor(
    private localizationService: LocalizationService,
    private authDataService: AuthDataService,
    private builderModalService: BuilderModalService,
    private trackingService: TrackingService,
    private builderDataService: BuilderDataService
  ) {}

  ngOnInit() {
    this.setAccompanyDisplay();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private setAccompanyDisplay() {
    this.authDataService.modules$
      .pipe(
        map(modules => modules?.accompany?.type),
        take(1),
        takeUntil(this.unsubscribe)
      )
      .subscribe(type => this.displayAccompany = type === AuthModuleAccompanyFeatureType.Generic);
  }

  traffic() {
    if (this.builderDataService.modeAdd()) {
      this.trackingService.track('[Route Add, Map tab] - click on traffic');
    } else {
      this.trackingService.track('[Route Edit, Map tab] - click on traffic');
    }
  }

  filter() {
    if (this.builderDataService.modeAdd()) {
      this.trackingService.track('[Route Add, Map tab] - click on eye icon');
    } else {
      this.trackingService.track('[Route Edit, Map tab] - click on eye icon');
    }

    this.mapFilterRef = this.builderModalService.show(
      BuilderMapFilterComponent,
      {
        class: 'u-modal u-modal_app-builder-popup',
        animated: true,
        initialState: {
          filter: {
            ...this.mapRide.filter
          }
        },
        ignoreBackdropClick: true
      },
      this.builderDataService.modal()
    );

    this.mapFilterRef
      .content
      .action
      .subscribe((action: any) => {
        if (action.type === 'apply') {
          this.builderDataService.mapFilter(action.content.filter);
        }
      });
  }
}
