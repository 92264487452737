import { RouteDirection } from '@app/routes/models';

const DICTIONARY_PATH: string = 'builder.duplicateRoute';

export const builderDuplicateRouteComponentConfig = {
  directions: [
    { value: RouteDirection.Forward, name: 'general.direction.forward' },
    { value: RouteDirection.Backward, name: 'general.direction.backward' }
  ],
  presetsStore: [
    { value: 'all', name: `${DICTIONARY_PATH}.presets.all` },
    { value: 'today', name: `${DICTIONARY_PATH}.presets.today` },
    { value: 'fromTodayOn', name: `${DICTIONARY_PATH}.presets.fromTodayOn` },
    { value: 'custom', name: `${DICTIONARY_PATH}.presets.custom` }
  ],
  routeNameDivider: '>',
  dictionary: {
    uInputDateRange: 'uInputDateRange',
    uSelectS: 'uSelectS',
    select: 'general.select',
    routeName: `${DICTIONARY_PATH}.routeName`,
    direction: `${DICTIONARY_PATH}.direction`,
    time: `${DICTIONARY_PATH}.time`,
    period: `${DICTIONARY_PATH}.period`,
    cancel: `${DICTIONARY_PATH}.cancel`,
    saveAndEdit: `${DICTIONARY_PATH}.saveAndEdit`,
    saveAndClose: `${DICTIONARY_PATH}.saveAndClose`
  }
};
