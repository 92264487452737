import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
  inject,
  DestroyRef,
  signal,
  effect,
  computed
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { filter, switchMap, take, tap } from 'rxjs/operators';
import { TranslateModule } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';
import { UTooltipModule } from '@shift/ulib';
import moment from 'moment';

import { SharedModule } from '@app/shared/shared.module';
import {
  TrackingService,
  ConstantsService,
  HeaderSearchFiltersService,
  LocalizedToastrService,
  TablePageService,
  MultiDeleteConfirmModalService
} from '@app/shared/services';
import { CellTemplates, ModalActions } from '@app/shared/models';
import { AppConstants } from '@app/shared/constants';
import { AuthDataSnapshotService } from '@app/auth/services';
import { AccompaniesDataService, AccompaniesService } from '@app/accompanies/services';
import { AccompaniesAddEditActionMode, Accompany } from '@app/accompanies/models';
import { accompaniesComponentConfig } from './accompanies.component.config';

@Component({
  selector: 'app-accompanies',
  templateUrl: './accompanies.component.html',
  providers: [ BsModalRef, TablePageService, AuthDataSnapshotService ],
  standalone: true,
  imports: [
    SharedModule,
    UTooltipModule,
    TranslateModule
  ]
})
export class AccompaniesComponent implements OnInit {
  @ViewChild('commentCell') private commentCell: TemplateRef<any>;
  @ViewChild('statusCell') private statusCell: TemplateRef<any>;

  private readonly destroyRef = inject(DestroyRef);
  private readonly toastr = inject(LocalizedToastrService);
  private readonly trackingService = inject(TrackingService);
  private readonly multiDeleteConfirmModalService = inject(MultiDeleteConfirmModalService);
  private readonly headerSearchFiltersService = inject(HeaderSearchFiltersService);
  private readonly accompaniesService = inject(AccompaniesService);
  private readonly constantsService = inject(ConstantsService);
  private readonly accompaniesDataService = inject(AccompaniesDataService);
  private readonly tablePageService = inject(TablePageService);
  private readonly authDataSnapshotService = inject(AuthDataSnapshotService);

  private statusTypes: { id: number; name: string; }[];

  readonly #accompanies = signal([]);
  readonly #config = signal(cloneDeep(accompaniesComponentConfig));
  readonly #search = toSignal(this.headerSearchFiltersService.appliedSearch$.pipe(tap(() => this.trackEvent('search'))));

  readonly rows = computed(() => {
    const accompanies = this.#accompanies();
    const search = this.#search();

    return accompanies
      .map(row => ({
        ...row,
        id: row.memberId,
        status: this.statusTypes.find(item => item.id === row.status)?.name || null,
        birthDate: row.birthDate && moment(row.birthDate, AppConstants.DATE_FORMAT_ISO).format(AppConstants.DATE_FORMAT_BASE_DOT)
      }))
      .filter(row => Object.keys(row).some(key => `${row[key]}`.toLowerCase().includes(search.toLowerCase())));
  });
  readonly config = this.#config.asReadonly();

  constructor() {
    effect(() => this.tablePageService.initRows(this.rows()));
  }

  ngOnInit() {
    this.initTableConfig();
    this.getAccompanyStatusType();
  }

  private trackEvent(event: string) {
    this.trackingService.track(`[${this.config().tablePageConfig.trackingId}] - ${event}`);
  }

  private initTableConfig() {
    this.#config.set({
      ...this.config(),
      tablePageConfig: {
        ...this.config().tablePageConfig,
        tableConfig: {
          ...this.config().tablePageConfig.tableConfig,
          columns: this.config().tablePageConfig.tableConfig.columns.map(column => ({
            ...column,
            ...(column.cellTemplateName === CellTemplates.EditRow && !this.authDataSnapshotService.manageAccompaniesPermission() ? { cellTemplateName: CellTemplates.EditViewRow } : {})
          })),
          cellTemplates: {
            statusCell: this.statusCell,
            commentCell: this.commentCell
          }
        }
      }
    });
  }

  private getAccompanyStatusType() {
    this.constantsService.getConstants('accompanyStatusType')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(constants => {
        this.statusTypes = constants.accompanyStatusType;

        this.getAccompanies();
      });
  }

  private deleteAccompanies() {
    this.accompaniesService.deleteAccompanies(this.tablePageService.selectedRows.map(row => row.id))
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.trackEvent('delete');
        this.getAccompanies();

        this.tablePageService.clearSelectedRows();
        this.toastr.success(this.config().dictionary.successful);
      });
  }

  private openModal(accompany?: Accompany) {
    this.accompaniesDataService.openAddEditModal(accompany).content.action
      .pipe(
        take(1),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(({ type, value, mode }) => {
        switch (type) {
          case ModalActions.Submit: {
            if (mode === AccompaniesAddEditActionMode.Add) {
              this.tablePageService.updateResetColumnsFilter(true);
            }

            this.getAccompanies(value?.accompanyId);

            break;
          }

          case ModalActions.Close: {
            this.tablePageService.highlightRow(value?.accompanyId);

            break;
          }

          case ModalActions.Delete: {
            this.getAccompanies();

            break;
          }
        }
      });
  }

  getAccompanies(id?: number) {
    this.accompaniesService.getAccompanies()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(response => {
        this.#accompanies.set(response.value);

        this.tablePageService.highlightRow(id);
      });
  }

  deleteConfirmAccompanies() {
    const deleteModalRef = this.multiDeleteConfirmModalService.openModal({
      deleteConfirmType: this.config().dictionary.deleteConfirmType,
      itemsToDeleteAmount: this.tablePageService.selectedRows.length
    });

    deleteModalRef.content.action
      .pipe(
        filter(action => action === ModalActions.Save),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => {
        this.deleteAccompanies();

        deleteModalRef.hide();
      });
  }

  addRow() {
    this.openModal();
  }

  editRow(id: number) {
    this.trackEvent('Edit accompany clicked');
    this.openModal(this.tablePageService.rows.find(row => row.id === id));
  }

  exportToExcel() {
    this.trackEvent('export to excel');

    this.tablePageService.getExcelExportObject()
      .pipe(
        switchMap(({ data, fileName }) =>
          this.accompaniesService.exportToExcel(
            {
              columns: data.columns,
              accompanyIds: data.ids
            },
            fileName
          )
        ),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }
}
