import { BehaviorSubject, from, throwError } from 'rxjs';
import { inject, Injectable } from '@angular/core';
import { OAuthService, TokenResponse } from 'angular-oauth2-oidc';
import { catchError, filter, map, take, tap } from 'rxjs/operators';
import { pick } from 'lodash';

import { AuthUserInfo } from '@app/auth/models';
import { appConfig } from '@app/shared/configs';
import { authConfig } from '@app/auth/configs';
import { AuthFacade } from '@app/auth/state/facades';
import { aiChatConfig } from '@app/ai-chat/configs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private readonly oAuthService = inject(OAuthService);
  private readonly authFacade = inject(AuthFacade);

  private refreshedAccessToken: BehaviorSubject<string> = new BehaviorSubject(null);
  private isRefreshingAccessToken: boolean;

  private clearLocalStorage(exceptions: string[]) {
    const localStorageKeys = Object.keys(localStorage);

    for (const localStorageKey of localStorageKeys) {
      if (!exceptions.includes(localStorageKey)) {
        localStorage.removeItem(localStorageKey);
      }
    }
  }

  clearUserTablesColumnsFiltersLocalStorage() {
    const usersTablesProps = localStorage.getItem(appConfig.storageKeys.usersTablesProps);
    const usersTablesPropsJson = usersTablesProps ? JSON.parse(usersTablesProps) : null;
    const userId = localStorage.getItem('userId');

    if (usersTablesPropsJson && usersTablesPropsJson[userId]) {
      const usersTablesPropsUpdate = {
        ...usersTablesPropsJson
      };

      for (const key in usersTablesPropsUpdate[userId]) {
        if (usersTablesPropsUpdate[userId].hasOwnProperty(key)) {
          usersTablesPropsUpdate[userId][key] = {
            ...usersTablesPropsUpdate[userId][key],
            columnsFiltersStore: []
          };
        }
      }

      localStorage.setItem(appConfig.storageKeys.usersTablesProps, JSON.stringify(usersTablesPropsUpdate));
    }
  }

  clearStorages() {
    this.clearLocalStorage([ appConfig.storageKeys.usersTablesProps, aiChatConfig.storageKey ]);

    sessionStorage.clear();
  }

  setLocalStorageUserInfo(userInfo: AuthUserInfo) {
    localStorage.setItem('userId', userInfo ? JSON.stringify(userInfo.person.memberId) : null);
  }

  switchRole(customerId: number, role: number) {
    this.oAuthService.customQueryParams = {
      customer_id: customerId,
      role,
      grant_type: 'switch_role'
    };

    return from(this.oAuthService.refreshToken());
  }

  loadUserProfile() {
    return from(this.oAuthService.loadUserProfile())
      .pipe(
        map((data: any) => pick(data.info, authConfig.userProfileFields)),
        tap(data => this.setLocalStorageUserInfo(data))
      );
  }

  isExpiredAccessToken() {
    const accessTokenExpiration = this.oAuthService.getAccessTokenExpiration();
    const currentTime = new Date().getTime() + (authConfig.accessTokenExtraTime * 1000);

    return !!(accessTokenExpiration && accessTokenExpiration <= currentTime);
  }

  refreshAccessToken() {
    if (!this.isRefreshingAccessToken) {
      this.isRefreshingAccessToken = true;
      this.refreshedAccessToken.next(null);

      return from(this.oAuthService.refreshToken())
        .pipe(
          catchError(err => {
            this.isRefreshingAccessToken = false;

            this.clearStorages();
            this.oAuthService.initLoginFlow();

            return throwError(err);
          }),
          map((data: TokenResponse) => {
            this.isRefreshingAccessToken = false;

            this.authFacade.updateUser();

            this.refreshedAccessToken.next(data.access_token);

            return data.access_token;
          })
        );
    }

    return this.refreshedAccessToken.pipe(
      filter(token => token !== null),
      take(1)
    );
  }
}

