<app-sidebar-menu></app-sidebar-menu>

<div class="layout__main">
  <app-header></app-header>
  <div class="layout__countdown-counter" *ngIf="countdownCounter$ | async as countdownCounter">
    <div class="layout__countdown-counter-content">{{config.dictionary.logoutCountdownCounter.message | translate}} {{countdownCounter}} {{config.dictionary.logoutCountdownCounter.seconds | translate}}.</div>
  </div>
  <div class="layout__wrapper">
    <div class="layout__container">
      <div class="layout__progress">
        <ng-progress
          id="{{progressBarId}}"
          class="ngx-progressbar"
          [spinner]="false"
          [thick]="true"
          [color]="'#666'" 
          [direction]="isRtl ? 'rtl+' : 'ltr+'"
        ></ng-progress>
      </div>
      <div
        class="layout__content"
        [class.layout__content_filters-drawer-open]="
          headerDataService.showGlobalSearch &&
          (headerSearchFiltersService.existFilters$ | async) && 
          (headerSearchFiltersService.showFiltersDrawer$ | async)
        "
        [class.layout__content_global-search]="headerDataService.showGlobalSearch"
      >
        <div
          *ngIf="commonService.showNotes()"
          class="layout__content-side-panel"
        >
          <app-notes
            class="layout__content-notes"
            (closeNotes)="commonService.updateVisibleComponent(visibleComponent.Notes, false)"
          ></app-notes>
        </div>
        <div
          *ngIf="commonService.showActivities()"
          class="layout__content-side-panel"
        >
          <app-activities
            class="layout__content-activities"
            (closeActivities)="commonService.updateVisibleComponent(visibleComponent.Activities, false);"
          ></app-activities>
        </div>
      
        <router-outlet></router-outlet>
      </div>  
    </div>
  </div>
</div>

@if (aiChatFeatureType() && aiChatPermission() && commonService.showAiChat()) {
  <app-ai-chat />
}
