import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { UButtonModule, UCommonModule, UPopoverModule, UTooltipModule } from '@shift/ulib';

import { SharedModule } from '@app/shared/shared.module';
import { IncludesPipe, MomentConvertPipe } from '@app/shared/pipes';
import {
  ActivitiesComponent,
  ActivitiesItemComponent,
  ActivitiesFiltersComponent
} from '@app/activities/components';
import {
  ActivitiesSearchPipe,
  ActivitiesPassengerChangeExtraDataPipe,
  ActivitiesPassengerChangeDashPipe,
  ActivityShuttleCompanyHasMoreThanSingleContractPipe
} from '@app/activities/pipes';

const COMPONENTS = [
  ActivitiesComponent,
  ActivitiesItemComponent,
  ActivitiesFiltersComponent
];

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    UCommonModule,
    UPopoverModule,
    UTooltipModule,
    UButtonModule,
    SharedModule,
    IncludesPipe,
    MomentConvertPipe,
    ActivitiesPassengerChangeDashPipe,
    ActivitiesPassengerChangeExtraDataPipe,
    ActivitiesSearchPipe,
    ActivityShuttleCompanyHasMoreThanSingleContractPipe
  ],
  declarations: [
    ...COMPONENTS
  ],
  exports: [
    ...COMPONENTS
  ]
})
export class ActivitiesModule {}
