import {
  Component,
  DestroyRef,
  EventEmitter,
  HostBinding,
  inject,
  OnDestroy,
  OnInit,
  Output,
  signal
} from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, Validators } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslateModule } from '@ngx-translate/core';
import { filter } from 'rxjs/operators';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { cloneDeep } from 'lodash';
import { UCommonModule, UPopupService } from '@shift/ulib';

import { LocalizationService, LocalizedToastrService, TrackingService, ValidationService } from '@app/shared/services';
import { ModalActions } from '@app/shared/models';
import { AuthDataSnapshotService } from '@app/auth/services';
import { AccompaniesService } from '@app/accompanies/services/accompanies.service';
import {
  AccompaniesAddEditActionMode,
  AccompaniesAddEditMode,
  Accompany,
  AccompanyAddEdit,
  AccompanyAddEditAction,
  AccompanyAddEditForm
} from '@app/accompanies/models';
import { AccompaniesAddEditInfoComponent } from './accompanies-add-edit-info/accompanies-add-edit-info.component';
import { accompaniesAddEditComponentConfig } from './accompanies-add-edit.component.config';

@Component({
  selector: 'app-accompanies-add-edit',
  templateUrl: './accompanies-add-edit.component.html',
  providers: [ AuthDataSnapshotService ],
  standalone: true,
  imports: [
    AccompaniesAddEditInfoComponent,
    TranslateModule,
    TabsModule,
    UCommonModule
  ]
})
export class AccompaniesAddEditComponent implements OnInit, OnDestroy {
  @Output() action = new EventEmitter<AccompanyAddEditAction>();

  @HostBinding('class') hostClasses: string = 'accompanies-add-edit';

  private readonly destroyRef = inject(DestroyRef);
  private readonly fb = inject(UntypedFormBuilder);
  private readonly modalRef = inject(BsModalRef);
  private readonly uPopupService = inject(UPopupService);
  private readonly validationService = inject(ValidationService);
  private readonly accompaniesService = inject(AccompaniesService);
  private readonly trackingService = inject(TrackingService);
  private readonly toastr = inject(LocalizedToastrService);
  private readonly localizationService = inject(LocalizationService);
  private readonly authDataSnapshotService = inject(AuthDataSnapshotService);

  readonly #config = signal(cloneDeep(accompaniesAddEditComponentConfig));

  readonly config = this.#config.asReadonly();

  addEditForm: AccompanyAddEditForm = this.fb.group({
    id: [ 0 ],
    accompanyId: [ 0 ],
    firstName: [ '', Validators.required ],
    lastName: [ '', Validators.required ],
    identity: [ '' ],
    address: [ '', Validators.required ],
    latitude: [ 0, Validators.required ],
    longitude: [ 0, Validators.required ],
    placeId: [ null ],
    birthDate: [ '' ],
    jobPercentage: [ '' ],
    statusId: [ 0 ],
    comment: [ '' ],
    contacts: this.fb.array([])
  });
  editData: Accompany;
  mode: AccompaniesAddEditMode = AccompaniesAddEditMode.Default;
  isRtl = this.localizationService.isRtl();

  ngOnInit() {
    this.initConfig();

    if (!this.authDataSnapshotService.manageAccompaniesPermission()) {
      this.addEditForm.disable();
    }

    if (this.editData && this.editData.memberId) {
      this.accompaniesService.getAccompany(this.editData.memberId)
        .pipe(
          takeUntilDestroyed(this.destroyRef),
          filter(response => response.success)
        )
        .subscribe(
          ({ value }) => this.updateForm(value),
          () => this.uPopupService.showErrorMessage({ message: this.config().dictionary.error })
        );
    }

    const contactsForm = this.addEditForm.get('contacts');
    const identifierForm = this.addEditForm.get('identity');

    identifierForm.setValidators([
      Validators.minLength(9),
      Validators.maxLength(9),
      ValidationService.number,
      ValidationService.identity,
      this.validationService.checkIdentifierPersonContactsAccompany.bind(this, contactsForm)
    ]);
  }

  ngOnDestroy() {
    this.modalRef.hide();
  }

  private initConfig() {
    this.#config.set({
      ...this.config(),
      buttons: this.config().buttons.filter(button => !button.permission || this.authDataSnapshotService.checkPermission(button.permission))
    });
  }

  onLoadAccompany(event) {
    this.loadAccompany(event);
  }

  loadAccompany(id) {
    this.accompaniesService.getAccompany(id)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter(response => response.success)
      )
      .subscribe(
        response => {
          if (this.mode === AccompaniesAddEditMode.AssignToRoute) {
            this.action.emit({ type: ModalActions.Submit, value: response.value });
            this.modalRef.hide();
          } else {
            this.updateForm(response.value);
          }
        }, () => this.uPopupService.showErrorMessage({ message: this.config().dictionary.error }));
  }

  checkActions(action: ModalActions) {
    switch (action) {
      case ModalActions.Submit: {
        this.saveAccompany();
        break;
      }

      case ModalActions.Delete: {
        this.deleteAccompany();
        break;
      }

      case ModalActions.Shutdown: {
        this.closeAccompany();
        break;
      }
    }
  }

  updateForm(data: AccompanyAddEdit) {
    this.addEditForm.patchValue({
      id: data.id,
      accompanyId: data.accompanyId,
      firstName: data.firstName,
      lastName: data.lastName,
      identity: data.identity,
      address: data.address,
      birthDate: data.birthDate,
      jobPercentage: data.jobPercentage,
      statusId: data.statusId,
      comment: data.comment,
      latitude: data.latitude,
      longitude: data.longitude,
      placeId: data.placeId
    });

    if (data.contacts) {
      this.updateAccompanyContacts(data.contacts);
    }
  }

  updateAccompanyContacts(accompanyContacts) {
    const accompanyContactsForm = this.addEditForm.get('contacts') as UntypedFormArray;

    accompanyContactsForm.controls.forEach((ob: any, i: number) => accompanyContactsForm.removeAt(i));

    accompanyContacts.forEach((accompanyContact: any) => {
      const personContactForm = this.fb.group({
        id: [ accompanyContact.id ],
        type: [ accompanyContact.type, [ Validators.required ] ],
        value: [ accompanyContact.value ],
        useForUpdates: [ accompanyContact.useForUpdates ],
        del: [ accompanyContact.del ]
      });

      const type = personContactForm.get('type');
      const value = personContactForm.get('value');

      this.validationService.updateValidationContactData(value, type.value);

      type.valueChanges.subscribe((v: any) => {
        if (!v) { return; }

        this.validationService.updateValidationContactData(value, v);
      });

      accompanyContactsForm.push(personContactForm);
    });

    if (!this.authDataSnapshotService.manageAccompaniesPermission()) {
      accompanyContactsForm.disable();
    }
  }

  deleteAccompany() {
    this.trackingService.track('[Accompany] - delete');

    this.uPopupService.showMessage({
      message: this.config().dictionary.deleteConfirm,
      yes: this.config().dictionary.yes,
      no: this.config().dictionary.no
    }, () => {
      const accompanyId = this.addEditForm.get('accompanyId').value;
      if (accompanyId > 0) {
        this.accompaniesService.deleteAccompanies([ accompanyId ])
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe(() => {
            this.trackingService.track('[Accompany] - delete');

            this.action.emit({
              type: ModalActions.Delete,
              value: { accompanyId }
            });

            this.modalRef.hide();
          });
      }

      this.modalRef.hide();
    });
  }

  saveAccompany() {
    this.trackingService.track(`Accompany] - Accompany ${this.editData ? 'edit' : 'add'} saved`);

    if (this.addEditForm.valid) {
      this.accompaniesService.saveAccompany(this.addEditForm.value as AccompanyAddEdit)
        .pipe(
          takeUntilDestroyed(this.destroyRef),
          filter(response => response && response.success)
        )
        .subscribe(({ value }) => {
          this.trackingService.track(`[Accompany] - ${this.addEditForm.value.id > 0 ? 'edit' : 'add'}`);

          this.action.emit({
            value,
            type: ModalActions.Submit,
            mode: this.editData ? AccompaniesAddEditActionMode.Edit : AccompaniesAddEditActionMode.Add
          });

          this.modalRef.hide();
          this.toastr.success(this.config().dictionary.successful);
        });
    } else {
      this.uPopupService.showErrorMessage({ message: this.config().dictionary.error });
    }
  }

  closeAccompany() {
    if (!this.addEditForm.dirty) {
      this.action.emit({ type: ModalActions.Close, value: this.editData ? this.editData.memberId : null });

      return;
    }

    if (this.addEditForm.touched) {
      if (this.uPopupService.popupRef) { return; }

      this.uPopupService.showMessage(
        {
          showXIcon: true,
          message: this.config().dictionary.closeConfirm,
          yes: this.config().dictionary.yes,
          no: this.config().dictionary.no
        },
        () => this.saveAccompany(),
        () => {
          this.modalRef.hide();
          this.action.emit({ type: ModalActions.Close, value: this.editData ? this.editData.memberId : null });
        }
      );
    } else {
      this.modalRef.hide();
    }
  }

  closeModal() {
    this.modalRef.hide();

    this.action.emit({ type: ModalActions.Close, value: null });
  }
}
