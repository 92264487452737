import { ChangeDetectionStrategy, Component, ElementRef, forwardRef, HostBinding, inject, OnInit, signal, ViewChild, OnDestroy } from '@angular/core';
import { NgClass } from '@angular/common';
import { cloneDeep, omit } from 'lodash';
import { TranslateModule } from '@ngx-translate/core';

import { FloatingAreaComponent } from '@app/shared/components';
import { VisibleComponent } from '@app/shared/models';
import { CommonService, LocalizationService } from '@app/shared/services';
import { AuthDataSnapshotService } from '@app/auth/services';
import { aiChatConfig } from '@app/ai-chat/configs';
import { AiChatAction, AiChatMode, AiChatState } from '@app/ai-chat/models';
import { AiChatDataService, AiChatService, AiChatStorageService } from '@app/ai-chat/services';
import { AiChatSelectedConversationComponent } from './ai-chat-selected-conversation/ai-chat-selected-conversation.component';
import { AiChatConversationsComponent } from './ai-chat-conversations/ai-chat-conversations.component';
import { aiChatComponentConfig } from './ai-chat.component.config';

@Component({
  selector: 'app-ai-chat',
  standalone: true,
  imports: [
    NgClass,
    TranslateModule,
    forwardRef(() => FloatingAreaComponent),
    AiChatSelectedConversationComponent,
    AiChatConversationsComponent
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './ai-chat.component.html',
  styleUrls: [ './ai-chat.component.scss', './ai-chat.component.rtl.scss' ],
  providers: [ AuthDataSnapshotService, AiChatStorageService, AiChatDataService, AiChatService ]
})
export class AiChatComponent implements OnInit, OnDestroy {
  @ViewChild('resizeCorner') resizeCornerRef: ElementRef;

  @HostBinding('class') hostClasses: string = 'ai-chat';

  private readonly commonService = inject(CommonService);
  private readonly localizationService = inject(LocalizationService);
  public readonly aiChatDataService = inject(AiChatDataService);

  readonly #config = signal(cloneDeep(aiChatComponentConfig));

  readonly config = this.#config.asReadonly();

  readonly isRtl: boolean = this.localizationService.isRtl();
  readonly aiChatAction = AiChatAction;
  readonly aiChatConfig = cloneDeep(aiChatConfig);

  ngOnInit() {
    this.aiChatDataService.initState();

    if (!this.aiChatDataService.conversations().length) {
      this.aiChatDataService.loadConversations();
    }
  }

  ngOnDestroy() {
    this.aiChatDataService.resetSelectedConversation();
  }

  onAiChatAction(action: AiChatAction) {
    switch (action) {
      case AiChatAction.Back: {
        const selectedConversation = this.aiChatDataService.selectedConversation();
        const state: Partial<AiChatState> = {
          selectedConversationId: null,
          selectedConversationMessageTableId: null
        };

        if (selectedConversation?.id === 0 && !!selectedConversation?.messages.length) {
          state.conversationsById = omit(this.aiChatDataService.conversationsById(), [ selectedConversation.id ]);
        }

        this.aiChatDataService.updateState(state);

        break;
      }

      case AiChatAction.SwitchMode: {
        this.aiChatDataService.updateState({
          mode: this.aiChatDataService.mode() === AiChatMode.Default
            ? AiChatMode.FullScreen
            : AiChatMode.Default,
          selectedConversationMessageTableId: null
        });

        break;
      }

      case AiChatAction.NewChat: {
        if (this.aiChatDataService.selectedConversationId() !== 0) {
          this.aiChatDataService.newConversation();
        }

        break;
      }

      case AiChatAction.Close: {
        this.commonService.updateVisibleComponent(VisibleComponent.AiChat, false);

        break;
      }
    }
  }
}
