import { inject, Pipe, PipeTransform } from '@angular/core';

import { ActivityLive } from '@app/activities/models';
import { ActivitiesDataService } from '@app/activities/services';

@Pipe({
  name: 'activitiesCounter',
  standalone: true
})
export class ActivitiesCounterPipe implements PipeTransform {
  private readonly activitiesDataService = inject(ActivitiesDataService);

  transform(activities: ActivityLive[]): number {
    return this.activitiesDataService.calculateActivitiesCounter(activities);
  }
}
