import { DestroyRef, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UPopupService } from '@shift/ulib';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { ApiService } from '@app/shared/services';
import { Errors } from '@app/shared/models';
import { AuthDataService } from '@app/auth/services';
import { AuthModuleDemandsPassengersViewFeatureType } from '@app/auth/models';
import { FileSaverService } from '@app/files/services';
import {
  DemandsTableParams,
  DemandsTable,
  DemandsShift,
  DemandsShiftsParams,
  DemandsShiftsTime,
  DemandsShiftsTimeParams,
  DemandsShiftByTimeParams,
  DemandsPassengerShiftParams,
  DemandsPassengerShift,
  DemandsPassengerShiftDeleteParams,
  DemandsValidateRepeatPeriodParams,
  DemandsValidateRepeatPeriod,
  DemandsBulkPassengersShiftsParams,
  DemandsBulkPassengersShiftsWeekDuplicateParams,
  DemandsBulkPassengersShiftsWeekDeleteParams,
  DemandsTableRow,
  DemandsExcelParams,
  DemandsBulkPassengersShiftsDeleteParams,
  DemandsPassengerShiftsWeekDeleteParams,
  DemandsPassengerShiftsWeekDuplicateParams,
  DemandsPassengerShiftUpdate,
  DemandsPassengerBulkShiftUpdate,
  DemandsRemovePassengersFromRideParams
} from '@app/demands/models';

@Injectable()
export class DemandsPassengersViewService {
  private BASE_PATH: string = 'DemandsPassengersView';
  private BASE_PATH_TYPE: string = '';

  constructor(
    private destroyRef: DestroyRef,
    private apiService: ApiService,
    private fileSaverService: FileSaverService,
    private uPopupService: UPopupService,
    private modalRef: BsModalRef,
    private authDataService: AuthDataService
  ) {
    this.initBasePathType();
  }

  initBasePathType() {
    this.authDataService.modules$
      .pipe(
        map(modules => modules?.demandsPassengersView?.type),
        filter(data => !!data),
        take(1),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(type => this.BASE_PATH_TYPE = type === AuthModuleDemandsPassengersViewFeatureType.Generic ? '' : `${type}/`);
  }

  getTableData(params: DemandsTableParams): Observable<DemandsTable> {
    return this.apiService.post(`${this.BASE_PATH}/${this.BASE_PATH_TYPE}Table`, params);
  }

  getShifts(params: DemandsShiftsParams): Observable<DemandsShift[]> {
    return this.apiService.get(`${this.BASE_PATH}/${this.BASE_PATH_TYPE}Shifts`, params);
  }

  getTime(params: DemandsShiftsTimeParams): Observable<DemandsShiftsTime> {
    return this.apiService.get(`${this.BASE_PATH}/${this.BASE_PATH_TYPE}Time`, params);
  }

  getShiftByTime(params: DemandsShiftByTimeParams): Observable<number> {
    return this.apiService.get(`${this.BASE_PATH}/${this.BASE_PATH_TYPE}ShiftByTime`, params);
  }

  getPassengerShift(params: DemandsPassengerShiftParams): Observable<DemandsPassengerShift> {
    return this.apiService.get(`${this.BASE_PATH}/${this.BASE_PATH_TYPE}PassengerShift`, params);
  }

  savePassengerShift(params: DemandsPassengerShift): Observable<DemandsTableRow> {
    return this.apiService.post(`${this.BASE_PATH}/${this.BASE_PATH_TYPE}PassengerShift`, params, 'text', false, false, [ Errors.OverlapShift ]);
  }

  saveBulkPassengersShifts(params: DemandsBulkPassengersShiftsParams): Observable<DemandsPassengerBulkShiftUpdate> {
    return this.apiService.post(`${this.BASE_PATH}/${this.BASE_PATH_TYPE}Bulk/PassengersShifts`, params);
  }

  duplicateBulkPassengersShiftsWeek(params: DemandsBulkPassengersShiftsWeekDuplicateParams): Observable<DemandsPassengerBulkShiftUpdate> {
    return this.apiService.post(`${this.BASE_PATH}/${this.BASE_PATH_TYPE}Bulk/PassengersShifts/Week/Duplicate`, params);
  }

  deleteBulkPassengersShiftsWeek(params: DemandsBulkPassengersShiftsWeekDeleteParams): Observable<DemandsPassengerBulkShiftUpdate> {
    return this.apiService.delete(`${this.BASE_PATH}/${this.BASE_PATH_TYPE}Bulk/PassengersShifts/Week`, {}, params);
  }

  updatePassengerShift(params: DemandsPassengerShift): Observable<DemandsPassengerShiftUpdate> {
    return this.apiService.put(`${this.BASE_PATH}/${this.BASE_PATH_TYPE}PassengerShift`, params, false, [ Errors.OverlapShift ]);
  }

  deletePassengerShift(params: DemandsPassengerShiftDeleteParams): Observable<DemandsPassengerShiftUpdate> {
    return this.apiService.delete(`${this.BASE_PATH}/${this.BASE_PATH_TYPE}PassengerShift`, {}, params);
  }

  deleteBulkPassengersShifts(params: DemandsBulkPassengersShiftsDeleteParams): Observable<DemandsPassengerBulkShiftUpdate> {
    return this.apiService.delete(`${this.BASE_PATH}/${this.BASE_PATH_TYPE}Bulk/PassengersShift`, {}, params);
  }

  validateRepeatPeriod(params: DemandsValidateRepeatPeriodParams): Observable<DemandsValidateRepeatPeriod> {
    return this.apiService.post(`${this.BASE_PATH}/${this.BASE_PATH_TYPE}ValidateRepeatPeriod`, params);
  }

  exportToExcel(params: DemandsExcelParams, fileName: string) {
    this.apiService.postBlob(`${this.BASE_PATH}/${this.BASE_PATH_TYPE}Export/Excel`, params, 'blob')
      .subscribe(
        res => this.fileSaverService.downloadBlobFile(res, fileName),
        async err => {
          const periodError = err.error ? JSON.parse(await err.error.text()).errors.find(error => error.code === '4502') : null;

          if (periodError) {
            this.showErrorPopup(periodError.code);
          }
        }
      );
  }

  showErrorPopup(code) {
    this.uPopupService.showErrorMessage({ message: `general.errorCode.${code}` }, () => this.modalRef.hide());
  }

  deletePassengerShiftsWeek(params: DemandsPassengerShiftsWeekDeleteParams): Observable<DemandsPassengerShiftUpdate> {
    return this.apiService.delete(`${this.BASE_PATH}/${this.BASE_PATH_TYPE}PassengerShifts/Week`, {}, params);
  }

  duplicatePassengerShiftsWeek(params: DemandsPassengerShiftsWeekDuplicateParams): Observable<DemandsPassengerShiftUpdate> {
    return this.apiService.post(`${this.BASE_PATH}/${this.BASE_PATH_TYPE}PassengerShifts/Week/Duplicate`, params);
  }

  removePassengerFromRide(body: DemandsRemovePassengersFromRideParams): Observable<void> {
    return this.apiService.delete(`${this.BASE_PATH}/${this.BASE_PATH_TYPE}RemovePassengersFromRides`, {}, body);
  }
}
