import { DaysOfWeek } from '@app/shared/models';

const DICTIONARY_PATH: string = 'builder.daysDuplicate';

export const builderDaysDuplicateComponentConfig = {
  dictionary: {
    uSelectDays: 'uSelectDays',
    message: `${DICTIONARY_PATH}.message`,
    apply: `${DICTIONARY_PATH}.apply`
  },
  daysAvailable: [
    DaysOfWeek.Sunday,
    DaysOfWeek.Monday,
    DaysOfWeek.Tuesday,
    DaysOfWeek.Wednesday,
    DaysOfWeek.Thursday,
    DaysOfWeek.Friday,
    DaysOfWeek.Saturday
  ]
};
