import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  inject
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, UntypedFormControl, Validators } from '@angular/forms';
import { cloneDeep } from 'lodash';
import { TranslateModule } from '@ngx-translate/core';
import { UCommonModule } from '@shift/ulib';

import { SharedModule } from '@app/shared/shared.module';
import { AuthDataService } from '@app/auth/services';
import { AuthModuleSupervisorsFeatureType } from '@app/auth/models';
import { TablePageService, TrackingService } from '@app/shared/services';
import { GeneralCardButtonValue } from '@app/shared/models';
import { RouteDirection } from '@app/routes/models';
import { RouteSplitEmptyStationsAction, RouteSplitPassenger } from '@app/route-split/models';
import { routeSplitCardComponentConfig } from './route-split-card.component.config';

@Component({
  selector: 'app-route-split-card',
  templateUrl: './route-split-card.component.html',
  styleUrls: [ './route-split-card.component.scss', './route-split-card.component.rtl.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ CommonModule, TranslateModule, SharedModule, UCommonModule, ReactiveFormsModule ],
  providers: [ TablePageService ]
})
export class RouteSplitCardComponent implements OnChanges, OnInit {
  @Input() routePassengers: RouteSplitPassenger[];
  @Input() routeDirection: RouteDirection;
  @Input() allowEmptyStations: boolean;
  @Input() trackingId: string;
  @Input() loading: boolean;

  @Output() closeAction: EventEmitter<void> = new EventEmitter();
  @Output() continue: EventEmitter<{ passengerIds: number[]; keepEmptyStations: boolean; }> = new EventEmitter();

  @ViewChild('nameCell', { static: true }) nameCell: TemplateRef<any>;

  @HostBinding('class') hostClasses: string = 'route-split-card';

  private readonly destroyRef = inject(DestroyRef);
  private readonly trackingService = inject(TrackingService);
  private readonly tablePageService = inject(TablePageService);
  private readonly authDataService = inject(AuthDataService);

  config = cloneDeep(routeSplitCardComponentConfig);
  supervisorsFeatureType: AuthModuleSupervisorsFeatureType;
  emptyStationsAction: UntypedFormControl = new UntypedFormControl(null, Validators.required);

  ngOnChanges(changes: SimpleChanges) {
    if (changes.routePassengers) {
      this.tablePageService.initRows(this.routePassengers);
    }

    if (changes.loading) {
      this.toggleContinueButtonState();
    }
  }

  ngOnInit() {
    this.updateColumns();
    this.getSupervisorsFeatureType();
  }

  private getSupervisorsFeatureType() {
    this.supervisorsFeatureType = this.authDataService.modules()?.supervisors?.type;
  }

  private updateColumns() {
    this.config.table.tableConfig.columns = this.config.table.tableConfig.columns.map(column => ({
      ...column,
      ...(column.cellTemplateName && this[column.cellTemplateName] ? { cellTemplate: this[column.cellTemplateName] } : {}),
      ...(column.nameByDirection ? { name: this.config.dictionary.namesByDirection[this.routeDirection] } : {})
    }));
  }

  private track(message: string) {
    this.trackingService.track(`${this.trackingId ? `[${this.trackingId}] - ` : ''}${message}`);
  }

  onContinueClick() {
    this.track('Split routes dialog - click on continue');

    this.continue.emit(
      {
        passengerIds: this.tablePageService.selectedRows.map(row => row.id),
        keepEmptyStations: this.emptyStationsAction.value === RouteSplitEmptyStationsAction.Keep
      }
    );
  }

  toggleContinueButtonState() {
    this.config.buttons = this.config.buttons.map(button => button.value === GeneralCardButtonValue.Continue ? {
      ...button,
      disabled:
        !!this.loading ||
        !this.tablePageService.selectedRows.length ||
        this.tablePageService.rows.length === this.tablePageService.selectedRows.length ||
        (!!this.allowEmptyStations && this.emptyStationsAction.invalid)
    } : button);
  }

  onCloseClick() {
    this.track('Split routes dialog - click on X button');

    this.closeAction.emit();
  }
}
