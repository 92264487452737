import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxMaskModule } from 'ngx-mask';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { NgProgressModule } from 'ngx-progressbar';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { RouterState, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { LottieModule } from 'ngx-lottie';
import { ToastrModule } from 'ngx-toastr';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import {
  UIdModule,
  UCommonModule,
  UAddressAutocompleteLoaderService,
  UHereMapsLazyApiLoaderService,
  UGridModule,
  UHereMapsModule,
  USplitModule,
  UHereMapsUnitSystem,
  UHereMapsReverseGeocodeLoaderService,
  UHolidaysLoaderService
} from '@shift/ulib';

import { environment } from '@environments/environment';
import { AppRoutingModule } from '@app/app-routing.module';
import { AuthModule } from '@app/auth/auth.module';
import { AuthStateModule } from '@app/auth/state/auth-state.module';
import { RoutesStateModule } from '@app/routes/state/routes-state.module';
import { RideOrdersStateModule } from '@app/ride-orders/state/ride-orders-state.module';
import {
  AddressAutocompleteService,
  GeoDataService,
  ReverseGeocodeService,
  ApplicationInsightsService,
  TrackingService,
  MetaDataService,
  ThemesService,
  ClarityService,
  GoogleAnalyticsService,
  HolidaysLoaderService,
  ResponsiveService
} from '@app/shared/services';
import { EnvMetricSystemType } from '@app/shared/models';
import { appConfig } from '@app/shared/configs';
import { LoadingComponent, LayoutComponent } from '@app/shared/components';
import { rootReducers, metaReducers } from '@app/shared/state/reducers';
import { AuthSessionStorageService } from '@app/auth/services';
import { AuthErrorInterceptor, AuthRefreshAccessTokenInterceptor } from '@app/auth/interceptors';
import { NextYearModeInterceptor } from '@app/next-year/interceptors';
import { NextYearDataService } from '@app/next-year/services';
import { ReminderDataService } from '@app/reminder/services';
import { AppComponent } from './app.component';

declare const VERSION: number;

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '/assets/i18n/', `.json${environment.production ? '?v=' + VERSION : ''}`);
}

export function UAddressAutocompleteLoaderServiceFactory(geoDataService: GeoDataService) {
  return new AddressAutocompleteService(geoDataService);
}

export function UHereMapsReverseGeocodeLoaderServiceFactory(geoDataService: GeoDataService) {
  return new ReverseGeocodeService(geoDataService);
}

export function initHereMaps(uHereMapsLazyApiLoaderService: UHereMapsLazyApiLoaderService) {
  return () => {
    uHereMapsLazyApiLoaderService.initOptions({
      apiKey: environment.config.hereMapsKey,
      libraries: [ 'core', 'core-legacy', 'ui', 'mapevents', 'clustering' ],
      language: localStorage.getItem('lang') || appConfig.defaultLanguage,
      unitSystem: environment.config.metricSystem === EnvMetricSystemType.Metric ? UHereMapsUnitSystem.Metric : UHereMapsUnitSystem.Imperial
    });
  };
}

export function authSessionStorageFactory(): OAuthStorage {
  return new AuthSessionStorageService();
}

export function playerFactory() {
  return import('lottie-web');
}

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    UCommonModule.forRoot({
      addressAutocompleteLoader: {
        provide: UAddressAutocompleteLoaderService,
        useFactory: UAddressAutocompleteLoaderServiceFactory,
        deps: [ GeoDataService ]
      },
      holidaysLoader: {
        provide: UHolidaysLoaderService,
        useClass: HolidaysLoaderService
      }
    }),
    UIdModule.forRoot(appConfig.idPrefix),
    UGridModule,
    NgxMaskModule.forRoot(),
    HttpClientModule,
    TranslateModule.forRoot(
      {
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [ HttpClient ]
        }
      }
    ),
    UHereMapsModule.forRoot({
      reverseGeocodeLoader: {
        provide: UHereMapsReverseGeocodeLoaderService,
        useFactory: UHereMapsReverseGeocodeLoaderServiceFactory,
        deps: [ GeoDataService ]
      }
    }),
    ToastrModule.forRoot({
      preventDuplicates: true,
      resetTimeoutOnDuplicate: true
    }),
    USplitModule.forRoot(),
    TabsModule.forRoot(),
    ModalModule.forRoot(),
    NgProgressModule,
    StoreModule.forRoot(rootReducers, {
      metaReducers,
      runtimeChecks: {
        // strictStateImmutability and strictActionImmutability are enabled by default
        strictStateSerializability: true,
        strictActionSerializability: true,
        strictStateImmutability: true,
        strictActionImmutability: true
      }
    }),
    StoreRouterConnectingModule.forRoot({
      routerState: RouterState.Minimal
    }),
    !environment.production ? StoreDevtoolsModule.instrument({ name: appConfig.store.name, logOnly: environment.production }) : [],
    EffectsModule.forRoot([]),
    OAuthModule.forRoot(),
    LottieModule.forRoot({ player: playerFactory }),
    AppRoutingModule,
    AuthModule,
    AuthStateModule,
    RoutesStateModule,
    RideOrdersStateModule,
    LayoutComponent,
    LoadingComponent
  ],
  declarations: [
    AppComponent
  ],
  providers: [
    {
      provide: OAuthStorage,
      useFactory: authSessionStorageFactory
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthRefreshAccessTokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthErrorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NextYearModeInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initHereMaps,
      deps: [ UHereMapsLazyApiLoaderService ],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (
        metaDataService: MetaDataService,
        themesService: ThemesService,
        clarityService: ClarityService,
        googleAnalyticsService: GoogleAnalyticsService,
        applicationInsightsService: ApplicationInsightsService,
        trackingService: TrackingService,
        reminderDataService: ReminderDataService,
        nextYearDataService: NextYearDataService,
        responsiveService: ResponsiveService
      ) => () => {
        metaDataService.init();
        themesService.init();
        clarityService.init();
        googleAnalyticsService.init();
        applicationInsightsService.init();
        trackingService.init();
        reminderDataService.init();
        nextYearDataService.init();
        responsiveService.init();
      },
      multi: true,
      deps: [
        MetaDataService,
        ThemesService,
        ClarityService,
        GoogleAnalyticsService,
        ApplicationInsightsService,
        TrackingService,
        ReminderDataService,
        NextYearDataService,
        ResponsiveService
      ]
    }
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {}
