<div class="builder-convert-to__wrapper">
  <div class="builder-convert-to__close" (click)="close()">
    <div class="u-icon u-icon_pointer u-icon_app-builder-close"></div>
  </div>
  <div class="builder-convert-to__main">
    <div class="builder-convert-to__message">
      {{config().dictionary.message | translate}}
    </div>
    <div class="builder-convert-to__list" (click)="$event.stopPropagation()">
      @for (item of checkboxes; track item) {
        <div class="builder-convert-to__item">
          <div class="builder-convert-to__item-content">
            <div class="builder-convert-to__item-checkbox">
              <u-checkbox class="u-checkbox_square" [ngModel]="item.check" (ngModelChange)="checkItem(item)"></u-checkbox>
            </div>
            <div class="builder-convert-to__item-name">{{item.name | translate}}</div>
          </div>
        </div>
      }
    </div>

    @if (showPassengers) {
      <div class="builder-convert-to__passengers" (click)="$event.stopPropagation()">
        <u-multiselect
          [placeholder]="config().dictionary.select | translate"
          [items]="items"
          [(ngModel)]="passengers"
          [messages]="config().dictionary.uMultiselect | translate"
        ></u-multiselect>
      </div>
    }

    <div class="builder-convert-to__actions">
      <button class="builder-convert-to__actions-btn" (click)="apply()">{{config().dictionary.apply | translate}}</button>
    </div> 
  </div>
</div>
