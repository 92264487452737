import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';

import { AuthModuleName } from '@app/auth/models';
import { AuthFacade } from '@app/auth/state/facades';
import { AuthDataService } from '@app/auth/services';

@Injectable({
  providedIn: 'root'
})
export class AuthModuleGuard  {
  MODULE_NAME: AuthModuleName;

  constructor(
    private router: Router,
    private authFacade: AuthFacade,
    private authDataService: AuthDataService
  ) {}

  canActivate(): Observable<boolean> {
    return this.authFacade.isInited$
      .pipe(
        filter(inited => !!inited),
        switchMap(() => this.authDataService.modules$),
        map(modules => {
          if (!modules[this.MODULE_NAME]) {
            this.router.navigate([ '/' ]);

            return false;
          }

          return true;
        }),
        take(1)
      );
  }
}
