import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { AddEditModalAction } from '@app/shared/models';

@Injectable({
  providedIn: 'root'
})
export class ContractsDataService {
  readonly #addEditModalAction = new Subject<{ id: number; action: AddEditModalAction; }>();

  readonly addEditModalAction$ = this.#addEditModalAction.asObservable();

  updateAddEditModalAction(id: number, action: AddEditModalAction) {
    this.#addEditModalAction.next({ id, action });
  }
}
