<app-general-card
  [title]="config.dictionary.title"
  [buttons]="config.buttons"
  (buttonClick)="onButtonClick($event)"
  (closeAction)="close()"
>
  <div class="routes-cancel-ride__body">
    <div class="routes-cancel-ride__body-title">{{activeRide.code}} {{activeRide.name}}</div>
    <div class="routes-cancel-ride__body-groups">
      <u-group
        class="u-group_app-routes-cancel-ride u-group_app-max-field-height"
        [name]="config.dictionary.fields.type | translate"
      >
        <div class="routes-cancel-ride__body-double">
          <div class="routes-cancel-ride__body-double-item">
            <input
              uInput
              type="text"
              [class]="'u-input_text-grey'"
              [disabled]="true"
              [value]="config.dictionary.unplanned | translate"
            >
          </div>
          <div class="routes-cancel-ride__body-double-item"></div>
        </div>
      </u-group>
      <u-group
        class="u-group_app-routes-cancel-ride"
        [name]="config.dictionary.fields.period | translate"
      >
        <app-routes-periods
          [defaultData]="routesPeriodsDefaultData"
          [form]="form.controls.periods"
          [hidePlusIcon]="isSCCustomer"
          [isOwnedBySc]="activeRide.isOwnedBySc"
          [authCustomer]="authCustomer"
          (clickOnPlusIcon)="track('Period - click on plus')"
        ></app-routes-periods>

        @if (isMultipleContractsApply) {
          <div class="routes-cancel-ride__body-item-text">
            {{config.dictionary.multipleContractsApply | translate}}
          </div>
        }
      </u-group>

      @if (!isSCCustomer || activeRide.isOwnedBySc) {
        <u-group
          class="u-group_app-routes-cancel-ride u-group_app-max-field-height"
          [name]="config.dictionary.fields.cancelClause | translate"
        >
          <u-select-s
            [classUInput]="'u-input_arrow-light u-input_light'"
            [classUDropdownContent]="'u-dropdown-content_app-routes-change'"
            [items]="config.cancelClauseList"
            [formControl]="form.controls.cancelClause"
            [messages]="config.dictionary.select.messages | translate"
            [empty]="false"
            [valid]="form.controls.cancelClause.valid"
            [placeholder]="config.dictionary.select.placeholder | translate"
          ></u-select-s>
        </u-group>
      }

      @if (form.controls.cancelClause.value > 0 || form.controls.cancelClause.value === routesCancelRideClauseType.Manual) {
        <u-group
          class="u-group_app-routes-cancel-ride u-group_app-max-field-height"
          [name]="config.dictionary.fields.charge | translate"
        >
          <div class="routes-cancel-ride__body-double">
            <div class="routes-cancel-ride__body-double-item">
              <u-input-number
                [formControl]="form.controls.chargeAmount"
                [decimal]="true"
                [maxDecimalDigits]="2"
                [valid]="form.controls.chargeAmount.disabled || form.controls.chargeAmount.valid"
                [enabled]="!form.controls.chargeAmount.disabled"
                [showCounter]="true"
                [min]="0"
                [max]="9999.99"
              ></u-input-number>
            </div>
            <div class="routes-cancel-ride__body-double-item">
              <u-select-s
                [classUInput]="'u-input_arrow-light u-input_light'"
                [classUDropdownContent]="'u-dropdown-content_app-routes-change'"
                [items]="config.chargeTypeList"
                [formControl]="form.controls.chargeType"
                [messages]="config.dictionary.select.messages | translate"
                [empty]="false"
                [placeholder]="config.dictionary.select.placeholder | translate"
              ></u-select-s>
            </div>
          </div>
        </u-group>
      }
    </div>
  </div>
</app-general-card>
