<div class="ai-chat-selected-conversation__container">
  <div 
    class="ai-chat-selected-conversation__main"
    [ngClass]="{
      'ai-chat-selected-conversation__main_view': !!aiChatDataService.selectedConversationMessageTableId()
    }"
  >
    <div #body class="ai-chat-selected-conversation__body">
      <div class="ai-chat-selected-conversation__item">
        <div class="ai-chat-selected-conversation__avatar ai-chat-selected-conversation__avatar_ai"></div>
  
        <div class="ai-chat-selected-conversation__message ai-chat-selected-conversation__message_hi">
          <div class="ai-chat-selected-conversation__message-text">
            {{config().dictionary.hi | translate}}
          </div>
        </div>
      </div>

      @for (message of aiChatDataService.selectedConversation().messages; track $index; let last = $last) {
        <div
          class="ai-chat-selected-conversation__item"
          [ngClass]="{
            'ai-chat-selected-conversation__item_active': !!aiChatDataService.selectedConversationMessageTableId() && aiChatDataService.selectedConversationMessageTableId() === message.tableId,
            'ai-chat-selected-conversation__item_user': !!message.user
          }"
        >
          <div
            class="ai-chat-selected-conversation__avatar"
            [ngClass]="{
              'ai-chat-selected-conversation__avatar_ai': !message.user
            }"
          >
            @if (message.user) {
              {{message.user.charAt(0)}}{{message.user.split(' ')[1]?.charAt(0)}}
            }
          </div>
    
          <div
            class="ai-chat-selected-conversation__message"
            [ngClass]="{
              'ai-chat-selected-conversation__message_user': !!message.user
            }"
          >
            <div class="ai-chat-selected-conversation__message-text">
              {{message.text}}
            </div>
            
            @if (message.tableId) {
              <div class="ai-chat-selected-conversation__message-table">
                <div class="ai-chat-selected-conversation__message-table-preview"></div>
                <div class="ai-chat-selected-conversation__message-table-actions">
                  <div class="ai-chat-selected-conversation__message-table-action">
                    <div class="ai-chat-selected-conversation__message-table-action-view-icon" (click)="viewTheTable(message.tableId)"></div>
                    <button uButton [size]="uButtonSize.Small" (click)="viewTheTable(message.tableId)">{{config().dictionary.viewTheTable | translate}}</button>
                  </div>
                  <div class="ai-chat-selected-conversation__message-table-action">
                    <div class="ai-chat-selected-conversation__message-table-action-excel-icon"></div>
                    <button uButton [size]="uButtonSize.Small">{{config().dictionary.excelDownload | translate}}</button>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
    
        @if (last && !!message.user) {
          <div class="ai-chat-selected-conversation__item">
            <div class="ai-chat-selected-conversation__avatar ai-chat-selected-conversation__avatar_ai"></div>
    
            <div class="ai-chat-selected-conversation__message ai-chat-selected-conversation__message_typing">
              <div class="ai-chat-selected-conversation__message-typing">
                <span class="ai-chat-selected-conversation__message-typing-dot"></span>
                <span class="ai-chat-selected-conversation__message-typing-dot"></span>
                <span class="ai-chat-selected-conversation__message-typing-dot"></span>
              </div>
            </div>
          </div>
        }
      }
    </div>
    
    <div 
      class="ai-chat-selected-conversation__footer"
      [ngClass]="{
        'ai-chat-selected-conversation__footer_disabled': messageText.disabled
      }"
    >
      <textarea
        uTextarea
        class="ai-chat-selected-conversation__footer-message"
        [formControl]="messageText"
        [placeholder]="config().dictionary.askQuestion | translate"
        [maxlength]="config().maxMessageTextLength"
        [appFormExtractChanges]="messageText"
        (keydown.enter)="$event.preventDefault(); sendMessageText()"
      ></textarea>
    
      <div class="ai-chat-selected-conversation__footer-send">
        <button
          class="ai-chat-selected-conversation__footer-button"
          (click)="sendMessageText()"
        ></button>
    
        <div class="ai-chat-selected-conversation__footer-text">
          {{messageText.value.length}} / {{config().maxMessageTextLength}}
        </div>
      </div>
    </div>  
  </div>
  
  @if (!!aiChatDataService.selectedConversationMessageTableId()) {
    <div class="ai-chat-selected-conversation__divider"></div>
    <div class="ai-chat-selected-conversation__view">
      <app-ai-chat-selected-conversation-view-table [tableId]="aiChatDataService.selectedConversationMessageTableId()" />
    </div>
  }
</div>