import { TablePageConfig } from '@app/shared/models';

const tablePageConfig: TablePageConfig = {
  id: 'aiChatSelectedConversationViewTable',
  trackingId: 'Ai Chat Selected Conversation View Table',
  tableConfig: {
    showCounters: false,
    saveTableProps: false,
    columns: []
  },
  hideMetadata: true
};

export const aiChatSelectedConversationViewTableComponentConfig = {
  tablePageConfig
};
