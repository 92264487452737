<div 
  class="builder-full-filter__main"
  *ngIf="form"
>
  <u-group
    *ngIf="builderFullCalculationService.routePlannerTypeShuttleCompany()"
    class="u-group_app-builder-full-filter"
    [name]="config.dictionary.customer | translate"
  >
    <u-select-s
      uId="builder-full-filter-customer"
      [empty]="false"
      [items]="builderFullCalculationService.customerItems()"
      [placement]="isRtl ? [ 'bottom-right', 'top-right' ] : [ 'bottom-left', 'top-left' ]"
      [valid]="form.get('customerId').valid"
      [formControl]="form.get('customerId')"
      [container]="'body'"
      [placeholder]="config.dictionary.select | translate"
      [messages]="config.dictionary.uSelectS | translate"
    ></u-select-s>
  </u-group>
  <u-group
    *ngIf="builderFullCalculationService.routePlannerMasterCustomer()"
    class="u-group_app-builder-full-filter"
    [name]="config.dictionary.customers | translate"
  >
    <u-multiselect
      uId="builder-full-filter-customers"
      [lazyLoadItems]="builderFullCalculationService.subCustomers$"
      [placement]="isRtl ? [ 'bottom-right', 'top-right' ] : [ 'bottom-left', 'top-left' ]"
      [valid]="form.get('subCustomers').valid"
      [formControl]="form.get('subCustomers')"
      [container]="'body'"
      [placeholder]="config.dictionary.select | translate"
      [messages]="config.dictionary.uMultiselect | translate"
    ></u-multiselect>
  </u-group>
  <u-group
    *ngIf="builderFullCalculationService.isAfterSchoolActivitiesEnabled"
    class="u-group_app-builder-full-filter"
    [name]="config.dictionary.routesFor | translate"
  >
    <u-select-s
      uId="builder-full-filter-routes-for"
      [classUDropdownContent]="'at-builder-full-filter-routes-for-dropdown'"
      [items]="options().routesFor"
      [messages]="config.dictionary.uSelectS | translate"
      [placement]="isRtl ? [ 'bottom-right', 'top-right' ] : [ 'bottom-left', 'top-left' ]"
      [valid]="form.get('routesFor').disabled || form.get('routesFor').valid"
      [empty]="false"
      [placeholder]="config.dictionary.select | translate"
      [formControl]="form.get('routesFor')"
      [container]="'body'"
    ></u-select-s>
  </u-group>
  <div
    *ngIf="configData().isTemplatesPlanningEnabled && (!form.get('routesFor') || form.get('routesFor').value === builderFullRoutesFor.Activities)"
    class="builder-full-filter__field"
  >
    <div class="builder-full-filter__field-label">
      <span class="builder-full-filter__field-label-text">
        {{config.dictionary.planningType | translate}}
      </span>
      <span
        *ngIf="form.get('mode').value === routePlannerRouteGenerationMode.ByTemplate"
        class="u-icon_app-info"
        [uTooltip]="config.dictionary.dailyRoutesOnly | translate"
        [tooltipClass]="'u-tooltip_general'"
        [placement]="[ 'top' ]"
        [container]="'body'"
      ></span>
    </div>
    <u-select-s
      uId="builder-full-filter-planning-type"
      [items]="options().planningTypes"
      [formControl]="form.get('mode')"
      [messages]="config.dictionary.uSelectS | translate"
      [placement]="isRtl ? [ 'bottom-right', 'top-right' ] : [ 'bottom-left', 'top-left' ]"
      [empty]="false"
      [placeholder]="config.dictionary.select | translate"
      [container]="'body'"
    ></u-select-s>
  </div>
  <u-group
    *ngIf="
      (!form.get('routesFor') || form.get('routesFor').value === builderFullRoutesFor.Activities) &&
      form.get('mode').value === routePlannerRouteGenerationMode.ByTemplate
    "
    class="u-group_app-builder-full-filter"
    [name]="config.dictionary.templates | translate"
  >
    <u-input-modal
      uId="builder-full-filter-templates"
      [config]="config.templatesFilterInputModal"
      [formControl]="form.get('routeTemplateIds')"
      [valid]="form.get('routeTemplateIds').valid"
    ></u-input-modal>
    <div class="builder-full-filter__checkbox-wrapper">
      <u-checkbox
        uId="builder-full-filter-minimize-vehicle-type"
        class="u-checkbox_box u-checkbox_app-round-checkbox u-checkbox_app-builder-full-filter"
        [formControl]="form.get('minimizeVehicleType')"
      ></u-checkbox>
      <span class="builder-full-filter__checkbox-label">{{config.dictionary.minimizeVehicleType | translate}}</span>
    </div>
    <div class="builder-full-filter__checkbox-wrapper">
      <u-checkbox
        uId="builder-full-filter-duplicate-templates"
        class="u-checkbox_box u-checkbox_app-round-checkbox u-checkbox_app-builder-full-filter"
        [formControl]="form.get('allowMultipleTemplateUsage')"
      ></u-checkbox>
      <span class="builder-full-filter__checkbox-label">{{config.dictionary.duplicateTemplate | translate}}</span>
    </div>
  </u-group>
  <u-group
    class="u-group_app-builder-full-filter"
    [name]="config.dictionary.demands | translate"
  >
    <u-toggle
      [class]="'u-toggle_app-full'"
      [messages]="config.dictionary.demandsToggle | translate"
      [formControl]="form.get('byDemand')"
    ></u-toggle>
  </u-group>
  <u-group
    class="u-group_app-builder-full-filter"
    [name]="config.dictionary.direction | translate"
  >
    <u-select-s
      uId="builder-full-filter-direction"
      [classUDropdownContent]="'at-builder-full-filter-direction-dropdown'"
      [items]="options().directions"
      [messages]="config.dictionary.uSelectS | translate"
      [placement]="isRtl ? [ 'bottom-right', 'top-right' ] : [ 'bottom-left', 'top-left' ]"
      [valid]="form.get('direction').disabled || form.get('direction').valid"
      [empty]="false"
      [placeholder]="config.dictionary.select | translate"
      [formControl]="form.get('direction')"
      [container]="'body'"
    ></u-select-s>
  </u-group>
  <u-input-date-range
    uId="builder-full-filter-date-range"
    [class]="'u-input-date-range_app-builder-full-filter'"
    [classUDropdownContent]="'at-builder-full-filter-date-range-dropdown'"
    [classUInput]="'u-input_text-center'"
    [container]="'body'"
    [placement]="[ 'bottom', 'top' ]"
    [(ngModel)]="builderFullCalculationService.filterDatesRange.dates"
    [sundayFirstDay]="true"
    [defaultDate]="false"
    [showIcon]="false"
    [showInputTitle]="true"
    [required]="true"
    [disableDaysBefore]="configData().minDate"
    [disableDaysAfter]="configData().maxDate"
    [checkDays]="true"
    [showTime]="form.get('byDemand').value"
    [startTime]="form.get('startTime')"
    [endTime]="form.get('endTime')"
    [checkDaysActive]="builderFullCalculationService.filterDatesRange.checkDaysActive"
    [checkDaysAvailable]="builderFullCalculationService.filterDatesRange.checkDaysAvailable"
    [messages]="config.dictionary.uInputDateRange | translate"
    [defaultTime]="false"
    [disabled]="form.get('customerId') ? !form.get('customerId').valid : false"
    (saveAction)="builderFullCalculationService.filterDatesRangeChange($event)"
  ></u-input-date-range>
  <u-group
    class="u-group_app-builder-full-filter"
    [name]="config.dictionary.setTime | translate"
    *ngIf="!form.get('byDemand').value"
  >
    <div class="builder-full-filter__double">
      <div class="builder-full-filter__double-item">
        <u-select-s
          [items]="options().timeTypes"
          [messages]="config.dictionary.uSelectS | translate"
          [placement]="isRtl ? [ 'bottom-right', 'top-right' ] : [ 'bottom-left', 'top-left' ]"
          [valid]="form.get('calculationTimeType').disabled ? true : form.get('calculationTimeType').valid"
          [empty]="false"
          [placeholder]="config.dictionary.select | translate"
          [formControl]="form.get('calculationTimeType')"
          [container]="'body'"
        ></u-select-s>
      </div>
      <div class="builder-full-filter__double-item">
        <u-input-time 
          [class]="'u-input-time_center'"
          [defaultTime]="false"
          [valid]="form.get('customTime').disabled || form.get('customTime').valid"
          [formControl]="form.get('customTime')"
        ></u-input-time>
      </div>
    </div>
  </u-group>
  <u-group
    class="u-group_app-builder-full-filter"
    [name]="config.dictionary.routeType | translate"
  >
    <u-select-s
      [items]="options().routeTypes"
      [messages]="config.dictionary.uSelectS | translate"
      [placement]="isRtl ? [ 'bottom-right', 'top-right' ] : [ 'bottom-left', 'top-left' ]"
      [valid]="form.get('routeType').valid"
      [empty]="false"
      [placeholder]="config.dictionary.select | translate"
      [formControl]="form.get('routeType')"
      [container]="'body'"
    ></u-select-s>
  </u-group>
  <u-group
    *ngIf="builderFullCalculationService.routePlannerTypeGeneric()"
    class="u-group_app-builder-full-filter"
    [name]="config.dictionary.shuttleCompany | translate"
  >
    <u-select-s
      [items]="options().shuttleCompanies"
      [messages]="config.dictionary.uSelectS | translate"
      [placement]="isRtl ? [ 'bottom-right', 'top-right' ] : [ 'bottom-left', 'top-left' ]"
      [valid]="form.get('shuttleCompanyId').valid"
      [empty]="true"
      [placeholder]="config.dictionary.select | translate"
      [formControl]="form.get('shuttleCompanyId')"
      [container]="'body'"
    ></u-select-s>
  </u-group>
  <u-group
    class="u-group_app-builder-full-filter"
    [name]="config.dictionary.pricing | translate"
  >
    <div class="builder-full-filter__double">
      <div class="builder-full-filter__double-item">
        <u-select-s
          [items]="options().pricingTypes"
          [messages]="config.dictionary.uSelectS | translate"
          [placement]="isRtl ? [ 'bottom-right', 'top-right' ] : [ 'bottom-left', 'top-left' ]"
          [valid]="form.get('costType').disabled || form.get('costType').valid"
          [empty]="false"
          [placeholder]="config.dictionary.select | translate"
          [formControl]="form.get('costType')"
          [container]="'body'"
        ></u-select-s>
      </div>
      <div
        *ngIf="form.get('costType').value === builderFullCostType.FixedPrice" 
        class="builder-full-filter__double-item"
      >
        <u-input-number
          [decimal]="true"
          [min]="0"
          [maxDecimalDigits]="2"
          [formControl]="form.get('fixedCost')"
        ></u-input-number>
      </div>
    </div>
  </u-group>
</div>
<div class="builder-full-filter__footer">
  <div class="builder-full-filter__footer-icons">
    <button
      uButton
      class="builder-full-filter__footer-icon"
      [container]="'body'"
      [uTooltip]="config.dictionary.routesPolicy | translate"
      [disabled]="form.get('customerId') ? !form.get('customerId').valid : false"
      (click)="toggleSettings.emit()"
    >
      <div uId="builder-full-filter-settings" class="u-icon u-icon_pointer u-icon_app-builder-settings"></div>
    </button>
    <button
      *ngIf="visibleComponents.passengers && !visibleComponents.map && !builderFullCalculationService.routePlannerMasterCustomer()"
      uButton
      class="builder-full-filter__footer-icon"
      [container]="'body'"
      [uTooltip]="config.dictionary.mapView | translate"
      (click)="loadMap.emit()"
    >
      <div class="u-icon u-icon_pointer u-icon_app-builder-map"></div>
    </button>
    <button
      *ngIf="visibleComponents.map"
      uButton
      class="builder-full__area-footer-btn"
      [container]="'body'"
      [uTooltip]="config.dictionary.tableView | translate"
      (click)="loadPassengersGrid.emit()"
    >
      <div class="u-icon u-icon_pointer u-icon_app-builder-grid"></div>
    </button>
  </div>

  <button
    uButton
    uId="builder-full-filter-calculate-routes"
    class="u-font-weight-medium"
    [disabled]="!builderFullCalculationService.calculationAllowed"
    [size]="uButtonSize.Small"
    (click)="calculate()"
  >
    {{config.dictionary.calculateRoutes | translate}}
  </button>
</div>
