import { USearchFilterType, UThreeDotsPopoverItem } from '@shift/ulib';

import { DaysOfWeek, GlobalSearchFilter, HeaderMenuIconValue, ThreeDotsPopoverItem } from '@app/shared/models';
import {
  AuthCustomerType,
  AuthModuleActivitiesFeature,
  AuthModuleDemandsPassengersViewFeature,
  AuthModuleDemandsShiftsViewFeature,
  AuthModuleDepartmentsFeature,
  AuthModuleDepartmentsFeatureType,
  AuthModuleFeatureGroupCondition,
  AuthModuleName,
  AuthModuleRoutesFeature,
  AuthModuleRoutesTableFeature,
  AuthModuleRoutesTableFeatureMasterCustomer,
  AuthModuleRoutesTableFeatureType,
  AuthModuleTransportationFeature,
  AuthPermission
} from '@app/auth/models';
import { RouteRideSupervisorLevel, RoutesThreeDotsPopoverItemAction, RouteType } from '@app/routes/models';

const DICTIONARY_PATH: string = 'routes';

const threeDotsNewFineItem: ThreeDotsPopoverItem = {
  name: `${DICTIONARY_PATH}.threeDots.newFine`,
  action: RoutesThreeDotsPopoverItemAction.NewFine,
  feature: {
    module: AuthModuleName.Transportation,
    name: AuthModuleTransportationFeature.ShuttleCompanyFines
  }
};

const threeDotsTempCommentItem: ThreeDotsPopoverItem = {
  name: `${DICTIONARY_PATH}.threeDots.addTempComment`,
  action: RoutesThreeDotsPopoverItemAction.TempComment,
  feature: {
    module: AuthModuleName.Activities,
    name: AuthModuleActivitiesFeature.TempComments
  }
};

const threeDotsCreateRouteTemplate: ThreeDotsPopoverItem = {
  name: `${DICTIONARY_PATH}.threeDots.createRouteTemplate`,
  action: RoutesThreeDotsPopoverItemAction.CreateRouteTemplate,
  feature: {
    module: AuthModuleName.RouteTemplates
  }
};

const threeDotsMenuOwnedByScActions: RoutesThreeDotsPopoverItemAction[] = [
  RoutesThreeDotsPopoverItemAction.SplitRoute,
  RoutesThreeDotsPopoverItemAction.TempComment,
  RoutesThreeDotsPopoverItemAction.CreateRouteTemplate
];

const threeDotsMenu: ThreeDotsPopoverItem[] = [
  {
    name: `${DICTIONARY_PATH}.threeDots.restoreRide`,
    action: RoutesThreeDotsPopoverItemAction.RestoreRide,
    permission: AuthPermission.CreateRoutes
  },
  {
    name: `${DICTIONARY_PATH}.threeDots.splitRoute`,
    action: RoutesThreeDotsPopoverItemAction.SplitRoute,
    permission: AuthPermission.CreateRoutes
  },
  {
    name: `${DICTIONARY_PATH}.threeDots.movePassengers`,
    action: RoutesThreeDotsPopoverItemAction.MovePassengers,
    feature: {
      module: AuthModuleName.Passengers
    },
    permission: AuthPermission.EditRoutes
  },
  threeDotsTempCommentItem,
  {
    name: `${DICTIONARY_PATH}.threeDots.deleteRoute`,
    action: RoutesThreeDotsPopoverItemAction.DeleteRoute,
    feature: {
      module: AuthModuleName.Routes,
      name: AuthModuleRoutesFeature.DeleteExitingRoutes
    },
    permission: AuthPermission.DeleteRoutes
  },
  threeDotsCreateRouteTemplate,
  threeDotsNewFineItem
];

const threeDotsMenuActive: ThreeDotsPopoverItem[]  = [
  { name: `${DICTIONARY_PATH}.threeDots.cancelRide`, action: RoutesThreeDotsPopoverItemAction.CancelRide },
  ...threeDotsMenu
];

const threeDotsMenuCanceled: ThreeDotsPopoverItem[] = [
  ...threeDotsMenu
];

const threeDotsMenuShuttleCompany: ThreeDotsPopoverItem[] = [
  {
    name: `${DICTIONARY_PATH}.threeDots.restoreRide`,
    action: RoutesThreeDotsPopoverItemAction.RestoreRide,
    permission: AuthPermission.CreateRoutes
  },
  {
    name: `${DICTIONARY_PATH}.threeDots.splitRoute`,
    action: RoutesThreeDotsPopoverItemAction.SplitRoute,
    permission: AuthPermission.CreateRoutes
  },
  {
    name: `${DICTIONARY_PATH}.threeDots.movePassengers`,
    action: RoutesThreeDotsPopoverItemAction.MovePassengers,
    permission: AuthPermission.EditRoutes
  },
  threeDotsTempCommentItem,
  threeDotsCreateRouteTemplate,
  threeDotsNewFineItem
];

const threeDotsMenuShuttleCompanyActive: UThreeDotsPopoverItem[] = [
  {
    name: `${DICTIONARY_PATH}.threeDots.cancelRide`,
    action: RoutesThreeDotsPopoverItemAction.CancelRide,
    permission: AuthPermission.EditRoutes
  },
  ...threeDotsMenuShuttleCompany
];

const threeDotsMenuShuttleCompanyCanceled: UThreeDotsPopoverItem[] = [
  ...threeDotsMenuShuttleCompany
];

const passengersFilter: GlobalSearchFilter = {
  items: null,
  value: [],
  name: 'passengers',
  type: USearchFilterType.Multiselect,
  title: `${DICTIONARY_PATH}.globalFilters.passengers`,
  feature: {
    module: AuthModuleName.Passengers
  }
};

const citiesFilter: GlobalSearchFilter = {
  items: null,
  value: [],
  name: 'citiesAreas',
  type: USearchFilterType.InputCitiesMultiselect,
  title: `${DICTIONARY_PATH}.globalFilters.cities`
};

const branchesFilter: GlobalSearchFilter = {
  items: null,
  value: [],
  name: 'branches',
  type: USearchFilterType.Multiselect,
  title: `${DICTIONARY_PATH}.globalFilters.branches`,
  resetFiltersByNames: [ citiesFilter.name ]
};

const contractCodeFilter: GlobalSearchFilter = {
  items: null,
  value: [],
  name: 'bidNumbers',
  type: USearchFilterType.Multiselect,
  title: `${DICTIONARY_PATH}.globalFilters.contractCode`
};

const departmentsFilter: GlobalSearchFilter = {
  items: [],
  value: [],
  name: 'departments',
  type: USearchFilterType.Multiselect,
  title: `${DICTIONARY_PATH}.globalFilters.departments`
};

const tagsFilter: GlobalSearchFilter = {
  items: [],
  value: [],
  name: 'tags',
  type: USearchFilterType.Multiselect,
  title: `${DICTIONARY_PATH}.globalFilters.tags`
};

const masterSubCustomersFilter: GlobalSearchFilter = {
  items: null,
  value: [],
  name: 'subCustomers',
  type: USearchFilterType.Multiselect,
  title: `${DICTIONARY_PATH}.globalFilters.customers`,
  feature: {
    module: AuthModuleName.RoutesTable,
    name: AuthModuleRoutesTableFeature.MasterCustomer,
    values: [ AuthModuleRoutesTableFeatureMasterCustomer.MasterCustomerFeature ]
  },
  resetFiltersByNames: [ branchesFilter.name, departmentsFilter.name, tagsFilter.name ]
};

const schoolsFilter: GlobalSearchFilter = {
  items: [],
  value: [],
  name: 'schools',
  type: USearchFilterType.Multiselect,
  title: `${DICTIONARY_PATH}.globalFilters.schools`
};

const classesFilter: GlobalSearchFilter = {
  items: [],
  value: [],
  name: 'classes',
  type: USearchFilterType.Multiselect,
  title: `${DICTIONARY_PATH}.globalFilters.classes`
};

const globalFiltersArmy: GlobalSearchFilter[] = [
  masterSubCustomersFilter,
  {
    ...departmentsFilter,
    title: `${DICTIONARY_PATH}.globalFilters.commands`
  },
  tagsFilter,
  {
    ...branchesFilter,
    title: `${DICTIONARY_PATH}.globalFilters.bases`
  },
  citiesFilter
];

const globalFiltersMunicipality: GlobalSearchFilter[] = [
  masterSubCustomersFilter,
  departmentsFilter,
  passengersFilter,
  schoolsFilter,
  classesFilter,
  citiesFilter,
  contractCodeFilter
];

const globalFiltersDefault: GlobalSearchFilter[] = [
  masterSubCustomersFilter,
  {
    ...departmentsFilter,
    feature: {
      module: AuthModuleName.Departments,
      name: AuthModuleDepartmentsFeature.Type,
      values: [ AuthModuleDepartmentsFeatureType.Generic, AuthModuleDepartmentsFeatureType.Iec ]
    }
  },
  {
    ...departmentsFilter,
    title: `${DICTIONARY_PATH}.globalFilters.departmentsDivision`,
    feature: {
      module: AuthModuleName.Departments,
      name: AuthModuleDepartmentsFeature.Type,
      values: [ AuthModuleDepartmentsFeatureType.Division ]
    }
  },
  passengersFilter,
  {
    items: [],
    value: [],
    name: 'shifts',
    type: USearchFilterType.Multiselect,
    title: `${DICTIONARY_PATH}.globalFilters.shifts`,
    featureGroup: {
      condition: AuthModuleFeatureGroupCondition.Or,
      values: [
        [
          {
            module: AuthModuleName.DemandsPassengersView,
            name: AuthModuleDemandsPassengersViewFeature.Type
          }
        ],
        [
          {
            module: AuthModuleName.DemandsShiftsView,
            name: AuthModuleDemandsShiftsViewFeature.Type
          }
        ]
      ]
    }
  },
  branchesFilter,
  citiesFilter
];

const routeTypeByHeaderMenuIconValue = {
  [HeaderMenuIconValue.ShuttleRoute]: RouteType.Shuttle,
  [HeaderMenuIconValue.DeliveryRoute]: RouteType.Delivery,
  [HeaderMenuIconValue.FixedRoute]: RouteType.Fixed,
  [HeaderMenuIconValue.RegularRoute]: RouteType.Regular,
  [HeaderMenuIconValue.ShiftsRoute]: RouteType.Shifts,
  [HeaderMenuIconValue.SpecialRoute]: RouteType.Special
};

export const routesConfig = {
  dictionaryPath: DICTIONARY_PATH,
  routeTypeByHeaderMenuIconValue,
  dictionary: {
    yes: 'general.yes',
    no: 'general.no',
    close: 'general.close',
    changeCarTypeConfirm: {
      ok: `${DICTIONARY_PATH}.changeCarTypeConfirm.ok`,
      cancel: `${DICTIONARY_PATH}.changeCarTypeConfirm.cancel`
    },
    reOptimization: {
      popupMessage: `${DICTIONARY_PATH}.reOptimization.popupMessage`,
      alreadyRunningMessage: `${DICTIONARY_PATH}.reOptimization.alreadyRunningMessage`
    },
    aiSuggestions: {
      noRoutesSelected: `${DICTIONARY_PATH}.daily.aiSuggestions.noRoutesSelectedMessage`,
      exitAiSuggestions: `${DICTIONARY_PATH}.daily.aiSuggestions.exitAiSuggestions`,
      exit: `${DICTIONARY_PATH}.daily.aiSuggestions.exit`
    }
  },
  rideSupervisorIcons: {
    [RouteRideSupervisorLevel.Low]: '/assets/images/routes/red-circle.svg',
    [RouteRideSupervisorLevel.Medium]: '/assets/images/routes/orange-circle.svg',
    [RouteRideSupervisorLevel.High]: '/assets/images/routes/green-circle.svg'
  },
  trackingId: 'Routes & rides, Routes table',
  feedTrackingId: 'Routes & Rides, Feed',
  extraRoutesDataLoadBuffer: 1000,
  threeDotsMenu: {
    default: {
      threeDotsMenu: [ ...threeDotsMenu ],
      threeDotsMenuActive: [ ...threeDotsMenuActive ],
      threeDotsMenuCanceled: [ ...threeDotsMenuCanceled ]
    },
    [AuthModuleRoutesTableFeatureType.ShuttleCompany]: {
      threeDotsMenu: [ ...threeDotsMenuShuttleCompany ],
      threeDotsMenuActive: [ ...threeDotsMenuShuttleCompanyActive ],
      threeDotsMenuCanceled: [ ...threeDotsMenuShuttleCompanyCanceled ]
    }
  },
  threeDotsMenuOwnedByScActions,
  globalFilters: {
    default: globalFiltersDefault,
    [AuthCustomerType.Army]: globalFiltersArmy,
    [AuthCustomerType.Municipality]: globalFiltersMunicipality,
    [AuthCustomerType.ShuttleCompany]: []
  },
  notes: {
    maxDisplayCounter: 99
  },
  daysOfWeek: [ DaysOfWeek.Sunday, DaysOfWeek.Monday, DaysOfWeek.Tuesday, DaysOfWeek.Wednesday, DaysOfWeek.Thursday, DaysOfWeek.Friday, DaysOfWeek.Saturday ],
  availableHeaderIconsInAiSuggestionsMode: [
    HeaderMenuIconValue.Plus,
    HeaderMenuIconValue.Notes,
    HeaderMenuIconValue.Activities
  ]
};
