import { FilesUploadError } from '@app/files/models';

const DICTIONARY_PATH: string = 'files';

const dictionary = {
  title: `${DICTIONARY_PATH}.title`,
  attachFile: `${DICTIONARY_PATH}.attachFile`,
  file: `${DICTIONARY_PATH}.file`,
  errors: {
    [FilesUploadError.Unsupported]: `${DICTIONARY_PATH}.errors.unsupported`,
    [FilesUploadError.MaxFileSize]: `${DICTIONARY_PATH}.errors.maxFileSize`,
    [FilesUploadError.MaxStorageCapacity]: `${DICTIONARY_PATH}.errors.maxStorageCapacity`,
    [FilesUploadError.ExistingFile]: `${DICTIONARY_PATH}.errors.existingFile`
  }
};

export const filesComponentConfig = {
  dictionary,
  fileSettings: {
    maximumFileSize: 20,
    acceptableExtensions: [ 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'pdf', 'jpeg', 'jpg', 'png' ],
    acceptableContentTypes: [ 'application/pdf', 'image/jpeg', 'image/png',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/msword',
      'application/vnd.ms-powerpoint',
      'application/vnd.ms-excel'
    ]
  }
};
