const DICTIONARY_PATH: string = 'builder.duplicateChanges';

export const builderDuplicateChangesComponentConfig = {
  dictionary: {
    uSelectS: 'uSelectS',
    uRange: 'uRange',
    select: 'general.select',
    message: `${DICTIONARY_PATH}.message`,
    type: `${DICTIONARY_PATH}.type`,
    apply: `${DICTIONARY_PATH}.apply`
  }
};
