<u-sidebar-menu
  [isRtl]="isRtl"
  [config]="sidebarMenuConfig()"
  [collapsed]="uSidebarMenuService.collapsed()"
  [opened]="uSidebarMenuService.opened()"
  (navigate)="onNavigate($event)"
  (menuToggle)="uSidebarMenuService.updateCollapsed($event)"
  (languageSelect)="setLanguage($event)"
  (menuHeaderItemSelect)="headerItemSelect($event)"
  (toggle)="uSidebarMenuService.updateOpened($event)"
></u-sidebar-menu>
