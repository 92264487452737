import { ChangeDetectionStrategy, Component, HostBinding, input, output } from '@angular/core';

import { AppConstants } from '@app/shared/constants';
import { MomentConvertPipe } from '@app/shared/pipes';
import { AiChatConversation } from '@app/ai-chat/models';

@Component({
  selector: 'app-ai-chat-conversations',
  standalone: true,
  imports: [
    MomentConvertPipe
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './ai-chat-conversations.component.html',
  styleUrls: [ './ai-chat-conversations.component.scss', './ai-chat-conversations.component.rtl.scss' ]
})
export class AiChatConversationsComponent {
  readonly conversations = input.required<AiChatConversation[]>();

  readonly selectConversation = output<number>();

  @HostBinding('class') hostClasses: string = 'ai-chat-conversations';

  readonly appConstants = AppConstants;
}
