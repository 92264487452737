import {
  Component,
  OnInit,
  HostBinding,
  ChangeDetectionStrategy,
  OnDestroy,
  EventEmitter,
  Output,
  DestroyRef
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Subject, forkJoin } from 'rxjs';
import { filter, pairwise, takeUntil, map, exhaustMap } from 'rxjs/operators';
import { UntypedFormControl } from '@angular/forms';
import { isEqual, cloneDeep } from 'lodash';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { UPopoverDirective, UPopupService } from '@shift/ulib';

import {
  TrackingService,
  LocalizationService,
  HeaderDataService
} from '@app/shared/services';
import { BulkChangeService } from '@app/bulk-change/services';
import { ActivitiesService, ActivitiesDataService } from '@app/activities/services';
import { ActivitiesFilters, Activity, ActivityUpdateStatus } from '@app/activities/models';
import { AppConstants } from '@app/shared/constants';
import { EmailsDataService } from '@app/emails/services';
import { FileSaverService } from '@app/files/services';
import { AuthDataSnapshotService } from '@app/auth/services';
import { activitiesComponentConfig } from './activities.component.config';

@Component({
  selector: 'app-activities',
  templateUrl: './activities.component.html',
  styleUrls: [ './activities.component.scss', './activities.component.rtl.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ AuthDataSnapshotService ]
})
export class ActivitiesComponent implements OnInit, OnDestroy {
  @Output() closeActivities: EventEmitter<void> = new EventEmitter();

  @HostBinding('class') hostClasses: string = 'activities';

  private unsubscribe: Subject<void> = new Subject();

  config = cloneDeep(activitiesComponentConfig);
  filtersPopover: UPopoverDirective;
  search: UntypedFormControl = new UntypedFormControl('');
  activeDate$ = this.activitiesDataService.activeDate$;
  activitiesFilters$ = this.activitiesDataService.activitiesFilters$;
  activitiesWithFilters$ = this.activitiesDataService.activitiesWithFilters$;
  activeRouteId$ = this.activitiesDataService.activeRouteId$;
  isRtl = this.localizationService.isRtl();

  constructor(
    private destroyRef: DestroyRef,
    private headerDataService: HeaderDataService,
    private uPopupService: UPopupService,
    private trackingService: TrackingService,
    private emailsDataService: EmailsDataService,
    private activitiesService: ActivitiesService,
    private bulkChangeService: BulkChangeService,
    private fileSaverService: FileSaverService,
    private translateService: TranslateService,
    private localizationService: LocalizationService,
    public authDataSnapshotService: AuthDataSnapshotService,
    public activitiesDataService: ActivitiesDataService
  ) {}

  ngOnInit() {
    this.onHeaderDateChange();
    this.track('Open');
    this.onUpdateActivities();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();

    this.clearActivitiesFilters();
  }

  onHeaderDateChange() {
    this.headerDataService.date$
      .pipe(
        takeUntil(this.unsubscribe),
        pairwise(),
        filter(([ prev, next ]) => !isEqual(prev, next)),
        filter(data => !!data)
      )
      .subscribe(() => this.reloadActivities());
  }

  onUpdateActivities() {
    this.activitiesDataService.activitiesUpdate$
      .pipe(
        takeUntil(this.unsubscribe),
        map(activities => activities.filter(activity => activity.status === ActivityUpdateStatus.Update && this.activitiesDataService.hasActivity(activity.activityId))),
        filter(activities => !activities.length),
        exhaustMap(activities => forkJoin([ ...activities.map(activity => this.activitiesService.getActivity(activity.activityId)) ]))
      )
      .subscribe(activities => this.activitiesDataService.updateActivities(activities));
  }

  reloadActivities() {
    this.activitiesDataService.reloadActivities(this.headerDataService.getDateRange());
  }

  deleteActivity(activityId: number) {
    this.track('Delete temp comment');

    this.uPopupService.showMessage({
      message: this.config.dictionary.deleteConfirm,
      yes: this.config.dictionary.yes,
      no: this.config.dictionary.no
    }, () => {
      this.activitiesDataService.deleteActivity(activityId);
    });
  }

  archiveActivity(activityId: number) {
    this.track('Notification card - click on archive icon');

    this.activitiesDataService.archiveActivity(activityId);
  }

  toggleActivity(activityId: number) {
    this.activitiesDataService.toggleActivity(activityId);
  }

  sendActivityEmails(activity: Activity) {
    this.track('Notification card - click on e-mail icon');

    this.emailsDataService.sendActivityEmails(activity.activityId, activity.additionalData.shuttleCompaniesEmailKeys.map(shuttleCompaniesEmailKey => shuttleCompaniesEmailKey.emailCacheKey))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => this.activitiesDataService.resetActivityShuttleCompaniesEmailKeys(activity.activityId));
  }

  refresh() {
    this.track('Click on refresh');

    this.activitiesDataService.loadActivities(this.headerDataService.getDateRange());
  }

  resetFilters() {
    this.track('Click on clear filter');

    this.activitiesDataService.loadActivities(this.headerDataService.getDateRange());
  }

  track(message: string) {
    this.trackingService.track(`[${this.config.trackingId}] - ${message}`);
  }

  downloadExcel(activity: Activity) {
    this.track('Notification card - click on excel icon');

    this.activitiesService.getErrorsExcel({ cacheKey: activity.additionalData.fileCacheKey })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(data => {
        const fileName = this.translateService.instant(this.config.dictionary.excelFileName);

        this.fileSaverService.downloadBlobFile(data, moment(this.headerDataService.getActiveDate()).format(AppConstants.DATE_FORMAT_BASE_DOT) + '-' + fileName + '.xlsx');
      });
  }

  downloadFile(activity: Activity) {
    this.activitiesService.getFile({ cacheKey: activity.additionalData.fileCacheKey })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(data => this.fileSaverService.downloadBlobFile(data, activity.additionalData.fileName));
  }

  applyActivitiesFilters(data: ActivitiesFilters) {
    this.activitiesDataService.applyActivitiesFilters(data);
  }

  initFiltersPopover(popover: UPopoverDirective) {
    this.filtersPopover = popover;
  }

  closeFiltersPopover() {
    if (this.filtersPopover.isOpen()) {
      this.filtersPopover.close();
    }
  }

  clearActivitiesFilters() {
    this.activitiesDataService.clearActivitiesFilters();
  }
}
