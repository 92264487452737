import { AuthCustomerType } from '@app/auth/models';
import { ActivityAdditionalStatus, ActivityRouteFromTemplateCreationResult } from '@app/activities/models';

const ACTIVITIES_DICTIONARY_PATH: string = 'activities';
const DICTIONARY_PATH: string = 'activities.item';

const dictionary = {
  emailIsExpired: `${DICTIONARY_PATH}.emailIsExpired`,
  notificationWillBeArchived: `${DICTIONARY_PATH}.notificationWillBeArchived`,
  fileIsExpired: `${DICTIONARY_PATH}.fileIsExpired`,
  routesSent: `${DICTIONARY_PATH}.routesSent`,
  routesFailed: `${DICTIONARY_PATH}.routesFailed`,
  rideRestoration: `${DICTIONARY_PATH}.rideRestoration`,
  rideCancelation: `${DICTIONARY_PATH}.rideCancelation`,
  routeDuplicated: `${DICTIONARY_PATH}.routeDuplicated`,
  templateDuplicated: `${DICTIONARY_PATH}.templateDuplicated`,
  somePassengersWereRemoved: `${DICTIONARY_PATH}.somePassengersWereRemoved`,
  passengerChanges: `${DICTIONARY_PATH}.passengerChanges`,
  reOptimizationStatuses: `${DICTIONARY_PATH}.reOptimizationStatuses`,
  statuses: {
    success: `${DICTIONARY_PATH}.statuses.success`,
    failed: `${DICTIONARY_PATH}.statuses.failed`,
    partialSuccess: `${DICTIONARY_PATH}.statuses.partialSuccess`
  },
  bulk: {
    statuses: `${DICTIONARY_PATH}.bulk.statuses`,
    changeSubject: {
      RideCancel: `${DICTIONARY_PATH}.bulk.changeSubject.rideCancel`,
      RideRestore: `${DICTIONARY_PATH}.bulk.changeSubject.rideRestore`,
      AccompanySet: `${DICTIONARY_PATH}.bulk.changeSubject.accompanySet`,
      AccompanyRemove: `${DICTIONARY_PATH}.bulk.changeSubject.accompanyRemove`,
      AccompanySwap: `${DICTIONARY_PATH}.bulk.changeSubject.accompanySwap`,
      DriverChange: `${DICTIONARY_PATH}.bulk.changeSubject.driverChange`,
      DriverRemove: `${DICTIONARY_PATH}.bulk.changeSubject.driverRemove`,
      DriverSwap: `${DICTIONARY_PATH}.bulk.changeSubject.driverSwap`,
      TimeChange: `${DICTIONARY_PATH}.bulk.changeSubject.timeChange`,
      PassengerMoveToNewStation: `${DICTIONARY_PATH}.bulk.changeSubject.passengerMoveToNewStation`,
      PassengerMoveToNewTarget: `${DICTIONARY_PATH}.bulk.changeSubject.passengerMoveToNewTarget`,
      PassengerRemove: `${DICTIONARY_PATH}.bulk.changeSubject.passengerRemove`,
      BranchNameChanged: `${DICTIONARY_PATH}.bulk.changeSubject.branchNameChanged`,
      BranchAddressChanged: `${DICTIONARY_PATH}.bulk.changeSubject.branchAddressChanged`,
      YitChange: `${DICTIONARY_PATH}.bulk.changeSubject.yitChange`,
      PassengerUpdate: `${DICTIONARY_PATH}.bulk.changeSubject.passengerUpdate`,
      AssignedAccompanyCost: `${DICTIONARY_PATH}.bulk.changeSubject.assignedAccompanyCost`,
      CarChange: `${DICTIONARY_PATH}.bulk.changeSubject.carChange`,
      CarRemove: `${DICTIONARY_PATH}.bulk.changeSubject.carRemove`,
      ShuttleCompanyChange: `${DICTIONARY_PATH}.bulk.changeSubject.shuttleCompanyChange`,
      ShuttleCompanyRemove: `${DICTIONARY_PATH}.bulk.changeSubject.shuttleCompanyRemove`,
      ExecutionCostChange: `${DICTIONARY_PATH}.bulk.changeSubject.executionCostChange`,
      AssignedShuttleCompanyCost: `${DICTIONARY_PATH}.bulk.changeSubject.assignedShuttleCompanyCost`,
      ShuttleCompanyCloseRides: `${DICTIONARY_PATH}.bulk.changeSubject.shuttleCompanyCloseRides`,
      ShuttleCompanyOpenRides: `${DICTIONARY_PATH}.bulk.changeSubject.shuttleCompanyOpenRides`
    },
    description: `${DICTIONARY_PATH}.bulk.description`,
    routes: `${DICTIONARY_PATH}.bulk.routes`
  },
  templateFromRoute: {
    statuses: `${DICTIONARY_PATH}.templateFromRoute.statuses`
  },
  routesFromTemplates: {
    statuses: `${DICTIONARY_PATH}.routesFromTemplates.statuses`,
    shuttleCompanyHasMoreThanSingleContractForSelectedPeriod: `${DICTIONARY_PATH}.routesFromTemplates.shuttleCompanyHasMoreThanSingleContractForSelectedPeriod`
  },
  aiSuggestions: {
    statuses: `${DICTIONARY_PATH}.aiSuggestions.statuses`,
    changeSubject: {
      VehicleAssignment: `${DICTIONARY_PATH}.aiSuggestions.changeSubject.vehicleAssignment`,
      DriverAssignment: `${DICTIONARY_PATH}.aiSuggestions.changeSubject.driverAssignment`,
      ShuttleCompanyAssignment: `${DICTIONARY_PATH}.aiSuggestions.changeSubject.shuttleCompanyAssignment`
    },
    description: `${DICTIONARY_PATH}.aiSuggestions.description`,
    routes: `${DICTIONARY_PATH}.aiSuggestions.routes`
  },
  routeRestoredAndRouteCancelledStatuses: {
    [ActivityAdditionalStatus.Success]: `${DICTIONARY_PATH}.statuses.success`,
    [ActivityAdditionalStatus.Failed]: `${DICTIONARY_PATH}.statuses.failed`,
    [ActivityAdditionalStatus.PartialSuccess]: `${DICTIONARY_PATH}.statuses.partialSuccess`
  },
  xReportStatuses: {
    [ActivityAdditionalStatus.Success]: `${DICTIONARY_PATH}.statuses.success`,
    [ActivityAdditionalStatus.Failed]: `${DICTIONARY_PATH}.statuses.failed`
  },
  download: `${DICTIONARY_PATH}.download`,
  files: `${DICTIONARY_PATH}.files`,
  types: `${ACTIVITIES_DICTIONARY_PATH}.types`,
  doneBy: 'general.doneBy'
};

const activitiesItemComponent = {
  dictionary,
  processedTemplatesSuccessCreationResults: [
    ActivityRouteFromTemplateCreationResult.Success,
    ActivityRouteFromTemplateCreationResult.ShuttleCompanyHasMoreThanSingleContractForSelectedPeriod
  ]
};

const activitiesItemComponentArmy = {
  ...activitiesItemComponent,
  dictionary: {
    ...activitiesItemComponent.dictionary,
    bulk: {
      ...activitiesItemComponent.dictionary.bulk,
      changeSubject: {
        ...activitiesItemComponent.dictionary.bulk.changeSubject,
        BranchNameChanged: `${DICTIONARY_PATH}.bulk.changeSubject.branchNameChangedArmy`,
        BranchAddressChanged: `${DICTIONARY_PATH}.bulk.changeSubject.branchAddressChangedArmy`
      }
    }
  }
};

export const activitiesItemComponentConfig = {
  default: activitiesItemComponent,
  [AuthCustomerType.Army]: activitiesItemComponentArmy
};
