import {
  Component,
  DestroyRef,
  ElementRef,
  HostBinding,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  WritableSignal,
  computed,
  effect,
  inject,
  signal
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { forkJoin, of, combineLatest } from 'rxjs';
import { debounceTime, filter, map, pairwise, startWith, switchMap, take, tap } from 'rxjs/operators';
import * as moment from 'moment';
import { isEqual, cloneDeep } from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { UGridScroll, UMultiselectItem, USearchFilter, USelectSItem, UDaysOrderService } from '@shift/ulib';

import { environment } from '@environments/environment';
import { HeaderSearchFiltersService, LoadingDataService, TablePageService, HeaderDataService } from '@app/shared/services';
import { AppConstants } from '@app/shared/constants';
import { TrackingService } from '@app/shared/services';
import { WeekSwitchStartEnd, GlobalSearchFilter } from '@app/shared/models';
import { generateWeekDates } from '@app/shared/utils';
import { AuthModuleDemandsShiftsViewFeatureType } from '@app/auth/models';
import { AuthDataService } from '@app/auth/services';
import { PassengersDataService } from '@app/passengers/services';
import { UserService } from '@app/user/services';
import { BranchesOption } from '@app/branches/models';
import { DemandsShiftsViewService, DemandsCommonService } from '@app/demands/services';
import { DemandsShiftsViewGetTableBody, DemandsShiftsViewInitialData, DemandsShiftsViewShift, DemandsShiftsViewShiftRow, DemandsCustomerData } from '@app/demands/models';
import { demandsShiftsViewComponentConfig } from './demands-shifts-view.component.config';

@Component({
  selector: 'app-demands-shifts-view',
  templateUrl: './demands-shifts-view.component.html',
  styleUrls: [ './demands-shifts-view.component.scss', 'demands-shifts-view.component.rtl.scss' ],
  providers: [ TablePageService, LoadingDataService ]
})
export class DemandsShiftsViewComponent implements OnInit, OnDestroy {
  @ViewChild('dayCellHeader', { static: false }) public dayCellHeader: TemplateRef<any>;
  @ViewChild('dayCell', { static: false }) public dayCell: TemplateRef<any>;
  @ViewChild('shiftCell', { static: false }) public shiftCell: ElementRef<HTMLElement>;
  @ViewChild('hoursCell', { static: false }) public hoursCell: ElementRef<HTMLElement>;
  @ViewChild('demandsTable', { static: false }) public demandsTable: ElementRef<HTMLElement>;

  @HostBinding('class') hostClasses: string = 'demands-shifts-view';

  private readonly translateService = inject(TranslateService);
  private readonly destroyRef = inject(DestroyRef);
  private readonly demandsShiftsViewService = inject(DemandsShiftsViewService);
  private readonly headerDataService = inject(HeaderDataService);
  private readonly headerSearchFiltersService = inject(HeaderSearchFiltersService);
  private readonly trackingService = inject(TrackingService);
  private readonly userService = inject(UserService);
  private readonly passengersDataService = inject(PassengersDataService);
  private readonly authDataService = inject(AuthDataService);
  private readonly loadingDataService = inject(LoadingDataService);
  private readonly uDaysOrderService = inject(UDaysOrderService);
  private readonly tablePageService = inject(TablePageService);
  public readonly demandsCommonService = inject(DemandsCommonService);

  private defaultDepartmentIds: number[] = [];
  private defaultBranchIds: number[] = [];
  private weekSwitchChangeStore: WeekSwitchStartEnd;
  private initialDataItemsStore: { [key: string]: (USelectSItem | UMultiselectItem)[]; };
  private initialDataItemsOriginalStore: DemandsShiftsViewInitialData = {};
  private maxAmountShifts: number = environment.config.maxAmountRecordsForPage;
  private skipShifts: number = 0;
  private toTranslated: string;
  private specialShiftTranslated: string;

  #config = signal(demandsShiftsViewComponentConfig.default);
  #featureType: WritableSignal<AuthModuleDemandsShiftsViewFeatureType> = signal(undefined);
  #initialized: WritableSignal<boolean> = signal(false);
  #rowHeights: WritableSignal<{ [key: number]: number; }> = signal({});
  #rowDepartmentGroupHeights: WritableSignal<{ [key: string]: { [key: number]: number; }; }> = signal({});
  #shifts: WritableSignal<DemandsShiftsViewShift[]> = signal([]);

  readonly config = this.#config.asReadonly();
  readonly featureType = this.#featureType.asReadonly();
  readonly initialized = this.#initialized.asReadonly();
  readonly rowHeights = this.#rowHeights.asReadonly();
  readonly rowDepartmentGroupHeights = this.#rowDepartmentGroupHeights.asReadonly();
  readonly rows = computed(() =>
    this.#shifts().map(row => {
      const startTime = row.startTime ? moment(row.startTime, AppConstants.TIME_FORMAT_FULL).format(AppConstants.TIME_FORMAT) : this.config().emptyTime;
      const endTime = row.endTime ? moment(row.endTime, AppConstants.TIME_FORMAT_FULL).format(AppConstants.TIME_FORMAT) : this.config().emptyTime;

      return {
        ...row,
        id: row.shiftId,
        shiftName: row.shiftId === null ? this.specialShiftTranslated : row.shiftName,
        hours: `${startTime} ${this.toTranslated} ${endTime}`,
        ...Object.assign(
          {},
          ...this.config().daysOfWeek.map(dayOfWeek =>
            ({
              [`dayOfWeek-${dayOfWeek}`]: row.demands ?
                row.demands
                  .filter(demand => demand.dayOfWeek === dayOfWeek)
                  .reduce((acc, demand) => {
                    const department = acc.find(obj => obj.departmentId === demand.departmentId);

                    if (department) {
                      return acc.map(obj => obj.departmentId === demand.departmentId ? { ...obj, demands: [ ...obj.demands, demand ] } : obj);
                    }

                    return [ ...acc, { departmentId: demand.departmentId, departmentName: demand.departmentName, demands: [ demand ] } ];
                  }, [])
                : row[`dayOfWeek-${dayOfWeek}`] || undefined
            })
          )
        )
      };
    })
  );

  constructor() {
    effect(() => this.tablePageService.initRows(this.rows()));
  }

  ngOnInit() {
    this.init();

    this.headerDataService.updateShowWeekSwitch(true);
  }

  ngOnDestroy() {
    this.headerDataService.updateShowWeekSwitch(false);
  }

  private init() {
    forkJoin([
      this.translateService.get(this.config().dictionary.to),
      this.translateService.get(this.config().dictionary.specialShift),
      this.userService.getUserDepartments().pipe(map(departments => departments.map(department => department.id))),
      this.userService.getUserBranches().pipe(map(branches => branches.map(branch => branch.id))),
      this.authDataService.modules$.pipe(map(modules => modules?.demandsShiftsView?.type), take(1))
    ])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(([ toTranslated, specialShiftTranslated, defaultDepartmentIds, defaultBranchIds, featureType ]) => {
        this.toTranslated = toTranslated;
        this.specialShiftTranslated = specialShiftTranslated;
        this.defaultDepartmentIds = defaultDepartmentIds;
        this.defaultBranchIds = defaultBranchIds;

        this.#featureType.set(featureType);

        this.initConfig();
        this.setGlobalFilters();
        this.onShiftsGet();
        this.onUpdateShift();
      });
  }

  private initConfig() {
    const config = cloneDeep(demandsShiftsViewComponentConfig[this.featureType()] || demandsShiftsViewComponentConfig.default);

    this.#config.set({
      ...config,
      globalFilters: config.globalFilters.filter(globalFilter => globalFilter.feature ? this.authDataService.checkFeature(globalFilter.feature) : true),
      tablePageConfig: {
        ...config.tablePageConfig,
        tableConfig: {
          ...config.tablePageConfig.tableConfig,
          columns: cloneDeep(demandsShiftsViewComponentConfig[this.featureType()] || demandsShiftsViewComponentConfig.default).tablePageConfig.tableConfig.columns
            .filter(column => column.feature ? this.authDataService.checkFeature(column.feature) : true),
          rowHeight: this.getRowHeight,
          cellTemplates: {
            dayCell: this.dayCell,
            shiftCell: this.shiftCell,
            hoursCell: this.hoursCell
          },
          filterSortingFns: {
            shiftNameFilterSortingFn: this.shiftNameFilterSortingFn
          }
        }
      }
    });
  }

  private onShiftsGet() {
    combineLatest([
      this.loadingDataService.refresh$,
      this.headerSearchFiltersService.appliedSearch$.pipe(tap(() => this.trackingService.track(`[${this.config().tablePageConfig.trackingId}] - search`))),
      this.headerDataService.weekSwitchChange$,
      this.headerSearchFiltersService.appliedFilters$
    ])
      .pipe(
        debounceTime(100),
        startWith([]),
        pairwise(),
        filter(([ prev, next ]) => !isEqual(prev, next) || !this.loadingDataService.getLoadingValue()),
        switchMap(() => {
          this.loadingDataService.updateLoading(true);

          return this.demandsShiftsViewService.getTable(this.getShiftsParams());
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(data => {
        this.tablePageService.updateTotalCount(data.totalCount);

        this.updateColumnsByWeekSwitchChange();

        this.#shifts.update(state => this.skipShifts > 0 ? [ ...state, ...data.shifts ] : data.shifts);
        this.#rowHeights.set({});

        this.loadingDataService.updateLoading(false);

        if (!this.initialized()) {
          this.#initialized.set(true);
        }
      });
  }

  private getShiftsParams(): DemandsShiftsViewGetTableBody {
    const weekSwitchChange = this.headerDataService.getWeekSwitchChangeValue();

    return {
      departmentIds: [],
      branchIds: [],
      shiftIds: [],
      startDate: weekSwitchChange.startDate,
      endDate: weekSwitchChange.endDate,
      search: this.headerSearchFiltersService.getSearchValue(),
      skip: this.skipShifts,
      take: this.maxAmountShifts,
      ...this.headerSearchFiltersService.getAppliedFiltersValue()
    };
  }

  private onUpdateShift() {
    this.demandsCommonService.updateShift$
      .pipe(
        switchMap(data => {
          const weekSwitchChange = this.headerDataService.getWeekSwitchChangeValue();

          return this.demandsShiftsViewService.getTable({
            departmentIds: [],
            branchIds: [ data.branchId ],
            shiftStartTime: data.startTime,
            shiftEndTime: data.endTime,
            shiftIds: data.shiftId === null ? [] : [ data.shiftId ],
            startDate: weekSwitchChange.startDate,
            endDate: weekSwitchChange.endDate,
            search: '',
            skip: 0,
            take: this.maxAmountShifts
          });
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(data => {
        this.#shifts.update(shifts => shifts.map(row =>
          data.shifts.find(shift =>
            shift.branchId === row.branchId &&
            shift.shiftId === row.shiftId &&
            shift.startTime === row.startTime &&
            shift.endTime === row.endTime
          ) || row
        ));

        this.#rowHeights.set({});
      });
  }

  private updateColumnsByWeekSwitchChange() {
    const weekSwitchChange = this.headerDataService.getWeekSwitchChangeValue();

    if (!isEqual(this.headerDataService.getWeekSwitchChangeValue(), this.weekSwitchChangeStore)) {
      this.weekSwitchChangeStore = weekSwitchChange;
      this.updateColumns(weekSwitchChange.startDate, weekSwitchChange.endDate);
    }
  }

  private getRowHeight = (row: DemandsShiftsViewShiftRow) => {
    const rowKey = `${row.shiftId}-${row.branchId}-${row.startTime}-${row.endTime}`;
    const rowHeight = this.rowHeights()[rowKey];

    if (rowHeight) {
      return rowHeight;
    }

    const defaultHeight = this.config().shiftHeight + this.config().rowPadding;
    const daysOfWeek = this.config().daysOfWeek;
    const daysOfWeekLength = daysOfWeek.length;
    const rowDepartmentGroupHeight: { [key: number]: number; } = {};

    for (let i = 0; i < daysOfWeekLength; i++) {
      const groups = row[`dayOfWeek-${daysOfWeek[i]}`] || [];
      const groupsLength = groups.length;

      for (let j = 0; j < groupsLength; j++) {
        const groupShiftsAmount = groups[j]?.demands?.length + 1;
        const groupHeight = groupShiftsAmount >= 1 ? this.config().departmentHeight + (groupShiftsAmount * this.config().shiftHeight) : defaultHeight;
        const daysOfWeekGroupHeight = rowDepartmentGroupHeight[j];

        if (!daysOfWeekGroupHeight || groupHeight >= daysOfWeekGroupHeight) {
          rowDepartmentGroupHeight[j] = groupHeight;
        }
      }
    }

    const rowDepartmentGroupHeightsTotal = Object.values(rowDepartmentGroupHeight).reduce((acc, item) => acc + item, 0);
    const height = rowDepartmentGroupHeightsTotal ? rowDepartmentGroupHeightsTotal + this.config().rowPadding : defaultHeight;

    this.#rowDepartmentGroupHeights.update(state => ({ ...state, [rowKey]: rowDepartmentGroupHeight }));
    this.#rowHeights.update(state => ({ ...state, [rowKey]: height }));

    return height;
  };

  private setGlobalFilters() {
    this.initialDataItemsStore = cloneDeep(this.initialDataItemsStore || this.config().initialDataFilters);

    const appliedFilters = this.headerSearchFiltersService.getAppliedFilters();
    const appliedFiltersValues = appliedFilters?.reduce((acc, obj) => ({ ...acc, [obj.name]: obj.value }), {}) || {};
    const currentFilters: USearchFilter[] = this.headerSearchFiltersService.getFilters();
    const currentFiltersValues = this.passengersDataService.generateFiltersValues(currentFilters);
    const selectedFiltersValues = { ...appliedFiltersValues, ...currentFiltersValues };
    const currentFiltersItems = this.passengersDataService.generateFiltersItems(currentFilters);
    const onlyDefaultFiltersSelected = !Object.keys(selectedFiltersValues)
      .filter(filterKey => filterKey !== 'departmentIds' && filterKey !== 'branchIds')
      .some(filterKey => !!selectedFiltersValues[filterKey]?.length);

    if (this.defaultDepartmentIds.length && !selectedFiltersValues?.departmentIds?.length && onlyDefaultFiltersSelected) {
      selectedFiltersValues.departmentIds = this.defaultDepartmentIds;
    }

    if (this.defaultBranchIds.length && !selectedFiltersValues?.branchIds?.length && onlyDefaultFiltersSelected) {
      selectedFiltersValues.branchIds = this.defaultBranchIds;
    }

    const selectedGlobalFilters = this.headerSearchFiltersService.getFilters();
    const filters: USearchFilter[] = this.config().globalFilters.map(filterObj => {
      const selectedGlobalFilter = selectedGlobalFilters.find(obj => obj.name === filterObj.name);

      return this.generateGlobalFilterItem(selectedGlobalFilter, filterObj, selectedFiltersValues);
    });

    this.onGlobalFiltersChange(filters);

    if (Object.keys(selectedFiltersValues).some(filterKey => !!selectedFiltersValues[filterKey]?.length)) {
      this.headerSearchFiltersService.updateFiltersControls(filters);

      const applySelectedFilters = () => {
        this.headerSearchFiltersService.onFiltersApplied(Object.keys(selectedFiltersValues).map(key => ({
          name: key,
          value: selectedFiltersValues[key]
        })));
      };

      const loadDefaultDepartments = !!this.defaultDepartmentIds.length && !currentFiltersItems.departmentIds?.length;
      const loadDefaultBranches = !!this.defaultBranchIds.length && !currentFiltersItems.branchIds?.length;

      if (loadDefaultDepartments || loadDefaultBranches) {
        const departmentsFilter = filters.find(item => item.name === 'departmentIds');
        const branchesFilter = filters.find(item => item.name === 'branchIds');

        forkJoin([
          loadDefaultDepartments && departmentsFilter.lazyLoadItems ? departmentsFilter.lazyLoadItems.pipe(take(1)) : of(undefined),
          loadDefaultBranches && branchesFilter.lazyLoadItems ? branchesFilter.lazyLoadItems.pipe(take(1)) : of(undefined)
        ])
          .pipe(
            takeUntilDestroyed(this.destroyRef)
          )
          .subscribe(() => applySelectedFilters());
      } else {
        applySelectedFilters();
      }
    } else {
      this.headerSearchFiltersService.applyFilters(filters);
    }
  }

  private onGlobalFiltersChange(filters: USearchFilter[]) {
    const shiftIdsDefaultFilter = this.config().globalFilters.find(ob => ob.name === 'shiftIds');

    filters.forEach(filterObj => {
      if (filterObj.lazyLoadItems) {
        filterObj.control
          .valueChanges
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe(branchIds => {
            let customValues = {};

            if (filterObj.name === 'branchIds') {
              const shiftIds = this.initialDataItemsOriginalStore && this.initialDataItemsOriginalStore.shiftIds ? this.initialDataItemsOriginalStore.shiftIds : [];
              let filteredShifts = [];

              if (branchIds && branchIds.length) {
                const hasBranchesWithoutShift = !branchIds
                  .every(branchId => shiftIds.some(shift => shift.branchId === branchId));

                filteredShifts = shiftIds
                  .filter(shift => branchIds.includes(shift.branchId) || (hasBranchesWithoutShift && shift.branchId === BranchesOption.Without));
              }

              const shiftIdsItems = (filteredShifts.length ? filteredShifts : shiftIds).map(item => ({ name: item.name, value: item.id }));

              const branchIdsStoreLength = this.initialDataItemsOriginalStore.branchIds && this.initialDataItemsOriginalStore.branchIds.length;

              this.initialDataItemsStore.shiftIds = <UMultiselectItem[]>(
                branchIds && (!branchIds.length || branchIds.length === branchIdsStoreLength) ?
                  [ ...shiftIdsDefaultFilter.items, ...shiftIdsItems ] : shiftIdsItems
              );

              customValues = { shiftIds: [] };
            }

            this.updateGlobalFilters(this.initialDataItemsStore, customValues);
          });
      }
    });
  }

  private generateGlobalFilterItem(selectedGlobalFilter: USearchFilter, filterObj: GlobalSearchFilter, selectedFiltersValues: { [key: string]: string | number | boolean | (string | number | boolean)[]; }): USearchFilter {
    return {
      name: filterObj.name,
      items: selectedGlobalFilter?.items || (filterObj.itemsLazyLoad ? null : filterObj.items),
      control: new UntypedFormControl(selectedFiltersValues[filterObj.name] || filterObj.value),
      type: filterObj.type,
      title: filterObj.title,
      ...(filterObj.emptyName ? { emptyName: filterObj.emptyName } : {}),
      lazyLoadItems: !selectedGlobalFilter?.items?.length && !this.initialDataItemsOriginalStore[filterObj.name] ?
        this.demandsShiftsViewService.getInitialData([ this.config().initialDataFilters[filterObj.name] ])
          .pipe(
            map(data => {
              let items = [];

              if (filterObj.name === 'shiftIds') {
                const filtersValues = this.headerSearchFiltersService.getFiltersValue();
                const shifts = data[this.config().initialDataFilters[filterObj.name]];
                let filteredShifts = [];

                if (filtersValues && Array.isArray(filtersValues.branchIds) && filtersValues.branchIds.length) {
                  const branchIds = <number[]>filtersValues.branchIds;
                  const hasBranchesWithoutShift = !branchIds
                    .every(branchId => shifts.some(shift => shift.branchId === branchId));

                  filteredShifts = shifts
                    .filter(shift =>
                      branchIds.includes(shift.branchId) || (hasBranchesWithoutShift && shift.branchId === BranchesOption.Without)
                    );
                }

                const branchIdsStoreLength = this.initialDataItemsOriginalStore.branchIds && this.initialDataItemsOriginalStore.branchIds.length;

                items = (filteredShifts.length ? filteredShifts : shifts).map(item => ({ name: item.name, value: item.id }));
                items = Array.isArray(filtersValues.branchIds) &&
                (
                  !filtersValues.branchIds.length || filtersValues.branchIds.length === branchIdsStoreLength
                ) ? [ ...filterObj.items, ...items ] : items;
              } else {
                items = data[this.config().initialDataFilters[filterObj.name]].map(ob => ({ name: ob.name, value: ob.id }));
                items = filterObj.items ? [ ...filterObj.items, ...items ] : items;
              }

              this.initialDataItemsOriginalStore[filterObj.name] = data[this.config().initialDataFilters[filterObj.name]];
              this.initialDataItemsStore[filterObj.name] = items;

              return items;
            }),
            tap(items => this.headerSearchFiltersService.updateFiltersItems({ [filterObj.name]: items }))
          )
        : undefined
    };
  }

  private updateGlobalFilters(customerData: DemandsCustomerData, customValues?: object) {
    const newFilters = this.headerSearchFiltersService.getFilters()
      .map(filterObj => {
        filterObj.items = customerData && Array.isArray(customerData[filterObj.name]) ? customerData[filterObj.name] : filterObj.items;

        if (customValues && customValues[filterObj.name]) {
          filterObj.control.patchValue(customValues[filterObj.name]);
        }

        return filterObj;
      });

    this.headerSearchFiltersService.updateFiltersItems(newFilters);
  }

  private updateColumns(startDate: string, endDate: string) {
    const weekColumns = [];
    const weekDates = generateWeekDates(startDate, endDate);
    const today = moment().startOf('day');

    for (const dayOfWeek of this.config().daysOfWeek) {
      const date = moment(weekDates[dayOfWeek]).startOf('day');

      weekColumns.push({
        prop: `dayOfWeek-${dayOfWeek}`,
        propAlias: dayOfWeek,
        name: date.format(AppConstants.DATE_FORMAT_BASE_DOT_DDD_DD_MM),
        minWidth: 172,
        maxWidth: 172,
        resizeable: false,
        cellTemplate: this.dayCell,
        dayOfWeek: dayOfWeek,
        date: date.format(AppConstants.DATE_FORMAT_BASE_DOT_DD_MM),
        dateISO: date.format(AppConstants.DATE_FORMAT_ISO),
        clickble: true,
        highlight: date.isSame(today),
        headerClass: 'day-header-cell',
        cellClass: 'day-body-cell',
        sortable: false,
        headerFilterTemplate: this.dayCellHeader
      });
    }

    if (!this.uDaysOrderService.sundayFirstDay()) {
      weekColumns.push(cloneDeep(weekColumns.splice(0, 1)[0]));
    }

    const config = { ...this.config() };
    const columns = [
      ...(
        cloneDeep(demandsShiftsViewComponentConfig[this.featureType()] || demandsShiftsViewComponentConfig.default).tablePageConfig.tableConfig.columns
          .filter(column => column.feature ? this.authDataService.checkFeature(column.feature) : true)
      ),
      ...weekColumns
    ];

    this.#config.set({
      ...config,
      tablePageConfig: {
        ...config.tablePageConfig,
        tableConfig: {
          ...config.tablePageConfig.tableConfig,
          columns: this.tablePageService.totalCount > this.maxAmountShifts ? columns.map(column => ({ ...column, filterType: undefined, sortable: false })) : columns
        }
      }
    });
  }

  private shiftNameFilterSortingFn = (items) => items.sort((elA, elB) => elA === this.specialShiftTranslated ? -1 : elB === this.specialShiftTranslated ? 1 : 0);

  refresh() {
    this.skipShifts = 0;
    this.loadingDataService.updateRefresh();
  }

  onScrollTable(scrollPosition: UGridScroll) {
    const demandsTableBody = this.demandsTable.nativeElement.querySelector('datatable-body');

    if (
      this.initialized() &&
      scrollPosition.offsetY === demandsTableBody.scrollHeight - demandsTableBody.clientHeight &&
      this.tablePageService.rows.length !== this.tablePageService.totalCount
    ) {
      this.skipShifts = this.tablePageService.rows.length;

      this.loadingDataService.updateRefresh();
    }
  }
}
