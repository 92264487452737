const DICTIONARY_PATH: string = 'monitoringDashboard';

export const monitoringDashboardComponentConfig = {
  dictionary: {
    anonymousAccompany: 'general.anonymousAccompany',
    search: 'general.search',
    timeIn: 'general.timeIn',
    minutes: 'general.minutes',
    rides: {
      title: `${DICTIONARY_PATH}.rides.title`,
      days: `${DICTIONARY_PATH}.rides.days`
    },
    details: {
      openAllStations: `${DICTIONARY_PATH}.details.openAllStations`,
      closeAllStations: `${DICTIONARY_PATH}.details.closeAllStations`,
      passengers: `${DICTIONARY_PATH}.details.passengers`,
      title: `${DICTIONARY_PATH}.details.title`,
      platform: `${DICTIONARY_PATH}.details.platform`,
      appVersion: `${DICTIONARY_PATH}.details.appVersion`,
      batteryLevel: `${DICTIONARY_PATH}.details.batteryLevel`,
      gpsStatus: `${DICTIONARY_PATH}.details.gpsStatus`,
      firstCoordinate: `${DICTIONARY_PATH}.details.firstCoordinate`,
      lastCoordinate: `${DICTIONARY_PATH}.details.lastCoordinate`,
      close: `${DICTIONARY_PATH}.details.close`,
      lastInteraction: `${DICTIONARY_PATH}.details.lastInteraction`
    },
    summary: {
      pickedUp: `${DICTIONARY_PATH}.summary.pickedUp`,
      notPickedUp: `${DICTIONARY_PATH}.summary.notPickedUp`,
      notMarked: `${DICTIONARY_PATH}.summary.notMarked`,
      reported: `${DICTIONARY_PATH}.summary.reported`,
      notReported: `${DICTIONARY_PATH}.summary.notReported`
    },
    station: {
      pickedUp: `${DICTIONARY_PATH}.station.pickedUp`,
      notReported: `${DICTIONARY_PATH}.station.notReported`,
      notPickedUp: `${DICTIONARY_PATH}.station.notPickedUp`,
      reported: `${DICTIONARY_PATH}.station.reported`,
      notComing: `${DICTIONARY_PATH}.station.notComing`,
      anonymousPassengers: `${DICTIONARY_PATH}.station.anonymousPassengers`,
      type: {
        driver: `${DICTIONARY_PATH}.station.type.driver`,
        pickup: `${DICTIONARY_PATH}.station.type.pickup`,
        target: `${DICTIONARY_PATH}.station.type.target`,
        accompany: `${DICTIONARY_PATH}.station.type.accompany`
      },
      time: {
        planned: `${DICTIONARY_PATH}.station.time.planned`,
        actual: `${DICTIONARY_PATH}.station.time.actual`,
        noEntrance: `${DICTIONARY_PATH}.station.time.noEntrance`,
        onTime: `${DICTIONARY_PATH}.station.time.onTime`
      }
    },
    rideStatus: {
      errorSecondPart: `${DICTIONARY_PATH}.rideStatus.errorSecondPart`
    }
  }
};
