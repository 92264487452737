import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { SignalRService } from '@app/shared/services';
import { ActivityUpdate } from '@app/activities/models';

@Injectable({
  providedIn: 'root'
})
export class ActivitiesHubService extends SignalRService {
  BASE_PATH = 'activitiesHub';

  onActivitiesUpdate(): Observable<ActivityUpdate[]> {
    return this.onChannel<ActivityUpdate[]>('ActivitiesUpdate');
  }
}
