<div
  class="builder-ride-order-details__main"
>
  <div class="builder-ride-order-details__wrapper">
    <div class="builder-ride-order-details__item">
      <div class="builder-ride-order-details__group">
        <div class="builder-ride-order-details__group-name">
          {{config.dictionary.orderNumber | translate}}
        </div>
        <div class="builder-ride-order-details__group-field">
          <input
            uInput
            [uId]="config.idPrefix + '-number'"
            [valid]="addEditForm.get('details.number').valid || addEditForm.get('details.number').disabled"
            [formControl]="addEditForm.get('details.number')"
            [placeholder]="config.dictionary.orderNumber | translate"
            [mask]="'0*.00000'"
            [dropSpecialCharacters]="false"
            (blur)="builderDataService.editNumber()"
          >
        </div>
      </div>
    </div>
    <div class="builder-ride-order-details__item">
      <div class="builder-ride-order-details__group">
        <div class="builder-ride-order-details__group-name">
          {{config.dictionary.routeType | translate}}
        </div>
        <div class="builder-ride-order-details__group-field">
          <u-select-s
            *ngIf="addEditForm.get('details.routeType') as formControl"
            [empty]="false"
            [class]="'u-select-s_app-builder-details'"
            [items]="options.routeTypes"
            [formControl]="formControl"
            [placeholder]="config.dictionary.select | translate"
            [messages]="config.dictionary.uSelectS | translate"
            [placement]="isRtl ? [ 'bottom-right', 'top-right' ] : [ 'bottom-left', 'top-left' ]"
            [viewportElement]="viewportElement"
            [valid]="formControl.disabled || formControl.valid"
          ></u-select-s>
        </div>
      </div>
    </div>
    <div class="builder-ride-order-details__item">
      <div class="builder-ride-order-details__group">
        <div class="builder-ride-order-details__group-name">
          {{config.dictionary.emptyStations.title | translate}}
        </div>
        <div class="builder-ride-order-details__group-field builder-ride-order-details__group-field_flex-items-center">
          <u-toggle
            [formControl]="addEditForm.get('details.allowEmptyStations')"
            [messages]="config.dictionary.uToggle | translate"
            (toggleChanged)="onAllowEmptyStationsChange($event)"
          ></u-toggle>
          <div
            class="builder-ride-order-details__group-field-info"
            [uTooltip]="config.dictionary.emptyStations.warning | translate"
            [placement]="['top']"
            [container]="'body'"
            [tooltipClass]="'u-tooltip_general'"
            [triggers]="'hover focus'"
          ></div>
        </div>
      </div>
    </div>
    <div
      class="builder-ride-order-details__item"
    >
      <div class="builder-ride-order-details__group">
        <div class="builder-ride-order-details__group-name">
          {{config.dictionary.region | translate}}
        </div>
        <div class="builder-ride-order-details__group-field">
          <u-select-s
            *ngIf="addEditForm.get('details.regionCode') as formControl"
            [uId]="config.idPrefix + '-region'"
            [emptyName]="config.dictionary.select | translate"
            [emptyRemove]="config.dictionary.removeSelect | translate"
            [emptyValue]="null"
            [items]="(builderDataService.modeAdd() || builderDataService.rideOrder.hasRequestingDeparment) ? options.memberRegions : options.regions"
            [formControl]="formControl"
            [placeholder]="config.dictionary.select | translate"
            [messages]="config.dictionary.uSelectS | translate"
            [placement]="isRtl ? [ 'bottom-right', 'top-right' ] : [ 'bottom-left', 'top-left' ]"
            [viewportElement]="viewportElement"
            [valid]="formControl.valid"
          ></u-select-s>
        </div>
      </div>
    </div>
    <div
      class="builder-ride-order-details__item"
    >
      <div class="builder-ride-order-details__group">
        <div class="builder-ride-order-details__group-name">
          {{(departmentsType === departmentsTypes.Section ? config.dictionary.command : departmentsType === departmentsTypes.Division ? config.dictionary.departmentDivision : config.dictionary.department) | translate}}
        </div>
        <div class="builder-ride-order-details__group-field">
          <u-select-s
            *ngIf="addEditForm.get('details.departmentId') as formControl"
            [uId]="config.idPrefix + '-department'"
            [emptyName]="config.dictionary.select | translate"
            [emptyRemove]="config.dictionary.removeSelect | translate"
            [emptyValue]="null"
            [items]="
              (builderDataService.modeAdd() || builderDataService.rideOrder.hasRequestingDeparment) ? 
              (addEditForm.get('details.regionCode').value ? builderDataStoreService.departmentsByMemberRegionCodesOptions[addEditForm.get('details.regionCode').value] : options.departments) : 
              builderDataStoreService.departmentsByRegionCodesOptions?.[addEditForm.get('details.regionCode').value]
            "
            [formControl]="formControl"
            [placeholder]="config.dictionary.select | translate"
            [messages]="config.dictionary.uSelectS | translate"
            [placement]="isRtl ? [ 'bottom-right', 'top-right' ] : [ 'bottom-left', 'top-left' ]"
            [viewportElement]="viewportElement"
            [valid]="formControl.valid"
          ></u-select-s>
        </div>
      </div>
    </div>
    <div
      class="builder-ride-order-details__item"
    >
      <div class="builder-ride-order-details__group">
        <div class="builder-ride-order-details__group-name">
          {{config.dictionary.assignSCBy | translate}}
        </div>
        <div class="builder-ride-order-details__group-field">
          <u-select-s
            [uId]="config.idPrefix + '-assign-sc-by'"
            [empty]="false"
            [items]="config.assignSCByItems"
            [formControl]="addEditForm.get('details.locationType')"
            [placeholder]="config.dictionary.select | translate"
            [messages]="config.dictionary.uSelectS | translate"
            [placement]="isRtl ? [ 'bottom-right', 'top-right' ] : [ 'bottom-left', 'top-left' ]"
            [viewportElement]="viewportElement"
          ></u-select-s>
        </div>
      </div>
    </div>
    <div class="builder-ride-order-details__item">
      <div class="builder-ride-order-details__group-double">
        <div class="builder-ride-order-details__group-double-name">
          {{config.dictionary.vehicleTypeAndQuantity | translate}}
        </div>
        <div class="builder-ride-order-details__group-double-fields">
          <div class="builder-ride-order-details__group-double-field">
            <u-select-s
              [uId]="config.idPrefix + '-car-type'"
              [empty]="false"
              [items]="options.carTypes"
              [formControl]="addEditForm.get('activeRide.carTypeId')"
              [placeholder]="config.dictionary.select | translate"
              [messages]="config.dictionary.uSelectS | translate"
              [placement]="isRtl ? [ 'bottom-right', 'top-right' ] : [ 'bottom-left', 'top-left' ]"
              [viewportElement]="viewportElement"
              [classUDropdownContent]="'u-dropdown-content_app-builder-ride-order-details'"
              [valid]="addEditForm.get('activeRide.carTypeId').disabled || addEditForm.get('activeRide.carTypeId').valid"
            ></u-select-s>
          </div>
          <div class="builder-ride-order-details__group-double-field">
            <u-input-number
              [formControl]="addEditForm.get('details.quantity')"
              [valid]="addEditForm.get('details.quantity').disabled || addEditForm.get('details.quantity').valid"
              [min]="1"
              [max]="999"
              [valueType]="'number'"
            ></u-input-number>
          </div>
        </div>
      </div>
    </div>
    <div
      class="builder-ride-order-details__item"
    >
      <div class="builder-ride-order-details__group">
        <div class="builder-ride-order-details__group-name">
          {{config.dictionary.orderPurpose | translate}}
        </div>
        <div class="builder-ride-order-details__group-field">
          <u-select-s
            [uId]="config.idPrefix + '-order-purpose'"
            [emptyName]="config.dictionary.select | translate"
            [emptyRemove]="config.dictionary.removeSelect | translate"
            [emptyValue]="null"
            [items]="builderDataStoreService.orderPurposesByRouteTypeOptions[addEditForm.get('details.routeType').value] || builderDataStoreService.orderPurposesByRouteTypeOptions['null'] || []"
            [formControl]="addEditForm.get('details.orderPurposeId')"
            [placeholder]="config.dictionary.select | translate"
            [messages]="config.dictionary.uSelectS | translate"
            [placement]="isRtl ? [ 'bottom-right', 'top-right' ] : [ 'bottom-left', 'top-left' ]"
            [viewportElement]="viewportElement"
            [valid]="addEditForm.get('details.orderPurposeId').disabled || addEditForm.get('details.orderPurposeId').valid"
          ></u-select-s>
        </div>
      </div>
    </div>
    <div class="builder-ride-order-details__item">
      <div class="builder-ride-order-details__group-double">
        <div class="builder-ride-order-details__group-name">
          {{config.dictionary.date | translate}}
        </div>
        <div class="builder-ride-order-details__group-field">
          <u-input-date
            class="u-input-date_app-builder-ride-order-details"
            [uId]="config.idPrefix + '-date'"
            [container]="'body'"
            [placement]="isRtl ? [ 'bottom-right' ] : [ 'bottom-left' ]"
            [defaultDate]="true"
            [showIcon]="false"
            [disableDaysBefore]="disableDaysBefore"
            [messages]="config.dictionary.uInputDate | translate"
            [formControl]="builderDataService.dateFormControl"
          ></u-input-date>
        </div>
      </div>
    </div>
    <div class="builder-ride-order-details__item">
      <div class="builder-ride-order-details__group-double">
        <div class="builder-ride-order-details__group-double-name">{{config.dictionary.time | translate}}</div>
        <div class="builder-ride-order-details__group-double-fields">
          <div class="builder-ride-order-details__group-double-field">
            <u-input-time
              [uId]="config.idPrefix + '-start-time'"
              [disabled]="activeRide.rideCalculationMode === builderCalculationMode.Manual || builderDataService.rideOrder.summaryVisible || !builderDataService.createOrEditRoutesAllowed()"
              [(ngModel)]="activeRide.startDateTime"
              (ngModelChange)="builderDataService.editStartTime()"
            ></u-input-time>
          </div>
          <div class="builder-ride-order-details__group-double-field">
            <u-input-time
              [uId]="config.idPrefix + '-end-time'"
              [disabled]="activeRide.rideCalculationMode === builderCalculationMode.Manual || builderDataService.rideOrder.summaryVisible || !builderDataService.createOrEditRoutesAllowed()"
              [(ngModel)]="activeRide.endDateTime"
              (ngModelChange)="builderDataService.editEndTime()"
            ></u-input-time>
          </div>
        </div>
      </div>
    </div>
    <div class="builder-ride-order-details__item">
      <div class="builder-ride-order-details__group">
        <div class="builder-ride-order-details__group-name">
          {{config.dictionary.rideSupervisor | translate}}
        </div>
        <div class="builder-ride-order-details__group-field">
          <u-select-s
            [uId]="config.idPrefix + '-supervisor'"
            [emptyName]="config.dictionary.select | translate"
            [emptyRemove]="config.dictionary.removeSelect | translate"
            [emptyValue]="null"
            [items]="options.supervisors"
            [formControl]="addEditForm.get('activeRide.supervisorId')"
            [placeholder]="config.dictionary.select | translate"
            [messages]="config.dictionary.uSelectS | translate"
            [placement]="isRtl ? [ 'bottom-right', 'top-right' ] : [ 'bottom-left', 'top-left' ]"
            [viewportElement]="viewportElement"
            [valid]="addEditForm.get('activeRide.supervisorId').disabled || addEditForm.get('activeRide.supervisorId').valid"
            [container]="'body'"
            [classUDropdownContent]="'u-dropdown-content_app-builder-ride-order-details'"
          ></u-select-s>
        </div>
      </div>
    </div>
    <div class="builder-ride-order-details__item">
      <div class="builder-ride-order-details__group builder-ride-order-details__group_comment">
        <div class="builder-ride-order-details__group-name">{{config.dictionary.comments | translate}}</div>
        <div class="builder-ride-order-details__group-field">
          <textarea
            uTextarea
            [uId]="config.idPrefix + '-comments'"
            [formControl]="addEditForm.get('details.comment')"
            [placeholder]="config.dictionary.comments | translate"
          ></textarea>
        </div>
      </div>
    </div>
  </div>
</div>
