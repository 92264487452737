import { AiChatMode, AiChatState } from '@app/ai-chat/models';

const initialState: AiChatState = {
  position: { x: 1400, y: 300 },
  size: { w: 384, h: 512 },
  mode: AiChatMode.Default,
  conversationsById: {},
  selectedConversationId: null,
  selectedConversationMessageTableId: null
};

export const aiChatConfig = {
  storageKey: 'aiChat',
  minSize: { w: 384, h: 512 },
  defaultPosition: { right: 17, bottom: 60 },
  initialState
};
