import { UInputModalConfig, UInputModalMode } from '@shift/ulib';

import { RouteTemplatesComponent } from '@app/route-templates/components';
import { RouteTemplatesMode } from '@app/route-templates/models';

const DICTIONARY_PATH: string = 'builder.fullFilter';

const templatesFilterInputModal: UInputModalConfig = {
  contentComponent: RouteTemplatesComponent,
  placeholder: 'general.select',
  mode: UInputModalMode.Counter,
  showCloseIcon: true,
  modalClassNames: 'u-modal u-modal_content u-modal_app-route-templates',
  contentComponentInitialValueKey: 'selectedRowIds',
  contentComponentEventName: 'selectedRowsChange',
  contentComponentInputs: {
    extraClass: 'route-templates_input-modal',
    mode: RouteTemplatesMode.Readonly,
    gridHeaderDictionary: {
      all: `${DICTIONARY_PATH}.templates`
    }
  }
};

export const builderFullFilterComponentConfig = {
  templatesFilterInputModal,
  dictionary: {
    uSelectS: 'uSelectS',
    uMultiselect: 'uMultiselect',
    uInputDateRange: 'uInputDateRange',
    select: 'general.select',
    customer: `${DICTIONARY_PATH}.customer`,
    customers: `${DICTIONARY_PATH}.customers`,
    routesFor: `${DICTIONARY_PATH}.routesFor`,
    planningType: `${DICTIONARY_PATH}.planningType`,
    dailyRoutesOnly: `${DICTIONARY_PATH}.dailyRoutesOnly`,
    templates: `${DICTIONARY_PATH}.templates`,
    minimizeVehicleType: `${DICTIONARY_PATH}.minimizeVehicleType`,
    duplicateTemplate: `${DICTIONARY_PATH}.duplicateTemplate`,
    demands: `${DICTIONARY_PATH}.demands`,
    demandsToggle: `${DICTIONARY_PATH}.demandsToggle`,
    direction: `${DICTIONARY_PATH}.direction`,
    setTime: `${DICTIONARY_PATH}.setTime`,
    routeType: `${DICTIONARY_PATH}.routeType`,
    shuttleCompany: `${DICTIONARY_PATH}.shuttleCompany`,
    pricing: `${DICTIONARY_PATH}.pricing`,
    mapView: `${DICTIONARY_PATH}.mapView`,
    tableView: `${DICTIONARY_PATH}.tableView`,
    routesPolicy: `${DICTIONARY_PATH}.routesPolicy`,
    calculateRoutes: `${DICTIONARY_PATH}.calculateRoutes`
  }
};
