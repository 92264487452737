import { Component, OnInit, HostBinding, Output, EventEmitter, signal, inject } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as moment from 'moment';
import { cloneDeep } from 'lodash';

import { builderDaysCopyComponentConfig } from './builder-days-copy.component.config';

@Component({
  selector: 'app-builder-days-copy',
  templateUrl: './builder-days-copy.component.html',
  styleUrls: [ './builder-days-copy.component.scss', './builder-days-copy.component.rtl.scss' ]
})
export class BuilderDaysCopyComponent implements OnInit {
  @Output() action = new EventEmitter();

  @HostBinding('class') hostClasses: string = 'builder-days-copy';

  private readonly bsModalRef = inject(BsModalRef);

  readonly #config = signal(cloneDeep(builderDaysCopyComponentConfig));

  readonly config = this.#config.asReadonly();

  readonly checkboxes = cloneDeep(this.config().checkboxes);
  dayActive = null;
  daysAvailable = [];
  days = [];
  sundayFirstDay: boolean;

  ngOnInit() {
    this.updateDays();
  }

  checkItem(item) {
    item.check = !item.check;

    const checkboxes = this.checkboxes.filter(checkbox => checkbox.value !== item.value);

    checkboxes.forEach(checkbox => checkbox.check = !item.check);
  }

  close() {
    this.bsModalRef.hide();
  }

  apply() {
    this.bsModalRef.hide();

    this.action.emit({ type: 'apply', content: { dayActive: this.dayActive, checkboxes: this.checkboxes } });
  }

  updateDays() {
    const momentDaysOfWeek = moment().localeData().weekdaysMin();
    const daysOfWeek = [];

    for (let i = 1; i < 7; i++) {
      const day = {
        value: i,
        name: momentDaysOfWeek[i]
      };

      daysOfWeek.push(day);
    }

    const firstDay = {
      value: 0,
      name: momentDaysOfWeek[0]
    };

    if (this.sundayFirstDay) {
      daysOfWeek.splice(0, 0, firstDay);
    } else {
      daysOfWeek.push(firstDay);
    }

    this.days = daysOfWeek.filter((obDay: any) => this.daysAvailable.some((el: number) => el === obDay.value));
  }
}
