import { FloatingAreaPosition, FloatingAreaSize } from '@app/shared/models';

export enum AiChatMode {
  Default = 'default',
  FullScreen = 'full-screen'
}

export enum AiChatAction {
  Back,
  NewChat,
  SwitchMode,
  Close
}

export interface AiChatConversationMessage {
  id: number;
  text: string;
  user: string;
  sendDate: string;
  tableId: number;
}

export interface AiChatConversation {
  id: number;
  messages: AiChatConversationMessage[];
}

export interface AiChatState {
  position: FloatingAreaPosition;
  size: FloatingAreaSize;
  mode: AiChatMode;
  conversationsById: Record<number, AiChatConversation>;
  selectedConversationId: number;
  selectedConversationMessageTableId: number;
}
