import {
  Component,
  HostBinding,
  OnInit,
  OnChanges,
  ChangeDetectionStrategy,
  inject,
  output,
  input
} from '@angular/core';
import { cloneDeep } from 'lodash';
import { TranslateModule } from '@ngx-translate/core';
import { UButtonModule, UTooltipModule } from '@shift/ulib';

import { AppLanguage } from '@app/shared/models';
import { LocalizationService, TrackingService } from '@app/shared/services';
import { AuthUserInfo } from '@app/auth/models';
import { NotesListItem } from '@app/notes/models';
import { NoteComponent } from '@app/notes/components/note/note.component';
import { notesListComponentConfig } from './notes-list.component.config';

@Component({
  selector: 'app-notes-list',
  templateUrl: './notes-list.component.html',
  styleUrls: [ './notes-list.component.scss', './notes-list.component.rtl.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TranslateModule,
    UTooltipModule,
    UButtonModule,
    NoteComponent
  ]
})
export class NotesListComponent implements OnInit, OnChanges {
  readonly subTitle = input<string>();
  readonly trackingId = input<string>();
  readonly data = input<NotesListItem[]>();
  readonly authUser = input<AuthUserInfo>();

  readonly closeNotes = output<void>();
  readonly newNote = output<NotesListItem>();
  readonly editNote = output<NotesListItem>();
  readonly readNotes = output<number[]>();
  readonly removeNote = output<NotesListItem>();

  @HostBinding('class') hostClasses: string = 'notes-list';

  private readonly trackingService = inject(TrackingService);
  private readonly localizationService = inject(LocalizationService);

  private readNoteIds: number[] = [];

  notes: NotesListItem[];
  selectedNoteId: number;
  pendingNewNote: NotesListItem;
  config = cloneDeep(notesListComponentConfig);
  isRtl: boolean = this.localizationService.isRtl();
  lang: AppLanguage = this.localizationService.getLanguage();

  ngOnInit() {
    this.notes = cloneDeep(this.data());
  }

  ngOnChanges() {
    if (this.pendingNewNote) {
      this.notes = [ this.pendingNewNote, ...this.data() ];
    } else {
      this.notes = [ ...this.data() ];
    }
  }

  saveNewNote(newNote: NotesListItem) {
    this.notes = this.notes.map(note => note.id === newNote.id ? { ...note, text: newNote.text } : note);

    this.pendingNewNote = null;

    this.newNote.emit(newNote);
  }

  private checkIfEmptyNote(id: number) {
    const noteIndex = this.notes.findIndex(note => note.id === id);

    if (noteIndex >= 0 && this.notes[noteIndex] && !this.notes[noteIndex].text) {
      this.notes = this.notes.filter((note, index) => index !== noteIndex);
    }
  }

  private trackEvent(event: string) {
    this.trackingService.track(`[${this.trackingId() && (this.trackingId() + ' - ')}Notes] - ${event}`);
  }

  close() {
    this.closeNotes.emit();
  }

  noteRead(noteId: number) {
    if (this.readNoteIds && !this.readNoteIds.includes(noteId)) {
      this.readNoteIds = [ ...this.readNoteIds, noteId ];

      this.readNotes.emit(this.readNoteIds);
    }
  }

  addNewNote(event: MouseEvent) {
    event.stopPropagation();

    this.notes = [
      {
        id: -1,
        text: '',
        isRead: true,
        allowEdit: true,
        createdBy: `${this.authUser().person.firstName} ${this.authUser().person.lastName}`
      },
      ...this.notes
    ];

    this.selectedNoteId = -1;
    this.pendingNewNote = this.notes[0];

    this.trackEvent('click on new note');
  }

  onClickInsideNote(id: number) {
    if (this.selectedNoteId !== id) {
      this.onClickOutsideNote(this.selectedNoteId);

      this.selectedNoteId = id;
    }
  }

  onClickOutsideNote(id: number) {
    if (this.selectedNoteId === id) {
      this.checkIfEmptyNote(id);
    }

    this.selectedNoteId = null;
    this.pendingNewNote = null;
  }

  onRemoveNote(id: number) {
    this.pendingNewNote = null;

    const removedNoteIndex = this.notes.findIndex(note => note.id === id);
    const removedNoteValue = this.notes[removedNoteIndex];

    if (removedNoteValue.text && removedNoteValue.id !== -1) {
      this.removeNote.emit(removedNoteValue);
    }

    this.notes = this.notes.filter((note, index) => index !== removedNoteIndex);

    this.trackEvent('click on done icon');
  }

  trackById(index: number, note: NotesListItem) {
    return note.id;
  }
}
