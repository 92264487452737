const DICTIONARY_PATH = 'aiChat.selectedConversation';

export const aiChatSelectedConversationComponentConfig = {
  dictionary: {
    hi: `${DICTIONARY_PATH}.hi`,
    askQuestion: `${DICTIONARY_PATH}.askQuestion`,
    viewTheTable: `${DICTIONARY_PATH}.viewTheTable`,
    excelDownload: `${DICTIONARY_PATH}.excelDownload`
  },
  maxMessageTextLength: 2000
};
