import { Component, OnInit, Output, EventEmitter, HostBinding, Input, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { cloneDeep } from 'lodash';
import { Subject } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { USelectSItem } from '@shift/ulib';

import { RoutePolicyCalculateBy, RoutePolicyPickUpOption } from '@app/shared/models';
import { LocalizationService, TrackingService } from '@app/shared/services';
import { routePolicyConfig } from '@app/shared/configs';
import { AuthModuleRouteBuilderFeatureSettings } from '@app/auth/models';
import { AuthDataService } from '@app/auth/services';
import { AuthDataSnapshotService } from '@app/auth/services';
import { BuilderSettings, BuilderViewMode } from '@app/builder/models';
import { builderConfig } from '@app/builder/configs';
import { builderSettingsComponentConfig } from './builder-settings.component.config';

@Component({
  selector: 'app-builder-settings',
  templateUrl: './builder-settings.component.html',
  styleUrls: [ './builder-settings.component.scss', './builder-settings.component.rtl.scss' ]
})
export class BuilderSettingsComponent implements OnInit, OnDestroy {
  @Input() addEditForm: any;

  @Output() closeAction: EventEmitter<any> = new EventEmitter();
  @Output() applyAction: EventEmitter<BuilderSettings> = new EventEmitter();

  @HostBinding('class') hostClasses: string = 'builder-settings';

  private unsubscribe: Subject<void> = new Subject();

  builderViewMode = BuilderViewMode;
  displayUseTraffic: boolean;
  config = cloneDeep(builderSettingsComponentConfig);
  isRtl: boolean = true;
  settingsForm: UntypedFormGroup;
  pickupOptionTypes: USelectSItem[] = routePolicyConfig.pickupOptionTypes;

  constructor(
    private authDataService: AuthDataService,
    private fb: UntypedFormBuilder,
    private trackingService: TrackingService,
    private localizationService: LocalizationService,
    private authDataSnapshotService: AuthDataSnapshotService
  ) {}

  ngOnInit() {
    this.isRtl = this.localizationService.isRtl();

    this.getBuilderSettingsFeature();
    this.generateForm();
    this.checkPermissions();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private checkPermissions() {
    if (!this.authDataSnapshotService.policiesAndSettingsManagementPermission()) {
      this.settingsForm.disable();
    }
  }

  private getBuilderSettingsFeature() {
    this.authDataService.modules$
      .pipe(
        map(modules => modules?.routeBuilder?.settings),
        take(1),
        takeUntil(this.unsubscribe)
      )
      .subscribe(setting => this.displayUseTraffic = setting === AuthModuleRouteBuilderFeatureSettings.UseTraffic);
  }

  private generateForm() {
    this.settingsForm = this.fb.group({
      waitingTime: [ '10', [ Validators.required ] ],
      useTollRoads: [ false ],
      curbApproach: [ RoutePolicyPickUpOption.VehicleAbilities ],
      calculateBy: [ RoutePolicyCalculateBy.Time ],
      cheapestPrice: [ false ],
      useTraffic: [ false ],
      speedRate: [ 100 ]
    });

    const { settings } = this.addEditForm.value;

    this.settingsForm.patchValue(settings);
  }

  close() {
    this.closeAction.emit();
  }

  apply() {
    if (this.settingsForm.valid) {
      this.applyAction.emit(this.settingsForm.value);
    }
  }

  cheapestPriceChange(newVal: boolean) {
    this.trackingService.track(`[${builderConfig.trackingId}, Route Policy] - Cheapest toggle - click on ${newVal ? 'Active' : 'Inactive'}`);
  }
}
