import {
  Component,
  OnInit,
  Input,
  HostBinding,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  inject,
  DestroyRef
} from '@angular/core';
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgForOf, NgIf } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslateModule } from '@ngx-translate/core';
import { cloneDeep, merge } from 'lodash';
import { UCommonModule } from '@shift/ulib';

import { FilesService } from '@app/files/services';
import {
  FilesBaseParams,
  FilesContainerType,
  FilesEntityType,
  FilesGetFromFolderItem,
  FilesUploadError
} from '@app/files/models';
import { filesComponentConfig } from './files.component.config';

@Component({
  selector: 'app-files',
  templateUrl: './files.component.html',
  styleUrls: [ './files.component.scss', './files.component.rtl.scss' ],
  standalone: true,
  imports: [
    UCommonModule,
    TranslateModule,
    ReactiveFormsModule,
    NgIf,
    NgForOf
  ]
})
export class FilesComponent implements OnInit {
  @Input() config: { dictionary?: { [key: string]: string; }; };
  @Input() entityId: string;
  @Input() entityType: FilesEntityType;
  @Input() disabled = false;

  @Output() clickOnAttachFile: EventEmitter<void> = new EventEmitter();

  @HostBinding('class') hostClasses: string = 'files';

  private readonly destroyRef = inject(DestroyRef);
  private readonly cdRef = inject(ChangeDetectorRef);
  private readonly formBuilder = inject(UntypedFormBuilder);
  private readonly filesService = inject(FilesService);

  filesComponentConfig = cloneDeep(filesComponentConfig);
  form: UntypedFormGroup;
  uploadFileErrors: FilesUploadError[];
  files: FilesGetFromFolderItem[] = [];

  ngOnInit() {
    this.initConfig();
    this.getEntityFiles();
    this.createForm();
  }

  private initConfig() {
    if (this.config) {
      this.filesComponentConfig = merge(this.filesComponentConfig, this.config);
    }
  }

  private getEntityFiles() {
    this.filesService.getFromFolder(this.getFilesBaseParams())
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(data => {
        this.files = data;

        this.cdRef.markForCheck();
      });
  }

  private getFilesBaseParams(): FilesBaseParams {
    return {
      entityType: this.entityType,
      entityId: this.entityId,
      containerType: FilesContainerType.Customer
    };
  }

  private createForm() {
    this.form = this.formBuilder.group({
      file: null
    });
  }

  private getFormData() {
    const formData = new FormData();
    const file = this.form.get('file').value;

    this.uploadFileErrors = [];

    if (!this.filesService.isSizeValid(file, this.filesComponentConfig.fileSettings.maximumFileSize)) {
      this.uploadFileErrors = [ ...this.uploadFileErrors, FilesUploadError.MaxFileSize ];
    }

    if (!this.filesService.isFileFormatValid(file, this.filesComponentConfig.fileSettings.acceptableExtensions, this.filesComponentConfig.fileSettings.acceptableContentTypes)) {
      this.uploadFileErrors = [ ...this.uploadFileErrors, FilesUploadError.Unsupported ];
    }

    if (this.uploadFileErrors && this.uploadFileErrors.length) {
      return null;
    }

    formData.append('entityId', `${this.entityId}`);
    formData.append('entityType', `${this.entityType}`);
    formData.append('containerType', `${FilesContainerType.Customer}`);
    formData.append('file', file);

    return formData;
  }

  deleteFile(file: File) {
    this.filesService.delete({
      ...this.getFilesBaseParams(),
      fileName: file.name
    })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.getEntityFiles());
  }

  downloadFile(file: File) {
    this.filesService.download({
      ...this.getFilesBaseParams(),
      fileName: file.name
    })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe();
  }

  uploadFile() {
    const formData = this.getFormData();

    if (formData) {
      this.filesService.upload(formData)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(response => {
          this.uploadFileErrors = response.errors;

          this.getEntityFiles();

          this.cdRef.markForCheck();
        });
    }
  }
}
