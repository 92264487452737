export interface FloatingAreaPosition {
  x: number;
  y: number;
}

export interface FloatingAreaSize {
  w: number;
  h: number;
}

export type FloatingAreaAnchorType = 'top' | 'left' | 'bottom' | 'right';

export type FloatingAreaDirectionType = 'x' | 'y' | 'xy';
