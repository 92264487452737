import { Injectable, inject } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { USidebarMenuService } from '@shift/ulib';

import { AccessibilityAffidavitComponent } from '@app/shared/components';

@Injectable()
export class AccessibilityAffidavitModalService {
  private readonly uSidebarMenuService = inject(USidebarMenuService);
  private readonly bsModalService = inject(BsModalService);

  openModal() {
    return this.bsModalService.show(
      AccessibilityAffidavitComponent,
      {
        class: this.uSidebarMenuService.collapsed() ? 'u-modal u-modal_content u-modal_content-hide-menu' : 'u-modal u-modal_content',
        animated: true,
        ignoreBackdropClick: true,
        backdrop: true,
        keyboard: false
      }
    );
  }
}
