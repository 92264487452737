import { inject, Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormArray, UntypedFormGroup } from '@angular/forms';

import { AuthDataService } from '@app/auth/services';
import { AuthModuleTransportationFeatureShuttleCompanyContractsSpecialRates } from '@app/auth/models';
import {
  ShuttleCompanyContractPricelistCostType,
  ShuttleCompaniesContractsAddEditModalTabDataName,
  ShuttleCompanyContractPricelistVehicleType,
  ShuttleCompanyContractPricelistCitiesPrice,
  ShuttleCompanyContractPricelistKmRangesPrice
} from '@app/shuttle-companies/models';

@Injectable({
  providedIn: 'root'
})
export class ShuttleCompanyContractsPricelistsService {
  private readonly authDataService = inject(AuthDataService);
  private readonly fb = inject(UntypedFormBuilder);

  private tabDataNamesToCheck = [
    ShuttleCompaniesContractsAddEditModalTabDataName.PricelistCities,
    ShuttleCompaniesContractsAddEditModalTabDataName.PricelistKmBase,
    ShuttleCompaniesContractsAddEditModalTabDataName.PricelistKmRanges
  ];

  kmBasePricesForm: UntypedFormArray;
  citiesPricingForm: UntypedFormGroup;
  kmRangesPricesForm: UntypedFormArray;
  hashcalPricesForm: UntypedFormArray;
  activeVehicleTypes: ShuttleCompanyContractPricelistVehicleType[];
  shuttleCompanyContractsSpecialRatesFeature: AuthModuleTransportationFeatureShuttleCompanyContractsSpecialRates = null;

  private getAnotherDefaultPricelistName(addEditModalForm: UntypedFormGroup, currentPricelist: string): string {
    return this.tabDataNamesToCheck.find(dataName =>
      dataName !== currentPricelist && addEditModalForm.get(dataName) && (<UntypedFormGroup>addEditModalForm.get(dataName)).getRawValue().default
    );
  }

  private getUpdatedActiveVehicleTypes(vehicleType: ShuttleCompanyContractPricelistVehicleType) {
    return [
      ...this.activeVehicleTypes,
      vehicleType
    ]
      .sort((prev: ShuttleCompanyContractPricelistVehicleType, next: ShuttleCompanyContractPricelistVehicleType) => {
        if (prev.seatsCount < next.seatsCount) { return -1; }

        if (prev.seatsCount > next.seatsCount) { return 1; }

        return prev.name.localeCompare(next.name);
      });
  }

  private addVehicleType(vehicleType: ShuttleCompanyContractPricelistVehicleType) {
    if (this.activeVehicleTypes) {
      this.activeVehicleTypes = this.getUpdatedActiveVehicleTypes(vehicleType);
    }

    if (this.citiesPricingForm) {
      this.addCitiesPricingVehicle(vehicleType);
    }

    if (this.kmBasePricesForm) {
      this.addKmBasePriceVehicle(vehicleType);
    }

    if (this.kmRangesPricesForm) {
      this.addKmRangesPricesVehicle(vehicleType);
    }

    if (this.hashcalPricesForm) {
      this.addHashcalPricesVehicle(vehicleType);
    }
  }

  private addKmBasePriceVehicle(vehicleType: ShuttleCompanyContractPricelistVehicleType) {
    this.kmBasePricesForm.insert(
      this.activeVehicleTypes.indexOf(vehicleType) || 0,
      this.fb.group({
        maxDistance: [ 0 ],
        price: [ 0 ],
        vehicleType: this.fb.group({
          id: [ vehicleType.id ],
          name: [ vehicleType.name ]
        })
      })
    );

    this.kmBasePricesForm.updateValueAndValidity();
  }

  private addCitiesPricingVehicle(vehicleType: ShuttleCompanyContractPricelistVehicleType) {
    (this.citiesPricingForm.get('citiesPrices') as UntypedFormArray).controls.forEach((control: UntypedFormGroup) => {
      (control.get('prices') as UntypedFormArray).insert(
        this.activeVehicleTypes.indexOf(vehicleType) || 0,
        this.fb.group({
          basePrice: [ 0 ],
          entryPrice: [ 0 ],
          vehicleTypeId: [ vehicleType.id ]
        })
      );
    });

    (this.citiesPricingForm.get('vehicleTypes') as UntypedFormArray).insert(
      this.activeVehicleTypes.indexOf(vehicleType) || 0,
      this.fb.group({
        id: [ vehicleType.id ],
        name: [ vehicleType.name ]
      })
    );

    this.citiesPricingForm.updateValueAndValidity();
  }

  private addKmRangesPricesVehicle(vehicleType: ShuttleCompanyContractPricelistVehicleType) {
    this.kmRangesPricesForm.insert(this.activeVehicleTypes.indexOf(vehicleType) || 0, this.fb.group({
      vehicleType: this.fb.group({
        id: [ vehicleType.id ],
        name: [ vehicleType.name ]
      }),
      kmRanges: this.fb.array([]),
      extraCost: [ 0 ],
      nightCostType: [ ShuttleCompanyContractPricelistCostType.Percent ],
      nightCost: [ 0 ],
      weekendCostType: [ ShuttleCompanyContractPricelistCostType.Percent ],
      weekendCost: [ 0 ]
    }));

    this.kmRangesPricesForm.updateValueAndValidity();
  }

  private addHashcalPricesVehicle(vehicleType: ShuttleCompanyContractPricelistVehicleType) {
    this.hashcalPricesForm.insert(this.activeVehicleTypes.indexOf(vehicleType) || 0, this.fb.group({
      vehicleType: this.fb.group({
        id: [ vehicleType.id ],
        name: [ vehicleType.name ]
      }),
      kmRanges: this.fb.array([]),
      timeRanges: this.fb.array([]),
      extraCost: [ 0 ],
      nightCostType: [ ShuttleCompanyContractPricelistCostType.Percent ],
      nightCost: [ 0 ],
      weekendCostType: [ ShuttleCompanyContractPricelistCostType.Percent ],
      weekendCost: [ 0 ],
      pricePerKm: [ 0 ],
      pricePerHour: [ 0 ],
      basePrice: [ 0 ],
      isTaxi: [ false ]
    }));

    this.hashcalPricesForm.updateValueAndValidity();
  }

  private checkInactivePricesFormVehicleTypes(form: UntypedFormArray) {
    form.value.forEach(price => {
      const isVehicleTypeActive = this.activeVehicleTypes.some(vehicleType => vehicleType.id === price.vehicleType.id);

      if (!isVehicleTypeActive) {
        this.removePricesFormVehicleType(price.vehicleType, form);
      }
    });
  }

  private checkInactiveCitiesPricingFormVehicleTypes() {
    (this.citiesPricingForm.get('citiesPrices') as UntypedFormArray).value.forEach((citiesPrice: ShuttleCompanyContractPricelistCitiesPrice) => {
      citiesPrice.prices.forEach(price => {
        const isVehicleTypeActive = this.activeVehicleTypes.some(type => type.id === price.vehicleTypeId);

        if (!isVehicleTypeActive) {
          const vehicleType = this.citiesPricingForm.get('vehicleTypes').value.find(vehicle => vehicle.id === price.vehicleTypeId);

          if (vehicleType) {
            this.removeCitiesPricingFormVehicleType(vehicleType);
          }
        }
      });
    });

    (this.citiesPricingForm.get('vehicleTypes') as UntypedFormArray).value.forEach(vehicleType => {
      const isVehicleTypeActive = this.activeVehicleTypes.some(type => type.id === vehicleType.id);

      if (!isVehicleTypeActive) {
        this.removeCitiesPricingFormVehicleType(vehicleType);
      }
    });
  }

  private removePricesFormVehicleType(vehicleType: ShuttleCompanyContractPricelistVehicleType, form: UntypedFormArray) {
    const inactivePriceIndex = form.value.findIndex(price => price.vehicleType.id === vehicleType.id);

    if (inactivePriceIndex >= 0) {
      form.removeAt(inactivePriceIndex);

      form.updateValueAndValidity();
    }
  }

  private removeCitiesPricingFormVehicleType(vehicleType: ShuttleCompanyContractPricelistVehicleType) {
    const vehicleTypeIndex = this.citiesPricingForm.get('vehicleTypes').value.findIndex(vehicle => vehicle.id === vehicleType.id);

    if (vehicleTypeIndex >= 0) {
      (this.citiesPricingForm.get('citiesPrices') as UntypedFormArray).controls.forEach((control: UntypedFormGroup) => {
        const vehiclePriceIndex = (control.get('prices') as UntypedFormArray).value.findIndex(price => price.vehicleTypeId === vehicleType.id);

        (control.get('prices') as UntypedFormArray).removeAt(vehiclePriceIndex);
      });

      (this.citiesPricingForm.get('vehicleTypes') as UntypedFormArray).removeAt(vehicleTypeIndex);

      this.citiesPricingForm.updateValueAndValidity();
    }
  }

  private removeVehicleType(vehicleType: ShuttleCompanyContractPricelistVehicleType) {
    if (this.activeVehicleTypes) {
      this.activeVehicleTypes.splice(this.activeVehicleTypes.findIndex(vehicle => vehicle.id === vehicleType.id), 1);
    }

    if (this.citiesPricingForm) {
      this.removeCitiesPricingFormVehicleType(vehicleType);
    }

    if (this.kmBasePricesForm) {
      this.removePricesFormVehicleType(vehicleType, this.kmBasePricesForm);
    }

    if (this.kmRangesPricesForm) {
      this.removePricesFormVehicleType(vehicleType, this.kmRangesPricesForm);
    }

    if (this.hashcalPricesForm) {
      this.removePricesFormVehicleType(vehicleType, this.hashcalPricesForm);
    }
  }

  initCitiesPricingForm(form: UntypedFormGroup) {
    this.citiesPricingForm = form;

    if (this.activeVehicleTypes) {
      this.checkInactiveCitiesPricingFormVehicleTypes();

      const existingVehicleTypeIds = (this.citiesPricingForm.get('vehicleTypes') as UntypedFormArray).value.map(type => type.id);

      this.activeVehicleTypes.forEach(vehicleType => {
        if (!existingVehicleTypeIds.includes(vehicleType.id)) {
          this.addCitiesPricingVehicle(vehicleType);
        }
      });
    }
  }

  initKmBasePricesForm(form: UntypedFormArray) {
    this.kmBasePricesForm = form;

    if (this.activeVehicleTypes) {
      this.checkInactivePricesFormVehicleTypes(this.kmBasePricesForm);

      const existingVehicleTypeIds = this.kmBasePricesForm.value.map(type => type.vehicleType.id);

      this.activeVehicleTypes.forEach(vehicleType => {
        if (!existingVehicleTypeIds.includes(vehicleType.id)) {
          this.addKmBasePriceVehicle(vehicleType);
        }
      });
    }
  }

  initKmRangesPricesForm(form: UntypedFormArray) {
    this.kmRangesPricesForm = form;

    if (this.activeVehicleTypes) {
      this.checkInactivePricesFormVehicleTypes(this.kmRangesPricesForm);

      const existingVehicleTypeIds = this.kmRangesPricesForm.value.map((price: ShuttleCompanyContractPricelistKmRangesPrice) =>
        price.vehicleType.id
      );

      this.activeVehicleTypes.forEach(vehicleType => {
        if (!existingVehicleTypeIds.includes(vehicleType.id)) {
          this.addKmRangesPricesVehicle(vehicleType);
        }
      });
    }
  }

  initHashcalPricesForm(form: UntypedFormArray) {
    this.hashcalPricesForm = form;

    if (this.activeVehicleTypes) {
      this.checkInactivePricesFormVehicleTypes(this.hashcalPricesForm);

      const existingVehicleTypeIds = this.hashcalPricesForm.value.map((price: ShuttleCompanyContractPricelistKmRangesPrice) =>
        price.vehicleType.id
      );

      this.activeVehicleTypes.forEach(vehicleType => {
        if (!existingVehicleTypeIds.includes(vehicleType.id)) {
          this.addHashcalPricesVehicle(vehicleType);
        }
      });
    }
  }

  initShuttleCompanyContractsSpecialRatesFeature() {
    if (this.shuttleCompanyContractsSpecialRatesFeature !== null) { return; }

    this.shuttleCompanyContractsSpecialRatesFeature = this.authDataService.modules()?.transportation?.shuttleCompanyContractsSpecialRates;
  }

  toggleVehicleType(vehicleType: ShuttleCompanyContractPricelistVehicleType) {
    if (vehicleType.isActive) {
      this.addVehicleType(vehicleType);
    } else {
      this.removeVehicleType(vehicleType);
    }
  }

  setFirstActivatedPricelistAsDefault(isEditMode: boolean, addEditModalForm: UntypedFormGroup, currentPricelistName: string) {
    if (isEditMode) { return; }

    const anotherDefaultPricelistExist = !!this.getAnotherDefaultPricelistName(addEditModalForm, currentPricelistName);
    const pricelist = addEditModalForm.get(currentPricelistName);

    if (!anotherDefaultPricelistExist && pricelist) {
      pricelist.get('default').patchValue(true);
    }
  }

  uncheckCurrentPricelist(addEditModalForm: UntypedFormGroup, currentPricelistName: string) {
    const anotherDefaultPricelistExist = !!this.getAnotherDefaultPricelistName(addEditModalForm, currentPricelistName);
    const pricelist = addEditModalForm.get(currentPricelistName);

    if (anotherDefaultPricelistExist && pricelist) {
      pricelist.get('default').patchValue(false);
    }
  }

  uncheckAnotherPricelist(addEditModalForm: UntypedFormGroup, currentPricelistName: string) {
    const anotherDefaultPricelistName = this.getAnotherDefaultPricelistName(addEditModalForm, currentPricelistName);
    const pricelist = addEditModalForm.get(anotherDefaultPricelistName);

    if (pricelist) {
      const defaultControl = pricelist.get('default');

      defaultControl.patchValue(false);
    }
  }
}
