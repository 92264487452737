import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BsModalService } from 'ngx-bootstrap/modal';
import { USidebarMenuService } from '@shift/ulib';
import moment from 'moment';

import { HeaderDataService, LoadingDataService } from '@app/shared/services';
import { AuthCustomer } from '@app/auth/models';
import { RouteDailyRow, RoutesMovePassengersRouteInitData } from '@app/routes/models';
import { RoutesMovePassengersComponent } from '@app/routes/components';

@Injectable()
export class RoutesMovePassengersModalService {
  private readonly destroyRef = inject(DestroyRef);
  private readonly bsModalService = inject(BsModalService);
  private readonly headerDataService = inject(HeaderDataService);
  private readonly uSidebarMenuService = inject(USidebarMenuService);
  private readonly loadingDataService = inject(LoadingDataService);

  openModal(data: RouteDailyRow, authCustomer: AuthCustomer): void {
    const activeDate: string = this.headerDataService.getDate();
    const route: RoutesMovePassengersRouteInitData = {
      routeId: data.routeId,
      code: data.code,
      name: data.name,
      direction: data.direction,
      routeStartDate: data.routeStartDate,
      routeEndDate: data.routeEndDate,
      days: data.days,
      activeDate,
      activeDay: moment(activeDate).day(),
      isOwnedBySc: data.isOwnedBySc
    };

    this.bsModalService.show(
      RoutesMovePassengersComponent,
      {
        class: `u-modal u-modal_content ${this.uSidebarMenuService.collapsed() ? 'u-modal_content-hide-menu ' : ''}u-modal_app-routes-daily-move-passengers`,
        animated: true,
        ignoreBackdropClick: true,
        backdrop: false,
        keyboard: false,
        initialState: {
          sourceRoute: route,
          authCustomer
        }
      }
    )
      .content
      .saved
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.loadingDataService.updateRefresh());
  }
}
