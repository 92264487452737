const DICTIONARY_PATH: string = 'builder.fullMovePassengers';

export const builderFullMovePassengersComponentConfig = {
  trackingId: 'Route planner, move passenger',
  passengersSearchFields: [ 'name', 'targetName', 'stationAddress' ],
  routesSearchFields: [ 'name', 'number', 'participants' ],
  dictionary: {
    shortDays: 'general.shortDays',
    forward: 'general.direction.forward',
    backward: 'general.direction.backward',
    anonymousAccompany: 'general.anonymousAccompany',
    selectedPassengers: `${DICTIONARY_PATH}.selectedPassengers`,
    searchPassengers: `${DICTIONARY_PATH}.searchPassengers`,
    allPassengers: `${DICTIONARY_PATH}.allPassengers`,
    moveAmountPassengers: `${DICTIONARY_PATH}.moveAmountPassengers`,
    allDone: `${DICTIONARY_PATH}.allDone`,
    noResults: `${DICTIONARY_PATH}.noResults`,
    routes: `${DICTIONARY_PATH}.routes`,
    routesAmount: `${DICTIONARY_PATH}.routesAmount`,
    searchRoutes: `${DICTIONARY_PATH}.searchRoutes`,
    clickOnMapIcon: `${DICTIONARY_PATH}.clickOnMapIcon`,
    passengers: `${DICTIONARY_PATH}.passengers`,
    noCar: `${DICTIONARY_PATH}.noCar`,
    anonymousPassenger: `${DICTIONARY_PATH}.anonymousPassenger`,
    noAvailableRoutes: `${DICTIONARY_PATH}.noAvailableRoutes`,
    close: `${DICTIONARY_PATH}.close`
  }
};
