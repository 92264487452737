const DICTIONARY_PATH: string = 'builder.daysWeek';

export const builderDaysWeekComponentConfig = {
  dictionary: {
    message: `${DICTIONARY_PATH}.message`,
    apply: `${DICTIONARY_PATH}.apply`
  },
  checkboxes: [
    { check: true, value: 'duplicate', name: `${DICTIONARY_PATH}.duplicate` },
    { check: false, value: 'rebuildNewDays', name: `${DICTIONARY_PATH}.rebuildNewDays` }
  ]
};
