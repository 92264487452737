import { Component, OnInit, HostBinding, signal } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { cloneDeep } from 'lodash';
import { UPopupService } from '@shift/ulib';

import { RoutePolicy } from '@app/shared/models';
import { CitiesCombinationsByBranchService, LocalizationService, TrackingService } from '@app/shared/services';
import { BuilderFullVisibleComponents } from '@app/builder/models';
import { BuilderFullMapService, BuilderFullCommonService, BuilderFullCalculationService } from '@app/builder/services';
import { builderFullConfig } from '@app/builder/configs';
import { builderFullSettingsComponentConfig } from './builder-full-settings.component.config';

@Component({
  selector: 'app-builder-full-settings',
  templateUrl: './builder-full-settings.component.html',
  styleUrls: [ './builder-full-settings.component.scss', './builder-full-settings.component.rtl.scss' ]
})
export class BuilderFullSettingsComponent implements OnInit {
  @HostBinding('class') hostClasses: string = 'builder-full-settings';

  readonly #config = signal(cloneDeep(builderFullSettingsComponentConfig));

  readonly config = this.#config.asReadonly();

  routePolicyStore: RoutePolicy;
  vehicleTypesStore: number[];
  isRtl: boolean = this.localizationService.isRtl();
  routePlannerTypeGeneric = this.builderFullCalculationService.routePlannerTypeGeneric;

  constructor(
    private builderFullMapService: BuilderFullMapService,
    private builderFullCommonService: BuilderFullCommonService,
    private uPopupService: UPopupService,
    private localizationService: LocalizationService,
    private trackingService: TrackingService,
    public builderFullCalculationService: BuilderFullCalculationService,
    public citiesCombinationsByBranchService: CitiesCombinationsByBranchService
  ) {}

  ngOnInit() {
    this.routePolicyStore = this.routePolicy && this.routePolicy.value;
    this.vehicleTypesStore = this.vehicleTypes && this.vehicleTypes.value;
  }

  get visibleComponents(): BuilderFullVisibleComponents {
    return this.builderFullCommonService.visibleComponents;
  }

  get routePolicy(): UntypedFormGroup {
    return this.builderFullCalculationService.routePolicy;
  }

  get vehicleTypes(): UntypedFormControl {
    return this.builderFullCalculationService.vehicleTypes;
  }

  get isFormDirty(): boolean {
    return this.routePolicy?.dirty || this.vehicleTypes?.dirty || this.citiesCombinationsByBranchService.rulesForm?.dirty;
  }

  private apply() {
    if (this.visibleComponents.routes) {
      this.trackingService.track(`[${builderFullConfig.trackingId}] - click on recalculate from route policy`);

      this.builderFullCalculationService.calculateRoutes();
    }

    if (this.visibleComponents.map) {
      this.builderFullMapService.loadMapData();
    }

    this.builderFullCommonService.updateVisible({
      settings: false
    });

    this.routePolicy.markAsPristine();
    this.vehicleTypes.markAsPristine();
  }

  closeRoutePolicy() {
    const close = (resetChanges?: boolean) => {
      this.builderFullCommonService.updateVisible({
        settings: false
      });

      if (resetChanges) {
        this.routePolicy.patchValue(this.routePolicyStore);
        this.vehicleTypes.patchValue(this.vehicleTypesStore);
      }

      this.routePolicy.markAsPristine();
      this.vehicleTypes.markAsPristine();
    };

    if (this.isFormDirty && (this.builderFullCalculationService.visibleComponents.routes || this.builderFullCalculationService.visibleComponents.map)) {
      this.uPopupService.showMessage({
        showXIcon: true,
        message: this.config().dictionary.close.confirm,
        yes: this.config().dictionary.yes,
        no: this.config().dictionary.no
      },
      () => {
        this.apply();
      },
      () => {
        close(true);
      });
    } else {
      close();
    }
  }
}
