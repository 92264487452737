<div class="accompanies-add-edit-info__wrapper">
  <div class="accompanies-add-edit-info__general">
    <div class="u-text-line u-text-line_app-title">
      {{config().dictionary.generalDetails | translate}}
    </div>
    <u-group
      class="u-group_large-field"
      [name]="config().dictionary.first | translate"
      [valid]="addEditForm.controls.firstName.valid"
      [disabled]="addEditForm.controls.firstName.disabled"
      [message]="config().dictionary.required | translate"
    >
      <input
        uInput
        type="text"
        [formControl]="addEditForm.controls.firstName"
        [valid]="addEditForm.controls.firstName.valid"
        [placeholder]="config().dictionary.first | translate"
      >
    </u-group>
    <u-group
      class="u-group_large-field"
      [name]="config().dictionary.last | translate"
      [valid]="addEditForm.controls.lastName.valid"
      [disabled]="addEditForm.controls.lastName.disabled"
      [message]="config().dictionary.required | translate"
    >
      <input
        uInput
        type="text"
        [formControl]="addEditForm.controls.lastName"
        [valid]="addEditForm.controls.lastName.valid"
        [placeholder]="config().dictionary.last | translate"
      >
    </u-group>
    <u-group
      class="u-group_large-field"
      [name]="config().dictionary.id | translate"
      [valid]="addEditForm.controls.identity.valid"
      [disabled]="addEditForm.controls.identity.disabled"
      [message]="config().dictionary.required | translate"
    >
      <input
        uInput
        type="text"
        [formControl]="addEditForm.controls.identity"
        [valid]="addEditForm.controls.identity.valid"
        [placeholder]="config().dictionary.id | translate"
        (blur)="checkPersonExist('', addEditForm.controls.identity.value)"
      >
    </u-group>
    <u-group
      class="u-group_large-field"
      [name]="config().dictionary.address | translate"
      [valid]="addEditForm.controls.address.valid"
      [disabled]="addEditForm.controls.address.disabled"
      [message]="config().dictionary.required | translate"
    >
      <u-address
        [valid]="addEditForm.get('address').valid && addEditForm.get('latitude').valid && addEditForm.get('longitude').valid"
        [disabled]="addEditForm.get('address').disabled"
        [address]="{
          address: addEditForm.get('address').value,
          lat: addEditForm.get('latitude').value,
          lng: addEditForm.get('longitude').value
        }"
        (addressChange)="updateAddress($event)"
        (mapIconClick)="trackingService.track('Address field - click on map icon')"
      ></u-address>
    </u-group>
    <u-group
      class="u-group_large-field"
      [name]="config().dictionary.birthday | translate"
      [valid]="addEditForm.controls.birthDate.valid"
      [disabled]="addEditForm.controls.birthDate.disabled"
      [message]="config().dictionary.required | translate"
    >
      <u-input-date
        [classUInput]="'u-input_text-center'"
        [formControl]="addEditForm.controls.birthDate"
        [disableDaysAfter]="disableBirthdayAfterToday"
        [valid]="addEditForm.controls.birthDate.valid"
        [placement]="isRtl ? [ 'bottom-right' ] : [ 'bottom-left' ]"
        [messages]="config().dictionary.uInputDate | translate"
      ></u-input-date>
    </u-group>
    <u-group
      class="u-group_large-field"
      [name]="config().dictionary.percentageOfJob | translate"
      [valid]="addEditForm.controls.jobPercentage.valid"
      [disabled]="addEditForm.controls.jobPercentage.disabled"
      [message]="config().dictionary.required | translate"
    >
      <input
        uInput
        type="text"
        [formControl]="addEditForm.controls.jobPercentage"
        [valid]="addEditForm.controls.jobPercentage.valid"
      >
    </u-group>
    <u-group
      class="u-group_large-field"
      [name]="config().dictionary.status | translate"
      [valid]="addEditForm.controls.statusId.valid"
      [disabled]="addEditForm.controls.statusId.disabled"
      [message]="config().dictionary.required | translate"
    >
      <u-select-s
        [empty]="false"
        [messages]="config().dictionary.uSelectS | translate"
        [items]="statusesItems()"
        [formControl]="addEditForm.controls.statusId"
        [valid]="addEditForm.controls.statusId.valid"
        [placeholder]="config().dictionary.select | translate"
        [container]="'body'"
        [classUDropdownContent]="'u-dropdown-content_app-default'"
      ></u-select-s>
    </u-group>
    <u-group
      class="u-group_large-field"
      [name]="config().dictionary.comment | translate"
      [valid]="addEditForm.controls.comment.valid"
      [disabled]="addEditForm.controls.comment.disabled"
      [message]="'general.required' | translate"
    >
      <textarea
        uTextarea
        [formControl]="addEditForm.controls.comment"
      ></textarea>
    </u-group>
  </div>
  <div class="accompanies-add-edit-info__contacts">
    <div class="u-text-line u-text-line_app-title">
      {{config().dictionary.contactDetails | translate}}
    </div>
    <div class="accompanies-add-edit-info__contacts-list">
      @for (personContact of addEditForm.controls.contacts.controls; track personContact; let i = $index) {
        <div
          class="accompanies-add-edit-info__contacts-item"
          [hidden]="personContact.value.del"
        >
          <div class="accompanies-add-edit-info__contacts-groups">
            <div class="u-group-line">
              <div class="u-group-line__info">
                <div class="u-group-line__name u-group__name">
                  {{config().dictionary.contacts.contactInformation | translate}}
                </div>
              </div>
              <div class="u-group-line__fields">
                <div class="u-group-line__field u-group-line__field_large">
                  <input
                    uInput
                    type="text"
                    [uId]="'contact-value'"
                    [formControl]="personContact.controls.value"
                    [valid]="personContact.controls.value.valid"
                    (blur)="checkPersonExist(personContact.value, '')"
                  >
                </div>
                <div class="u-group-line__field">
                  <u-select-s
                    [uId]="'contact-type'"
                    [empty]="false"
                    [messages]="config().dictionary.uSelectS | translate"
                    [items]="config().contacts"
                    [formControl]="personContact.controls.type"
                    [valid]="personContact.controls.type.valid"
                    [placeholder]="config().dictionary.select | translate"
                    [container]="'body'"
                    [classUDropdownContent]="'u-dropdown-content_app-default'"
                  ></u-select-s>
                </div>
              </div>
            </div>
          </div>
          <div
            class="accompanies-add-edit-info__contacts-delete"
            [ngClass]="{'accompanies-add-edit-info__contacts-delete_hidden': addEditForm.controls.contacts.controls.length <= 1}"
          >
            <div
              class="accompanies-add-edit-info__contacts-delete-icon"
              [uId]="'remove-contact'"
              (click)="removeContact(i)"
            ></div>
          </div>
        </div>
      }

      @if (authDataSnapshotService.manageAccompaniesPermission()) {
        <div class="accompanies-add-edit-info__actions">
          <button
            uButton
            class="u-font-weight-medium"
            [uId]="'add-contact'"
            (click)="addContact()"
          >
            {{config().dictionary.newContact | translate}}
          </button>
        </div>
      }
    </div>
  </div>
</div>

@if (addEditForm.controls.accompanyId.value !== 0) {
  <div class="accompanies-add-edit-info__contact-files">
    <app-files
      [config]="{
        dictionary: appDictionaryConfig.contactFiles
      }"
      [disabled]="!authDataSnapshotService.manageAccompaniesPermission()"
      [entityType]="2"
      [entityId]="addEditForm.controls.accompanyId.value"
    ></app-files>
  </div>
}
