import { computed, DestroyRef, inject, Injectable, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { omit } from 'lodash';
import moment from 'moment';

import { patchSignal } from '@app/shared/utils';
import { AuthDataSnapshotService } from '@app/auth/services';
import { aiChatConfig } from '@app/ai-chat/configs';
import { AiChatMode, AiChatState } from '@app/ai-chat/models';
import { AiChatStorageService } from './ai-chat-storage.service';
import { AiChatService } from './ai-chat.service';

@Injectable()
export class AiChatDataService {
  private readonly destroyRef = inject(DestroyRef);
  private readonly authDataSnapshotService = inject(AuthDataSnapshotService);
  private readonly aiChatStorageService = inject(AiChatStorageService);
  private readonly aiChatService = inject(AiChatService);

  readonly #state = signal(aiChatConfig.initialState);

  readonly position = computed(() => this.#state().position);
  readonly size = computed(() => this.#state().size);
  readonly mode = computed(() => this.#state().mode);
  readonly isFullScreenMode = computed(() => this.#state().mode === AiChatMode.FullScreen);
  readonly conversationsById = computed(() => this.#state().conversationsById);
  readonly selectedConversationId = computed(() => this.#state().selectedConversationId);
  readonly selectedConversationMessageTableId = computed(() => this.#state().selectedConversationMessageTableId);
  readonly conversations = computed(() => Object.values(this.conversationsById()));
  readonly conversationsSortedByDate = computed(() =>
    this.conversations()
      .filter(conversation => !!conversation.messages.length)
      .sort((ojbA, objB) =>
        moment(objB.messages[objB.messages.length - 1].sendDate).valueOf() - moment(ojbA.messages[ojbA.messages.length - 1].sendDate).valueOf()
      )
  );
  readonly selectedConversation = computed(() => this.conversationsById()[this.#state().selectedConversationId]);
  readonly authUserFullName = computed(() => {
    const userInfo = this.authDataSnapshotService.userInfo();

    return `${userInfo.person.firstName} ${userInfo.person.lastName}`;
  });

  initState() {
    const savedState = this.aiChatStorageService.get();

    if (savedState) {
      patchSignal(this.#state, savedState);

      if (savedState.selectedConversationId === null) {
        this.newConversation();
      }
    } else {
      patchSignal(this.#state, {
        position: {
          x: window.innerWidth - this.size().w - aiChatConfig.defaultPosition.right,
          y: window.innerHeight - this.size().h - aiChatConfig.defaultPosition.bottom
        }
      });
    }
  }

  updateState(state: Partial<AiChatState>) {
    patchSignal(this.#state, state);

    this.aiChatStorageService.update(this.#state());
  }

  loadConversations() {
    this.aiChatService.getConversations()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(conversationsById => this.updateState({ conversationsById }));
  }

  newConversation() {
    this.updateState({
      conversationsById: {
        ...this.conversationsById(),
        0: {
          id: 0,
          messages: []
        }
      },
      selectedConversationId: 0
    });
  }

  createConversation(messageText: string, messageUser: string) {
    this.aiChatService.createConversation(this.conversations().length, messageText, messageUser)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(conversation => {
        this.updateState({
          selectedConversationId: conversation.id,
          conversationsById: { ...omit(this.conversationsById(), [ 0 ]), [conversation.id]: conversation }
        });
      });
  }

  sendMessage(text: string) {
    const selectedConversationId = this.selectedConversationId();
    const selectedConversation = this.selectedConversation();

    if (selectedConversationId === 0) {
      this.createConversation(text, this.authUserFullName());

      return;
    }

    const newMessage = {
      id: selectedConversation.messages.length + 1,
      text,
      user: this.authUserFullName(),
      sendDate: new Date().toISOString(),
      tableId: null
    };

    this.updateState({
      conversationsById: {
        ...this.conversationsById(),
        [selectedConversationId]: {
          ...selectedConversation,
          messages: [
            ...selectedConversation.messages,
            newMessage
          ]
        }
      }
    });
  }

  selectConversation(id: number) {
    this.updateState({ selectedConversationId: id });
  }

  resetSelectedConversation() {
    const selectedConversation = this.selectedConversation();
    const lastMessage = selectedConversation?.messages[selectedConversation.messages.length - 1];

    if (!lastMessage?.user) {
      this.updateState({
        selectedConversationId: null,
        selectedConversationMessageTableId: null
      });
    }
  }
}
