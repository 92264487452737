import { UGridHeaderButton } from '@shift/ulib';

import { AuthModuleFeature, AuthPermission } from '@app/auth/models';

export enum GridHeaderButtonValue {
  ResetFilters = 'reset-filters',
  BulkChange = 'bulk-change',
  Delete = 'delete',
  Excel = 'excel',
  Pdf = 'pdf',
  PdfRouteDetails = 'pdf-route-details',
  PdfSCReport = 'pdf-sc-report',
  PdfBillingReport = 'pdf-billing-report',
  RouteDetailsExcel = 'route-details-excel',
  DailyAgendaExcel = 'daily-agenda-excel',
  SendServiceSheet = 'send-service-sheet',
  ReOptimization = 'reopimization',
  Cleanup = 'cleanup'
}

export enum GridHeaderTemplate {
  Default = 'default',
  General = 'general',
  RoutesDaily = 'routesDaily',
  RoutesDailyAiSuggestions = 'routesDailyAiSuggestions',
  RoutesDailyReOptimization = 'routesDailyReOptimization',
  RoutesDailyReOptimizationEmptyStations = 'routesDailyReOptimizationEmptyStations',
  RoutesWeekly = 'routesWeekly',
  RoutesDailySC = 'routesDailySC',
  RoutesWeeklySC = 'routesWeeklySC',
  Accompanies = 'accompanies',
  Accounting = 'accounting',
  Schools = 'schools',
  DemandsPassengersView = 'demandsPassengersView',
  ShuttleCompanies = 'shuttleCompanies',
  Stations = 'stations',
  Passengers = 'passengers',
  PassengersArmy = 'passengersArmy',
  PassengersMunicipality = 'passengersMunicipality',
  ReportsAccompanyHours = 'reportsAccompanyHours',
  ReportsAgenda = 'reportsAgenda',
  ReportsChangesHistory = 'reportsChangesHistory',
  ReportsExpenses = 'reportsExpenses',
  ReportsExpensesSC = 'reportsExpensesSC',
  ReportsExpensesSummary = 'reportsExpensesSummary',
  ReportsExpensesSummarySC = 'reportsExpensesSummarySC',
  ReportsIncomeDetailed = 'reportsIncomeDetailed',
  ReportsIncomeSummary = 'reportsIncomeSummary',
  ReportsServiceSheet = 'reportsServiceSheet',
  ReportsScExpenses = 'reportsScExpenses',
  ReportsX = 'reportsX',
  ReportsXGenerated = 'reportsXGenerated',
  ReportsTravelTimeDetailed = 'reportsTravelTimeDetailed'
}

export interface GridHeaderButton extends UGridHeaderButton {
  feature?: AuthModuleFeature;
  permission?: AuthPermission;
}
