import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OAuthService } from 'angular-oauth2-oidc';
import { HubConnectionBuilder, HubConnectionState, HttpTransportType, HubConnection } from '@microsoft/signalr';

import { environment } from '@environments/environment';
import { AuthService } from '@app/auth/services';

@Injectable({
  providedIn: 'root'
})
export class SignalRService {
  private readonly oAuthService = inject(OAuthService);
  private readonly authService = inject(AuthService);

  BASE_PATH: string = '';
  signalRClient: HubConnection;

  get isConnected(): boolean {
    return this.signalRClient ? this.signalRClient.state === HubConnectionState.Connected : false;
  }

  init(parameters = null): void {
    this.signalRClient = new HubConnectionBuilder()
      .withUrl(`${environment.config.serverUrlBase}${this.BASE_PATH}${parameters ? parameters : ''}`,
        {
          transport: HttpTransportType.WebSockets,
          accessTokenFactory: () => {
            if (this.authService.isExpiredAccessToken()) {
              return this.authService.refreshAccessToken().toPromise();
            }

            return this.oAuthService.getAccessToken();
          }
        })
      .withAutomaticReconnect()
      .build();
  }

  start() {
    if (this.signalRClient) {
      return this.signalRClient.start();
    }
  }

  stop() {
    if (this.signalRClient) {
      return this.signalRClient.stop();
    }
  }

  onChannel<T>(name: string): Observable<T> {
    return new Observable<T>(observer => {
      this.signalRClient
        .on(name, (data: T) => observer.next(data));
    });
  }

  onClose() {
    return new Observable(observer => this.signalRClient.onclose(err => observer.next(err)));
  }
}
