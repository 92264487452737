<div class="builder-settings__close" (click)="close()">
  <div class="u-icon u-icon_pointer u-icon_app-builder-close"></div>
</div>
<div class="builder-settings__name">{{config.dictionary.title | translate}}</div>
<div class="builder-settings__wrapper">
  <div class="builder-settings__item">
    <div class="builder-settings__group">
      <div class="builder-settings__group-name">{{config.dictionary.waitingTime | translate}}</div>
      <div class="builder-settings__group-field">
        <u-input-time
          [class]="'u-input-time_app-builder-settings'"
          [formControl]="settingsForm.get('waitingTime')"
          [mode]="builderViewMode.Full"
          [valid]="settingsForm.get('waitingTime').valid"
        ></u-input-time>
      </div>
    </div>
  </div>
  <div class="builder-settings__item">
    <div class="builder-settings__group">
      <div class="builder-settings__group-name">{{config.dictionary.useTollRoads | translate}}</div>
      <div class="builder-settings__group-field">
        <u-toggle
          [class]="'u-toggle_app-builder-settings'"
          [formControl]="settingsForm.get('useTollRoads')"
          [messages]="config.dictionary.uToggle | translate"
        ></u-toggle>
      </div>
    </div>
  </div>
  <div class="builder-settings__item">
    <div class="builder-settings__group">
      <div class="builder-settings__group-name">{{config.dictionary.pickupOptions | translate}}</div>
      <div class="builder-settings__group-field">
        <u-select-s
          [items]="pickupOptionTypes"
          [placement]="isRtl ? [ 'bottom-right', 'top-right', 'bottom-left', 'top-left' ] : [ 'bottom-left', 'top-left', 'bottom-right', 'top-right' ]"
          [formControl]="settingsForm.get('curbApproach')"
          [valid]="settingsForm.get('curbApproach').valid"
          [empty]="false"
          [placeholder]="'general.select' | translate"
          [container]="'body'"
          [classUInput]="'u-input_app-builder-settings'"
          [classUDropdownContent]="'u-dropdown-content_app-builder-settings'"
        ></u-select-s>
      </div>
    </div>
  </div>
  <div class="builder-settings__item">
    <div class="builder-settings__group">
      <div class="builder-settings__group-name">{{config.dictionary.calculateBy | translate}}</div>
      <div class="builder-settings__group-field">
        <u-select-s
          [items]="config.calculateTypes"
          [placement]="isRtl ? [ 'bottom-right', 'top-right', 'bottom-left', 'top-left' ] : [ 'bottom-left', 'top-left', 'bottom-right', 'top-right' ]"
          [formControl]="settingsForm.get('calculateBy')"
          [empty]="false"
          [placeholder]="config.dictionary.select | translate"
          [container]="'body'"
          [classUInput]="'u-input_app-builder-settings'"
          [classUDropdownContent]="'u-dropdown-content_app-builder-settings'"
        ></u-select-s>
      </div>
    </div>
  </div>
  <div class="builder-settings__item">
    <div class="builder-settings__group">
      <div class="builder-settings__group-name">{{config.dictionary.cheapestRoute | translate}}</div>
      <div class="builder-settings__group-field">
        <u-toggle
          [class]="'u-toggle_app-builder-settings'"
          [formControl]="settingsForm.get('cheapestPrice')"
          [messages]="config.dictionary.uToggle | translate"
          (toggleChanged)="cheapestPriceChange($event)"
        ></u-toggle>
      </div>
    </div>
  </div>
  <div
    class="builder-settings__item"
    *ngIf="displayUseTraffic"
  >
    <div class="builder-settings__group">
      <div class="builder-settings__group-name">{{config.dictionary.useTrafficData | translate}}</div>
      <div class="builder-settings__group-field">
        <u-toggle
          [class]="'u-toggle_app-builder-settings'"
          [formControl]="settingsForm.get('useTraffic')"
          [messages]="config.dictionary.uToggle | translate"
        ></u-toggle>
      </div>
    </div>
  </div>
  <div class="builder-settings__item">
    <div class="builder-settings__group">
      <div class="builder-settings__group-name">{{config.dictionary.speedRate | translate}}</div>
      <div class="builder-settings__group-field">
        <u-input-number
          [class]="'u-input-number_app-builder-settings'"
          [formControl]="settingsForm.get('speedRate')"
          [min]="50"
          [max]="200"
          [valueType]="'number'"
        ></u-input-number>
      </div>
      <div class="builder-settings__extra-info">%</div>
      <div
        class="builder-settings__question-icon"
        [uTooltip]="config.dictionary.speedRateInfoTooltip | translate"
        [tooltipClass]="'u-tooltip_general u-tooltip_app-route-policy-question'"
        [placement]="isRtl ? [ 'right', 'top', 'bottom' ] : [ 'left', 'top', 'bottom' ]"
        [container]="'body'"
      ></div>
    </div>
  </div>
</div>
<div class="builder-settings__action">
  <button
    uButton
    class="u-font-weight-bold"
    [disabled]="settingsForm.disabled"
    (click)="apply()"
  >
    {{'builder.settings.apply' | translate}}
  </button>
</div>
