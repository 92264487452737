import { Component, HostBinding, Output, EventEmitter, signal, inject } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { cloneDeep } from 'lodash';
import { UMultiselectItem } from '@shift/ulib';

import { builderConvertToComponentConfig } from './builder-convert-to.component.config';

@Component({
  selector: 'app-builder-convert-to',
  templateUrl: './builder-convert-to.component.html',
  styleUrls: [ './builder-convert-to.component.scss', './builder-convert-to.component.rtl.scss' ]
})
export class BuilderConvertToComponent {
  @Output() action = new EventEmitter();

  @HostBinding('class') hostClasses: string = 'builder-convert-to';

  private readonly bsModalRef = inject(BsModalRef);

  readonly #config = signal(cloneDeep(builderConvertToComponentConfig));

  readonly config = this.#config.asReadonly();

  readonly checkboxes = cloneDeep(this.config().checkboxes);
  items: UMultiselectItem[] = [];
  passengers: number[] = [];
  showPassengers: boolean;

  checkItem(item) {
    item.check = !item.check;

    const checkboxes = this.checkboxes.filter(checkbox => checkbox.value !== item.value);

    checkboxes.forEach(checkbox => checkbox.check = !item.check);

    this.showPassengers = item.value === 'specific' && item.check;
  }

  close() {
    this.bsModalRef.hide();
  }

  apply() {
    this.bsModalRef.hide();

    this.action.emit({ type: 'apply', content: { passengers: this.passengers, checkboxes: this.checkboxes } });
  }
}
