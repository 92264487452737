<div
  class="note__container"
  [ngClass]="{ 'note__container_unread': !note().isRead }"
  (click)="note().allowEdit ? onClickInsideNote(noteTextarea) : null"
  (uClickOutside)="isFocused && onClickOutsideNote(noteTextarea)"
>
  <textarea
    #noteTextarea
    maxlength="160"
    class="note__input"
    [appAutofocus]="note().id < 0"
    [formControl]="text"
    [readOnly]="!note().allowEdit"
    (focus)="updateFocused(true)"
  ></textarea>

  <div class="note__footer">
    @if (note().createdBy) {
      <span
        [uTooltip]="note().createdBy"
        class="note__footer-created-by"
      >
        {{config.dictionary.createdBy | translate}} {{note().createdBy}}
      </span>
    }
    <span
      class="note__footer-done"
      [ngClass]="{ 'note__footer-done_disabled': !note().allowEdit }"
      [uTooltip]="config.dictionary.done | translate"
      (click)="removeNote($event)"
    ></span>
  </div>
</div>
