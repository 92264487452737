<div class="header-main">
  <u-sidebar-menu-toggle
    class="header-main__sidebar-menu-toggle"
    (toggle)="uSidebarMenuService.updateOpened(!uSidebarMenuService.opened())"
  ></u-sidebar-menu-toggle>

  @if (headerDataService.headerTemplate()) {
    <div class="header-main__template">
      <ng-container [ngTemplateOutlet]="headerDataService.headerTemplate()"></ng-container>

      @if (headerMenuIconsService.actionMenuIcons$ | async; as actionMenuIcons) {
        <u-menu-icons
          *ngIf="actionMenuIcons.length"
          [class]="'u-menu-icons_app-header'"
          [isRtl]="isRtl"
          [icons]="actionMenuIcons"
          (iconClick)="headerDataService.menuIconClick($event)"
        ></u-menu-icons>
      }
    </div>
  } @else {
    <div class="header-main__container">
      <ng-container [ngTemplateOutlet]="header"></ng-container>
    </div>
  }

  <ng-template #header>
    <ng-container
      *ngIf="{
        mainMenuIcons: headerMenuIconsService.mainMenuIcons$ | async,
        collapsed: uSidebarMenuService.collapsed(),
        showDate: headerDataService.showDate$ | async,
        actionMenuIcons: headerMenuIconsService.actionMenuIcons$ | async
      } as obsObj"
    >
      <div class="header-block">
        <u-menu-icons
          *ngIf="obsObj.mainMenuIcons.length && (headerMenuIconsService.showMenu$ | async)"
          class="header-block__icons"
          [isRtl]="isRtl"
          [icons]="obsObj.mainMenuIcons"
          (iconClick)="headerDataService.menuIconClick($event, true)"
          (iconDropdownClose)="headerMenuIconsService.unselectIcon($event)"
        ></u-menu-icons>
        <div class="header-block__search-wrapper">
          <u-search
            *ngIf="headerDataService.showGlobalSearch"
            [isRtl]="isRtl"
            class="u-search_app-header"
            [class.u-search_app-header_menu-collapsed]="obsObj.collapsed"
            [config]="headerSearchFiltersService.uSearchConfigValue$ | async"
            [showFiltersDrawer]="headerSearchFiltersService.showHeaderFiltersDrawer$ | async"
            (applySearch)="headerSearchFiltersService.applySearch($event)"
            (applyFilters)="headerSearchFiltersService.onFiltersApplied($event)"
            (filterIconClick)="headerSearchFiltersService.toggleShowFiltersDrawer()"
          ></u-search>
        </div>
      </div>

      <div class="header-block">
        <button
          *ngIf="nextYearDataService.isInNextYearMode && obsObj.showDate"
          uButton
          uId="header-next-year-mode-indicator"
          class="header__next-year-mode-indicator"
          [view]="uButtonView.Flat"
          [size]="uButtonSize.Small"
          (click)="nextYearDataService.leaveNextYear()"
        >
          {{config.dictionary.nextYearPlan | translate}}
        </button>
        <u-menu-icons
          *ngIf="obsObj.actionMenuIcons.length"
          [class]="'u-menu-icons_app-header header-main__action-icons'"
          [isRtl]="isRtl"
          [icons]="obsObj.actionMenuIcons"
          (iconClick)="headerDataService.menuIconClick($event)"
        ></u-menu-icons>
        <app-builder-full-routes-generation
          *ngIf="builderFullRoutesGenerationService.show()"
          class="header-main__routes-generation"
        ></app-builder-full-routes-generation>
        <app-header-demands-view
          *ngIf="demandsStoreService.showViewToggle()"
          class="header-main__demands-view"
        ></app-header-demands-view>
        <app-header-routes-view-type
          *ngIf="headerDataService.showRoutesViewType$ | async"
          class="header-main__routes-view-type"
          [ngClass]="{ 'header-main__routes-view-type_disabled': headerDataService.isRoutesViewTypeDisabled() }"
        ></app-header-routes-view-type>
        <div
          uId="header-date"
          class="header-date"
          [ngClass]="{ 'header-date_disabled': headerDataService.isDateDisabled() }"
        >
          @if (obsObj.showDate) {
            @if (headerDataService.showTodayButton()) {
              <button
                uButton
                uId="header-today-button"
                class="header-date__switch"
                [view]="uButtonView.Flat"
                [size]="uButtonSize.Small"
                [disabled]="headerDataService.showWeekSwitch() ? disabledThisWeekButton : disabledTodayButton"
                (click)="headerDataService.showWeekSwitch() ? backToThisWeek() : backToday()"
              >
                {{(headerDataService.showWeekSwitch() ? config.dictionary.thisWeek : config.dictionary.today) | translate}}
              </button>
            }

            @if (headerDataService.showDateRange()) {
              <div class="header-date__range">
                <u-input-date-range
                  [container]="'body'"
                  [mode]="'pick'"
                  [emptyInvalid]="false"
                  [sundayFirstDay]="true"
                  [classUInput]="'u-input_text-center'"
                  [datePickerRangeMode]="uDatePickerRangeMode.SaveOnly"
                  [formControl]="headerDataService.headerForm.controls.dateRange"
                  [placement]="isRtl ? [ 'bottom-right', 'bottom-left', 'top-right', 'top-left' ] : [ 'bottom-left', 'bottom-right', 'top-left', 'top-right' ]"
                  [messages]="config.dictionary.uInputDateRange | translate"
                  [datePickerRangeFooterTemplate]="nextYearDataService.nextYearAvailable && nextYearFeatureType() && nextYear"
                ></u-input-date-range>
              </div>
            } @else {
              @if (headerDataService.showWeekSwitch()) {
                <div class="header-date__week">
                  <div class="header-date__week-box">
                    <app-week-switch
                      class="header-date__week-calendar week-switch_app-header"
                      [initDate]="headerDataService.weekSwitch.initDate"
                      [startDateLimit]="headerDataService.weekSwitch.startDateLimit"
                      [endDateLimit]="headerDataService.weekSwitch.endDateLimit"
                      [datePickerRangeFooterTemplate]="nextYearDataService.nextYearAvailable && nextYearFeatureType() && nextYear"
                      (weekChange)="headerDataService.weekSwitchChangeSet($event)"
                    ></app-week-switch>
                  </div>
                </div>
              } @else {
                <div
                  class="header-date__day"
                  uId="header-date-day"
                >
                  <div class="header-date__day-box">
                    <div class="header-date__day-calendar">
                      <u-input-date
                        class="u-input-date_app-header"
                        [mode]="'pick'"
                        [showArrows]="xlUpBreakpoint()"
                        [formControl]="headerDataService.dateForm"
                        [lang]="lang"
                        [format]="isShiftNYEnv ? uInputDateFormat.American : uInputDateFormat.BaseSlashShort"
                        [valid]="headerDataService.dateForm.valid"
                        [placement]="isRtl ? ['bottom-left'] : ['bottom-right']"
                        [readonly]="true"
                        [defaultUncheckDate]="true"
                        [messages]="'uInputDate' | translate"
                        [datePickerFooterTemplate]="nextYearDataService.nextYearAvailable && nextYearFeatureType() && nextYear"
                        [idPrefix]="'header'"
                      ></u-input-date>
                    </div>
                  </div>
                </div>
              }
            }
          }
        </div>
        <div
          *ngIf="commonService.showMonitoringDashboard()"
          class="header-monitoring-dashboard"
          [ngClass]="{ 'header-monitoring-dashboard_full-width': obsObj.collapsed }"
        >
          <app-monitoring-dashboard />
        </div>
      </div>
    </ng-container>
  </ng-template>
</div>

<ng-template #nextYear>
  <div
    class="header-next-year"
    (click)="toggleNextYearMode()"
  >
    <div class="header-next-year__icon"></div>
    <button
      uButton
      [size]="uButtonSize.Small"
    >
      {{config.dictionary[nextYearDataService.isInNextYearMode ? 'nextYearPlan' : 'nextYear'] | translate}}
    </button>
  </div>
</ng-template>
