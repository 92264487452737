import { Component, HostBinding, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { cloneDeep } from 'lodash';

import { HeaderMenuIconsService, LocalizationService } from '@app/shared/services';
import { AuthDataService } from '@app/auth/services';
import { BuilderCommonService, BuilderDataService, BuilderRoutesStoreService } from '@app/builder/services';
import { BuilderBuildMode } from '@app/builder/models';
import { builderDefaultComponentConfig } from './builder-default.component.config';

@Component({
  selector: 'app-builder-default',
  templateUrl: './builder-default.component.html',
  styleUrls: [ './builder-default.component.scss', './builder-default.component.rtl.scss' ]
})
export class BuilderDefaultComponent implements OnInit {
  @HostBinding('class')
  get hostClasses() {
    return {
      'builder-default': true,
      'builder-default_ride-order': this.builderDataService.buildModeRideOrders()
    };
  }

  private unsubscribe: Subject<void> = new Subject();

  isRtl: boolean = true;
  builderBuildMode = BuilderBuildMode;
  config = cloneDeep(builderDefaultComponentConfig);

  constructor(
    public builderDataService: BuilderDataService,
    private localizationService: LocalizationService,
    public builderRoutesStoreService: BuilderRoutesStoreService,
    private headerMenuIconsService: HeaderMenuIconsService,
    private authDataService: AuthDataService,
    public builderCommonService: BuilderCommonService
  ) {}

  ngOnInit() {
    this.isRtl = this.localizationService.isRtl();

    const routeBuilderFeatureType = this.authDataService.modules()?.routeBuilder?.type;

    this.headerMenuIconsService.setMenuIconsByTemplate(
      (this.config[routeBuilderFeatureType] || this.config.default).buildMode[this.builderDataService.config().buildMode]?.headerMenuIconsTemplate
    );
  }
}
