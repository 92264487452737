import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { SignalRService } from '@app/shared/services';
import { RoutePlannerAiSuggestionsCalculated, RoutePlannerCalculated, RoutePlannerFinished, RoutePlannerRouteStatus } from '@app/route-planner/models';

@Injectable({
  providedIn: 'root'
})
export class RoutePlannerHubService extends SignalRService {
  BASE_PATH = 'routePlannerHub';

  onCalculated(): Observable<RoutePlannerCalculated> {
    return this.onChannel<RoutePlannerCalculated>('Calculated');
  }

  onRouteStatus(): Observable<RoutePlannerRouteStatus> {
    return this.onChannel<RoutePlannerRouteStatus>('RouteStatus');
  }

  onFinished(): Observable<RoutePlannerFinished> {
    return this.onChannel<RoutePlannerFinished>('Finished');
  }

  onAiSuggestionsCalculated(): Observable<RoutePlannerAiSuggestionsCalculated> {
    return this.onChannel<RoutePlannerAiSuggestionsCalculated>('AiSuggestionsCalculated');
  }
}
