<div class="areas__table">
  <div class="areas__top-buttons">
    <ng-container *ngFor="let button of config.buttons">
      <button
        *ngIf="!button.popover && !button.hidden && !control?.disabled"
        uButton
        class="areas__top-button"
        [ngClass]="{
          'u-font-weight-medium': button.medium
        }"
        [uId]="button.uId"
        [disabled]="button.disabled"
        [withPlus]="button.withPlus"
        (click)="onConfigButtonClick(button)"
      >
        {{button.title | translate}}
      </button>
      <button
        #buttonPopover="uPopover"
        *ngIf="button.popover && !button.hidden"
        uButton
        class="areas__top-button"
        [ngClass]="{
          'u-font-weight-medium': button.medium
        }"
        [uId]="button.uId"
        [disabled]="button.disabled"
        [withPlus]="button.withPlus"
        [uPopover]="button.popover.template"
        [container]="button.popover.container || 'body'"
        [placement]="isRtl ? button.popover.placement.rtl : button.popover.placement.ltr"
        [popoverClass]="button.popover.class"
        [autoClose]="button.popover.autoClose || 'outside'"
        [verticalOffset]="button.popover.verticalOffset"
        (click)="onConfigButtonClick(button, buttonPopover)"
      >
        {{button.title | translate}}
      </button>
    </ng-container>
  </div>

  <u-grid
    *ngIf="areas"
    class="u-grid_white u-grid_app-areas"
    [tableName]="'areas'"
    [saveTableProps]="true"
    [rows]="rows"
    [columns]="columns"
    [columnMode]="'force'"
    [headerHeight]="45"
    [headerOffset]="0"
    [footerHeight]="0"
    [rowHeight]="55"
    [reorderable]="true"
    [scrollbarV]="true"
    [scrollbarH]="false"
    [showColumnsFilter]="false"
    [isRtl]="isRtl"
    [lang]="lang"
    [scrollToRowIndex]="scrollToRowIndex"
    [messages]="config.dictionary.uGrid | translate"
    [rowClassObjects]="specificRowClassObjects"
    [messagesTable]="config.dictionary.messagesTable | translate"
  ></u-grid>
</div>

<div class="areas__map">
  <u-here-map
    class="u-here-map_app-areas"
    [class.u-here-map_add-edit-polygons]="activePolygonId || activePolygonId === 0"
    [center]="config.map.center"
    [zoom]="config.map.zoom"
  >
    <u-here-map-markers-cluster
      *ngIf="markers.length"
      [markers]="markers"
    ></u-here-map-markers-cluster>
    <u-here-map-polygons
      [polygons]="polygons"
      [polygonId]="activePolygonId"
      [mode]="uHereMapsPolygonsMode.Manual"
      (saveAction)="savePolygon($event)"
      (selectPolygon)="selectPolygon($event)"
      (polygonChange)="polygonChange($event)"
      (redoAction)="trackEvent('Map header - click on \'Redo\' button')"
      (undoAction)="trackEvent('Map header - click on \'Undo\' button')"
      (resetAction)="trackEvent('Map header - click on \'Reset\' button')"
    ></u-here-map-polygons>
  </u-here-map>
</div>

<ng-template #cityAssignmentColumn ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
  <div
    class="areas-table-city"
    [ngClass]="{
      'areas-table-city_edit': row.selectCityOpen || !row.cityId && !(invalidAreaIndex && invalidAreaIndex !== row.index),
      'areas-table-city_disabled': invalidAreaIndex && invalidAreaIndex !== row.index
    }"
  >
    <div class="areas-table-city__value">
      {{row.cityName}}
    </div>

    <div class="areas-table-city__input">
      <u-select-s
        *ngIf="!(invalidAreaIndex && invalidAreaIndex !== row.index)"
        class="u-select-s_app-areas-table"
        [emptyName]="config.dictionary.selectCity | translate"
        [emptyRemove]="config.dictionary.removeSelect | translate"
        [emptyValue]="null"
        [items]="cities"
        [emptyHidden]="true"
        [container]="'body'"
        [valid]="showErrors || areas.at(rowIndex).get('cityId').dirty ? areas.at(rowIndex).get('cityId').valid : true"
        [placeholder]="config.dictionary.selectCity | translate"
        [messages]="config.dictionary.uSelectS | translate"
        [placement]="isRtl ? [ 'bottom-right', 'top-right' ] : [ 'bottom-left', 'top-left' ]"
        [formControl]="areas.at(rowIndex).get('cityId')"
        (click)="checkIfAllAreasValid(areas.at(rowIndex))"
        (openDropdownChange)="updateRowControl(areas.at(rowIndex), 'selectCityOpen', $event);"
        (selectItem)="selectCity($event, areas.at(rowIndex)); checkUniqueAreaName(areas.at(rowIndex))"
      ></u-select-s>
    </div>
  </div>
</ng-template>

<ng-template #areaNameColumn ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
  <div
    class="areas-table-area"
    [ngClass]="{
      'areas-table-area_edit': areas.at(rowIndex).get('cityArea.name').invalid || areas.at(rowIndex).get('cityArea.name').touched,
      'areas-table-area_disabled': activePolygonId || (invalidAreaIndex && invalidAreaIndex !== rowIndex)
    }"
  >
    <span class="areas-table-area__value">
      {{areas.at(rowIndex).get('cityArea.name').value}}
    </span>

    <div class="areas-table-area__input-container">
      <input
        uInput
        type="text"
        class="u-input_app-areas-table"
        [maxlength]="'50'"
        [valid]="((showErrors || areas.at(rowIndex).get('cityArea.name').dirty) ? areas.at(rowIndex).get('cityArea.name').valid : true)"
        [formControl]="areas.at(rowIndex).get('cityArea.name')"
        [placeholder]="config.dictionary.areaName | translate"
        (change)="onAreaNameChange(areas.at(rowIndex))"
        (focus)="areas.at(rowIndex).get('cityArea.name').markAsTouched()"
        (blur)="areas.at(rowIndex).get('cityArea.name').markAsUntouched()"
        (click)="checkIfAllAreasValid(areas.at(rowIndex))"
      >
      <span class="areas-table-area__error" *ngIf="areas.at(rowIndex).get('cityArea.name').errors && areas.at(rowIndex).get('cityArea.name').errors['existingArea']">
        {{config.dictionary.existingArea | translate}}
      </span>
    </div>

    <div
      *ngIf="!control.disabled"
      class="areas-table-area__map-icon"
      [ngClass]="{
        'areas-table-area__map-icon_active': row.index === activePolygonId,
        'areas-table-area__map-icon_invalid': showErrors && areas.at(rowIndex).get('cityArea.polygon').invalid
      }"
      (click)="checkIfAllAreasValid(areas.at(rowIndex)); (invalidAreaIndex ? invalidAreaIndex === row.index : true) ? editPolygon(areas.at(rowIndex)) : null"
    ></div>
    <div *ngIf="!control.disabled" class="areas-table-area__trash-icon u-grid-row-visible" (click)="deleteConfirmArea(areas.at(rowIndex))"></div>
  </div>
</ng-template>

<u-side-modal
  *ngIf="showExplanationModal && explanationModalConfig"
  [config]="explanationModalConfig"
  [class]="'u-side-modal_app-cities-areas'"
  (modalOpen)="$event.manualOpen && trackEvent('click on Explanation button')"
  (modalBodyButtonClick)="onExplanationButtonClick($event)"
></u-side-modal>

<ng-template #explanationModalBody>
  <div class="areas-explanation-text" *ngFor="let line of config.dictionary.explanationText">
    {{line | translate}}
  </div>
</ng-template>
