<div
  class="builder-full-move-passengers__close"
  [class.builder-full-move-passengers__close_disabled]="builderFullMovePassengersDataService.isLoading()"
  (click)="close()"
>
  <div class="u-icon u-icon_pointer u-icon_app-close-move-passengers"></div>
</div>
<div class="builder-full-move-passengers__container">
  <div class="builder-full-move-passengers__main">
    <div class="builder-full-move-passengers__blocks" cdkDropListGroup>
      <div class="builder-full-move-passengers__block builder-full-move-passengers__block_passengers"
        [ngClass]="{ 'builder-full-move-passengers__block_with-border': passengers.length }"
      >
        <div class="builder-full-move-passengers__search">
          <div class="builder-full-move-passengers__search-header">
            <div class="builder-full-move-passengers__search-name">
              <span class="builder-full-move-passengers__search-info">{{route.number}}</span>
              <span
                class="builder-full-move-passengers__search-info builder-full-move-passengers__search-info_name"
                uTooltip="{{route.name}}"
                [placement]="isRtl ? [ 'top-right' ] : [ 'top-left' ]"
                [tooltipClass]="'u-tooltip_app-builder-full-move-passengers'"
                [container]="'body'"
              >
                {{route.name}}
              </span>
            </div>
            <div class="builder-full-move-passengers__search-result">({{passengersSelected.length}} {{config.dictionary.selectedPassengers | translate}})</div>
          </div>
          <input
            class="builder-full-move-passengers__search-field"
            uInput
            [placeholder]="config.dictionary.searchPassengers | translate"
            type="text"
            [(ngModel)]="searchPassengers"
          >
        </div>
        <div class="builder-full-move-passengers__info">
          <div class="builder-full-move-passengers__check-all">
            <u-checkbox 
              class="u-checkbox_box u-checkbox_app-builder-full-move-passengers" 
              [ngModel]="passengers | movePassengersSearch: searchPassengers : config.passengersSearchFields | movePassengersCheckAll"
              (click)="toggleCheckAllPassengers()"
            ></u-checkbox>
            <div class="builder-full-move-passengers__check-all-name">{{config.dictionary.allPassengers | translate}}</div>
          </div>
        </div>
        <div
          class="builder-full-move-passengers__items"
          cdkDropList
        >
          <div
            *ngFor="let passenger of passengers | movePassengersSearch: searchPassengers : config.passengersSearchFields"
            class="builder-full-move-passengers__item"
            cdkDrag
            (cdkDragStarted)="dragPassengersStarted($event, passenger)"
            (cdkDragEnded)="dragPassengersEnded()"
            (cdkDragDropped)="droppedPassengers($event)"
          >
            <div class="builder-full-move-passengers__item-wrapper" *ngIf="!draggingPassengers || !passenger.check">
              <div class="builder-full-move-passengers__item-checkbox">
                <u-checkbox 
                  class="u-checkbox_box u-checkbox_app-builder-full-move-passengers" 
                  [ngModel]="passenger.check"
                  (click)="updateCheckForPassenger(passenger.id, !passenger.check)"
                ></u-checkbox>
              </div>
              <div class="builder-full-move-passengers__item-main">
                <div
                  class="builder-full-move-passengers__item-name"
                  uTooltip="{{passenger.name}}"
                  [placement]="isRtl ? [ 'top-right' ] : [ 'top-left' ]"
                  [tooltipClass]="'u-tooltip_app-builder-full-move-passengers'"
                  [container]="'body'"
                >
                  {{passenger.name}}
                </div>
                <div
                  class="builder-full-move-passengers__item-address"
                  uTooltip="{{passenger.stationAddress}}"
                  [placement]="isRtl ? [ 'top-right' ] : [ 'top-left' ]"
                  [tooltipClass]="'u-tooltip_app-builder-full-move-passengers'"
                  [container]="'body'"
                >
                  {{passenger.stationAddress}}
                </div>
                <div
                  class="builder-full-move-passengers__item-destination"
                  uTooltip="{{passenger.targetName}}"
                  [placement]="isRtl ? [ 'top-right' ] : [ 'top-left' ]"
                  [tooltipClass]="'u-tooltip_app-builder-full-move-passengers'"
                  [container]="'body'"
                >
                  {{passenger.targetName}}
                </div>
              </div>
              <div *cdkDragPreview>
                <div
                  class="builder-full-move-passengers__dnd-preview"
                  [translate]="config.dictionary.moveAmountPassengers"
                  [translateParams]="{ amount: passengersSelected.length || 1 }"
                ></div>
              </div>
            </div>
          </div>
          <div
            *ngIf="!(passengers | movePassengersSearch: searchPassengers : config.passengersSearchFields).length"
            class="builder-full-move-passengers__message"
          >
            {{(searchPassengers === '' ? config.dictionary.allDone : config.dictionary.noResults) | translate}}
          </div>
        </div>
      </div>
      <div class="builder-full-move-passengers__block builder-full-move-passengers__block_routes">
        <div class="builder-full-move-passengers__search">
          <div class="builder-full-move-passengers__search-header">
            <div class="builder-full-move-passengers__search-name builder-full-move-passengers__search-name_routes-direction">
              <div class="builder-full-move-passengers__search-name-routes">
                {{config.dictionary.routes | translate}}
              </div>
              <div class="builder-full-move-passengers__search-name-direction">
                {{(route.direction === direction.Forward ? config.dictionary.forward : config.dictionary.backward) | translate}}
              </div>
            </div>
            <div class="builder-full-move-passengers__search-result">
              ({{(routes | filter: { name: 'name', value: searchRoutes }).length}} {{config.dictionary.routesAmount | translate}})
            </div>
          </div>
          <input
            class="builder-full-move-passengers__search-field"
            uInput
            [placeholder]="config.dictionary.searchRoutes | translate"
            type="text"
            [(ngModel)]="searchRoutes"
          >
        </div>
        
        <div class="builder-full-move-passengers__info builder-full-move-passengers__info_routes">
          {{config.dictionary.clickOnMapIcon | translate}}
        </div>
        <div class="builder-full-move-passengers__routes">
          <div
            *ngFor="let route of routes | movePassengersSearch: searchRoutes : config.routesSearchFields"
            class="builder-full-move-passengers__route"
            [ngClass]="{
              'builder-full-move-passengers__route_purple': route.color === movePassengersRouteColor.Purple,
              'builder-full-move-passengers__route_green': route.color === movePassengersRouteColor.Green,
              'builder-full-move-passengers__route_blue': route.color === movePassengersRouteColor.Blue
            }"
            cdkDropList
            (cdkDropListDropped)="droppedPassengersIntoRoute($event, route.routeId)"
          >
            <div class="builder-full-move-passengers__route-container">
              <div 
                class="builder-full-move-passengers__route-icon"
                [ngClass]="{
                  'builder-full-move-passengers__route-icon_disabled': routes | movePassengersDisabledRouteMapIcon: route
                }"
              >
                <div 
                  class="u-icon u-icon_pointer"
                  [ngClass]="{
                    'u-icon_app-map': !route.color && !(routes | movePassengersDisabledRouteMapIcon: route),
                    'u-icon_app-map-disabled': routes | movePassengersDisabledRouteMapIcon: route,
                    'u-icon_app-map-purple': route.color === movePassengersRouteColor.Purple,
                    'u-icon_app-map-green': route.color === movePassengersRouteColor.Green,
                    'u-icon_app-map-blue': route.color === movePassengersRouteColor.Blue
                  }" 
                  (click)="clickOnStationMap(route)"
                ></div>
              </div>
              <div class="builder-full-move-passengers__route-main">
                <div class="builder-full-move-passengers__route-title">
                  <span class="builder-full-move-passengers__route-number">{{route.number}}</span>
                  <span
                    class="builder-full-move-passengers__route-name"
                    uTooltip="{{route.name}}"
                    [placement]="isRtl ? [ 'top-right' ] : [ 'top-left' ]"
                    [tooltipClass]="'u-tooltip_app-builder-full-move-passengers'"
                    [container]="'body'"
                  >
                    {{route.name}}
                  </span>
                </div>
                <div class="builder-full-move-passengers__route-details">
                  <div class="builder-full-move-passengers__route-details-time">
                    {{route.startTime | momentConvert : appConstants.TIME_FORMAT_FULL : appConstants.TIME_FORMAT}} -
                    {{route.endTime | momentConvert : appConstants.TIME_FORMAT_FULL : appConstants.TIME_FORMAT}}
                    <span class="builder-full-move-passengers__route-divider">|</span>
                  </div>
                  <div class="builder-full-move-passengers__route-details-days">
                    {{route.activeDays | obData: (config.dictionary.shortDays | translate)}}
                    <span class="builder-full-move-passengers__route-divider">|</span>
                  </div>
                  <div class="builder-full-move-passengers__route-details-passengers">
                    {{route.totalPassengers}} {{config.dictionary.passengers | translate}}
                    <span class="builder-full-move-passengers__route-divider">|</span>
                  </div>
                  <div class="builder-full-move-passengers__route-details-car">
                    {{route.vehicleTypeName ? route.vehicleTypeName : config.dictionary.noCar | translate}}
                  </div>
                </div>
              </div>
            </div>
            <div class="builder-full-move-passengers__route-participants">
              <div
                *ngFor="let participant of route.participants"
                class="builder-full-move-passengers__route-participant"
                [ngClass]="{ 'builder-full-move-passengers__route-participant_destination': participant.type === movePassengersRouteParticipantType.Target }"
              >
                <div
                  class="builder-full-move-passengers__route-participant-name"
                  uTooltip="
                    {{participant.amount === null ? participant.name : (config.dictionary.anonymousPassenger | translate)}} 
                    {{participant.amount}} {{participant.type === movePassengersRouteParticipantType.Accompany && participant.isAnonymous ? (config.dictionary.anonymousAccompany | translate) : null}}
                  "
                  [placement]="isRtl ? [ 'top-right' ] : [ 'top-left' ]"
                  [tooltipClass]="'u-tooltip_app-builder-full-move-passengers'"
                  [container]="'body'"
                >
                  {{participant.amount === null ? participant.name : (config.dictionary.anonymousPassenger | translate)}} {{participant.amount}}
                  {{participant.type === movePassengersRouteParticipantType.Accompany && participant.isAnonymous ? (config.dictionary.anonymousAccompany | translate) : null}}
                </div>
                <div
                  class="builder-full-move-passengers__route-participant-address"
                  [uTooltip]="participant.address"
                  [placement]="isRtl ? [ 'top-right' ] : [ 'top-left' ]"
                  [tooltipClass]="'u-tooltip_app-builder-full-move-passengers'"
                  [container]="'body'"
                >
                  {{participant.address}}
                </div>
              </div>
            </div>
          </div>
          <div
            *ngIf="!(routes | movePassengersSearch: searchRoutes : config.routesSearchFields).length"
            class="builder-full-move-passengers__message"
          >
            {{(searchRoutes === '' ? config.dictionary.noAvailableRoutes : config.dictionary.noResults) | translate}}
          </div>
        </div>
      </div>
    </div>
    <div class="builder-full-move-passengers__action">
      <button
        uButton
        class="u-font-weight-medium"
        [disabled]="builderFullMovePassengersDataService.isLoading()"
        [size]="uButtonSize.Small"
        (click)="close()"
      >
        {{config.dictionary.close | translate}}
      </button>
    </div>
  </div>
  <div class="builder-full-move-passengers__map">
    <div class="builder-full-move-passengers__map-header">
      <app-move-passengers-info
        *ngIf="builderFullMovePassengersDataService.infoSourceRoute"
        [sourceRoute]="builderFullMovePassengersDataService.infoSourceRoute"
        [routes]="builderFullMovePassengersDataService.infoRoutes"
        (routeDeleteIconClick)="builderFullMovePassengersDataService.updateRouteColor($event)"
      ></app-move-passengers-info>
    </div>
    <u-here-map
      [center]="builderFullMovePassengersDataService.mapData.coords"
      [zoom]="builderFullMovePassengersDataService.mapData.zoom"
      [bounds]="builderFullMovePassengersDataService.mapData.bounds"
    >
      <u-here-map-point-center
        [position]="builderFullMovePassengersDataService.mapData.fitCenter.position"
      ></u-here-map-point-center>
      <u-here-map-point-center
        [position]="builderFullMovePassengersDataService.mapData.markerCenter.position"
        [config]="builderFullMovePassengersDataService.mapData.markerCenter.config"
      ></u-here-map-point-center>
      <u-here-map-marker
        *ngFor="let marker of builderFullMovePassengersDataService.mapData.markers.stations"
        [config]="marker"
        [draggable]="false"
      ></u-here-map-marker>
      <u-here-map-path
        *ngFor="let path of builderFullMovePassengersDataService.mapData.paths"
        [config]="path"
        [draggable]="false"
      ></u-here-map-path>
    </u-here-map>
  </div>
</div>
