import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  HostBinding,
  inject,
  input,
  OnInit,
  signal
} from '@angular/core';
import { cloneDeep } from 'lodash';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { filter, switchMap } from 'rxjs/operators';

import { SharedModule } from '@app/shared/shared.module';
import { TablePageService } from '@app/shared/services';
import { GridColumn } from '@app/shared/models';
import { AiChatService } from '@app/ai-chat/services';
import { aiChatSelectedConversationViewTableComponentConfig } from './ai-chat-selected-conversation-view-table.component.config';

@Component({
  selector: 'app-ai-chat-selected-conversation-view-table',
  templateUrl: './ai-chat-selected-conversation-view-table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  providers: [ TablePageService ],
  imports: [
    SharedModule
  ]
})
export class AiChatSelectedConversationViewTableComponent implements OnInit {
  readonly tableId = input<number>();

  @HostBinding('class') hostClasses: string = 'ai-chat-selected-conversation-view-table';

  private readonly destroyRef = inject(DestroyRef);
  private readonly tablePageService = inject(TablePageService);
  private readonly aiChatService = inject(AiChatService);

  readonly #config = signal(cloneDeep(aiChatSelectedConversationViewTableComponentConfig));

  readonly config = this.#config.asReadonly();

  readonly tableId$ = toObservable(this.tableId);

  ngOnInit() {
    this.tableId$
      .pipe(
        filter(data => !!data),
        switchMap(tableId => this.aiChatService.getTable(tableId)),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(table => {
        const columns = table.columns;
        const rows = table.rows;
        const maxTextLengthByColumn: Record<string, number> = columns.reduce((acc, column) => ({
          ...acc,
          [column.prop]: Math.max(...rows.map(row => (row[column.prop] ? row[column.prop].toString().length : 0)))
        }), {});
        const maxTextLengthByColumnTotal = Object.values(maxTextLengthByColumn).reduce((acc, value) => acc + value, 0);

        this.updateColumns(
          columns.map(column => ({
            ...column,
            width: Math.round(maxTextLengthByColumn[column.prop] / maxTextLengthByColumnTotal * 100) + 20
          }))
        );

        this.tablePageService.initRows(rows);
      });
  }

  private updateColumns(columns: GridColumn[]) {
    this.#config.set({
      ...this.config(),
      tablePageConfig: {
        ...this.config().tablePageConfig,
        tableConfig: {
          ...this.config().tablePageConfig.tableConfig,
          columns: columns.map(column => ({ ...column, minWidth: 150, sortable: true, filterType: 'text' }))
        }
      }
    });
  }
}
