import { Component, OnInit, HostBinding, Output, EventEmitter, signal, inject } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { cloneDeep } from 'lodash';

import { ModalActions } from '@app/shared/models';
import { builderAskComponentConfig } from './builder-ask.component.config';

@Component({
  selector: 'app-builder-ask',
  templateUrl: './builder-ask.component.html',
  styleUrls: [ './builder-ask.component.scss', './builder-ask.component.rtl.scss' ]
})
export class BuilderAskComponent implements OnInit {
  @Output() action = new EventEmitter();

  @HostBinding('class') hostClasses: string = 'builder-ask';

  private readonly bsModalRef = inject(BsModalRef);

  readonly #config = signal(cloneDeep(builderAskComponentConfig));

  readonly config = this.#config.asReadonly();

  message = '';
  checkboxes = [];
  checkboxesStore = [];
  dependencies = [];

  ngOnInit() {
    this.checkboxesStore = this.checkboxes.map(obj => ({ ...obj }));
  }

  show(item: any) {
    const dependency = this.dependencies.find(obj => obj.source === item.value);

    if (dependency) {
      return this.checkboxesStore.some(obj => obj.value === dependency.from && obj.check);
    }

    return true;
  }

  close() {
    this.bsModalRef.hide();

    this.action.emit({ type: ModalActions.Close, content: { checkboxes: this.checkboxes } });
  }

  apply() {
    this.bsModalRef.hide();

    this.action.emit({ type: 'apply', content: { checkboxes: this.checkboxesStore } });
  }
}
